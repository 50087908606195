import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Chip,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Switch,
  TextField,
  ListItem,
  List,
  MenuItem,
  Tooltip,
  IconButton,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import TuneIcon from '@mui/icons-material/Tune';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Search } from '@mui/icons-material';
import NewProduct from './newProduct';
import EditProduct from './editProduct';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import AssetClassSelect from '../../../components/inputs/assetClassSelect';
import { formatMoneyValue } from '../../../util';
import { usePageState } from '../../../util/usePageState';
import { textColumnStyles } from '../../../util/styles';
import { colors } from '../../../theme/colors';
import { Exchanges } from '../../../interfaces/financialProduct';

export const FETCH_FINANCIAL_PRODUCTS = gql`
  query fetchFinancialProducts($input: FetchFinancialProductsInput!) {
    fetchFinancialProducts(input: $input) {
      financialProducts {
        id
        url
        translatedName {
          en
          fr
        }
        ticker
        exchange
        currentPriceCents
        autoUpdatePrices
        primaryAssetClass {
          id
          translatedName {
            en
          }
        }
        secondaryAssetClass {
          id
          translatedName {
            en
          }
        },
        tertiaryAssetClass {
          id
          translatedName {
            en
          }
        },
        substituteFinancialProduct {
          id
          ticker
          translatedName {
            en
          }
        },
        schedule{
          id
          name
          nextDate
        }
        waivedFeePercentage
        taxRank
        volatile
        settlementDays,
        state
        isPartial
        cusip
        isin
        ric
      }
      totalCount
    }
  }
`;

const ProductShelf = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['configureModels']);
  const [productOpen, setProductOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [page, setPage] = usePageState(1, 'page');
  const [showFilters, setShowFilters] = usePageState(false, 'filters');
  const pageSize = 15;
  const [filterByExchange, setFilterByExchange] = usePageState('', 'exchange');
  const [filterByPrimaryAssetClassId, setFilterByPrimaryAssetClassId] = usePageState('', 'primaryClass');
  const [filterBySecondaryAssetClassId, setFilterBySecondaryAssetClassId] = usePageState('', 'secondaryClass');
  const [filterByHasSubstituteProduct, setFilterByHasSubstituteProduct] = usePageState(false, 'hasSubstitute');
  const [filterByIsScheduled, setFilterByIsScheduled] = usePageState(false, 'isScheduled');
  const [filterByIsAutoUpdatePrices, setFilterByIsAutoUpdatePrices] = usePageState(false, 'isAutoUpdatePrices');
  const [searchString, setSearchString] = usePageState('', 'q');
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_FINANCIAL_PRODUCTS, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          searchText: searchString,
          exchange: !showFilters ? undefined : filterByExchange || undefined,
          primaryAssetClassId: !showFilters ? undefined : filterByPrimaryAssetClassId || undefined,
          secondaryAssetClassId: !showFilters ? undefined : filterBySecondaryAssetClassId || undefined,
          hasSubstituteProduct: !showFilters ? undefined : filterByHasSubstituteProduct || undefined,
          isScheduled: !showFilters ? undefined : filterByIsScheduled || undefined,
          autoUpdatePrices: !showFilters ? undefined : filterByIsAutoUpdatePrices || undefined,
        },
        pagination: {
          sortField: 'ticker', sortDesc: false, perPage: pageSize, offset: (page - 1) * pageSize,
        },
      },
    },
  });

  const exchanges = Object.keys(Exchanges).filter((key) => isNaN(Number(key))).sort();

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ overflowX: 'auto' }}>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <TextField
                label={t('search')}
                sx={{ width: '300px', m: 1 }}
                size='small'
                value={searchString}
                onChange={(event) => {
                  setSearchString(event.target.value);
                  setPage(1);
                }}
                id='searchText'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
                <Tooltip title={showFilters ? t('filters.clearFilters') : t('filters.moreFilters')} placement="right">
                  <IconButton sx={{ cursor: 'pointer', mt: 1 }} onClick={() => {
                    setShowFilters(!showFilters);
                    setFilterByExchange('');
                    setFilterByPrimaryAssetClassId('');
                    setFilterBySecondaryAssetClassId('');
                    setFilterByHasSubstituteProduct(false);
                    setFilterByIsScheduled(false);
                    setFilterByIsAutoUpdatePrices(false);
                  }} >
                    <TuneIcon />
                  </IconButton>
                </Tooltip>
            </Grid>
            {permissions.includes('write:financial_products') && (
              <Grid item sx={{ marginRight: 1 }}>
                <NewProduct afterCreate={refetch} />
              </Grid>
            )}
          </Grid>
          {showFilters && (<Grid container justifyContent='space-between'>
            <Grid item>
              <Grid container>
                <Grid item>
                  <List sx={{ minWidth: '150px' }}>
                    <ListItem>
                      <TextField select label={t('filters.exchange')} fullWidth
                        value={filterByExchange || ''}
                        onChange={(e) => {
                          setFilterByExchange(e.target.value);
                          setPage(1);
                        }}
                      >
                        {exchanges.map((option) => (
                         <MenuItem key={option} value={option}>
                          {option}
                         </MenuItem>
                        ))}
                      </TextField>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item>
                  <List sx={{ minWidth: '250px' }}>
                    <ListItem>
                      <AssetClassSelect
                        value={filterByPrimaryAssetClassId}
                        setAssetClass={(e) => {
                          setFilterByPrimaryAssetClassId(e.target.value);
                          setPage(1);
                        }}
                        label={t('filters.primaryAssetClass')}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item>
                  <List sx={{ minWidth: '250px' }}>
                    <ListItem>
                      <AssetClassSelect
                        value={filterBySecondaryAssetClassId}
                        setAssetClass={(e) => {
                          setFilterBySecondaryAssetClassId(e.target.value);
                          setPage(1);
                        }}
                        label={t('filters.secondaryAssetClass')}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item>
                  <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <FormControlLabel
                      control={<Switch
                        checked={filterByHasSubstituteProduct}
                        onChange={async (event) => {
                          setFilterByHasSubstituteProduct(event.target.checked);
                          setPage(1);
                        }}
                      />}
                      label={t('filters.hasSubstitute')}
                    />
                  </FormGroup>
                </Grid>
                <Grid item>
                  <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <FormControlLabel
                      control={<Switch
                        checked={filterByIsScheduled}
                        onChange={async (event) => {
                          setFilterByIsScheduled(event.target.checked);
                          setPage(1);
                        }}
                      />}
                      label={t('filters.isScheduled')}
                    />
                  </FormGroup>
                </Grid>
                <Grid item>
                  <FormGroup sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <FormControlLabel
                      control={<Switch
                        checked={filterByIsAutoUpdatePrices}
                        onChange={async (event) => {
                          setFilterByIsAutoUpdatePrices(event.target.checked);
                          setPage(1);
                        }}
                      />}
                      label={t('filters.autoUpdatePrices')}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>)}
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('table.ticker')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.exchange')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.primaryAssetClass')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.secondaryAssetClass')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.substituteProduct')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.taxRank')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.tradeSchedule')}</Typography></TableCell>
                <TableCell align='right'><Typography variant='overline'>{t('table.currentPrice')}</Typography></TableCell>
                <TableCell align='right'><Typography variant='overline'>{t('table.autoUpdatePrices')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchFinancialProducts?.financialProducts?.map((product: any) => (
                <TableRow
                  hover
                  onClick={() => {
                    if (permissions.includes('write:financial_products')) {
                      setSelectedProduct(product);
                      setProductOpen(true);
                    }
                  }}
                  key={product.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                >
                  <TableCell component="th" scope="row">
                    {product.ticker}
                  </TableCell>
                  <TableCell>
                    <Typography sx={textColumnStyles}>{product.translatedName.en}</Typography>
                  </TableCell>
                  <TableCell><Chip size='small' label={product.exchange} /></TableCell>
                  <TableCell><Typography sx={textColumnStyles}>{product.primaryAssetClass?.translatedName?.en}</Typography></TableCell>
                  <TableCell><Typography sx={textColumnStyles}>{product.secondaryAssetClass?.translatedName?.en}</Typography></TableCell>
                  <TableCell title={product.substituteFinancialProduct?.translatedName?.en}>{product.substituteFinancialProduct?.ticker}</TableCell>
                  <TableCell component="th" scope="row">
                    { product.taxRank && (<Chip label={product.taxRank} size='small' sx={{ background: colors.black, color: colors.white, mr: 1 }} />) }
                  </TableCell>
                  <TableCell>{product?.schedule ? t('table.planned') : t('table.noSchedule')}</TableCell>
                  <TableCell align='right'>{formatMoneyValue(product.currentPriceCents)}</TableCell>
                  <TableCell align='right'>{product?.autoUpdatePrices ? t('table.yes') : t('table.no')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchFinancialProducts?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
          {permissions.includes('write:financial_products') && (
            <EditProduct afterUpdate={() => {
              setProductOpen(false);
              refetch();
            }} productToUpdate={selectedProduct} open={productOpen} handleClose={() => setProductOpen(false)} />
          )}
        </>
      )}
    </Box>
  );
};

export default ProductShelf;
