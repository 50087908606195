import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProductShelf from './components/productShelf';

const ProductShelfPage = () => {
  useNavigate();

  return (
    <Paper sx={{ width: '100%', typography: 'body1' }}>
      <ProductShelf />
    </Paper>
  );
};

export default ProductShelfPage;
