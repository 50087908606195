import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { isNil, isUndefined } from 'lodash/fp';
import { UserContext } from 'providers/userContextProvider';
import { CustomField, CustomFieldWorkflowSteps, FETCH_CUSTOM_FIELDS } from 'interfaces/customField';
import {
  Button, Dialog, DialogContent, DialogTitle, Form, IconButton, MenuItem, SelectField,
} from '../../../2-component';
import { Box, Typography } from '../../../1-primative';
import { WidgetInterface } from '../pageConfiguration';
import { initialConfigs } from './initialConfigs';
import { ObjectType } from '../../../../providers/statsHooks';
import { EditField, FieldTypes } from '../../../4-module/configurableOptionFields/optionEditComponent';
import { CustodianConnection } from '../../../../interfaces/custodianConnection';

const WIDGET_TYPES: any = {
  INDIVIDUAL: [
    'MARKET_VALUE_CHART',
    'ACCOUNTS',
    'GOALS',
    'GOALS_AND_ACCOUNTS',
    'HOLDINGS',
    'DOCUMENTS',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'PERSONAL_INFORMATION',
    'RELATIONSHIP_INFORMATION',
    'EMPLOYMENT_INFORMATION',
    'RESIDENCY_INFORMATION',
    'PERSONS_OF_INTEREST',
    'INCOME_ASSETS_AND_DEBTS',
    'SUITABILITY',
    'ID_VERIFICATION',
    'NEWS_AND_INSIGHTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'ACCESS',
    'ADVISOR_CONTACT_INFORMATION',
    'NOTES',
    'AUDIT_LOG',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'COMPLIANCE',
    'REVIEW_BANK_ACCOUNT_INFORMATION',
    'TRANSFERS',
  ],
  NON_INDIVIDUAL: [
    'MARKET_VALUE_CHART',
    'ACCOUNTS',
    'GOALS',
    'GOALS_AND_ACCOUNTS',
    'HOLDINGS',
    'DOCUMENTS',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'NON_INDIVIDUAL_INFORMATION',
    'RESIDENCY_INFORMATION',
    'PERSONS_OF_INTEREST',
    'SUITABILITY',
    'ID_VERIFICATION',
    'NEWS_AND_INSIGHTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'ACCESS',
    'ADVISOR_CONTACT_INFORMATION',
    'NOTES',
    'AUDIT_LOG',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'COMPLIANCE',
    'REVIEW_BANK_ACCOUNT_INFORMATION',
    'TRANSFERS',
    'RELATIONSHIPS',
  ],
  GOAL: [
    'MARKET_VALUE_CHART',
    'HOLDINGS',
    'LEDGER',
    'GOAL_SUITABILITY',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'SUB_ACCOUNTS',
    'REFINITIV',
    'PORTFOLIO',
    'TRANSFERS',
  ],
  ACCOUNT: [
    'MARKET_VALUE_CHART',
    'HOLDINGS',
    'DOCUMENTS',
    'EDIT_ACCOUNT',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'SUB_ACCOUNTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'PROJECTED_INCOME_REPORT',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'TRANSFERS',
  ],
  SUB_ACCOUNT: [
    'MARKET_VALUE_CHART',
    'HOLDINGS',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'NEWS_AND_INSIGHTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'PORTFOLIO',
    'TRANSFERS',
  ],
  HOUSEHOLD: [
    'MARKET_VALUE_CHART',
    'ACCOUNTS',
    'GOALS',
    'GOALS_AND_ACCOUNTS',
    'HOLDINGS',
    'DOCUMENTS',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'NEWS_AND_INSIGHTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'ADVISOR_CONTACT_INFORMATION',
    'PROJECTED_INCOME_REPORT',
    'NOTES',
    'AUDIT_LOG',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'HOUSE_HOLD_MEMBERS',
    'TRANSFERS',
  ],
};

const FETCH_WIDGET_OPTIONS = gql`
  query fetchWidgetOptions($widgetType: WidgetConfigurationTypes!) {
    fetchWidgetOptions(widgetType: $widgetType) {
      widgetOptions {
        default
        key
        options
        showIf
        requiredIf
        showWith
        showCustomField
        customFieldFilter
        optionType
        info {
          en
          fr
        }
        showBasedOnCustodianConnection
        removeFromPages
        label {
          en
          fr
        }
      }
    }
  }
`;

const availavleCustomFieldsSteps = [
  CustomFieldWorkflowSteps.PERSONAL_INFORMATION,
  CustomFieldWorkflowSteps.PERSONS_OF_INTEREST,
  CustomFieldWorkflowSteps.NON_INDIVIDUAL_INFORMATION,
  CustomFieldWorkflowSteps.EMPLOYMENT_INFORMATION,
  CustomFieldWorkflowSteps.DOCUMENTS,
  CustomFieldWorkflowSteps.EDIT_ACCOUNT,
];

export const NewWidget = ({
  setWidgets, widgets, objectType, activeWidget = { type: '', options: {} }, index, open, setOpen, onSave,
}: {
  widgets: WidgetInterface[]; setWidgets: (layout: any) => void; objectType: ObjectType, activeWidget?: WidgetInterface, index?: number,
  open: boolean, setOpen: (o: boolean) => void, onSave: () => void,
}) => {
  const { t } = useTranslation('pageConfiguration');
  const { custodianConnection } = useContext(UserContext);
  const { activeOrganization } = useContext(UserContext);
  const [options, setOptions] = useState<any>([]);
  const [widget, setWidget] = useState<WidgetInterface>(activeWidget ?? {
    type: '',
    options: {},
  });

  useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: widget.type,
          organizationId: activeOrganization?.id ?? undefined,
        },
      },
    },
    skip: !widget.type || !availavleCustomFieldsSteps.includes(widget.type as CustomFieldWorkflowSteps),
    fetchPolicy: 'no-cache',
    onCompleted: async (res: any) => {
      const customOptions: any[] = [];
      if (res) {
        res?.fetchCustomFields?.customFields.forEach((customField: CustomField) => {
          const optionObj = {
            __typename: 'WidgetOption',
            label: customField.translatedName,
            key: customField.key,
            optionType: FieldTypes.BASIC_FIELD,
            options: {
              allowAdditionalInfo: true,
              isMarkdown: true,
            },
            default: {
              enabled: true,
              label: customField.translatedName,
              required: 'NOT_REQUIRED',
            },
            value: {
              enabled: true,
              label: customField.translatedName,
              required: 'NOT_REQUIRED',
              customField,
            },
          };
          customOptions.push(optionObj);
        });
      }
      if (widget.type) {
        const { data: fetchedInfo } = await fetchWidgetOptions({
          variables: {
            widgetType: widget.type,
          },
        });
        if (fetchedInfo) {
          const newOptions = [...(fetchedInfo?.fetchWidgetOptions?.widgetOptions || [])];
          newOptions.forEach((x: any, i: number) => {
            newOptions[i] = { ...x, value: (!isUndefined(widget?.options[x.key]) ? widget?.options[x.key] : x.default) };
          });
          if (JSON.stringify(options) !== JSON.stringify(newOptions)) {
            setOptions([...newOptions, ...customOptions]);
          }
        }
      }
    },
  });
  const [fetchWidgetOptions] = useLazyQuery(FETCH_WIDGET_OPTIONS, {
    variables: { widgetType: widget.type },
    fetchPolicy: 'no-cache',
  });

  const { data: widgetOptionsData } = useQuery(FETCH_WIDGET_OPTIONS, {
    variables: {
      widgetType: widget.type,
    },
    fetchPolicy: 'no-cache',
    skip: !widget.type || availavleCustomFieldsSteps.includes(widget.type as CustomFieldWorkflowSteps),
  });

  useEffect(() => {
    if (widgetOptionsData) {
      const newOptions = [...(widgetOptionsData?.fetchWidgetOptions?.widgetOptions || [])];
      const itemsToRemove: string[] = [];
      newOptions.forEach((x: any, i: number) => {
        if (x.showWith) {
          const parentOptionsIndex = newOptions.findIndex((opt) => opt.key === x.showWith);
          if (x.key.includes('MobileView')) {
            const mobileTableView = { ...x, value: (!isUndefined(widget?.options[x.key]) ? widget?.options[x.key] : x.default) };
            newOptions[parentOptionsIndex] = { ...newOptions[parentOptionsIndex], mobileTableView };
            itemsToRemove.push(x.key);
          }
        }
        newOptions[i] = { ...x, value: (!isUndefined(widget?.options[x.key]) ? widget?.options[x.key] : x.default) };
      });

      if (itemsToRemove.length > 0) {
        itemsToRemove.forEach((key) => {
          const idx = newOptions.findIndex((elem) => elem.key === key);
          newOptions.splice(idx, 1);
        });
      }
      if (JSON.stringify(options) !== JSON.stringify(newOptions)) {
        setOptions(newOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetOptionsData, widget, open]);

  useEffect(() => {
    if (options) {
      const newFormattedOptions: any = {};
      options.forEach((x: any) => {
        newFormattedOptions[x.key] = x.value;
        if (x.mobileTableView) {
          const { key } = x.mobileTableView;
          newFormattedOptions[key] = x.mobileTableView.value;
        }
      });
      if (JSON.stringify(widget.options) !== JSON.stringify(newFormattedOptions)) {
        setWidget({ ...widget, options: newFormattedOptions });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    setWidget(activeWidget);
    if (activeWidget.type === '') {
      setOptions([]);
    }
  }, [activeWidget, open]);

  const handleCustodianOption = (showBasedOnCustodianConnection: any[] | undefined) => {
    if (isNil(showBasedOnCustodianConnection)) return true;
    if (!custodianConnection) return false;
    return showBasedOnCustodianConnection.some((elem: any) => Object.values(elem)[0] === custodianConnection[Object.keys(elem)[0] as keyof CustodianConnection]);
  };

  const reset = () => {
    setWidget({ type: '', options: {} });
    setOptions([]);
    setOpen(false);
  };

  const onSubmit = () => {
    if (!widget.options) {
      widget.options = initialConfigs[widget.type];
    }
    const newWidgets = [...widgets, widget];

    const translatedStringOptions = options.filter((elem: any) => elem.optionType === FieldTypes.TRANSLATED_STRING);
    const validateTranslatedStringOptions = translatedStringOptions.every((elem: any) => {
      if (elem.requiredIf) {
        const key = Object.keys(elem.requiredIf)[0];
        const value = Object.values(elem.requiredIf)[0];
        if (isNil(widget.options[key]) || widget.options[key] === value) {
          if (!widget.options[elem.key]?.en) {
            return false;
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!widget.options[elem.key]?.en) return false;
      }
      return true;
    });
    if (validateTranslatedStringOptions) {
      if (typeof index === 'number') {
        const updatedWidgets = [...widgets];
        updatedWidgets[index] = widget;
        setWidgets(updatedWidgets);
      } else {
        setWidgets(newWidgets);
      }
      reset();
      onSave();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={reset} maxWidth='xs' fullWidth>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
            <Typography variant='headingSmall' sx={{ mr: 0.5 }}>
              {t('addWidget')}
            </Typography>
            <IconButton onClick={reset} aria-label='close' size='medium'>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={onSubmit}>
            <SelectField fullWidth label={t('widgetType')} value={widget.type} onChange={(e: any) => setWidget({ ...widget, type: e.target.value })}>
              {WIDGET_TYPES[objectType].map((type: string) => (
                <MenuItem key={type} value={type}>
                  {t(`widgetTypes.${type}`)}
                </MenuItem>
              ))}
            </SelectField>
            {options.length > 0 && (
              <Box mt={2}>
                <Typography variant='bodyMedium' weight='bold' mb={1}>
                  {t('options')}
                </Typography>
                {(options || []).map((x: any, i: number) => (
                  <>
                    {(isNil(x.showIf) || options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value === Object.values(x.showIf)[0])
                    && (isNil(x.removeFromPages) || !x.removeFromPages.includes(objectType))
                    && handleCustodianOption(x.showBasedOnCustodianConnection) ? (
                      <React.Fragment key={i}>
                        <EditField option={x} options={options} setOptions={setOptions} i={i} optionType={x.optionType} widgetType={widget.type} />
                      </React.Fragment>
                      ) : (
                      <></>
                      )}
                  </>
                ))}
              </Box>
            )}
            <Box display='flex' flexDirection='row' justifyContent='end'>
              <Button type='submit' disabled={!widget.type} label={t('add')} sx={{ mt: 3 }} />
            </Box>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
