import { gql } from '@apollo/client';

export const GET_ACTIVE_WORKFLOWS = gql`
  query getActiveWorkflows($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        activeWorkflowCompletions {
          id
          objectId
          objectType
          context
          workflow {
            id
            name {
              en
            }
            steps {
              id
              name {
                en
              }
              subSteps {
                id
                type
              }
            }
          }
          currentStep {
            id
            name {
              en
            }
            subSteps {
              id
              type
            }
          }
          currentSubStep {
            id
            options
            type
            canGoBack
            skippable
            rolesCompleteableBy {
              id
            }
          }
        }
      }
    }
  }
`;

export const FETCH_USER_INCOMPLETE_DOCUMENTS = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        completedAt
        allIncompleteFormAgreements {
          id
        }
      }
    }
  }
`;
