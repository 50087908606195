import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { invalidFields } from '../utils';
import {
  Button, TextField, Typography, Form, Box, Grid,
  Radio,
  RadioGroup,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const PersonsOfInterestVisual = ({
  options, userData, loading, updateUser, continueFunc, activeCustomFields, workflowCompletion, grid = false, updateMode = false,
}: {
  options: any; userData: any; loading: boolean; updateUser: any; continueFunc: any, grid?: boolean, updateMode?: boolean,
  activeCustomFields?: string[], workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation('client');
  const [updated, setUpdated] = useState(false);
  const [focused, setFocused] = useState<string[]>([]);
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);

  const update = (newValue: any) => {
    setUpdated(true);
    updateUser(newValue);
  };

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);
  const submit = () => {
    const fields = invalidFields(
      {
        ...options,
        ssSymbols: {
          ...options.ssSymbols,
          requiredIf: (data: any) => data?.isOwnerOfPublicCompany,
        },
        insiderSymbols: {
          ...options.insiderSymbols,
          requiredIf: (data: any) => data?.memberOfIiroc,
        },
        regulatorName: {
          ...options.regulatorName,
          requiredIf: (data: any) => data?.isEntityRegulated,
        },
        firmNumber: {
          ...options.firmNumber,
          requiredIf: (data: any) => data?.isEntityRegulated,
        },
        issuerName: {
          ...options.issuerName,
          requiredIf: (data: any) => data?.isReportingIssuer,
        },
        issuerExchange: {
          ...options.issuerExchange,
          requiredIf: (data: any) => data?.isReportingIssuer,
        },
        issuerTicker: {
          ...options.issuerTicker,
          requiredIf: (data: any) => data?.isReportingIssuer,
        },
        isRegisteredWithCRA: {
          ...options.isRegisteredWithCRA,
          requiredIf: (data: any) => data?.isNonProfit,
        },
        charityRegistrationNumber: {
          ...options.charityRegistrationNumber,
          requiredIf: (data: any) => data?.isRegisteredWithCRA,
        },
        receivesDonationsFromPublic: {
          ...options.receivesDonationsFromPublic,
          requiredIf: (data: any) => data?.isNonProfit,
        },
      },
      userData,
    );
    setFocused(fields);

    if (fields.length === 0) {
      sendAnalytic(
        ovAnalyticsEvents.workflowsPersonOfInterestContinueButtonSelect,
        {
          workflowStepTitle: options?.title,
          workflowId: workflowCompletion?.workflow?.id,
          workflowName: workflowCompletion?.workflow?.name,
          activeWorkflowCompletionId: workflowCompletion?.id,
          objectId: workflowCompletion?.objectId,
          objectType: workflowCompletion?.objectType,
        },
      );
      continueFunc();
    }
  };

  const FieldComponent = ({ field, children, noGrid = false }: { field: string, children?: any, noGrid?: boolean }) => {
    if (!options?.[field]?.enabled) return <></>;

    const additionalInfoOpts = options?.[field]?.additionalInfo;

    return <Grid item xs={12} md={grid && !noGrid ? 4 : 12}>
      <RadioGroup
        testId={field}
        value={userData?.[field] ? 'yes' : 'no'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { update({ ...userData, [field]: event.target.value === 'yes' }); }}
        disabled={loading}
        label={translateBackend(options?.[field]?.label)}
        infoTooltip={additionalInfo(additionalInfoOpts)}
      >
        <Radio testId={`${field}-option-no`} value={'no'} label={t('shared:no')} />
        <Radio testId={`${field}-option-yes`} value={'yes'} label={t('shared:yes')} />
      </RadioGroup>
      {children}
    </Grid >;
  };

  const ssSymbolsField = (
    <TextField
      size='small'
      data-testid="ssSymbols"
      onChange={(e: any) => update({ ...userData, ssSymbols: [e.target.value] })}
      disabled={loading}
      label={translateBackend(options?.ssSymbols?.label)}
      infoTooltip={additionalInfo(options?.ssSymbols?.additionalInfo)}
      fullWidth
      value={userData.ssSymbols ?? ''}
      onBlur={() => setFocused([...focused, 'ssSymbols'])}
      error={!userData.ssSymbols && focused.includes('ssSymbols') && options?.ssSymbols?.required !== 'NOT_REQUIRED'}
    />
  );

  const regulatedEntityDetails = userData.isEntityRegulated ? (
    <Grid item container xs={12} columnSpacing={2} rowSpacing={{ xs: 2, md: 0 }}>
      <Grid item xs={12} md={6}>
        {options?.regulatorName?.enabled && userData.isEntityRegulated && (
          <TextField
            size='small'
            data-testid="is-entity-regulated"
            onChange={(e: any) => update({ ...userData, regulatorName: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.regulatorName?.label)}
            infoTooltip={additionalInfo(options?.regulatorName?.additionalInfo)}
            fullWidth
            value={userData.regulatorName ?? ''}
            onBlur={() => setFocused([...focused, 'regulatorName'])}
            error={!userData.regulatorName && focused.includes('regulatorName') && options?.regulatorName?.required !== 'NOT_REQUIRED'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {options?.firmNumber?.enabled && userData.isEntityRegulated && (
          <TextField
            size='small'
            data-testid="firm-number"
            onChange={(e: any) => update({ ...userData, firmNumber: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.firmNumber?.label)}
            infoTooltip={additionalInfo(options?.firmNumber?.additionalInfo)}
            fullWidth
            value={userData.firmNumber ?? ''}
            onBlur={() => setFocused([...focused, 'firmNumber'])}
            error={!userData.firmNumber && focused.includes('firmNumber') && options?.firmNumber?.required !== 'NOT_REQUIRED'}
          />
        )}
      </Grid>
    </Grid>
  ) : null;

  const reportingIssuerDetails = userData.isReportingIssuer ? (
    <Grid item container columnSpacing={2} rowSpacing={{ xs: 2, md: 0 }}>
      <Grid item xs={12} md={4}>
        {options?.issuerName?.enabled && userData.isReportingIssuer && (
          <TextField
            size='small'
            data-testid="issuer-name"
            onChange={(e: any) => update({ ...userData, issuerName: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.issuerName?.label)}
            infoTooltip={additionalInfo(options?.issuerName?.additionalInfo)}
            fullWidth
            value={userData.issuerName ?? ''}
            onBlur={() => setFocused([...focused, 'issuerName'])}
            error={!userData.issuerName && focused.includes('issuerName') && options?.issuerName?.required !== 'NOT_REQUIRED'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        {options?.issuerExchange?.enabled && userData.isReportingIssuer && (
          <TextField
            size='small'
            data-testid="issuer-exchange"
            onChange={(e: any) => update({ ...userData, issuerExchange: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.issuerExchange?.label)}
            infoTooltip={additionalInfo(options?.issuerExchange?.additionalInfo)}
            fullWidth
            value={userData.issuerExchange ?? ''}
            onBlur={() => setFocused([...focused, 'issuerExchange'])}
            error={!userData.issuerExchange && focused.includes('issuerExchange') && options?.issuerExchange?.required !== 'NOT_REQUIRED'}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        {options?.issuerTicker?.enabled && userData.isReportingIssuer && (
          <TextField
            size='small'
            data-testid="issuer-ticker"
            onChange={(e: any) => update({ ...userData, issuerTicker: e.target.value })}
            disabled={loading}
            label={translateBackend(options?.issuerTicker?.label)}
            infoTooltip={additionalInfo(options?.issuerTicker?.additionalInfo)}
            fullWidth
            value={userData.issuerTicker ?? ''}
            onBlur={() => setFocused([...focused, 'issuerTicker'])}
            error={!userData.issuerTicker && focused.includes('issuerTicker') && options?.issuerTicker?.required !== 'NOT_REQUIRED'}
          />
        )}
      </Grid>
    </Grid>
  ) : null;

  const charityRegistrationNumber = options?.charityRegistrationNumber?.enabled && userData.isRegisteredWithCRA ? (
    <Grid item xs={12}>
      <TextField
        size='small'
        data-testid="charity-registration-number"
        onChange={(e: any) => update({ ...userData, charityRegistrationNumber: e.target.value })}
        disabled={loading}
        label={translateBackend(options?.charityRegistrationNumber?.label)}
        infoTooltip={additionalInfo(options?.charityRegistrationNumber?.additionalInfo)}
        fullWidth
        value={userData.charityRegistrationNumber ?? ''}
        onBlur={() => setFocused([...focused, 'charityRegistrationNumber'])}
        error={!userData.charityRegistrationNumber && focused.includes('charityRegistrationNumber') && options?.charityRegistrationNumber?.required !== 'NOT_REQUIRED'}
      />
    </Grid>
  ) : null;

  const insiderSymbols = options?.insiderSymbols?.enabled && userData.isMemberOfIiroc ? (
    <Grid item xs={12}>
      <TextField
        size='small'
        data-testid="insider-symbol"
        onChange={(e: any) => update({ ...userData, insiderSymbols: [e.target.value] })}
        disabled={loading}
        label={translateBackend(options?.insiderSymbols?.label)}
        infoTooltip={additionalInfo(options?.insiderSymbols?.additionalInfo)}
        fullWidth
        value={userData.insiderSymbols ?? ''}
        onBlur={() => setFocused([...focused, 'insiderSymbols'])}
        error={!userData.insiderSymbols && focused.includes('insiderSymbols') && options?.insiderSymbols?.required !== 'NOT_REQUIRED'}
      />
    </Grid>
  ) : null;

  const subTitleStyle = {
    display: 'inline-flex',
    alignItems: 'end',
  };

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography style={subTitleStyle} variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      <Grid container spacing={2}>
        <FieldComponent field='isEntityRegulated'>
          {regulatedEntityDetails}
        </FieldComponent>
        <FieldComponent field='isReportingIssuer'>
          {reportingIssuerDetails}
        </FieldComponent>
        <FieldComponent field='isNonProfit'>
          {userData.isNonProfit && <FieldComponent field='isRegisteredWithCRA' noGrid/>}
          {charityRegistrationNumber}
          {userData.isNonProfit && <FieldComponent field='receivesDonationsFromPublic' noGrid />}
        </FieldComponent>
        <FieldComponent field='isForThirdParty' />
        <FieldComponent field='isAFinancialEntity' />
        <FieldComponent field='isAffiliateOfFinancialEntity' />
        <FieldComponent field='instructionsFromFinancialEntity' />
        <FieldComponent field='isPublicBody' />
        <FieldComponent field='isLargeCorporation' />
        <FieldComponent field='powerOfAttorneyGranted' />
        <FieldComponent field='headOfInternationalOrganization' />
        <FieldComponent field='isOfficerOfPublicCompany'>
          {options?.ssSymbols?.enabled && userData.isOfficerOfPublicCompany && ssSymbolsField}
        </FieldComponent>
        <FieldComponent field='isOwnerOfPublicCompany'>
          {options?.ssSymbols?.enabled && userData.isOwnerOfPublicCompany && !userData.isOfficerOfPublicCompany && ssSymbolsField}
        </FieldComponent>
        <FieldComponent field='isMemberOfIiroc'>
          {insiderSymbols}
        </FieldComponent>
        <FieldComponent field='politicallyExposedForeignPerson' />
        <FieldComponent field='politicallyExposedDomesticPerson' />
        <FieldComponent field='closeAssociateOfPEP' />
        <CustomFieldRenderer
          customOptions={customOptionSelected}
          update={update}
          customData={userData}
          focused={focused}
          setFocused={setFocused}
          loading={loading}
        />
      </Grid>
      <Box display='flex' justifyContent='end'>
        <Button data-testid="continue-button" label={t(updateMode ? 'update' : 'continue')} disabled={loading || (!updated && updateMode)} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default PersonsOfInterestVisual;
