import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { colors } from 'theme/colors';
import { DissociateAccountFromHouseholdModal } from 'components/modals/dissociateAccountFromHouseholdModal';
import { AssociateAccountToHouseholdModal } from 'components/modals/associateAccountToHouseholdModal';
import UpdateTaxLosHarvestingModal from '../../../components/modals/updateTaxLostHarvestingModal';
import { generateClientNameString } from '../../../util';
import {
  Account, AccountStates, AccountTypes, ApplyForGovFunds, ClientGroup, ClientGroupTypes, getAccountName, getIconForState,
} from '../../../interfaces';
import {
  Highlight, Pill, Summary,
} from '../../../components/layout/client/summary';
import { usePermissions } from '../../../providers/userContextProvider';
import FeePaymentModal from '../../../components/modals/feePaymentModal';
import EditAccountBilling from '../../client/components/editAccountBilling';
import CloseAccountModal from '../../../components/modals/closeAccountModal';
import FormModal from '../../../components/modals/formModal';

interface SummaryProps {
  account: Account;
  onUpdateAccount: () => void,
  onAccountState: () => void,
  onUpdateAccountState: (state: string) => void
  onForceOpen: () => void
}

export function AccountSummary({
  account, onUpdateAccount, onAccountState, onUpdateAccountState, onForceOpen,
}: SummaryProps) {
  const { t } = useTranslation(['accountsDetail', 'accountTypes', 'client', 'subAccountsDetail']);
  const { permissions } = usePermissions();
  const [openFeePaymentModal, setOpenFeePaymentModal] = useState(false);
  const [editBillingAndFees, setEditBillingAndFees] = useState(false);
  const [closeAccountModal, setCloseAccountModal] = useState(false);
  const [closeAccountDisclaimer, setCloseAccountDisclaimer] = useState(false);
  const [associateAccountModal, setAssociateAccountModal] = useState<ClientGroup | null>(null);
  const [dissociateAccountModal, setDissociateAccountModal] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<string>('');
  const [openTLHModel, setOpenTLHModel] = useState(false);

  if (!account) return <></>;

  const transitions = [
    {
      name: 'ready',
      displayName: t('client:accountDetails.transitions.ready'),
      from: AccountStates.INITIATED,
    },
    {
      name: 'activate',
      displayName: t('client:accountDetails.transitions.active'),
      from: [AccountStates.REQUESTED, AccountStates.FROZEN],
    },
    {
      name: 'request',
      displayName: t('client:accountDetails.transitions.request'),
      from: [AccountStates.INITIATED],
    },
    {
      name: 'fail',
      displayName: t('client:accountDetails.transitions.fail'),
      from: [AccountStates.REQUESTED],
    },
    {
      name: 'cancel',
      displayName: t('client:accountDetails.transitions.cancel'),
      from: [AccountStates.INITIATED, AccountStates.REQUESTED, AccountStates.READY],
    },
    {
      name: 'freeze',
      displayName: t('client:accountDetails.transitions.freeze'),
      from: [AccountStates.INITIATED, AccountStates.ACTIVE, AccountStates.REQUESTED, AccountStates.READY],
    },
  ];

  const onHighlightClicked = (prop: string) => {
    setEditBillingAndFees(true);
    setSelectedHighlight(prop);
  };

  const closeAccountClicked = () => {
    const anyActiveSubAccount = account.subAccounts?.some((x) => ['ACTIVE', 'AWAITING_APPROVAL'].includes(x.state ?? ''));
    const anyScheduledDeposit = (account.statistics?.pendingContributionCents ?? 0) > 0;
    if (anyActiveSubAccount || anyScheduledDeposit) {
      setCloseAccountDisclaimer(true);
    } else {
      setCloseAccountModal(true);
    }
  };

  const showPills: Pill[] = [
    {
      label: t(`accountsDetail:accountState.${account.state}`),
      leadingIcon: getIconForState(account.state),
    },
  ];

  if (account.jurisdiction !== null) {
    showPills.push({
      label: `${('Jurisdiction')}: ${t(`accountsDetail:jurisdiction:${account.jurisdiction}`)}`,
    });
  }

  // RESP related
  if (account.applyForGovFunds?.length > 0) {
    const grantList = [];
    for (const grant of [ApplyForGovFunds.BASIC_CESG, ApplyForGovFunds.CLB, ApplyForGovFunds.ADDITIONAL_CESG]) {
      if (account.applyForGovFunds.includes(grant as ApplyForGovFunds)) grantList.push(t(`govFundOptions.${grant}`));
    }
    showPills.push({
      label: `${t('grant', { count: grantList.length })}: ${grantList.join(', ')}`,
    });
  }

  const highlights: Highlight[] = [];

  if (permissions.includes('read:client_billing')) {
    highlights.push(
      {
        leadingLabel: t('accountsDetail:feeGrid'),
        label: account?.feeTier?.name ?? t('shared:inheritedDashWhat', { what: account?.applicableFeeTier?.name }),
        onClick: permissions.includes('write:client_billing') ? () => onHighlightClicked('feeGrid') : undefined,
      },
      {
        leadingLabel: t('accountsDetail:billingCycle'),
        label: account?.billingSchedule?.frequency
          ? t(`subAccountsDetail:frequency.${account?.billingSchedule?.frequency}`)
          : t('shared:inheritedDashWhat', { what: account?.applicableBillingSchedule?.frequency }),
        onClick: permissions.includes('write:client_billing') ? () => onHighlightClicked('billingCycle') : undefined,
      },
    );
  }

  if (permissions.includes('read:account_number')) {
    const missingCustodianNumber = !account.custodianAccountNumber && ![AccountStates.REQUESTED, AccountStates.INITIATED].includes(account.state);
    highlights.push({
      icon: missingCustodianNumber ? <WarningRoundedIcon style={{ color: colors.error }} /> : <></>,
      leadingLabel: t('accountsDetail:custodianAccountNumber'),
      label: account.custodianAccountNumber ? account.custodianAccountNumber : t('accountsDetail:none'),
      onClick: permissions.includes('write:account_number') ? () => onUpdateAccount() : undefined,
    });
  }

  const feeAccount = account.feePaymentAccount ?? account;
  highlights.push({
    icon: <></>,
    leadingLabel: t('accountsDetail:feePaymentAccount'),
    label: getAccountName(feeAccount),
    onClick: () => setOpenFeePaymentModal(true),
  });

  if ([
    AccountTypes.PERSONAL,
    AccountTypes.CASH_JOINT,
    AccountTypes.CORPORATE_CASH,
    AccountTypes.CORPORATE_CHARITY,
    AccountTypes.CORPORATE_ESTATE,
    AccountTypes.CORPORATE_TRUST,
  ].includes(account.type as AccountTypes)) {
    highlights.push({
      icon: <></>,
      leadingLabel: t('accountsDetail:tlh'),
      label: t(`accountsDetail:tlhOptions.${account.tlhEnabled ?? false}`),
      onClick: () => setOpenTLHModel(true),
    });
  }

  const menu = [
    {
      label: t('client:accountDetails.updateAccNo'),
      onClick: () => onUpdateAccount(),
    },
    {
      label: t('client:accountDetails.viewStates'),
      onClick: () => onAccountState(),
    },
  ];

  if (permissions.includes('transition:account')) {
    const menuTransitions = transitions.filter((transition) => transition.from.includes(account.state))
      .map((transition: any) => ({
        label: transition.displayName,
        onClick: () => onUpdateAccountState(transition.name),
      }));
    menu.push(...menuTransitions);
  }

  if (permissions.includes('write:force_account_open') && !account.forceOpen) {
    const forceOpen = [{
      label: t('client:accountDetails.forceOpen'),
      onClick: () => onForceOpen(),
    }];
    menu.push(...forceOpen);
  }

  if (account.householdClientGroup?.type === ClientGroupTypes.Household) {
    menu.push({
      label: t('client:accountDetails.dissociateAccountFromHousehold'),
      onClick: () => setDissociateAccountModal(true),
    });
  }

  if (account.householdClientGroup === null && account.user.households) {
    for (const household of account.user.households) {
      menu.push({
        label: account.user.households.length > 1
          ? t('client:accountDetails.asociateAccountToNamedHousehold', { name: household.name })
          : t('client:accountDetails.asociateAccountToHousehold'),
        onClick: () => setAssociateAccountModal(household),
      });
    }
  }
  menu.push(
    {
      label: t('client:accountDetails.closeAccount'),
      onClick: closeAccountClicked,
    },
  );

  const accountTitle = account.householdClientGroup
    ? `${t(`accountTypes:${account.type}`)} - ${generateClientNameString(account.user, true)}`
    : `${t(`accountTypes:${account.type}`)}`;

  return (
    <>
      <Summary
        title={accountTitle}
        householdLinks={account.householdClientGroup ? [{ id: account.householdClientGroup.id, name: account.householdClientGroup.name }] : undefined}
        pills={showPills}
        highlights={highlights}
        contextShowId={account.id}
        contextMenu={menu}
      />
      {openFeePaymentModal && (
        <FeePaymentModal
          open={true}
          handleClose={() => setOpenFeePaymentModal(false)}
          account={account}
        />
      )}
      {openTLHModel && (
        <UpdateTaxLosHarvestingModal
          open={true}
          handleClose={() => setOpenTLHModel(false)}
          account={account}
        />
      )}
      <EditAccountBilling
        afterUpdate={() => {
          setEditBillingAndFees(false);
          setSelectedHighlight('');
        }}
        accountToUpdate={account}
        highlight={selectedHighlight}
        open={editBillingAndFees}
        handleClose={() => {
          setEditBillingAndFees(false);
          setSelectedHighlight('');
        }}
      />
      <FormModal
        title={t('accountsDetail:closeAccountConfirmationDialog.title')}
        subTitle={t('accountsDetail:closeAccountConfirmationDialog.subtitle')}
        open={closeAccountDisclaimer}
        maxWidth='sm'
        onSubmit={(e) => {
          setCloseAccountDisclaimer(false);
          e.preventDefault();
        }}
        handleClose={() => setCloseAccountDisclaimer(false)}
        loading={false}
        formButton={t('accountsDetail:closeAccountConfirmationDialog.continueButton')}
      >
      </FormModal>
      <CloseAccountModal
        open={closeAccountModal}
        accountId={account.id}
        handleClose={() => setCloseAccountModal(false)}
      />
      {associateAccountModal && (
        <AssociateAccountToHouseholdModal
          accountId={account.id}
          household={associateAccountModal}
          handleClose={() => setAssociateAccountModal(null)}
        />
      )}
      {dissociateAccountModal && (
        <DissociateAccountFromHouseholdModal
          accountId={account.id}
          householdName={account.householdClientGroup?.name ?? ''}
          handleClose={() => setDissociateAccountModal(false)}
        />
      )}
    </>
  );
}
