import {
  Box, CircularProgress, Pagination, IconButton, Menu, MenuItem,
} from '@mui/material';
import {
  Button, Grid, Table, TableBody, TableCell, TableHeadCell, TableRow, TextField, Tooltip, Typography,
} from 'ovComponents';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import { translateBackend } from 'assets/i18n/config';
import { ClientReportTemplate } from 'interfaces';
import AddIcon from '@mui/icons-material/AddRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';

const FETCH_CLIENT_REPORT_TEMPLATES = gql`
query fetchClientReportTemplates($input: FetchClientReportTemplatesInput!) {
  fetchClientReportTemplates(input: $input) {
    clientReportTemplates {
      id
      translatedName { en fr }
      type
      scheduler { frequency }
      objectType
      state
    }
    totalCount
  }
}
`;

const ClientReportTemplates = () => {
  const { t } = useTranslation(['orgSettings']);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const [selectedRow, setSelectedRow] = useState<null | ClientReportTemplate>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { activeOrganization } = useContext(UserContext);
  const {
    loading, data, previousData,
  } = useQuery(FETCH_CLIENT_REPORT_TEMPLATES, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: {
          sortField: 'name', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  return (
    <Box>
      <Grid container>
        <Grid item xs={4}>
          <Tooltip title='TODO'>
            <TextField leadingIcon='search' fullWidth disabled />
          </Tooltip>
        </Grid>
        <Grid item xs={8}>
          <Box alignContent='right' sx={{ float: 'right', m: 1 }}>
            <Tooltip title='TODO'>
              <Button variant='filled' disabled
                leadingIcon={AddIcon}
                label={t('clientReportTemplates.new')}
                onClick={() => { }}
              />
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      {
        loading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <CircularProgress sx={{ m: 10 }} />
          </Box >
        ) : (
          <>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableHeadCell>{t('shared:name')}</TableHeadCell>
                  <TableHeadCell>{t('shared:type')}</TableHeadCell>
                  <TableHeadCell>{t('clientReportTemplates.frequency')}</TableHeadCell>
                  <TableHeadCell>{t('clientReportTemplates.association')}</TableHeadCell>
                  <TableHeadCell>{t('clientReportTemplates.lastGenerationDate')}</TableHeadCell>
                  <TableHeadCell>{t('shared:state')}</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                </TableRow>
                {data?.fetchClientReportTemplates?.clientReportTemplates?.map((clientReportTemplate: ClientReportTemplate, index: number) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover
                    onClick={() => {
                      navigate(`clientReportTemplate/${clientReportTemplate.id}`);
                    }}
                  >
                    <TableCell>
                      {translateBackend(clientReportTemplate.translatedName)}
                    </TableCell>
                    <TableCell>
                      {clientReportTemplate.type}
                    </TableCell>
                    <TableCell>
                      {t(`clientReportTemplates.frequencies.${clientReportTemplate.scheduler.frequency}`)}
                    </TableCell>
                    <TableCell>
                      {t(`clientReportTemplates.associations.${clientReportTemplate.objectType}`)}
                    </TableCell>
                    <TableCell>
                      {/* TODO backend support */}
                    </TableCell>
                    <TableCell>
                      {t(`clientReportTemplates.states.${clientReportTemplate.state}`)}
                    </TableCell>
                    <TableCell onClick={(e) => { e.stopPropagation(); }}>
                      <IconButton size='small'
                        onClick={(e) => {
                          setSelectedRow(clientReportTemplate);
                          setAnchorEl(e.currentTarget);
                        }}
                      ><MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchClientReportTemplates?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </>
        )
      }
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography sx={{ p: 2 }}>{selectedRow?.id}</Typography>
        {/* TODO menu actions */}
        <MenuItem disabled><VisibilityIcon />&nbsp; Preview</MenuItem>
        <MenuItem disabled><EditIcon />&nbsp; edit</MenuItem>
        <MenuItem disabled>activate</MenuItem>
        <MenuItem disabled>deactivate</MenuItem>
        <MenuItem disabled>archive</MenuItem>
      </Menu>
    </Box>
  );
};

export default ClientReportTemplates;
