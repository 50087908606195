import { ListItem } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import CreateNewModal from '../../../components/modals/createNewModal';
import TranslatableString from '../../../components/inputs/translatableString';
import { UserContext } from '../../../providers/userContextProvider';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import OvTextField from '../../../components/fields/ovTextField';
import { ValidateRule } from '../../../interfaces/validateRule';
import { validateForm } from '../../../util';

const CREATE_ASSET_CLASS = gql`
mutation createAssetClass($input: CreateAssetClassInput!) {
  createAssetClass(input: $input) {
    assetClass {
      id
    }
  }
}
`;

const NewAssetClass = ({ afterCreate }: { afterCreate: () => void }) => {
  const { t } = useTranslation(['configureModels']);
  const { showToast } = useGlobalToast();
  const { activeOrganization } = useContext(UserContext);
  const [assetClass, setAssetClass] = useState({
    key: '',
    translatedName: {
      en: '',
    },
    translatedDescription: {
      en: '',
    },
    organizationId: activeOrganization.id,
  });
  const [initialState] = useState({
    key: '',
    translatedName: {
      en: '',
    },
    translatedDescription: {
      en: '',
    },
    organizationId: activeOrganization.id,
  });
  const [createAssetClass, { loading }] = useMutation(CREATE_ASSET_CLASS, {
    variables: {
      input: assetClass,
    },
  });

  const create = async () => {
    const response = await createAssetClass();
    if (response?.data?.createAssetClass?.assetClass?.id) {
      showToast({ severity: 'success', message: t('assetClassModal.newAssetClassToastMessage') });
      setAssetClass({ ...assetClass });
    }
    afterCreate();
  };

  const formRules: ValidateRule[] = [
    {
      key: 'key',
      rule: 'SHOULD_EXIST',
    },
    {
      key: 'translatedName.en', // nested attributes
      rule: 'SHOULD_EXIST',
      pattern: '$.translatedName..en', // define a pettern when comparing nested attribute
    },
    {
      key: 'translatedDescription.en',
      rule: 'SHOULD_EXIST',
      pattern: '$.translatedDescription..en', // define a pettern when comparing nested attribute
    },
  ];

  return (
    <CreateNewModal
      initialState={initialState}
      state={assetClass}
      loading={loading} disabled={!validateForm(formRules, assetClass)} title={t('assetClassModal.title')} onSubmit={create} fullWidth sx={{ marginTop: 1 }} >
      <ListItem>
        <OvTextField
          label={t('assetClassModal.key')}
          value={assetClass.key}
          fullWidth
          onChange={(e) => setAssetClass({ ...assetClass, key: e.target.value.trimStart() })}
          required
        />
      </ListItem>
      <ListItem>
        <TranslatableString required label={t('assetClassModal.name')} value={assetClass.translatedName} onChange={(e) => setAssetClass({ ...assetClass, translatedName: e })} />
      </ListItem>
      <ListItem>
        <TranslatableString required
          label={t('assetClassModal.description')} value={assetClass.translatedDescription}
          onChange={(e) => setAssetClass({ ...assetClass, translatedDescription: e })} rows={3}
        />
      </ListItem>
      <ListItem>
        <OrganizationSelect
          value={assetClass.organizationId ?? ''}
          onChange={(e) => {
            setAssetClass({ ...assetClass, organizationId: e.target.value });
          }}
          label={t('productModal.organization')}
        />
      </ListItem>
    </CreateNewModal>
  );
};

export default NewAssetClass;
