/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Grid, LinearProgress, MenuItem, TextField, Typography,
} from '@mui/material';
import {
  sum, groupBy,
} from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { formatMoneyValue } from '../../../../util';
import { Holding } from '../../../../interfaces';
import { usePageState } from '../../../../util/usePageState';
import { ObjectType } from '../../../../providers/statsHooks';

const Holdings = ({
  holdings, type, removeTarget = false, id,
}: {
  holdings: Holding[], type?: ObjectType, removeTarget?: boolean, id?: string,
}) => {
  const { t } = useTranslation(['client', 'subaccount']);

  const [stats, setStats] = useState<{ holdings: Holding[], totalCents: number }>({ holdings: [], totalCents: 0 });
  const [groupedBy, setGroupedBy] = usePageState('secondaryAssetClass', 'assetsBy');

  const getHoldingStats = () => {
    const h = holdings.filter((holding: Holding) => holding.quantity !== 0).map((holding: Holding) => ({
      totalCents: holding.totalCents,
      security: holding.financialProduct.ticker ? `${holding.financialProduct.ticker} - ${holding.financialProduct.name}` : holding.financialProduct.name,
      primaryAssetClass: holding.financialProduct.primaryAssetClass?.translatedName?.en,
      secondaryAssetClass: holding.financialProduct.secondaryAssetClass?.translatedName?.en,
      expectedTotalCents: holding.expectedTotalCents,
      quantity: holding.quantity,
    }));

    const grouped = groupBy(groupedBy, h);
    const newHoldings: any[] = [];

    Object.keys(grouped).forEach((x: any) => {
      newHoldings.push({
        name: x,
        totalCents: sum(grouped[x].map((y: any) => y.totalCents)),
        expectedTotalCents: sum(grouped[x].map((y: any) => y.expectedTotalCents)),
        quantity: sum(grouped[x].map((y: any) => y.quantity)),
      });
    });

    const totalCents = sum(newHoldings.map((x: any) => x.totalCents));

    return { holdings: newHoldings.sort((a: any, b: any) => (a.totalCents > b.totalCents ? -1 : 1)), totalCents };
  };

  useEffect(() => {
    setStats(getHoldingStats());
  }, [groupedBy, holdings]);

  return (
    <>
      <TextField
        select
        value={groupedBy}
        label={t('groupBy')}
        onChange={(e: any) => setGroupedBy(e.target.value)}
        size='small'
        fullWidth
        sx={{ marginBottom: 2 }}
      >
        <MenuItem value='security'>{t('security')}</MenuItem>
        <MenuItem value='primaryAssetClass'>{t('primaryAssetClass')}</MenuItem>
        <MenuItem value='secondaryAssetClass'>{t('secondaryAssetClass')}</MenuItem>
      </TextField>
      {stats.holdings.map((holding: any, index: number) => (
        <Grid container key={index} sx={{ marginBottom: 1 }}>
          <Grid item xs={(type && type === ObjectType.SUB_ACCOUNT) ? 11.65 : 12}>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography variant='body2'><b>{holding.name}</b></Typography>
              </Grid>
              <Grid item>
                <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <Typography variant='body2'>{formatMoneyValue(holding.totalCents ?? 0)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ position: 'relative' }}>
              <LinearProgress variant="determinate" value={((holding.totalCents / stats.totalCents) * 100)} sx={{ height: '8px' }} />
              {!removeTarget && (
                <div
                  style={{
                    height: '14px',
                    width: '2px',
                    background: 'black',
                    position: 'absolute',
                    left: `${(holding.expectedTotalCents / stats.totalCents) * 100}%`,
                    marginTop: '-11px',
                  }}
                ></div>
              )}
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography variant='caption'>{((holding.totalCents / stats.totalCents) * 100).toFixed(0)}%</Typography>
                </Grid>
                {groupedBy === 'security' && (
                  <Grid item>
                    <Typography variant='caption'>{holding.quantity}</Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default Holdings;
