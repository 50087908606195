import React from 'react';
import { t } from 'i18next';
import { TextField } from '../../2-component';

interface AmountFieldProps {
  amount: string,
  setAmount: (amount: string) => void,
  label?: string,
  onBlur?: any,
  error?: any,
  sx?: any,
  disabled?: boolean
  locked?: boolean
  infoTooltip?: string,
  errorText?: string;
  inputProps?: any;
  InputProps?: any;
  size?: any;
  fullWidth?: boolean;
}
export const AmountField = ({
  amount, setAmount, label, onBlur, error, sx, infoTooltip, disabled, locked, errorText, inputProps, InputProps, size, fullWidth = true,
}: AmountFieldProps) => (
  <TextField
    onChange={(e: any) => setAmount(e.target.value)}
    label={label ?? t('components:amount')}
    fullWidth={fullWidth}
    lockMessage={t('pageConfiguration:notEditableMessage')}
    disabled={disabled}
    locked={locked}
    value={amount}
    placeholder="0.00"
    type="dollar"
    leadingIcon='dollar'
    onBlur={onBlur}
    error={error}
    sx={sx}
    infoTooltip={infoTooltip}
    errorText={errorText}
    inputProps={inputProps}
    size={size}
    InputProps={InputProps}
  />
);
