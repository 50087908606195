import i18n from '../assets/i18n/config';
import { Account } from './account';
import { ClientGroup } from './clientGroup';
import { FinancialProduct } from './financialProduct';
import { Organization } from './organization';
import { Statistics } from './stats';
import { SubAccount, TRADING_PROCESS_LIST } from './subAccount';
import { User } from './user';

export enum GoalTimeHorizons {
  VERY_SHORT_TERM = 'VERY_SHORT_TERM',
  SHORT_TERM = 'SHORT_TERM',
  MID_TERM = 'MID_TERM',
  LONG_TERM = 'LONG_TERM',
  VERY_LONG_TERM = 'VERY_LONG_TERM',
}
export const timeHorizons = Object.values(GoalTimeHorizons);

export enum GoalTypes {
  CAR = 'CAR',
  BUILD_WEALTH = 'BUILD_WEALTH',
  RETIREMENT = 'RETIREMENT',
  MAJOR_PURCHASE = 'MAJOR_PURCHASE',
  HOUSE = 'HOUSE',
  EDUCATION = 'EDUCATION',
  VACATION = 'VACATION',
  START_A_BUSINESS = 'START_A_BUSINESS',
  WEDDING = 'WEDDING',
  PARENTAL_LEAVE = 'PARENTAL_LEAVE',
  SAFETY_NET = 'SAFETY_NET',
  OTHER = 'OTHER',
  CASH_RESERVE = 'CASH_RESERVE',
  LEGACY = 'LEGACY',
  GROUP = 'GROUP',
  RETIREMENT_INCOME = 'RETIREMENT_INCOME',
}
export const goalTypes = Object.values(GoalTypes);

export enum GoalRiskLevels {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
  LEVEL_4 = 'LEVEL_4',
  LEVEL_5 = 'LEVEL_5',
}
export const goalRiskLevels = Object.values(GoalRiskLevels);

export enum GoalStates {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export interface Goal {
  id: string;
  name?: string;
  type?: GoalTypes;
  targetAmountCents?: number;
  riskQuestion1?: GoalRiskLevels;
  state?: GoalStates;
  subAccounts?: [SubAccount];
  suitabilityScore?: number;
  timeHorizon?: GoalTimeHorizons;
  user: User;
  lastUpdatedBy?: string;
  stats?: Statistics;
  statistics?: Statistics;
  stateChanges?: any[];
  organization?: Organization;
  householdClientGroup?: ClientGroup;
  financialProductId?: string;
  financialProduct?: FinancialProduct | null;
  tradingProcess?: string;
  taxOptimized?: boolean;
  [key: string]: any;
}

export interface CreateGoalInput {
  userId: string;
  riskQuestion1: GoalRiskLevels;
  timeHorizon: GoalTimeHorizons;
  type: GoalTypes;
  financialProductId?: string;
  householdClientGroupId?: string;
  name?: string;
  targetAmountCents?: number;
  taxOptimized?: boolean;
}

export interface DraftGoal extends CreateGoalInput {
  financialProduct?: FinancialProduct,
  householdClientGroup?: ClientGroup;
  accountsLinked?: Account[],
}

export const getGoalName = (goal?: Goal): string => {
  if (goal?.name) return goal.name;
  return goal?.type ? i18n.t(`client:goalType.${goal.type}`) : '';
};

export const GOAL_TRADING_PROCESS_LIST = [...TRADING_PROCESS_LIST.filter((tradingProcess) => ['NO_AUTOMATION', 'PORTFOLIO_OPTIMIZER'].includes(tradingProcess.value))];
