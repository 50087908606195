import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Grid } from '../../../../1-primative';
import { InputAdornment, Tooltip } from '../../../../2-component';
import { formatMoneyValue } from '../../../../../util';

const ChangeTooltip = ({
  change, original, total = false, flip = false, isSellAll = false,
}: {
  change: number, original: number, total?:boolean, flip?: boolean, isSellAll?: boolean,
}) => {
  const isPositive = () => {
    if (change > 0 && !flip) {
      return true;
    }
    if (change > 0 && flip) {
      return false;
    }
    if (change < 0 && !flip) {
      return false;
    }
    return true;
  };

  return (
    <>
      { change !== 0 ? (
        <Grid item>
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line' }}>
                {isSellAll ? 'SELL ALL\n' : ''}{`ORIGINAL: ${formatMoneyValue(original)}\n${total ? 'TOTAL ' : ''}${isPositive() ? 'BUY' : 'SELL'} ${formatMoneyValue(Math.abs(change))}`}
              </div>
            }
          >
            {change > 0 ? (
              <InputAdornment position="end"><ArrowUpwardIcon color='success' sx={{ fontSize: '14px' }} /></InputAdornment>
            ) : (
              <InputAdornment position="end"><ArrowDownwardIcon color='error' sx={{ fontSize: '14px' }} /></InputAdornment>
            )}
          </Tooltip>
        </Grid>
      ) : (
        <InputAdornment position="end"><FiberManualRecordIcon color='disabled' sx={{ fontSize: '14px' }} /></InputAdornment>
      )}
    </>
  );
};

export default ChangeTooltip;
