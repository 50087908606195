import { useTranslation } from 'react-i18next';
import { TriggerRule, TriggerRuleComparisonTypes, Workflow } from '../../../../interfaces/workflow';
import { Box } from '../../../1-primative';
import {
  Button, Card, CardContent, MenuItem, SelectField, TextField,
} from '../../../2-component';

const BuildEventTriggerRules = ({
  workflow, setWorkflow, disabled = false,
} : {
  workflow: Workflow, setWorkflow: (workflow: Workflow) => void, disabled?: boolean,
}) => {
  const { t } = useTranslation(['workflowCompletions']);
  let rules = workflow.triggerRules ?? [];
  const hasPendingLines = !!rules.find((rule) => !rule.field || !rule.value);
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
          {rules.map((rule, i) => (
            <Box key={i} display='flex' flexDirection='row' alignItems='end' marginBottom={2}>
              <TextField label={t('eventTriggerRule.field')} value={rule.field ?? ''} fullWidth sx={{ mr: 2, width: 250 }} onChange={(e: any) => {
                rules[i].field = e.target.value;
                setWorkflow({ ...workflow, triggerRules: rules });
              }} />
              <SelectField label={t('eventTriggerRule.comparison')} value={rule.comparison || ''} fullWidth sx={{ mr: 2, width: 250 }} onChange={(e: any) => {
                rules[i].comparison = e.target.value;
                setWorkflow({ ...workflow, triggerRules: rules });
              }}>
                {Object.values(TriggerRuleComparisonTypes).map((comp) => (
                  <MenuItem key={comp} value={comp}>{comp}</MenuItem>
                ))}
              </SelectField>
              <TextField label={t('eventTriggerRule.value')} value={rule.value ?? ''} fullWidth sx={{ mr: 2, width: 250 }} onChange={(e: any) => {
                rules[i].value = e.target.value;
                setWorkflow({ ...workflow, triggerRules: rules });
              }} />
              {rules.length > 0 && !disabled && (
                <Button sx={{ mt: 1 }} variant='text' color='destructive' label={t('remove')} onClick={() => {
                  rules = rules.filter((f, index) => index !== i);
                  setWorkflow({ ...workflow, triggerRules: rules });
                }} />
              )}
            </Box>
          ))}
          <Button label={t('eventTriggerRule.addNewRule')} disabled={hasPendingLines || disabled} onClick={() => {
            const newLine: TriggerRule = { field: '', comparison: TriggerRuleComparisonTypes.EQUALS, value: '' };
            rules = [...rules, newLine];
            setWorkflow({ ...workflow, triggerRules: rules });
          }}/>
      </CardContent>
    </Card>
  );
};

export default BuildEventTriggerRules;
