import { gql, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loop } from '@mui/icons-material';
import { translateBackend } from 'assets/i18n/config';
import {
  Button, Typography, Box,
  Skeleton,
  ActionContext,
} from '../../../..';
import { WorkflowContext } from '../../workflowCompletion';
import { UserContext } from '../../../../../providers/userContextProvider';
import { useClientContext } from '../../../../../pages/client';
import { useHouseholdContext } from '../../../../../pages/household';
import { Goal, GoalStates } from '../../../../../interfaces';
import GoalCard from '../../../goalCard/goalCard';
import { InnerWorkflowModal } from '../../../../3-pattern/innerWorkflowModal/innerWorkflowModal';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const WORKFLOW_COMPLETION = `
  workflowCompletion {
    id
    objectId
    context
    workflow {
      name {
        en
        fr
      }
      steps {
        id
        name {
          en
          fr
        }
        subSteps {
          id
          type
        }
      }
    }
    currentStep {
      id
      name {
        en
        fr
      }
      subSteps {
        id
        type
      }
    }
    currentSubStep {
      id
      options
      type
      canGoBack
      skippable
      rolesCompleteableBy {
        id
      }
    }
  }
`;

const FETCH_GOALS = gql`
  query fetchGoals($input: FetchGoalsInput!) {
    fetchGoals(input: $input) {
      goals {
        id
        name
        type
        riskQuestion1
        timeHorizon
        targetAmountCents
        financialProduct {
          name
          theme {
            name
          }
        }
        subAccounts {
          account {
            type
          }
        }
      }
      totalCount
    }
  }
`;

const GO_TO_STEP = gql`
  mutation goToSubStep($input: GoToSubStepInput!) {
    goToSubStep(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

const CREATE_WORKFLOW_COMPLETION = gql`
  mutation createWorkflowCompletion($input: CreateWorkflowCompletionInput!) {
    createWorkflowCompletion(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

const UPDATE_WORKFLOW_COMPLETION = gql`
  mutation updateWorkflowCompletion($input: UpdateWorkflowCompletionInput!) {
    updateWorkflowCompletion(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

export const ReviewGoal = ({
  options, onNext, setActiveWorkflowCompletion, workflowCompletion,
  userId,
}: {
  options: any, onNext: any, setActiveWorkflowCompletion: any, workflowCompletion: any,
  userId?: string
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { activeOrganization } = useContext(UserContext);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const { refetch: refetchWorkflow } = useContext(ActionContext);
  const clientContext = useClientContext();
  const [newActiveWorkflow, setNewActiveWorkflow] = useState<any>();
  const [goals, setGoals] = useState<Goal[]>();
  const [innerOpen, setInnerOpen] = useState(false);
  const householdContext = useHouseholdContext();
  const [updateWorkflowCompletion] = useMutation(UPDATE_WORKFLOW_COMPLETION);

  const [createWorkflowCompletion] = useMutation(CREATE_WORKFLOW_COMPLETION, {
    onCompleted: (data: any) => {
      refetchWorkflow();
      setNewActiveWorkflow({
        ...data.createWorkflowCompletion.workflowCompletion,
        context: workflowData,
      });
      setInnerOpen(true);
      if (workflowCompletion?.id) {
        const updatingContext = {
          ...workflowData,
          childWorkflowCompletion: data.createWorkflowCompletion.workflowCompletion,
        };
        updateParentWorkflowCompletion(workflowCompletion.id, updatingContext);
      }
    },
  });

  const updateParentWorkflowCompletion = (parentWorkflowComplationId: string, updatedContext: any) => {
    updateWorkflowCompletion({
      variables: {
        input: {
          workflowCompletionId: parentWorkflowComplationId,
          context: updatedContext ?? undefined,
        },
      },
    });
  };
  const organizationId = clientContext?.orgSettings.id || householdContext?.orgSettings.id || activeOrganization?.id;
  const { t } = useTranslation(['workflowCompletions', 'accountTypes']);

  const { data: goalsData, loading: goalsDataLoading, refetch } = useQuery(FETCH_GOALS, {
    variables: {
      input: {
        filter: {
          userId: workflowData.currentUserId || userId,
          states: [GoalStates.ACTIVE],
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    skip: !workflowData.currentUserId,
    fetchPolicy: 'no-cache',
  });

  const [goToStep] = useMutation(GO_TO_STEP);
  useEffect(() => {
    setGoals(goalsData?.fetchGoals?.goals);
  }, [goalsData]);

  useEffect(() => {
    if (workflowCompletion && workflowCompletion?.context?.childWorkflowCompletion) {
      setNewActiveWorkflow({
        ...workflowCompletion?.context?.childWorkflowCompletion,
        context: workflowData,
      });
      setInnerOpen(true);
    }
  }, [workflowCompletion, workflowData]);

  const goToGoalCreate = (createAnotherGoal?: boolean) => {
    if (createAnotherGoal) {
      const subSteps = workflowCompletion.workflow.steps.map((x: any) => x.subSteps).flat();
      const goalCreateSubStep = subSteps.find((x: any) => x.type === 'CREATE_GOAL');
      goToStep({
        variables: {
          input: {
            workflowCompletionId: workflowCompletion.id,
            subStepId: goalCreateSubStep.id,
          },
        },
        onCompleted: (d: any) => {
          setWorkflowData({
            ...workflowData,
            currentGoalId: null,
            currentGoalName: null,
            currentGoalType: null,
            currentAccountId: null,
            currentAccountType: null,
            currentAccountState: null,
            currentSubAccountId: null,
          });
          setActiveWorkflowCompletion(d.goToSubStep.workflowCompletion);
        },
      });
    }
  };

  const onEdit = async (selectedGoal: Goal) => {
    setWorkflowData({
      ...workflowData,
      currentGoalId: selectedGoal.id,
      currentGoalName: selectedGoal.name,
      currentGoalType: selectedGoal.type,
    });
    createWorkflowCompletion({
      variables: {
        input: {
          objectId: workflowData.currentUserId ?? userId,
          objectType: 'USER',
          workflowId: options.goalWorkflow,
          organizationId,
          parentWorkflowCompletionId: workflowCompletion?.id,
        },
      },
    });
  };

  const onSubmit = () => {
    sendAnalytic(
      ovAnalyticsEvents.workflowsReviewGoalContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    onNext();
  };

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>
        {translateBackend(options?.title)}
      </Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        {translateBackend(options?.subtitle)}
      </Typography>
      {goalsDataLoading || !workflowData.currentUserId || !userId ? (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Skeleton width={250} />
            <Skeleton width={150} />
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Skeleton width={250} />
            <Skeleton width={150} />
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Skeleton width={250} />
            <Skeleton width={150} />
          </Box>
        </>
      ) : (
        goals && goals.map((g: any) => <GoalCard goal={g} onEdit={onEdit} options={options} />)
      )}
      <Box display='flex' justifyContent='space-between'>
        {options.createAnotherGoal && (
          <Button label={t('createAnotherGoal')} variant='filled' sx={{ mt: 3, mr: 1 }} disabled={goalsDataLoading} onClick={() => goToGoalCreate(options.createAnotherGoal)} leadingIcon={Loop} />
        )}
        <Button label={t('continue')} sx={{ mt: 3 }} disabled={goalsDataLoading} onClick={onSubmit} />
      </Box>
      {newActiveWorkflow && (
        <InnerWorkflowModal
          newActiveWorkflow={newActiveWorkflow}
          setOpen={setInnerOpen}
          open={innerOpen}
          refetch={() => {
            refetchWorkflow();
            refetch();
          }}
          updateParent={(activeCompletion?: any) => {
            if (!activeCompletion) {
              delete workflowData.childWorkflowCompletion;
              updateParentWorkflowCompletion(workflowCompletion.id, {
                ...workflowData,
              });
            } else {
              const updatingContext = {
                ...workflowData,
                childWorkflowCompletion: activeCompletion,
              };
              updateParentWorkflowCompletion(workflowCompletion.id, updatingContext);
            }
          }}
        />
      )}
    </>
  );
};

export default ReviewGoal;
