import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Button, Dialog, DialogContent, DialogTitle, Form, IconButton, TextField,
} from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import DroppableFileInput, { FileTypes } from './droppableFileInput';
import { FileUploaderProps, fileUploader } from '../../../../../util/fileUploader';
import { FileDocumentObjectTypes, FileDocumentTypes } from '../../../../../interfaces/fileDocument';
import { PageObjectType } from '../../../../5-page';

export const UploadDocument = ({
  open, setOpen, refetch, objectId, objectType, userId,
}: {
  open: boolean, setOpen: (arg: boolean) => void, refetch: () => void, objectId: string, objectType?: PageObjectType, userId?: string
}) => {
  const { t } = useTranslation(['document']);
  const [file, setFile] = useState<File | null>();
  const [inputValue, setInputValue] = useState('');
  const client = useApolloClient();

  const selectFile = (chosenFile: File) => {
    setFile(chosenFile);
    setInputValue(chosenFile.name);
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setInputValue('');
  };

  const onSubmit = async (e: any) => {
    if (file) {
      const createFileInput = {
        objectType: objectType === PageObjectType.ACCOUNT ? FileDocumentObjectTypes.ACCOUNT : FileDocumentObjectTypes.USER,
        objectId,
        ...(objectType === PageObjectType.ACCOUNT ? { userId: userId ?? '' } : { userId: objectId }),
        fileName: file.name,
        type: FileDocumentTypes.OTHER,
        name: inputValue || file.name,
        mediaType: file.type,
        permissionType: 'PUBLIC',
      };

      const fileUploaderInput: FileUploaderProps = {
        file,
        createFileInput,
        apolloClient: client,
        onSuccess: () => {
          refetch();
          handleClose();
        },
      };

      await fileUploader(fileUploaderInput);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingSmall'>{t('document:addDocumentModal:title')}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={(e: any) => onSubmit(e)}
        >
          <Typography variant="bodyMedium" sx={{ mb: 4 }}>{t('document:addDocumentModal.subText')}</Typography>
          <Box sx={{ mb: 1 }}>
            <Typography variant="labelSmall">{t('document:addDocumentModal.selectFile')}</Typography>
          </Box>
          <DroppableFileInput
            onFileChosen={(chosenFile) => selectFile(chosenFile)}
            fileMaxSize={20}
            acceptedFiles={[FileTypes.PDF, FileTypes.JPEG, FileTypes.JPG, FileTypes.PNG]}
          />
          <TextField
            label={t('document:addDocumentModal.inputLabel')}
            value={inputValue}
            fullWidth
            onChange={(e: any) => setInputValue(e.target.value)}
          />
          <Box display='flex' justifyContent='end' mt={2}>
            <Button label={t('document:droppableFileInput.submit')} type='submit' disabled={!file} />
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
