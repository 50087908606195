import { Dialog, DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PortfolioTable from '../../../components/tables/portfolioTable/portfolioTable';
import ConfirmationModal from '../../../components/modals/confirmationModal';
import { styles } from '../../../components/modals/formModal';
import { ObjectType } from '../../../providers/statsHooks';
import { PortfolioReport } from '../../../interfaces';

export const getObjectType = (typename: string): ObjectType => (typename === ObjectType.SUB_ACCOUNT.toString() ? ObjectType.SUB_ACCOUNT : ObjectType.GOAL);

export interface PortfolioAdjustmentActiveItem {
  id: string;
  type: ObjectType;
  isCashRebalanceRequired?: boolean;
  portfolioOptimizerId?: string;
  portfolioReport?: PortfolioReport;
}

const PortfolioAdjustmentsModal = ({
  open, handleClose, item, onRefetchRequired,
}: { open: boolean; handleClose: () => void; item: PortfolioAdjustmentActiveItem; onRefetchRequired?: () => void }) => {
  const { t } = useTranslation('components');
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState<boolean>(false);
  const [isTradesUpdated, setIsTradesUpdated] = useState<boolean>(false);
  const [isRefetchRequired, setIsRefetchRequired] = useState<boolean>(false);

  const close = () => {
    handleClose();
  };

  useEffect(() => {
    if (isRefetchRequired && onRefetchRequired) {
      onRefetchRequired();
      setIsRefetchRequired(false);
    }
  }, [isRefetchRequired, onRefetchRequired, setIsRefetchRequired]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          if (!isTradesUpdated) {
            close();
          } else {
            setExitConfirmationOpen(true);
          }
        }}
        maxWidth='xl'
        fullWidth
      >
        <DialogContent>
          <PortfolioTable
            type={item.type}
            id={item.id}
            setIsTradesUpdated={setIsTradesUpdated}
            setIsRefetchRequired={setIsRefetchRequired}
            isCashRebalanceRequired={item.isCashRebalanceRequired}
            portfolioOptimizerId={item.portfolioOptimizerId}
            portfolioReport={item.portfolioReport}
          />
        </DialogContent>
      </Dialog>

      <ConfirmationModal
        open={exitConfirmationOpen}
        title={t('shared:wizardTitle', { wizardTitle: t('portfolio') })}
        bodyText={t('shared:wizardInturruptionMsg')}
        onConfirm={() => {
          setExitConfirmationOpen(false);
          close();
        }}
        onCancel={() => setExitConfirmationOpen(false)}
        confirmButton={t('shared:yesExit')}
        cancelButton={t('shared:cancel')}
        dialogStyles={styles.confirmation}
        confirmColor='error'
      />
    </>
  );
};

export default PortfolioAdjustmentsModal;
