/* eslint-disable no-console */
import React, { ReactNode, useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import auth0, {
  auth0RefreshToken,
  getOrganizationKeyFromURL,
  JWT_CLAIMS_NAMESPACE,
  auth0Logout,
} from './auth0Client';
import { parseJwt } from '../util';
import { Box } from '../ovComponents/1-primative/box/box';
import { Typography } from '../ovComponents/1-primative/typography/typography';
import { CircularProgress } from '../ovComponents/2-component/circularProgress/circularProgress';
import { Dialog, DialogContent, DialogTitle } from '../ovComponents/2-component/dialog/dialog';
import { Button } from '../ovComponents/2-component/button/button';
import { PublicOrganizationSelectDialog } from '../ovComponents/3-pattern/publicOrganizationSelectDialog/publicOrganizationSelectDialog';
import { useAuthContext } from './ovApolloProvider';
import { Organization } from '../interfaces/organization';

export const BASIC_ME = gql`
  query basicMe {
    me {
      id
      firstName
      lastName
      email
    }
  }
`;

export const FETCH_ORGANIZATION_PUBLIC_SETTINGS = gql`
  query fetchOrganizationPublicSettings($input: FetchOrganizationPublicSettingsInput!) {
    fetchOrganizationPublicSettings(input: $input) {
      subdomain
      faviconLink
      browserTabTitle
    }
  }
`;

export const EXCHANGE_CLIENT_TOKEN = gql`
  mutation exchangeClientToken($input: ExchangeClientTokenInput!) {
    exchangeClientToken(input: $input) {
      accessToken
    }
  }
`;

const Auth0Provider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [openOrganizationSelectDialog, setOpenOrganizationSelectDialog] = useState(false);
  const [accessibleOrgs, setAccessibleOrgs] = useState<string[]>();
  const [openSignUpNotAllowedDialog, setSignUpNotAllowedDialog] = useState(false);
  const { setAuthToken } = useAuthContext();
  const queryParams = new URLSearchParams(window.location.search);
  const invitiation = queryParams.get('invitation');
  const organization = queryParams.get('organization');
  const organizationName = queryParams.get('organization_name') || undefined;
  const state = queryParams.get('state');
  const connection = queryParams.get('connection');
  const allowPopup = queryParams.get('allow_popup');
  const externalAccessTokenParams = queryParams.get('accessToken');
  const isOneWealthDomain = window.location.host.includes('onevest.') || window.location.host.includes('onewealth.');
  const [faviconLink, setFaviconLink] = useState('');
  const [browserTabTitle, setBrowserTabTitle] = useState('');
  /**
   * If it's a isOneWealthDomain, we can safely assume that the organizationKey (aka subdomain) will be present in the first part of the host
   * e.g.: admin.staging.onevest.com, where `admin` would be the `organizationKey`
   *
   * In cases where it's not a isOneWealthDomain, meaning it's a custom domain/url, we need to fetch the organizationKey asynchronously
   * e.g.: wealth.partner.com
   */
  const { auth0RedirectUri, organizationKey } = isOneWealthDomain ? getOrganizationKeyFromURL(organizationName) : { auth0RedirectUri: window.location.origin, organizationKey: null };
  const { t } = useTranslation('authentication');

  if (invitiation && organization) {
    // eslint-disable-next-line max-len
    window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?invitation=${invitiation}&organization=${organization}&response_type=token&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${auth0RedirectUri}`;
  }

  const [basicMe] = useLazyQuery(BASIC_ME);
  const [fetchOrganizationPublicSettings] = useLazyQuery(FETCH_ORGANIZATION_PUBLIC_SETTINGS);
  const [exchangeClientTokenMutation] = useMutation(EXCHANGE_CLIENT_TOKEN);

  const exchangeClientToken = async ({
    externalAccessToken, externalRefreshToken,
  }: {
    externalAccessToken: string,
    externalRefreshToken?: string,
  }) => {
    console.log({ event: 'EXCHANGE_CLIENT_TOKEN', organizationKey });
    const exchangeClientTokenResponse = await exchangeClientTokenMutation({
      variables: {
        input: {
          organizationId: organizationKey,
          externalClientToken: externalAccessToken,
          externalRefreshToken,
        },
      },
    });

    const token = exchangeClientTokenResponse?.data?.exchangeClientToken?.accessToken;
    if (token) {
      setAuthToken(token);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (faviconLink) {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = faviconLink;
    }
    if (browserTabTitle) {
      const tabTitle = document.getElementById('org-title');
      if (tabTitle) {
        tabTitle.textContent = browserTabTitle;
      }
    }
  }, [faviconLink, browserTabTitle]);

  const SignUpNotAllowedDialog = () => (
    <Dialog open={true || openSignUpNotAllowedDialog} fullWidth maxWidth='xs'>
      <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='titleLarge'>{t('signUpNotAllowedDialog.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='bodyLarge'>{t('signUpNotAllowedDialog.body')}</Typography>
        <Box mt={5}>
          <Button type='button' fullWidth variant='filled' onClick={() => auth0Logout()} label={t('shared:okGotIt')} />
        </Box>
      </DialogContent>
    </Dialog>
  );

  const onOrgSelect = (org: Organization) => {
    auth0.loginWithRedirect({
      authorizationParams: {
        redirect_uri: auth0RedirectUri,
        organization: org.subdomain,
      },
    });
  };

  const setOrgPublicSettings = (orgPublicSettings: any) => {
    if (orgPublicSettings?.faviconLink) {
      setFaviconLink(orgPublicSettings?.faviconLink);
    }
    if (orgPublicSettings?.browserTabTitle) {
      setBrowserTabTitle(orgPublicSettings?.browserTabTitle);
    }
  };

  useEffect(() => {
    console.log({ event: 'AUTH', organizationKey, isOneWealthDomain });

    if (externalAccessTokenParams && organizationKey) {
      exchangeClientToken({ externalAccessToken: externalAccessTokenParams });
      return;
    }

    if (invitiation) return;
    const handleAccessToMultipleOrgs = (payloadAccessibleOrgs: string[], newToken: string) => {
      if (payloadAccessibleOrgs.length > 1) {
        setAccessibleOrgs([...payloadAccessibleOrgs]);
        setOpenOrganizationSelectDialog(true);
        setAuthToken(newToken);
        setLoading(false);
      } else {
        auth0.loginWithRedirect({
          authorizationParams: {
            redirect_uri: auth0RedirectUri,
            organization: payloadAccessibleOrgs[0],
            ...(connection ? { connection } : {}),
          },
        });
      }
    };

    const getTokenSilentlyAuth0 = async () => {
      auth0.getTokenSilently().then(async (newToken: string) => {
        const payload = parseJwt(newToken);
        const payloadAccessibleOrgs = payload[`${JWT_CLAIMS_NAMESPACE}/accessibleOrgs`];
        if (payloadAccessibleOrgs && payloadAccessibleOrgs.length > 0) {
          handleAccessToMultipleOrgs(payloadAccessibleOrgs, newToken);
        } else {
          setAuthToken(newToken);
          setLoading(false);
        }
      }).catch(async () => {
        let orgSubdomain = organizationKey;
        const input = {
          ...(organizationKey ? { subdomain: organizationKey } : { customUrl: window.location.host }),
        };
        const resp = await fetchOrganizationPublicSettings({ variables: { input } });
        orgSubdomain = resp?.data?.fetchOrganizationPublicSettings?.subdomain;
        if (resp) {
          setOrgPublicSettings(resp?.data?.fetchOrganizationPublicSettings);
        }
        const authParams = {
          authorizationParams: {
            redirect_uri: auth0RedirectUri,
            ...(orgSubdomain ? { organization: orgSubdomain } : {}),
            ...(connection ? { connection } : {}),
          },
        };
        if (allowPopup === 'true') {
          auth0.loginWithPopup(authParams).then((value) => { console.log({ value }); });
        } else {
          auth0.loginWithRedirect(authParams);
        }
      });
    };

    if (state) {
      auth0.handleRedirectCallback().then(() => {
        auth0.getTokenSilently().then(async (newToken: string) => {
          const payload = parseJwt(newToken);
          const respForLoggedUser = await fetchOrganizationPublicSettings({
            variables: {
              input: {
                subdomain: payload.org_name,
              },
            },
          });

          if (respForLoggedUser) {
            setOrgPublicSettings(respForLoggedUser?.data?.fetchOrganizationPublicSettings);
          }

          const payloadAccessibleOrgs = payload[`${JWT_CLAIMS_NAMESPACE}/accessibleOrgs`];
          if (payloadAccessibleOrgs && payloadAccessibleOrgs.length > 0) {
            handleAccessToMultipleOrgs(payloadAccessibleOrgs, newToken);
          } else if (!payload.permissions || payload.permissions.length === 0) {
            setLoading(true);
            const resp = await basicMe({
              context: { headers: { authorization: `Bearer ${newToken}` } },
              variables: { skipErrorHandler: true },
              onError: () => {
                setLoading(false);
                setSignUpNotAllowedDialog(true);
              },
            });
            if (resp.data?.me?.id) {
              auth0RefreshToken({ orgSubdomain: payload.org_name });
            }
          } else {
            console.log({ event: 'AUTHENTICATED_USER', userReferenceId: payload.sub });
            setAuthToken(newToken);
            setLoading(false);
            const path = localStorage.getItem('auth0RedirectPath');
            if (path) {
              const auth0RedirectPath = JSON.parse(path);
              if (dayjs().isBefore(auth0RedirectPath.expireAt)) {
                window.history.replaceState(null, '', auth0RedirectPath.locationPath);
              }
              localStorage.removeItem('auth0RedirectPath');
            }
          }
        }).catch(async (error) => {
          console.log({ error });
          let orgSubdomain = organizationKey;
          const input = {
            ...(organizationKey ? { subdomain: organizationKey } : { customUrl: window.location.host }),
          };
          const resp = await fetchOrganizationPublicSettings({
            variables: {
              input,
            },
          });
          orgSubdomain = resp?.data?.fetchOrganizationPublicSettings?.subdomain;
          if (resp) {
            setOrgPublicSettings(resp?.data?.fetchOrganizationPublicSettings);
          }
          auth0.loginWithRedirect({
            authorizationParams: {
              redirect_uri: auth0RedirectUri,
              ...(orgSubdomain ? { organization: orgSubdomain } : {}),
              ...(connection ? { connection } : {}),
            },
          });
        });
      }).catch(() => {
        getTokenSilentlyAuth0();
      });
    } else {
      getTokenSilentlyAuth0();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            margin: 'auto',
          }}
        >
          <CircularProgress />
        </Box>
      ) : accessibleOrgs && accessibleOrgs.length > 0 ? (
        <PublicOrganizationSelectDialog open={openOrganizationSelectDialog} onOrgSelect={onOrgSelect} filter={{ referenceIds: accessibleOrgs }} />
      ) : openSignUpNotAllowedDialog ? (
        <SignUpNotAllowedDialog />
      ) : (
        children
      )}
    </>
  );
};

export default Auth0Provider;
