import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash/fp';
import dayjs from 'dayjs';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import {
  ADJUSTED_COST_BASE_TYPES, Currencies, HOLDING_CONTRIBUTION_TRANSACTION_TYPES, TRADE_TYPES, Transaction,
} from '../../../../../interfaces/transaction';
import { Box } from '../../../../1-primative';
import {
  Button, DateField, Dialog, DialogContent, DialogFooter, DialogTitle, Form, MenuItem, TextField,
} from '../../../../2-component';
import { AmountField, SecuritySelect, NumberField } from '../../../../3-pattern';
import { DELETE_TRANSACTION } from './queries';

export const UPDATE_TRANSACTION = gql`
  mutation updateTransaction($input: UpdateTransactionInput!) {
    updateTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export interface TransactionUpdateInputPros {
  date?: string,
  valueCents?: number,
  description?: string,
  financialProductId?: string,
  quantity?: number,
  priceCents?: number,
  currency?: Currencies,
}

const UpdateTransaction = ({
  afterUpdate, transaction, open, handleClose, options,
}: {
  afterUpdate: () => void, transaction: Transaction, open: boolean, handleClose: () => void, options: any,
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const { showToast } = useGlobalToast();
  const [updateInput, setUpdateInput] = useState<TransactionUpdateInputPros>({});

  useEffect(() => {
    setUpdateInput({
      date: transaction.date ? dayjs(transaction.date).format('YYYY-MM-DD') : undefined,
      valueCents: Math.abs(transaction.valueCents ?? 0) / 100,
      description: transaction.description,
      financialProductId: transaction.financialProduct?.id,
      quantity: Math.abs(transaction.quantity ?? 0),
      priceCents: (transaction.priceCents ?? 0) / 100,
      currency: transaction.currency,
    });
  }, [transaction]);

  const [updateTransaction, { loading }] = useMutation(UPDATE_TRANSACTION, {
    variables: {
      input: {
        transactionId: transaction.id,
        ...pick(['description', 'date', 'currency'], updateInput),
        ...(TRADE_TYPES.includes(transaction.type) ? {
          ...pick(['financialProductId'], updateInput),
          priceCents: (updateInput.priceCents ?? 0) * 100,
          quantity: ((transaction.quantity ?? 0) >= 0 ? 1 : -1) * (updateInput.quantity ?? 0),
        } : {}),
        valueCents: ((transaction.valueCents ?? 0) >= 0 ? 1 : -1) * (updateInput.valueCents ?? 0) * 100,
      },
    },
  });

  const [deleteTransaction] = useMutation(DELETE_TRANSACTION, {
    variables: {
      transactionId: transaction.id,
    },
  });

  const update = async () => {
    const response = await updateTransaction();
    if (response?.data) {
      showToast({ severity: 'info', message: t('components:transaction.updateTransaction.updatedToastMessage') });
    }
    setUpdateInput({});
    afterUpdate();
    handleClose();
  };

  const deleteTrans = async () => {
    await deleteTransaction();
    setUpdateInput({});
    afterUpdate();
    handleClose();
  };

  const disabled = !(updateInput.date && (updateInput.valueCents !== undefined && updateInput.valueCents >= 0));

  const getPriceFieldLabel = () => t(`components:transaction.newTransaction.${ADJUSTED_COST_BASE_TYPES.includes(transaction.type) ? 'adjustedCostBase' : 'price'}`);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle onClose={handleClose}>
        {t('components:transaction.updateTransaction.title')}
      </DialogTitle>
      <Form onSubmit={update}>
        <DialogContent>
          <DateField
            label={t('components:transaction.newTransaction.date')}
            value={updateInput.date}
            fullWidth
            onChange={(date: any) => setUpdateInput((prev) => ({ ...prev, date: dayjs(date).format('YYYY-MM-DD') }))}
            renderInput={(params: any) => <TextField fullWidth {...params} />}
            disableFuture={true}
          />
          {transaction.type && TRADE_TYPES.includes(transaction.type) && (
            <>
              <SecuritySelect
                value={updateInput.financialProductId ?? ''}
                setSecurity={(e) => setUpdateInput((prev) => ({ ...prev, financialProductId: e.id }))}
                label={t('components:transaction.newTransaction.financialProduct')}
              sx={{ mt: 2 }}
              />
              <NumberField
                label={t('components:transaction.newTransaction.quantity')}
                sx={{ mt: 2 }}
                decimalPlaces={4}
                number={updateInput.quantity?.toString() ?? ''}
                setNumber={(value: any) => setUpdateInput((prev) => ({ ...prev, quantity: value }))}
              />
              <AmountField
                label={getPriceFieldLabel()}
                sx={{ mt: 2 }}
                amount={updateInput.priceCents?.toString() ?? ''}
                fullWidth
                setAmount={(e: any) => setUpdateInput((prev) => ({ ...prev, priceCents: e }))}
              />
            </>
          )}
          {!HOLDING_CONTRIBUTION_TRANSACTION_TYPES.includes(transaction.type) && (
            <AmountField
              label={t('components:transaction.newTransaction.amount')}
              sx={{ mt: 2 }}
              amount={updateInput.valueCents?.toString() ?? ''}
              fullWidth
              setAmount={(e: any) => setUpdateInput((prev) => ({ ...prev, valueCents: e }))}
            />
          )}
          <TextField
            label={t('components:transaction.newTransaction.details')}
            sx={{ mt: 2 }}
            fullWidth
            value={updateInput.description ?? ''}
            onChange={(e: any) => setUpdateInput((prev) => ({ ...prev, description: e.target.value }))}
          />
          <TextField
            select
            sx={{ mt: 2 }}
            label={t('components:transaction.table.currency')}
            fullWidth
            value={updateInput.currency ?? ''}
            placeholder={updateInput.currency ?? ''}
            onChange={(e: any) => setUpdateInput((prev) => ({ ...prev, currency: e.target.value as Currencies }))}
          >
              {Object.values(Currencies).map((x: Currencies) => (
                <MenuItem value={x} key={x}>{t(`components:transaction.currencies.${x}`)}</MenuItem>
              ))}
          </TextField>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='space-between' width='100%'>
            <Box>
              { options.canDelete && (
                <Button label={t('shared:delete')} disabled={disabled || loading} variant='tonal' color='destructive' onClick={deleteTrans} />
              )}
            </Box>
            <Button type='submit' label={t('shared:update')} disabled={disabled || loading} />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

export default UpdateTransaction;
