import { useThemeTokens } from 'providers/themeTokenProvider';
import { Typography } from '../../../../1-primative';

const TaxRank = ({ taxRank }: { taxRank: number }) => {
  const { sys } = useThemeTokens();
  return (
    <span style={{
      background: sys.color.onSurface, width: '12px', height: '12px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', borderRadius: '2px', marginRight: '4px',
    }}>
      <Typography sx={{ color: sys.color.surface, fontSize: '10px' }}>{taxRank}</Typography>
    </span>
  );
};

export default TaxRank;
