import { ListItem, MenuItem, TextField } from '@mui/material';
import FormModal from 'components/modals/formModal';
import {
  RelatedEntity, RelatedEntityInput, RelatedEntityTypes, User, nonIndividualRelationTypes,
} from 'interfaces';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { usePermissions } from 'providers/userContextProvider';
import { generateClientNameString } from '../../../util';
import { FETCH_USER_RELATED_ENTITIES } from './relatedEntitiesTable';

export const UPDATE_USER_RELATED_ENTITIES = gql`
  mutation updateUserRelatedEntities($userId: ID!, $relatedEntities: [RelatedEntityInput]) {
    updateUser(input: {
      userId: $userId
      relatedEntities: $relatedEntities
    }) {
      user { id }
    }
  }
`;

export const RelatedEntityEdit = ({
  user, existingRelatedEntities, entityToUpdate, onClose,
}: {
  user: User, existingRelatedEntities: RelatedEntity[], entityToUpdate: RelatedEntity, onClose: () => void
}) => {
  const { t } = useTranslation('nonIndividualClients');
  const { permissions } = usePermissions();

  const [relation, setRelation] = useState<RelatedEntityTypes>(entityToUpdate.relation);

  const [updateUserRelatedEntities] = useMutation(UPDATE_USER_RELATED_ENTITIES, {
    refetchQueries: [FETCH_USER_RELATED_ENTITIES(permissions)],
  });

  const save = async (event: any) => {
    event.preventDefault();
    const updatedRelatedEntities:RelatedEntityInput[] = existingRelatedEntities.map((relEnt:RelatedEntity) => (relEnt.entity.id === entityToUpdate.entity.id
      ? { entityId: relEnt.entity.id, relation }
      : { entityId: relEnt.entity.id, relation: relEnt.relation }));
    await updateUserRelatedEntities({
      variables: {
        userId: user.id,
        relatedEntities: updatedRelatedEntities,
      },
      onCompleted: onClose,
    });
  };

  return (
    <FormModal
      title={t('editRelationship')}
      open={true}
      onSubmit={save}
      loading={false}
      handleClose={onClose}
      formButton={t('shared:save')}
    >
      <ListItem>
        <TextField select fullWidth
            label={t('edit.relationshipTo', { whom: generateClientNameString(user) })}
            value={relation}
            onChange={(event) => setRelation(event.target.value as RelatedEntityTypes)}
          >
            {nonIndividualRelationTypes.map((relationType, index) => (
              <MenuItem key={index} value={relationType}>
                {t(`relatedEntityTypes:${relationType}`)}
              </MenuItem>
            ))}
          </TextField>
      </ListItem>
      <ListItem/>
    </FormModal>
  );
};
