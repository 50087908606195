/* eslint-disable no-return-assign */
import { useContext, useRef, useState } from 'react';
import {
  Button, Grid, TextField,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../../client/components/documentUpload';
import { UserContext } from '../../../providers/userContextProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { FETCH_DOCUMENTS } from '../../client/components/documents';

const EditableTemplate = () => {
  const { t } = useTranslation(['orgSettings', 'shared']);
  const editorRef = useRef<any>(null);
  const [uploadPending, setUploadPending] = useState(false);
  const { showToast } = useGlobalToast();
  const { activeOrganization, userContext } = useContext(UserContext);
  const [content, setContent] = useState('');
  const [text, setText] = useState();
  const [name, setName] = useState('');

  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);
  const log = async () => {
    if (editorRef.current) {
      const newHtml = new File([editorRef.current.getContent()], name, { type: 'text/html; charset=UTF-8' });
      await doUpload(newHtml);
    }
  };
  const onEditorChange = (a: string, editor: any) => {
    setContent(a);
    setText(editor.getContent({ format: 'text' }));
  };
  const doUpload = async (uploadingFile: File) => {
    const createFileInput: any = {
      objectType: 'ORGANIZATION',
      objectId: activeOrganization.id,
      fileName: uploadingFile?.name,
      type: 'TEMPLATE',
      sourceId: activeOrganization.id, // will be replaced by templateId or autogenerate Id
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: userContext.id } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    if (!uploadUrl || queryResult?.error) {
      showToast({ severity: 'error', message: `Upload failed, step 1 of 3 (FETCH_FILE_UPLOAD_URL) ${queryResult?.error}` });
      return;
    }
    /* (2) do the upload */
    try {
      setUploadPending(true);
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      showToast({ severity: 'error', message: `Upload failed, step 2 of 3 (HTTP PUT) ${e}` });
      return;
    } finally {
      setUploadPending(false);
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.sharedClient = true;
    createFileInput.sharedCustodian = true;
    createFileInput.permissionType = 'ORGANIZATIONAL';
    createFileInput.sourceType = 'TEMPLATE';

    try {
      await createFileDocument({
        variables: { input: createFileInput },
        refetchQueries: [FETCH_DOCUMENTS],
      });
    } catch (error) {
      showToast({ severity: 'error', message: `Upload failed, step 3 of 3 (CREATE_FILE_DOCUMENT) ${error}` });
      return;
    }
    showToast({ severity: 'success', message: `Document uploaded successfully: ${uploadingFile.name}` });
  };
  return (
    <Grid container spacing={2} px={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          data-testid="templateName"
          value={name}
          placeholder={t('orgSettings:editableTemplate.placeholder')}
          label={t('orgSettings:editableTemplate.templateName')}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Button disabled={!(name && text)} sx={{ marginBottom: '5px', width: '100%', height: '100%' }} variant='contained' onClick={log}>{t('orgSettings:editableTemplate.saveTemplate')}</Button>
      </Grid>
      <Grid item xs={12}>
        <Editor
          id='mceEditor'
          disabled={uploadPending}
          onEditorChange={onEditorChange}
          // initialValue={content}
          value={content}
          apiKey='h2ei7vjv47z3v0yht9gkp0a133mfefjlnr3ivbywfjhr0x2c' // will be coming from env variable
          onInit={(evt: any, editor: any) => editorRef.current = editor}
          init={{
            hidden_input: true,
            height: 900,
            menubar: true,
            // plugins: 'variable',
            plugins: [
              'variable', 'insert', 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'template',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
            ],
            toolbar: 'variable | save | undo redo | blocks | '
              + 'bold italic forecolor | alignleft aligncenter '
              + 'alignright alignjustify | bullist numlist outdent indent | '
              + 'template'
              + 'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            // save_enablewhendirty: true,
            variable_mapper: {
              organization_id: activeOrganization.id,
              user_first_name: userContext.firstName,
              user_last_name: userContext.lastName,
            },
            variable_valid: ['organization_id', 'user_first_name', 'user_last_name'],
            variable_prefix: '{{',
            variable_suffix: '}}',
            template_replace_values: {
              organization_id: activeOrganization.id,
              user_first_name: userContext.firstName,
              user_last_name: userContext.lastName,
            },
            template_preview_replace_values: {
              organization_id: activeOrganization.id,
              user_first_name: userContext.firstName,
              user_last_name: userContext.lastName,
            },
            templates: [
              {
                title: 'Replace values example',
                description: 'These values will be replaced when the template is inserted into the editor content.',
                content: '<p>Template createdBy: {$user_first_name} {$user_last_name}, OrgId: {$organization_id}</p>',
              },
            ],
            branding: false,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default EditableTemplate;
