import {
  TextField, ListItem, MenuItem, FormGroup, FormControlLabel, Switch, InputAdornment,
} from '@mui/material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { EXCHANGES_COUNTRIES_MAP, Exchanges, FinancialProductState } from '../../../interfaces/financialProduct';
import TranslatableString from '../../../components/inputs/translatableString';
import AssetClassSelect from '../../../components/inputs/assetClassSelect';
import FormModal from '../../../components/modals/formModal';
import ScheduleSelect from '../../../components/inputs/scheduleSelect';
import SecuritySelect from '../../../components/inputs/securitySelect';
import AmountField from '../../../components/inputs/amountField';
import { usePermissions } from '../../../providers/userContextProvider';
import { FETCH_FINANCIAL_PRODUCT } from '../../../components/tables/tradesTable/newTrade';

const UPDATE_PRODUCT = gql`
mutation updateFinancialProduct($input: UpdateFinancialProductInput!) {
  updateFinancialProduct(input: $input) {
    financialProduct {
      id
    }
  }
}
`;

const TRANSITION_PRODUCT = gql`
  mutation transitionFinancialProduct($input: TransitionFinancialProductInput!) {
    transitionFinancialProduct(input: $input) {
      financialProduct {
        id
      }
    }
  }
`;

export const isAlphaNumeric = (str: string): boolean => /^[a-zA-Z0-9]+$/.test(str);
export const isAlphanumericOrHasFullstop = (str: string): boolean => /^(\.[a-zA-Z0-9]+|[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*)$/.test(str);

const EditProduct = ({
  afterUpdate, productToUpdate, open, handleClose,
}: {
  afterUpdate: () => void, productToUpdate: any, open: boolean, handleClose: () => void,
}) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['configureModels']);
  const { showToast } = useGlobalToast();
  const [product, setProduct] = useState(productToUpdate);
  const [initialState] = useState(productToUpdate);
  const [localOpen, setLocalOpen] = useState(open);
  const [enableSchedules, setEnableSchedules] = useState(!!productToUpdate?.schedule);
  const [enableTertiaryAssetClass, setEnableTertiaryAssetClass] = useState(!!productToUpdate?.tertiaryAssetClass);
  const [invalidCusip, setInvalidCusip] = useState<boolean | undefined>(undefined);
  const [invalidIsin, setInvalidIsin] = useState<boolean | undefined>(undefined);
  const [invalidRic, setInvalidRic] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setProduct(productToUpdate);
    setEnableSchedules(!!productToUpdate?.schedule);
  }, [productToUpdate]);

  const [updateProduct, { loading }] = useMutation(UPDATE_PRODUCT, {
    variables: {
      input: {
        financialProductId: product?.id,
        translatedName: { en: product?.translatedName?.en, fr: product?.translatedName?.fr },
        ticker: product?.ticker,
        exchange: product?.exchange,
        primaryAssetClassId: product?.primaryAssetClass?.id ?? '',
        secondaryAssetClassId: product?.secondaryAssetClass?.id ?? '',
        tertiaryAssetClassId: enableTertiaryAssetClass ? product?.tertiaryAssetClass?.id ?? '' : null,
        substituteFinancialProductId: product?.substituteFinancialProduct?.id ?? undefined,
        autoUpdatePrices: product?.autoUpdatePrices,
        currentPriceCents: product?.currentPriceCents,
        scheduleId: enableSchedules ? product?.schedule?.id : null,
        waivedFeePercentage: product?.waivedFeePercentage,
        taxRank: product?.taxRank,
        volatile: product?.volatile,
        settlementDays: product?.settlementDays,
        url: product?.url,
        isPartial: product?.isPartial,
        cusip: product?.cusip ?? undefined,
        isin: product?.isin ?? undefined,
        ric: product?.ric ?? undefined,
      },
    },
  });

  useEffect(() => {
    setLocalOpen(open);
    setEnableTertiaryAssetClass(!!productToUpdate?.tertiaryAssetClass);
    setProduct(productToUpdate);
  }, [productToUpdate, open]);

  const update = async (event: any) => {
    event.preventDefault();
    await updateProduct();
    afterUpdate();
  };

  const [transitionProduct] = useMutation(TRANSITION_PRODUCT);
  const [fetchProductPriceHistoryURL] = useLazyQuery(FETCH_FINANCIAL_PRODUCT, {
    variables: {
      input: {
        financialProductId: product?.id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (!product) {
    return <></>;
  }

  const disabled = !product?.ticker
  || !product?.translatedName?.en
  || !product?.exchange
  || !product?.primaryAssetClass?.id
  || !product?.secondaryAssetClass?.id
  || (product?.cusip && !isAlphaNumeric(product?.cusip))
  || (product?.isin && !isAlphaNumeric(product?.isin))
  || (product?.ric && !isAlphanumericOrHasFullstop(product?.ric))
  || (enableTertiaryAssetClass && !product?.tertiaryAssetClass?.id);

  const exchanges = Object.keys(Exchanges).filter((key) => isNaN(Number(key))).sort();

  const transition = async (name: string) => {
    await transitionProduct({ variables: { input: { financialProductId: product.id, transition: name } } });
    if (name === 'archive') showToast({ severity: 'info', message: t('productModal.archiveToastMessage') });
    afterUpdate();
  };

  let menuItems: any[] | undefined = [];

  const getDownloadUrl = async () => {
    const result = await fetchProductPriceHistoryURL();
    const priceHistoryUrl = result?.data?.fetchFinancialProduct?.financialProduct?.priceHistoryUrl;
    if (!priceHistoryUrl) showToast({ severity: 'error', message: t('productModal.noHistoryToastMessage', { ticker: product?.ticker }) });
    return priceHistoryUrl;
  };

  const downloadPriceHistoryCSV = async () => {
    const url = await getDownloadUrl();
    window.open(url, '_blank');
  };

  if (permissions.includes('transition:financial_products')) {
    if (product.state !== FinancialProductState.ARCHIVED) {
      menuItems.push(
        <MenuItem key='archive' onClick={async () => transition('archive')}>
          {t('productModal.archive')}
        </MenuItem>,
      );
    }
  }

  if (permissions.includes('read:financial_products_snapshots')) {
    menuItems.push(
      <MenuItem key='download' onClick={async () => downloadPriceHistoryCSV()}>
        {t('productModal.download')}
      </MenuItem>,
    );
  }

  if (!menuItems.length) {
    menuItems = undefined;
  }

  return (
    <FormModal
      loading={loading}
      disabled={disabled}
      title={t('productModal.editTitle')}
      formButton={t('update')}
      onSubmit={update}
      open={localOpen}
      handleClose={handleClose}
      menuItems={menuItems}
      withCloseBtn
      state={product}
      initialState={initialState}
    >
      <ListItem>
        <TextField label={t('productModal.ticker')} fullWidth value={product.ticker} onChange={(e) => setProduct({ ...product, ticker: e.target.value })} />
      </ListItem>
      <ListItem>
        <TranslatableString label={t('productModal.name')} value={product.translatedName} onChange={(e) => setProduct({ ...product, translatedName: e })} />
      </ListItem>
      <ListItem>
        <TextField select label={t('productModal.exchange')} fullWidth value={product.exchange} onChange={(e) => setProduct({ ...product, exchange: e.target.value })}>
        {exchanges.map((option: any) => (
          <MenuItem key={option} value={option}>
            {option} {EXCHANGES_COUNTRIES_MAP[option] ? `(${EXCHANGES_COUNTRIES_MAP[option]})` : ''}
          </MenuItem>
        ))}
        </TextField>
      </ListItem>
      <ListItem>
        <AssetClassSelect
          value={product?.primaryAssetClass?.id ?? ''}
          setAssetClass={(e) => {
            setProduct({ ...product, primaryAssetClass: { id: e.target.value } });
          }}
          label={t('productModal.primaryAssetClass')}
        />
      </ListItem>
      <ListItem>
        <AssetClassSelect
          value={product?.secondaryAssetClass?.id ?? ''}
          setAssetClass={(e) => {
            setProduct({ ...product, secondaryAssetClass: { id: e.target.value } });
          }}
          label={t('productModal.secondaryAssetClass')}
        />
      </ListItem>
      <ListItem>
        <SecuritySelect
          value={product?.substituteFinancialProduct?.id ?? ''}
          setSecurity={(chosenProduct) => {
            setProduct({ ...product, substituteFinancialProduct: { id: chosenProduct.id } });
          }}
          label={t('productModal.substituteProduct')}
          exceptTickers={[product.ticker]}
        />
      </ListItem>
      <ListItem>
        <AmountField
          label={t('productModal.currentPrice')}
          fullWidth
          value={product.currentPriceCents}
          onChange={(e) => setProduct({ ...product, currentPriceCents: Math.round(Number(e.target.value) * 100) })}
        />
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={product.autoUpdatePrices}
              onChange={async (event) => setProduct({ ...product, autoUpdatePrices: event.target.checked })}
            />}
            label={t('productModal.autoUpdatePrices')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={enableSchedules}
              onChange={async (event) => {
                setEnableSchedules(event.target.checked);
              }}
            />}
            label={t('productModal.illiquidMessage')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={product.volatile}
              onChange={async (event) => setProduct({ ...product, volatile: event.target.checked })}
            />}
            label={t('productModal.volatile')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={product.isPartial}
              onChange={async (event) => setProduct({ ...product, isPartial: event.target.checked })}
            />}
            label={t('productModal.isPartial')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={enableTertiaryAssetClass}
              onChange={(event) => {
                setEnableTertiaryAssetClass(event.target.checked);
                if (!event.target.checked) setProduct({ ...product, tertiaryAssetClass: productToUpdate?.tertiaryAssetClass });
              }}
            />}
            label={t('productModal.tertiaryAssetClass')}
          />
        </FormGroup>
      </ListItem>
      {enableTertiaryAssetClass && (
        <ListItem>
          <AssetClassSelect
            value={product?.tertiaryAssetClass?.id ?? ''}
            setAssetClass={(e) => {
              setProduct({ ...product, tertiaryAssetClass: { id: e.target.value } });
            }}
            label={t('productModal.tertiaryAssetClass')}
          />
        </ListItem>
      )}
      {enableSchedules ? (<ListItem>
        <ScheduleSelect
          value={product?.schedule ? product.schedule.id : ''}
          setSchedule={(e) => {
            setProduct({ ...product, schedule: { id: e.target.value } });
          }}
          setScheduleData={(schedule: any) => {
            setProduct({ ...product, schedule });
          }}
          label={t('productModal.schedule')}
        />
      </ListItem>) : null}
      {enableSchedules && product?.schedule?.nextDate ? (
        <ListItem>
          <TextField
            label={t('productModal.nextDate')}
            fullWidth
            value={product.schedule.nextDate}
            disabled />
        </ListItem>
      ) : null}
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.waivedFeePercentage')}
          type='number'
          value={product.waivedFeePercentage ?? ''}
          onChange={(e) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const waivedFeePercentage = round(parseFloat(e.target.value), 2);
              if (waivedFeePercentage >= 0 && waivedFeePercentage <= 100) {
                setProduct((prev: any) => ({ ...prev, waivedFeePercentage }));
              }
            } else {
              setProduct((prev: any) => ({ ...prev, waivedFeePercentage: null }));
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.taxRank')}
          type='number'
          value={product.taxRank ?? ''}
          onChange={(e) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const taxRank = round(parseFloat(e.target.value), 2);
              if (taxRank >= 0) {
                setProduct((prev: any) => ({ ...prev, taxRank }));
              }
            } else {
              setProduct((prev: any) => ({ ...prev, taxRank: null }));
            }
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.settlementDays')}
          type='number'
          value={product.settlementDays ?? ''}
          onChange={(e) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const settlementDays = round(parseFloat(e.target.value), 2);
              if (settlementDays >= 0) {
                setProduct((prev: any) => ({ ...prev, settlementDays }));
              }
            } else {
              setProduct((prev: any) => ({ ...prev, settlementDays: null }));
            }
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.url')}
          value={product.url ?? ''}
          onChange={(e) => setProduct((prev: any) => ({ ...prev, url: e.target.value }))}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.cusip')}
          value={product.cusip ?? ''}
          onChange={(e) => {
            if (e.target.value !== '') {
              setInvalidCusip(!isAlphaNumeric(e.target.value));
              setProduct((prev: any) => ({ ...prev, cusip: e.target.value }));
            } else {
              setInvalidCusip(undefined);
              setProduct((prev: any) => ({ ...prev, cusip: undefined }));
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, cusip: product.cusip }))}
          error={invalidCusip}
          helperText={invalidCusip ? t('productModal.alphaNumericErrorMessage') : '22 characters are required.'}
          inputProps={{ maxLength: 22 }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.isin')}
          value={product.isin ?? ''}
          onChange={(e) => {
            if (e.target.value !== '') {
              setInvalidIsin(!isAlphaNumeric(e.target.value));
              setProduct((prev: any) => ({ ...prev, isin: e.target.value }));
            } else {
              setInvalidIsin(undefined);
              setProduct((prev: any) => ({ ...prev, isin: undefined }));
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, isin: product.isin }))}
          error={invalidIsin}
          helperText={invalidIsin ? t('productModal.alphaNumericErrorMessage') : '12 characters are required.'}
          inputProps={{ maxLength: 12 }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.ric')}
          value={product.ric ?? ''}
          onChange={(e) => {
            if (e.target.value !== '') {
              setInvalidRic(!isAlphanumericOrHasFullstop(e.target.value));
              setProduct((prev: any) => ({ ...prev, ric: e.target.value }));
            } else {
              setInvalidRic(undefined);
              setProduct((prev: any) => ({ ...prev, ric: undefined }));
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, ric: product.ric }))}
          error={invalidRic}
          helperText={invalidRic ? t('productModal.alphaNumericWithDotErrorMessage') : ''}
        />
      </ListItem>
    </FormModal>
  );
};

export default EditProduct;
