import { useContext, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { WorkflowContext } from '../../workflowCompletion';
import { GoalStates } from '../../../../../interfaces';
import { CreateSubAccountForAccountVisual } from './createSubAccountForAccount.visual';

const FETCH_GOALS = gql`
  query fetchGoals($input: FetchGoalsInput!) {
    fetchGoals(input: $input) {
      goals {
        id
        name
        type
        state
      }
    }
  }
`;

const FETCH_ACCOUNT = gql`
query fetchAccount($accountId: ObjectID!) {
  fetchAccount(accountId: $accountId) {
    account {
      id
      type
    }
  }
}
`;

const CREATE_SUB_ACCOUNT = gql`
  mutation createSubAccount($input: CreateSubAccountInput!) {
    createSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

export const CreateSubAccountForAccount = ({
  options, userId, onNext, stepLoading,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean }) => {
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const [selectedGoal, setSelectedGoal] = useState<string | null>(null);
  const [goals, setGoals] = useState([]);
  const [accountType, setAccountType] = useState('');

  const { loading: goalsLoading } = useQuery(FETCH_GOALS, {
    variables: {
      input: {
        filter: {
          userId,
          state: GoalStates.ACTIVE,
        },
        pagination: { perPage: 1000 },
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: ((goalsData) => {
      setGoals(goalsData.fetchGoals.goals);
    }),
  });

  const { loading: accountLoading } = useQuery(FETCH_ACCOUNT, {
    variables: { accountId: workflowData.currentAccountId },
    fetchPolicy: 'no-cache',
    onCompleted: ((accountData) => {
      setAccountType(accountData.fetchAccount.account.type);
    }),
  });

  const [createSubAccount, { loading }] = useMutation(CREATE_SUB_ACCOUNT, {
    variables: {
      input: {
        goalId: selectedGoal,
        accountId: workflowData.currentAccountId,
        accountType,
        userId,
      },
    },
    onCompleted: (d: any) => {
      setWorkflowData({ ...workflowData, currentSubAccountId: d.createSubAccount.subAccount.id });
      onNext();
    },
  });

  return (
    <CreateSubAccountForAccountVisual
      continueFunc={createSubAccount}
      goals={goals}
      selectedGoal={selectedGoal}
      loading={goalsLoading || accountLoading || loading || stepLoading}
      options={options}
      setSelectedGoal={setSelectedGoal}
    />
  );
};
