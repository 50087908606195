import {
  TextField, ListItem, MenuItem, FormGroup, FormControlLabel, Switch, InputAdornment,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import CreateNewModal from '../../../components/modals/createNewModal';
import TranslatableString from '../../../components/inputs/translatableString';
import AssetClassSelect from '../../../components/inputs/assetClassSelect';
import { UserContext } from '../../../providers/userContextProvider';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import ScheduleSelect from '../../../components/inputs/scheduleSelect';
import SecuritySelect from '../../../components/inputs/securitySelect';
import { EXCHANGES_COUNTRIES_MAP, Exchanges } from '../../../interfaces/financialProduct';
import { isAlphaNumeric, isAlphanumericOrHasFullstop } from './editProduct';

const CREATE_PRODUCT = gql`
mutation createFinancialProduct($input: CreateFinancialProductInput!) {
  createFinancialProduct(input: $input) {
    financialProduct {
      id
    }
  }
}
`;

const NewProduct = ({ afterCreate }: { afterCreate: () => void }) => {
  const { t } = useTranslation(['configureModels']);
  const { showToast } = useGlobalToast();
  const { activeOrganization } = useContext(UserContext);
  const [enableSchedules, setEnableSchedules] = useState(false);
  const [enableTertiaryAssetClass, setEnableTertiaryAssetClass] = useState(false);
  const [nextDate, setNextDate] = useState('');
  const emptyProduct = {
    ticker: '',
    url: '',
    translatedName: {
      en: '',
    },
    primaryAssetClassId: '',
    secondaryAssetClassId: '',
    tertiaryAssetClassId: null,
    substituteFinancialProductId: null,
    exchange: '',
    autoUpdatePrices: false,
    organizationId: activeOrganization.id,
    scheduleId: null,
    waivedFeePercentage: null as null | number,
    taxRank: null as null | number,
    settlementDays: 2,
    volatile: false,
    isPartial: true,
    cusip: undefined,
    isin: undefined,
    ric: undefined,
  };
  const [product, setProduct] = useState(emptyProduct);
  const [initialState] = useState({
    ticker: '',
    translatedName: {
      en: '',
    },
    primaryAssetClassId: '',
    secondaryAssetClassId: '',
    tertiaryAssetClassId: null,
    substituteFinancialProductId: null,
    exchange: '',
    autoUpdatePrices: false,
    organizationId: activeOrganization.id,
    scheduleId: null,
    waivedFeePercentage: null as null | number,
    taxRank: null as null | number,
    settlementDays: 2,
    volatile: false,
    isPartial: true,
  });

  const [invalidCusip, setInvalidCusip] = useState<boolean | undefined>(undefined);
  const [invalidIsin, setInvalidIsin] = useState<boolean | undefined>(undefined);
  const [invalidRic, setInvalidRic] = useState<boolean | undefined>(undefined);

  const onClose = () => {
    setProduct(emptyProduct);
    setEnableTertiaryAssetClass(false);
  };

  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT, {
    variables: {
      input: product,
    },
  });

  useEffect(() => {
    setProduct((prev) => ({ ...prev, organizationId: activeOrganization.id }));
  }, [activeOrganization]);

  const create = async (event: any) => {
    if (!product.scheduleId) {
      setProduct((current: any) => {
        //  remove scheduleId key from state if it is not assigned
        const { scheduleId, ...rest } = current;
        return rest;
      });
    }
    const response = await createProduct();
    if (response?.data) {
      showToast({ severity: 'success', message: t('productModal.newProductToastMessage') });
      setProduct({ ...product });
    }
    afterCreate();
  };
  const disabled = (enableSchedules && !product.scheduleId)
  || !product.translatedName.en
  || !product.exchange;
  const exchanges = Object.keys(Exchanges).filter((key) => isNaN(Number(key))).sort();
  return (
    <CreateNewModal
      initialState={initialState}
      state={product}
      onClose={onClose}
      loading={loading} disabled={disabled} title={t('productModal.title')} onSubmit={create} fullWidth sx={{ marginTop: 1 }} >
      <ListItem>
        <TextField label={t('productModal.ticker')} fullWidth value={product.ticker} onChange={(e) => setProduct({ ...product, ticker: e.target.value })} />
      </ListItem>
      <ListItem>
        <TranslatableString label={t('productModal.name')} value={product.translatedName} onChange={(e) => setProduct({ ...product, translatedName: e })} />
      </ListItem>
      <ListItem>
        <TextField select label={t('productModal.exchange')} fullWidth value={product.exchange} onChange={(e) => setProduct({ ...product, exchange: e.target.value })}>
        {exchanges.map((option) => (
          <MenuItem key={option} value={option}>
            {option} {EXCHANGES_COUNTRIES_MAP[option] ? `(${EXCHANGES_COUNTRIES_MAP[option]})` : ''}
          </MenuItem>
        ))}
        </TextField>
      </ListItem>
      <ListItem>
        <AssetClassSelect
          value={product.primaryAssetClassId}
          setAssetClass={(e) => {
            setProduct({ ...product, primaryAssetClassId: e.target.value });
          }}
          label={t('productModal.primaryAssetClass')}
        />
      </ListItem>
      <ListItem>
        <AssetClassSelect
          value={product.secondaryAssetClassId}
          setAssetClass={(e) => {
            setProduct({ ...product, secondaryAssetClassId: e.target.value });
          }}
          label={t('productModal.secondaryAssetClass')}
        />
      </ListItem>
      <ListItem>
        <SecuritySelect
          value={product.substituteFinancialProductId ?? ''}
          setSecurity={(chosenProduct) => {
            setProduct({ ...product, substituteFinancialProductId: chosenProduct.id });
          }}
          label={t('productModal.substituteProduct')}
        />
      </ListItem>
      <ListItem>
        <OrganizationSelect
          value={product.organizationId}
          onChange={(e) => {
            setProduct({ ...product, organizationId: e.target.value });
          }}
          label={t('productModal.organization')}
        />
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={product.autoUpdatePrices}
              onChange={async (event) => setProduct({ ...product, autoUpdatePrices: event.target.checked })}
            />}
            label={t('productModal.autoUpdatePrices')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={enableSchedules}
              onChange={async (event) => {
                setEnableSchedules(event.target.checked);
              }}
            />}
            label={t('productModal.illiquidMessage')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={product.volatile}
              onChange={async (event) => setProduct({ ...product, volatile: event.target.checked })}
            />}
            label={t('productModal.volatile')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={product.isPartial}
              onChange={async (event) => setProduct({ ...product, isPartial: event.target.checked })}
            />}
            label={t('productModal.isPartial')}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ height: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={enableTertiaryAssetClass}
              onChange={(event) => {
                setEnableTertiaryAssetClass(event.target.checked);
                if (!event.target.checked) setProduct({ ...product, tertiaryAssetClassId: null });
              }}
            />}
            label={t('productModal.tertiaryAssetClass')}
          />
        </FormGroup>
      </ListItem>
      {enableTertiaryAssetClass && (
        <ListItem>
          <AssetClassSelect
            value={product?.tertiaryAssetClassId ?? ''}
            setAssetClass={(e) => {
              setProduct({ ...product, tertiaryAssetClassId: e.target.value });
            }}
            label={t('productModal.tertiaryAssetClass')}
          />
        </ListItem>
      )}
      {enableSchedules ? (<ListItem>
        <ScheduleSelect
          value={product?.scheduleId ? product.scheduleId : ''}
          setSchedule={async (e) => {
            setProduct({ ...product, scheduleId: e.target.value });
          }}
          setScheduleData={(schedule: any) => {
            setNextDate(schedule.nextDate);
          }}
          label={t('productModal.schedule')}
        />
      </ListItem>) : null}
      {nextDate ? (
        <ListItem>
          <TextField
            label={t('productModal.nextDate')}
            fullWidth
            value={nextDate}
            disabled />
        </ListItem>
      ) : null}
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.waivedFeePercentage')}
          type='number'
          value={product.waivedFeePercentage ?? ''}
          onChange={(e) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const waivedFeePercentage = round(parseFloat(e.target.value), 2);
              if (waivedFeePercentage >= 0 && waivedFeePercentage <= 100) {
                setProduct((prev) => ({ ...prev, waivedFeePercentage }));
              }
            } else {
              setProduct((prev) => ({ ...prev, waivedFeePercentage: null }));
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.taxRank')}
          type='number'
          value={product.taxRank ?? ''}
          onChange={(e) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const taxRank = parseInt(e.target.value, 10);
              if (taxRank >= 0) {
                setProduct((prev) => ({ ...prev, taxRank }));
              }
            } else {
              setProduct((prev) => ({ ...prev, taxRank: null }));
            }
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.settlementDays')}
          type='number'
          value={product.settlementDays ?? ''}
          onChange={(e) => {
            if (e.target.value && !Number.isNaN(e.target.value)) {
              const settlementDays = round(parseFloat(e.target.value), 2);
              if (settlementDays >= 0) {
                setProduct((prev: any) => ({ ...prev, settlementDays }));
              }
            } else {
              setProduct((prev: any) => ({ ...prev, settlementDays: null }));
            }
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.url')}
          value={product.url ?? ''}
          onChange={(e) => setProduct((prev: any) => ({ ...prev, url: e.target.value }))}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.cusip')}
          value={product.cusip ?? ''}
          onChange={(e) => {
            if (e.target.value !== '') {
              setInvalidCusip(!isAlphaNumeric(e.target.value));
              setProduct((prev: any) => ({ ...prev, cusip: e.target.value }));
            } else {
              setProduct((prev: any) => ({ ...prev, cusip: undefined }));
              setInvalidCusip(undefined);
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, cusip: product.cusip }))}
          error={invalidCusip}
          helperText={invalidCusip ? t('productModal.alphaNumericErrorMessage') : '22 characters are required.'}
          inputProps={{ maxLength: 22 }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.isin')}
          value={product.isin ?? ''}
          onChange={(e) => {
            if (e.target.value !== '') {
              setInvalidIsin(!isAlphaNumeric(e.target.value));
              setProduct((prev: any) => ({ ...prev, isin: e.target.value }));
            } else {
              setInvalidIsin(undefined);
              setProduct((prev: any) => ({ ...prev, isin: undefined }));
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, isin: product.isin }))}
          error={invalidIsin}
          helperText={invalidIsin ? t('productModal.alphaNumericErrorMessage') : '12 characters are required.'}
          inputProps={{ maxLength: 12 }}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          label={t('productModal.ric')}
          value={product.ric ?? ''}
          onChange={(e) => {
            if (e.target.value !== '') {
              setInvalidRic(!isAlphanumericOrHasFullstop(e.target.value));
              setProduct((prev: any) => ({ ...prev, ric: e.target.value }));
            } else {
              setInvalidRic(undefined);
              setProduct((prev: any) => ({ ...prev, ric: undefined }));
            }
          }}
          onBlur={() => setProduct((prev: any) => ({ ...prev, ric: product.ric }))}
          error={invalidRic}
          helperText={invalidRic ? t('productModal.alphaNumericWithDotErrorMessage') : ''}
        />
      </ListItem>
    </CreateNewModal>
  );
};

export default NewProduct;
