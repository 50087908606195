import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash/fp';
import { Box, Grid, Typography } from '../../1-primative';
import { translateBackend } from '../../../assets/i18n/config';
import { AdvisorContactItem } from '../advisorContactItem/advisorContactItem';
import { OrganizationUserEntityRelationTypes } from '../../../interfaces';
import { PageObjectType } from '../../5-page/pageConfiguration/pageConfiguration';

export const FETCH_ORG_USERS_WITH_ACCESS_TO_ENTITY = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        accessibleBy {
          organizationUser {
            id
            firstName
            lastName
            email
            phone
            avatar
          }
          displayOptions {
            hide
            order
          }
          relation
        }
      }
    }
  }
`;

export const FETCH_CLIENTGROUP_RELATIONSHIPS = gql`
  query fetchClientGroupRelationships($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId:$clientGroupId) {
      clientGroup {
        relationships {
          user {
            id
            accessibleBy {
              organizationUser {
                id
                firstName
                lastName
                email
                phone
                avatar
              }
              displayOptions {
                hide
                order
              }
              relation
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_DISPLAY_OPTIONS = gql`
  mutation updateUserOrganizationUserDisplayOptions($input: UpdateUserOrganizationUserDisplayOptionsInput!) {
    updateUserOrganizationUserDisplayOptions(input: $input) {
      userOrganizationUserDisplayOptions {
        organizationUser {
          id
        }
        user {
          id
        }
        hide
        order
      }
    }
  }
`;

export const AdvisorContactInformation = ({ objectId, objectType, options }: { objectId: string, objectType: PageObjectType, options: any }) => {
  const [advisors, setAdvisors] = useState<any[]>([]);
  const { t } = useTranslation(['components']);

  const isEntity = [PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType);
  const [updateDisplayOptions] = useMutation(UPDATE_DISPLAY_OPTIONS);
  const { data } = useQuery(FETCH_ORG_USERS_WITH_ACCESS_TO_ENTITY, {
    variables: {
      userId: objectId,
    },
    skip: !objectId || !isEntity,
  });

  const { data: resp } = useQuery(FETCH_CLIENTGROUP_RELATIONSHIPS, {
    variables: {
      clientGroupId: objectId,
    },
    skip: !objectId || !!isEntity,
  });

  useEffect(() => {
    if (data) {
      const advisorsMap = data.fetchUser.user.accessibleBy.filter((x: any) => x.relation === OrganizationUserEntityRelationTypes.ADVISOR).map((x: any) => ({
        ...options,
        name: { en: `${x?.organizationUser?.firstName ?? ''} ${x?.organizationUser?.lastName ?? ''}` },
        email: x?.organizationUser?.email,
        phoneNumber: x?.organizationUser?.phone,
        attachment: x?.organizationUser?.avatar,
        role: { en: t(`components:accessToEntity.relations.${x?.relation}`) },
        userId: data?.fetchUser?.user?.id,
        organizationUserId: x?.organizationUser?.id,
        hide: x?.displayOptions?.hide,
        order: x?.displayOptions?.order,
      }));
      setAdvisors(advisorsMap);
    }
    // eslint-disable-next-line
  }, [data, options]);

  useEffect(() => {
    if (resp) {
      const allAdvisors = resp?.fetchClientGroup?.clientGroup?.relationships?.map((relationship: any) => ({
        accessibleBy: relationship.user.accessibleBy.map((x: any) => ({
          ...x,
          user: relationship.user,
        })),
      })).flatMap((r: any) => r.accessibleBy) ?? [];
      const advisorsMap = _.uniqBy(allAdvisors.filter((x: any) => x.relation === OrganizationUserEntityRelationTypes.ADVISOR), 'organizationUser.id')
        .map((x: any) => ({
          ...options,
          name: { en: `${x?.organizationUser?.firstName ?? ''} ${x?.organizationUser?.lastName ?? ''}` },
          email: x?.organizationUser?.email,
          phoneNumber: x?.organizationUser?.phone,
          attachment: x?.organizationUser?.avatar,
          role: { en: t(`components:accessToEntity.relations.${x?.relation}`) },
          userId: x?.user?.id,
          organizationUserId: x?.organizationUser?.id,
          hide: x?.displayOptions?.hide,
          order: x?.displayOptions?.order,
        }));
      setAdvisors(advisorsMap);
    }
    // eslint-disable-next-line
  }, [resp, options]);

  const ignoreDefault = options?.attachment === ''
    && translateBackend(options?.name) === ''
    && options?.email === ''
    && options?.link === ''
    && options?.phoneNumber === ''
    && translateBackend(options?.role) === ''
    && translateBackend(options?.nickName) === '';

  const replaceDefault = options.replaceDefault && advisors.length > 0;
  const advisorsList = (replaceDefault || ignoreDefault || !options.displayDefaultContact) ? advisors : [options, ...advisors];

  const onChange = (organizationUserId: string, hide: boolean, order: number) => {
    const newAdvisors = advisors.map((x: any) => {
      if (x.organizationUserId === organizationUserId) {
        return {
          ...x,
          hide,
          order,
        };
      }
      return x;
    });
    setAdvisors(newAdvisors);
    const grouped = groupBy(({ userId }) => userId, newAdvisors);
    Object.keys(grouped).forEach((userId: string) => {
      updateDisplayOptions({
        variables: {
          input: {
            userId,
            displayOptions: grouped[userId].map((x: any) => ({
              organizationUserId: x.organizationUserId,
              hide: x.hide,
              order: parseInt(x.order, 10),
            })),
          },
        },
      });
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginTop="32px">
        <Typography variant='headingSmall' mb={2}>{translateBackend(options.customTitle)}</Typography>
      </Box>
      <Grid container spacing={2}>
        {advisorsList
          .filter((x: any) => !x.hide || options.allowEditing)
          .sort((a: any, b: any) => (a.order > b.order ? 1 : -1))
          .map((x: any) => (
            <Grid item xs={12} md={6} key={x.email}>
              <AdvisorContactItem options={x} onChange={onChange} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};
