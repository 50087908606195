import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import { RelationshipInformationVisual } from './relationshipInformation.visual';

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        maritalStatus
        spouseFirstName
        spouseMiddleName
        spouseLastName
        spouseEmail
        spouseSin
        spouseDateOfBirth
        spouseEmploymentType
        spouseCompanyType
        spouseJobTitle
        numberOfDependents
        trustedContactPerson {
          name
          relation
          physicalAddress { city province streetName postal unitNumber houseNumber neighborhood country }
          email
          phone
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const RelationshipInformation = ({
  options, userId, onNext, stepLoading, workflowCompletion, grid = false, updateMode = false,
}: {
  options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, grid?: boolean, updateMode?: boolean,
}) => {
  const [userData, setUserData] = useState<any>({
    maritalStatus: '',
    spouseFirstName: '',
    spouseMiddleName: '',
    spouseLastName: '',
    spouseEmail: '',
    spouseSin: '',
    spouseEmploymentStatus: '',
    spouseCompanyType: '',
    spouseJobTitle: '',
    spouseDateOfBirth: '',
    numberOfDependents: 0,
    trustedContactPerson: {
      name: '',
      physicalAddress: {
        city: '',
        province: '',
        streetName: '',
        postal: '',
        unitNumber: '',
        houseNumber: '',
        neighborhood: '',
        country: '',
      },
      email: '',
      phone: '',
      relation: '',
    },
  });

  const { data, loading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        ...userData,
        trustedContactPerson: {
          ...userData.trustedContactPerson,
          physicalAddress: isEmpty(userData.trustedContactPerson.physicalAddress) ? undefined : userData.trustedContactPerson.physicalAddress,
        },
        userId,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setUserData({
        maritalStatus: data.fetchUser.user.maritalStatus || undefined,
        spouseFirstName: data.fetchUser.user.spouseFirstName || undefined,
        spouseMiddleName: data.fetchUser.user.spouseMiddleName || undefined,
        spouseLastName: data.fetchUser.user.spouseLastName || undefined,
        spouseEmail: data.fetchUser.user.spouseEmail || undefined,
        spouseSin: data.fetchUser.user.spouseSin || undefined,
        spouseCompanyType: data.fetchUser.user.spouseCompanyType || undefined,
        spouseJobTitle: data.fetchUser.user.spouseJobTitle || undefined,
        spouseDateOfBirth: data.fetchUser.user.spouseDateOfBirth || null,
        numberOfDependents: data.fetchUser.user.numberOfDependents || null,
        trustedContactPerson: {
          name: data.fetchUser.user.trustedContactPerson?.name || undefined,
          physicalAddress: data.fetchUser.user.trustedContactPerson?.physicalAddress ? {
            city: data.fetchUser.user.trustedContactPerson?.physicalAddress?.city || undefined,
            province: data.fetchUser.user.trustedContactPerson?.physicalAddress?.province || undefined,
            streetName: data.fetchUser.user.trustedContactPerson?.physicalAddress?.streetName || undefined,
            postal: data.fetchUser.user.trustedContactPerson?.physicalAddress?.postal || undefined,
            unitNumber: data.fetchUser.user.trustedContactPerson?.physicalAddress?.unitNumber || undefined,
            houseNumber: data.fetchUser.user.trustedContactPerson?.physicalAddress?.houseNumber || undefined,
            neighborhood: data.fetchUser.user.trustedContactPerson?.physicalAddress?.neighborhood || undefined,
            country: data.fetchUser.user.trustedContactPerson?.physicalAddress?.country || undefined,
          } : {},
          email: data.fetchUser.user.trustedContactPerson?.email || undefined,
          phone: data.fetchUser.user.trustedContactPerson?.phone || undefined,
          relation: data.fetchUser.user.trustedContactPerson?.relation || undefined,
        },
      });
    }
  }, [data]);

  return (
    <RelationshipInformationVisual
      options={options}
      userData={userData}
      updateUser={setUserData}
      continueFunc={updateUser}
      loading={loading || updateUserLoading || stepLoading}
      grid={grid}
      updateMode={updateMode}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default RelationshipInformation;
