import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Typography, Box, ActionContext,
} from '../../../..';
import { TransferContext } from '../../../depositWorkflow/depositWorkflow';
import { getSubAccountName } from '../../../../../interfaces';
import { formatMoneyValue } from '../../../../../util';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import {
  CREATE_DEPOSIT_TRANSFER, CREATE_EXTERNAL_TRANSFER, CREATE_SCHEDULED_DEPOSIT_TRANSFER,
  TRANSFER_ALL_BETWEEN_SUB_ACCOUNTS, TRANSFER_BETWEEN_SUB_ACCOUNTS,
} from './depositReview.queries';
import { translateBackend } from '../../../../../assets/i18n/config';
import { useLocalization } from '../../../../../util/useLocalization';

export const DepositReview = ({
  options, onNext,
}: {
  options: any, onNext: any,
}) => {
  const { t } = useTranslation('workflowCompletions');
  const { localizedDate } = useLocalization();
  const { transferData, setTransferData } = useContext(TransferContext);
  const { refetch, refreshWidgets } = useContext(ActionContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { showToast } = useGlobalToast();
  const { sys } = useThemeTokens();

  const [createDepositTransfer] = useMutation(CREATE_DEPOSIT_TRANSFER);
  const [createScheduledDepositTransfer] = useMutation(CREATE_SCHEDULED_DEPOSIT_TRANSFER);
  const [createTransferBetweenSubAccounts] = useMutation(TRANSFER_BETWEEN_SUB_ACCOUNTS);
  const [createTransferAllBetweenSubAccounts] = useMutation(TRANSFER_ALL_BETWEEN_SUB_ACCOUNTS);
  const [createExternalTransfer] = useMutation(CREATE_EXTERNAL_TRANSFER);

  const fullTransfer = () => transferData?.fromSubAccount?.statistics?.marketValueCents && transferData.amountCents > (transferData?.fromSubAccount?.statistics?.marketValueCents || 0) * 0.97;

  const submit = () => {
    setLoading(true);
    if (transferData.type === 'EFT') {
      if (transferData.schedule === 'ONE_TIME') {
        createDepositTransfer({
          variables: {
            input: {
              amountCents: transferData.amountCents,
              bankAccountId: transferData.bankAccount.id,
              subAccountId: transferData.subAccount.id,
            },
          },
          onCompleted: () => {
            onNext();
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      } else {
        createScheduledDepositTransfer({
          variables: {
            input: {
              amountCents: transferData.amountCents,
              bankAccountId: transferData.bankAccount.id,
              subAccountId: transferData.subAccount.id,
              scheduledDate: transferData.scheduledDate,
              frequency: transferData.schedule,
            },
          },
          onCompleted: () => {
            setTransferData({ ...transferData, skipDocuments: false });
            onNext();
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      }
    } else if (transferData.type === 'INTERNAL_TRANSFER') {
      if (!fullTransfer()) {
        createTransferBetweenSubAccounts({
          variables: {
            input: {
              amountCents: transferData.amountCents,
              fromSubAccountId: transferData.fromSubAccount.id,
              toSubAccountId: transferData.subAccount.id,
            },
          },
          onCompleted: () => {
            onNext();
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      } else {
        createTransferAllBetweenSubAccounts({
          variables: {
            input: {
              fromSubAccountId: transferData.fromSubAccount.id,
              toSubAccountId: transferData.subAccount.id,
            },
          },
          onCompleted: () => {
            onNext();
            refetch();
            setLoading(false);
            showToast({
              message: t('depositReview.success'),
              severity: 'success',
            });
            if (refreshWidgets) refreshWidgets();
          },
        });
      }
    } else {
      createExternalTransfer({
        variables: {
          input: {
            amountCents: transferData.amountCents,
            subAccountId: transferData.subAccount.id,
            institution: { id: transferData.institution.id },
            transferAccountNumber: transferData.accountNumber,
            transferMethod: transferData.transferType,
            transferAccount: transferData.accountType,
          },
        },
        onCompleted: () => {
          onNext();
          refetch();
          setLoading(false);
          showToast({
            message: t('depositReview.success'),
            severity: 'success',
          });
          if (refreshWidgets) refreshWidgets();
        },
      });
    }
  };

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      { transferData?.type === 'EFT' && (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.fromBankAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{`${transferData.bankAccount.name} ${transferData.bankAccount.bankAccountNumber}`}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.toSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(transferData.subAccount)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.amount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{formatMoneyValue(transferData.amountCents)}</Typography>
          </Box>
          { transferData.schedule !== 'ONE_TIME' && (
            <>
              <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
                <Typography variant='bodyLarge'>{t('depositReview.schedule')}</Typography>
                <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t(`schedule.${transferData.schedule}`)}</Typography>
              </Box>
              <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
                <Typography variant='bodyLarge'>{t('depositReview.startsOn')}</Typography>
                <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{localizedDate(transferData.scheduledDate)}</Typography>
              </Box>
            </>
          )}
          <Box sx={{
            mb: 1, background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.lg, p: 2,
          }}>
            <Typography variant='bodySmall'>
              {translateBackend(options.eftDisclaimer)}
            </Typography>
          </Box>
        </>
      )}
      { transferData?.type === 'INTERNAL_TRANSFER' && (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.fromSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(transferData.fromSubAccount)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.toSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(transferData.subAccount)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.amount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{fullTransfer() ? t('depositReview.fullTransfer') : formatMoneyValue(transferData.amountCents)}</Typography>
          </Box>
          <Box sx={{
            mb: 1, background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.lg, p: 2,
          }}>
            <Typography variant='bodySmall'>
              {translateBackend(options.internalTransferDisclaimer)}
            </Typography>
          </Box>
        </>
      )}
      { transferData?.type === 'EXTERNAL_TRANSFER' && (
        <>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.toSubAccount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(transferData.subAccount)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.financialInstitution')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{transferData.institution.name}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.accountNumber')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{transferData.accountNumber}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.accountType')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{transferData.accountType}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.transferType')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t(`depositReview.${transferData.transferType}`)}</Typography>
          </Box>
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('depositReview.amount')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{fullTransfer() ? t('depositReview.fullTransfer') : formatMoneyValue(transferData.amountCents)}</Typography>
          </Box>
          <Box sx={{
            mb: 1, background: sys.color.surfaceContainerVariant, borderRadius: sys.borderRadius.lg, p: 2,
          }}>
            <Typography variant='bodySmall'>
              {translateBackend(options.externalTransferDisclaimer)}
            </Typography>
          </Box>
        </>
      )}
      <Box display='flex' justifyContent='end'>
        <Button label={t('depositReview.createDeposit')} sx={{ mt: 3, textAlign: 'center' }} disabled={loading} onClick={submit} />
      </Box>
    </>
  );
};

export default DepositReview;
