import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Close, Edit } from '@mui/icons-material';
import {
  Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, Button,
  SelectField,
  MenuItem,
} from '../../../2-component';
import {
  FETCH_MODEL_PORTFOLIOS, FETCH_SUB_ACCOUNT, UPDATE_SUB_ACCOUNT,
  FETCH_GOAL, UPDATE_GOAL,
} from './changeTheme.queries';
import { Box, Skeleton, Typography } from '../../../1-primative';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../assets/i18n/config';
import { ThemeSelect } from '../../../3-pattern/themeSelect/themeSelect';
import { UserContext } from '../../../../providers/userContextProvider';
import { PageObjectType } from '../../../5-page';

export const ChangeTheme = ({ objectId, objectType, options }: { objectId: string, objectType: PageObjectType, options: any }) => {
  const { t } = useTranslation('components');
  const [theme, setTheme] = useState<any>();
  const [modelPortfolio, setModelPortfolio] = useState<any>();
  const [portfolios, setPortfolios] = useState<any[]>();
  const [object, setObject] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { sys } = useThemeTokens();
  const { activeOrganization } = useContext(UserContext);

  const { data: subAccountData, loading: subAccountLoading, refetch: subAccountRefetch } = useQuery(FETCH_SUB_ACCOUNT, {
    variables: {
      subAccountId: objectId,
    },
    fetchPolicy: 'no-cache',
    skip: objectType !== PageObjectType.SUB_ACCOUNT,
  });

  const { data: goalData, loading: goalLoading, refetch: goalRefetch } = useQuery(FETCH_GOAL, {
    variables: {
      goalId: objectId,
    },
    fetchPolicy: 'no-cache',
    skip: objectType !== PageObjectType.GOAL,
  });

  const refetch = () => {
    if (objectType === PageObjectType.SUB_ACCOUNT) subAccountRefetch();
    if (objectType === PageObjectType.GOAL) goalRefetch();
  };

  useQuery(FETCH_MODEL_PORTFOLIOS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          themeId: theme?.id,
          state: 'ACTIVE',
        },
        pagination: { perPage: 1000 },
      },
    },
    onCompleted: (d: any) => {
      setPortfolios(d.fetchModelPortfolios.modelPortfolios);
    },
    fetchPolicy: 'no-cache',
  });

  const [updatePortfolio] = useMutation(objectType === PageObjectType.SUB_ACCOUNT ? UPDATE_SUB_ACCOUNT : UPDATE_GOAL);

  const [updateTheme] = useMutation(objectType === PageObjectType.SUB_ACCOUNT ? UPDATE_SUB_ACCOUNT : UPDATE_GOAL, {
    variables: {
      input: {
        subAccountId: objectType === PageObjectType.SUB_ACCOUNT ? objectId : undefined,
        goalId: objectType === PageObjectType.GOAL ? objectId : undefined,
        themeId: objectType === PageObjectType.SUB_ACCOUNT ? theme?.id : undefined,
        financialProductId: options.setModelPortfolio ? modelPortfolio?.id : undefined,
      },
    },
    onCompleted: (d: any) => {
      if (!options.setModelPortfolio && objectType === PageObjectType.SUB_ACCOUNT) {
        updatePortfolio({
          variables: {
            input: {
              subAccountId: objectType === PageObjectType.SUB_ACCOUNT ? objectId : undefined,
              financialProductId: d.updateSubAccount?.subAccount?.suggestedFinancialProduct?.id,
            },
          },
          onCompleted: () => {
            setOpen(false);
            refetch();
          },
        });
      } else {
        setOpen(false);
        refetch();
      }
    },
  });

  useEffect(() => {
    if (subAccountData || goalData) {
      const d = objectType === PageObjectType.SUB_ACCOUNT ? subAccountData.fetchSubAccount.subAccount : goalData.fetchGoal.goal;
      setObject(d);
      setTheme(d?.theme || d?.financialProduct?.theme);
      setModelPortfolio(d.financialProduct);
    }
  }, [subAccountData, goalData, objectType]);

  useEffect(() => {
    if (subAccountLoading || goalLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [subAccountLoading, goalLoading]);

  if (loading) {
    return (
      <Skeleton width='100%' height='80px' variant='rectangular'></Skeleton>
    );
  }

  return (
    <Card>
      <CardContent sx={{ paddingBottom: '16px !important' }}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='row'>
              <Typography variant='labelLarge' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>{t('changeTheme.portfolioTheme')}:</Typography>
              <Typography variant='labelLarge' sx={{ ml: 1 }}>
                {translateBackend(object?.theme?.translatedName || object?.financialProduct?.theme?.translatedName) || t('changeTheme.unset')}
              </Typography>
            </Box>
            { options.setModelPortfolio && (
               <Box display='flex' flexDirection='row' mt={1}>
                <Typography variant='labelLarge' sx={{ color: sys.color.onSurfaceVariant, whiteSpace: 'nowrap' }}>{t('changeTheme.modelPortfolio')}:</Typography>
                <Typography variant='labelLarge' sx={{ ml: 1 }}>{translateBackend(object?.financialProduct?.translatedName) || t('changeTheme.unset')}</Typography>
              </Box>
            )}
          </Box>
          <IconButton size='medium' sx={{ height: '32px' }} onClick={() => setOpen(true)}><Edit sx={{ fontSize: '16px' }}/></IconButton>
        </Box>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
          <DialogTitle>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
              {t('changeTheme.portfolioTheme')}
              <IconButton onClick={() => setOpen(false)}><Close /></IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <ThemeSelect
              label={t('changeTheme.portfolioTheme')}
              onThemeSelect={(th: any) => setTheme(th)}
              selectedTheme={theme}
              filter={{ organizationId: activeOrganization.id }}
            />
            <Typography variant='bodyLarge' sx={{ mt: 2 }}>{translateBackend(theme?.translatedDescription || {})}</Typography>
            { options.setModelPortfolio && (
              <SelectField
                label={t('changeTheme.modelPortfolio')}
                value={modelPortfolio?.id}
                onChange={(e: any) => setModelPortfolio(portfolios?.find((x: any) => x.id === e.target.value))}
                sx={{ mt: 2 }}
                fullWidth
              >
                {portfolios?.map((p: any) => (
                  <MenuItem key={p.id} value={p.id}>
                    {translateBackend(p.translatedName)}{object?.suggestedFinancialProduct?.id === p.id && ` (${t('changeTheme.suggested')})`}
                  </MenuItem>
                ))}
              </SelectField>
            )}
            <Box display='flex' justifyContent='flex-end' sx={{ mt: 3 }}>
              <Button label={t('changeTheme.save')} onClick={() => updateTheme()} />
            </Box>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};
