import {
  Table as MuiTable, TableProps,
  TableBody as MuiTableBody, TableBodyProps,
  TableRow as MuiTableRow, TableRowProps as MuiTableRowProps,
  TableCell as MuiTableCell, TableCellProps as MuiTableCellProps,
  TableHead as MuiTableHead, TableHeadProps as MuiTableHeadProps,
  SxProps,
  SortDirection,
} from '@mui/material';
import { omit } from 'lodash';
import { ReactNode } from 'react';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import Ascending from '../../../assets/images/sorting/Ascending.svg';
import Descending from '../../../assets/images/sorting/Descending.svg';
import Unsorted from '../../../assets/images/sorting/Unsorted.svg';

interface TableCellProps extends MuiTableCellProps {
  dense?: boolean;
  number?: boolean;
  right?: boolean;
  bold?: boolean;
  maxWidth?: string;
  leadingComponent?: ReactNode;
  isSortable?: boolean;
  isFirst?: boolean;
}

interface TableRowProps extends MuiTableRowProps {
  pointer?: boolean;
}
const extraTableCellProps = ['dense', 'number', 'right', 'bold', 'maxWidth'];

export const Table = (props: TableProps) => (<MuiTable {...props} sx={[{ overflow: 'auto' }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]} />);
export const TableBody = (props: TableBodyProps) => (<MuiTableBody {...props} sx={[{ display: 'table', width: '100%' }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]} />);
export const TableHead = (props: MuiTableHeadProps) => (<MuiTableHead {...props} sx={[{ display: 'table', width: '100%' }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]} />);

export const TableRow = (props: TableRowProps) => {
  const { sys } = useThemeTokens();
  return (<MuiTableRow {...props} sx={{
    backgroundColor: `${sys.color.surface} !important`,
    '&:hover': {
      backgroundColor: props.hover ? `${sys.color.surfaceContainerVariant} !important` : undefined,
      cursor: props.pointer ? 'pointer' : undefined,
    },
  }} />);
};

export const TableCell = (props: TableCellProps) => {
  const { sys } = useThemeTokens();
  const { sx, isFirst, ...restProps } = props;

  const baseSx: SxProps = {
    borderBottom: `1px solid ${sys.color.outlineVariant}`,
    height: !props.dense ? '32px' : 'auto',
    textAlign: props.right || props.number ? 'right' : 'left',
    color: sys.color.onSurface,
  };
  const composedSx = [baseSx, ...(isFirst ? [{ position: 'sticky', left: 0, backgroundColor: 'inherit' }] : []), ...(Array.isArray(sx) ? sx : [sx])];

  return (
    <MuiTableCell sx={composedSx} {...omit(restProps, extraTableCellProps)}>
      <Typography variant='bodySmall' weight={props.bold ? 'bold' : 'regular'} sx={{
        fontVariant: props.number ? 'tabular-nums' : 'normal',
        maxWidth: props.maxWidth,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: 'inherit',
        ...(props.leadingComponent
          ? {
            display: 'flex', alignItems: 'center', gap: 1, justifyContent: props.right ? 'end' : 'start',
          }
          : {}
        ),
      }}>
        {props.leadingComponent ? (
          <>
            <Box>{props.leadingComponent}</Box>
            <Box>{props.children}</Box>
          </>
        ) : props.children}
      </Typography>
    </MuiTableCell>
  );
};

const SortIcon = ({ direction }: { direction: SortDirection | undefined }) => {
  const icon = direction === 'asc' ? Ascending : direction === 'desc' ? Descending : Unsorted;

  return <img src={icon} alt={direction || 'unsorted'} style={{ marginLeft: '8px' }} />;
};

export const TableHeadCell = (props: TableCellProps) => {
  const { sys } = useThemeTokens();
  const { sx, isFirst, ...restProps } = props;

  const baseSx: SxProps = {
    outline: `1px solid ${sys.color.surface}`,
    outlineOffset: '-1px',
    borderBottom: 'none',
    textAlign: props.right || props.number ? 'right' : 'left',
    verticalAlign: 'bottom',
    background: sys.color.background,
    padding: '14px 16px',
    cursor: props.isSortable ? 'pointer' : 'default',
    '&:hover': {
      background: props.isSortable ? sys.color.neutralPressed : sys.color.background,
    },
  };

  const composedSx = [baseSx, ...(isFirst ? [{ position: 'sticky', left: 0 }] : []), ...(Array.isArray(sx) ? sx : [sx])];

  return (
    <MuiTableCell sx={composedSx} {...omit(restProps, extraTableCellProps)}>
      <Box display='flex' alignItems='center' justifyContent={ props.right ? 'end' : 'start' } onClick={(e: any) => { props.isSortable && props.onClick && props.onClick(e); }}>
        <Typography variant='labelSmall' weight='bold' sx={{ color: sys.color.onSurfaceVariant }}>{props.children}</Typography>
        { props.isSortable && <SortIcon direction={props.sortDirection} /> }
      </Box>
    </MuiTableCell>
  );
};

export const TableTotalCell = (props: TableCellProps) => {
  const { sys } = useThemeTokens();
  const { sx, isFirst, ...restProps } = props;

  const baseSx: any = {
    borderTop: `1px solid ${sys.color.outline}`,
    borderBottom: 'none',
    height: !props.dense ? '32px' : 'auto',
    textAlign: props.right || props.number ? 'right' : 'left',
    backgroundColor: '#FBFBFB',
    '&:last-of-type': {
      borderRadius: `0 0 ${sys.borderRadius.xl} 0`,
    },
    '&:first-of-type': {
      borderRadius: `0 0 0 ${sys.borderRadius.xl}`,
    },
    ...(isFirst ? { position: 'sticky', left: 0 } : {}),
    ...props.sx,
  };

  return (
    <MuiTableCell sx={baseSx} {...omit(restProps, extraTableCellProps)}>
      <Typography variant='bodySmall' weight={props.bold ? 'bold' : 'regular'} sx={{
        fontVariant: props.number ? 'tabular-nums' : 'normal',
        maxWidth: props.maxWidth,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        color: 'inherit',
      }}>{props.children}</Typography>
    </MuiTableCell>
  );
};
