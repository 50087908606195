import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Box } from '../../1-primative';
import { Card, CardContent } from '../../2-component';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import { AccountStates } from '../../../interfaces';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { PageObjectType } from '../../5-page';
import { translateBackend } from '../../../assets/i18n/config';

type Tag = {
  text: string,
  sequenceNumber: number | 'HOUSEHOLD'
};

const SubAccountItem = ({ subAccount, tag, objectType }: { subAccount: any, tag?: Tag, objectType: PageObjectType }) => {
  const { t } = useTranslation(['client', 'accountTypes', 'subAccountsDetail']);
  const { sys } = useThemeTokens();
  const navigate = useNavigate();

  const name = () => {
    if (objectType === PageObjectType.ACCOUNT) {
      return subAccount.goal.name;
    }
    return t(`accountTypes:${subAccount.account.type}`);
  };

  return (
    <Card
      hover
      onClick={() => navigate(`subAccount/${subAccount.id}`)}
      sx={{
        cursor: 'pointer',
        backgroundColor: subAccount.state === AccountStates.INACTIVE ? sys.color.disabled : sys.color.surface,
      }}
    >
      <CardContent sx={{ paddingBottom: '16px !important' }}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant='titleMedium'>{name()}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant='bodySmall' sx={{ color: subAccount.state === AccountStates.INACTIVE ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
                {translateBackend(subAccount.theme.translatedName)}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="end">
            <Typography variant='titleMedium'>
              {formatMoneyValue(subAccount?.statistics?.marketValueCents ?? 0)}
            </Typography>
            <Typography
              variant='bodySmall'
              color={subAccount.state === AccountStates.INACTIVE ? sys.color.onDisabled : (subAccount?.statistics?.simpleReturnAmount || 0) >= 0 ? sys.color.positive : sys.color.negative}
            >
              {`${formatMoneyValue(subAccount?.statistics?.simpleReturnAmount ?? 0)}
              (${formatPercentValue(subAccount?.statistics?.simpleReturnPercent ?? 0)})`}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubAccountItem;
