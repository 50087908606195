/* eslint-disable array-callback-return */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { translateBackend } from 'assets/i18n/config';
import { UserContext } from 'providers/userContextProvider';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import {
  Button, Typography, Form, Box, Checkbox, Grid, Chip,
  CircularProgress,
  Autocomplete,
} from '../../../..';
import { Affiliate, AffiliateType } from './components/affiliate';
import { SectionType, AccountConfig } from './accountConfig';
import { PaymentInstruction } from './components/paymentInstruction';
import { FieldOptions, FormErrors, validateFields } from '../utils';
import { HouseholdSelection, ObjectTypes } from '../editGoal/components/householdSelection';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const EditAccountVisual = ({
  options, accountData, loading, loadingData, updateAccount, continueFunc, grid = false, refetch, skip, updateMode = false, userId, filterApplicableProvinces,
  getCurrentJurisdictionOption, getJurisdictionOptions, getSourceOfFundsOptions,
  activeCustomFields, workflowCompletion,
}: {
  options: any, accountData: any, loading: boolean, loadingData: boolean, updateAccount: any, continueFunc: any, grid?: boolean, refetch?: any, skip: () => void, updateMode?: boolean,
  userId?: string, filterApplicableProvinces?: any,
  getCurrentJurisdictionOption?: any,
  getJurisdictionOptions?: any,
  getSourceOfFundsOptions?: any,
  activeCustomFields?: string[],
  workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [skipped, setSkipped] = useState(false);
  const [edited, setEdited] = useState(false);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);
  const { t } = useTranslation(['client', 'accountsDetail']);
  const accountConfig = AccountConfig();
  const { activeOrganization } = useContext(UserContext);
  const enableHousehold: boolean = activeOrganization.availableFeatureFlags?.includes('HOUSEHOLD') ?? false;
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);
  const [requireFieldValidated, setErrorsuireFieldValidated] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fieldOptions: FieldOptions = useMemo(() => (accountConfig[accountData.type]?.fieldOptions || {}), [accountConfig]);

  const validate = useCallback((candidateFields?: string[]): FormErrors => {
    const newErrors = validateFields(fieldOptions, accountData, candidateFields);
    setErrors(newErrors);
    return newErrors;
  }, [accountData, fieldOptions]);

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  const submit = () => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    if (options.validateAffiliate && validateRequiredAffiliateFields()) {
      setErrorsuireFieldValidated(true);
      return;
    }
    setEdited(false);
    sendAnalytic(
      ovAnalyticsEvents.workflowsEditAccountContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    continueFunc();
  };

  const sections = (): any[] => {
    let secs = (accountConfig[accountData.type]?.sections || []).filter((x: any) => !(x.excludeProvinces || []).includes(accountData.user.physicalAddress?.province))
      .filter((y: any) => !(y.excludeMaritalStatus || []).includes(accountData.user.maritalStatus));

    if (updateMode) {
      if (!options.affiliates) {
        secs = secs.filter((x: any) => x.type !== SectionType.AFFILIATE);
      }
      if (!options.respGrants) {
        secs = secs.filter((x: any) => x.type !== SectionType.RESP_GRANTS);
      }
      if (!options.rrifPaymentInstructions) {
        secs = secs.filter((x: any) => x.type !== SectionType.RRIF_PAYMENT_INSTRUCTIONS);
      }
    }
    if (!options.defaultLinkToHousehold || !enableHousehold) {
      secs = secs.filter((x: any) => x.type !== SectionType.LINK_TO_HOUSEHOLD);
    }
    return secs;
  };

  const validateRequiredAffiliateFields = () => {
    const errofields: any[] = [];
    const renderedSections = sections();
    renderedSections.filter((section: any) => (section.type === SectionType.AFFILIATE && section.required)).map((sec: any) => {
      if (!isAffiliateAddedToTheAccount(sec)) {
        errofields.push(sec);
      }
    });
    return errofields.length !== 0;
  };

  const isAffiliateAddedToTheAccount = (section: any) => {
    let found = false;

    const affiliationData = (options.useCustodianAffiliates) ? accountData.custodianAffiliates : accountData.affiliations;
    affiliationData?.forEach((d: any) => {
      if (section.affiliateType === AffiliateType.OTHER
          && (section?.defaultRelationType ? d.relation === section.defaultRelationType && d.type === section.affiliateType : d.type === section.affiliateType)) {
        found = true;
      } else if (d.type === section.affiliateType && section.affiliateType !== AffiliateType.OTHER) {
        found = true;
      }
    });
    return found;
  };

  const validateRequiredAffiliate = (section: any) => {
    if (section.type === SectionType.AFFILIATE && section.required) {
      return !isAffiliateAddedToTheAccount(section);
    }
    return false;
  };

  const affiliateData = () => {
    if (options.useCustodianAffiliates) {
      return accountData.custodianAffiliates;
    }
    return accountData.affiliations;
  };

  useEffect(() => {
    if (!skipped && accountData?.accountId && sections().length === 0) {
      setSkipped(true);
      skip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData, accountConfig, skipped, skip]);

  const renderSection = (section: any) => {
    switch (section.type) {
      case SectionType.AFFILIATE:
        return (
          <Affiliate
            data-testid='affiliates'
            type={section.affiliateType}
            account={accountData}
            affiliates={affiliateData()?.filter((x: any) => x.type === section.affiliateType && (section.defaultRelationType ? x.relation === section.defaultRelationType : true)) || []}
            allAffiliates={affiliateData()}
            updateAccount={updateAccount}
            multi={section.multi}
            showAllocation={section.showAllocation && !options.hideAllocation}
            showRelationship={section.showRelationship && !options.hideRelationship}
            fields={section.fields}
            defaultRelationType={section.defaultRelationType}
            refetch={refetch}
            updateMode={updateMode}
            edittable={(section.edittableAfterOpen && updateMode && options.editableAffiliates) || !updateMode}
            onEdit={() => setEdited(true)}
            title={section.title}
            useAccountHoldersAddress={section.useAccountHoldersAddress}
            error={requireFieldValidated && validateRequiredAffiliate(section)}
            supportText={translateBackend(options.customSupportMessage)}
            errorText={t('shared:requireAffiliate', {
              accountType: t(`accountTypes:${accountData.type}`),
              affiliateType: t(`affiliationTypes:${section.affiliateType === 'OTHER' && section.defaultRelationType ? section.defaultRelationType : section.affiliateType}`),
            })}
          />
        );
      case SectionType.RESP_GRANTS:
        return (
          <>
            <Typography variant='labelLarge' sx={{ mt: grid ? 0 : 3 }} weight='bold'>{t('accountsDetail:govGrants')}</Typography>
            {updateMode ? (
              <Box sx={{ mt: 1 }}>
                {accountData.applyForGovFunds.includes('BASIC_CESG') && (<Chip data-testid="gov-fund-basic-cesg" text={t('accountsDetail:govFundOptions.BASIC_CESG')} />)}
                {accountData.applyForGovFunds.includes('ADDITIONAL_CESG') && (<Chip data-testid="gov-fund-additional-cesg" text={t('accountsDetail:govFundOptions.ADDITIONAL_CESG')} />)}
                {accountData.applyForGovFunds.includes('CLB') && (<Chip data-testid="gov-fund-clb" text={t('accountsDetail:govFundOptions.CLB')} />)}
              </Box>
            ) : (
              <Box display='flex' flexDirection='row' sx={{ flexFlow: 'wrap' }} mt={1}>
                <Checkbox
                  data-testid="gov-fund-basic-cesg"
                  chip
                  label={t('accountsDetail:govFundOptions.BASIC_CESG')}
                  checked={accountData.applyForGovFunds.includes('BASIC_CESG')}
                  onChange={(checked: boolean) => updateAccount({
                    ...accountData, applyForGovFunds: checked ? [...accountData.applyForGovFunds, 'BASIC_CESG'] : accountData.applyForGovFunds.filter((x: string) => x !== 'BASIC_CESG'),
                  })}
                />
                <Checkbox
                  data-testid="gov-fund-additional-cesg"
                  chip
                  label={t('accountsDetail:govFundOptions.ADDITIONAL_CESG')}
                  checked={accountData.applyForGovFunds.includes('ADDITIONAL_CESG')}
                  onChange={(checked: boolean) => updateAccount({
                    ...accountData, applyForGovFunds: checked ? [...accountData.applyForGovFunds, 'ADDITIONAL_CESG'] : accountData.applyForGovFunds.filter((x: string) => x !== 'ADDITIONAL_CESG'),
                  })}
                />
                <Checkbox
                  data-testid="gov-fund-clb"
                  chip
                  label={t('accountsDetail:govFundOptions.CLB')}
                  checked={accountData.applyForGovFunds.includes('CLB')}
                  onChange={(checked: boolean) => updateAccount({
                    ...accountData, applyForGovFunds: checked ? [...accountData.applyForGovFunds, 'CLB'] : accountData.applyForGovFunds.filter((x: string) => x !== 'CLB'),
                  })}
                />
              </Box>
            )}
          </>
        );
      case SectionType.RRIF_PAYMENT_INSTRUCTIONS:
        return (
          <PaymentInstruction
            data-testid="rif-payment-instruction"
            userId={userId ?? ''}
            account={accountData}
            refetch={refetch}
            updateMode={updateMode}
            fundTitle={section.titlePrefix}
          />
        );
      case SectionType.LINK_TO_HOUSEHOLD:
        return (
          <HouseholdSelection
            data-testid="house-hold-selection"
            data={accountData}
            updateData={updateAccount}
            objectType={ObjectTypes.ACCOUNT}
          />
        );
      case SectionType.JURISDICTION:
        return (<Box sx={{ mt: 2 }}><Autocomplete
          data-testid="jurisdiction"
          label={t('workflowCompletions:updateAccount.jurisdiction')}
          value={getCurrentJurisdictionOption(accountData.jurisdiction ?? '')}
          options={getJurisdictionOptions()}
          onChange={(e: React.SyntheticEvent, value: any) => {
            if (value && value.id) {
              updateAccount({ ...accountData, jurisdiction: value.id });
            }
          }}
          onBlur={() => setFocused([...focused, 'jurisdiction'])}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: undefined,
          }}
          errorText={errors?.jurisdiction?.message}
          error={!!errors?.jurisdiction}
          fullWidth
        ></Autocomplete></Box>);
      case SectionType.SOURCE_OF_FUNDS:
        return (<Box sx={{ mt: 2, mb: 2 }}><Autocomplete
          data-testid="source-of-funds"
          label={t('workflowCompletions:updateAccount.sourceOfFunds')}
          value={accountData.sourceOfFunds ? t(`workflowCompletions:updateAccount.sourceOfFundsOptions.${accountData.sourceOfFunds}`) : null}
          options={getSourceOfFundsOptions()}
          onChange={(e: React.SyntheticEvent, value: any) => {
            if (value && value.id) {
              updateAccount({ ...accountData, sourceOfFunds: value.id });
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: undefined,
            disableClearable: true,
          }}
          onBlur={() => setFocused([...focused, 'sourceOfFunds'])}
          errorText={errors?.sourceOfFunds?.message}
          error={!!errors?.sourceOfFunds}
          fullWidth
        ></Autocomplete></Box>);
      default:
        return (<></>);
    }
  };

  return (
    <Form onSubmit={submit}>
      {(loadingData) ? (
        <>
          <Box display='flex' justifyContent='center' alignItems='center' height='200px'>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          {options?.title && (<Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>)}
          {options?.subtitle && (<Typography variant='bodyLarge' sx={{ mb: 3 }}>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Typography>)}

          <Grid container columnSpacing={2}>
            {sections().map((section: any, index: number) => (
              <Grid item xs={12} md={grid && sections().length > 1 ? 6 : 12} key={`${section.type}-${index}`}>
                {section && section?.type && renderSection(section)}
              </Grid>
            ))}
            {customOptionSelected && customOptionSelected.length > 0 && (<Grid item xs={12} md={12} spacing={2} key='custom'>
              <CustomFieldRenderer
                customOptions={customOptionSelected}
                customData={accountData}
                update={updateAccount}
                grid={grid}
                focused={focused}
                setFocused={setFocused}
                loading={loading}
              />
            </Grid>)}
          </Grid>
        </>
      )}
      {(!updateMode || edited) && (
        <Box display='flex' justifyContent='end'>
          <Button data-testid="continue-btn" label={updateMode ? t('update') : t('continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
        </Box>
      )}
    </Form>
  );
};

export default EditAccountVisual;
