import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { MaritalStatus, User } from 'interfaces';
import { translateBackend } from 'assets/i18n/config';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { invalidFields } from '../utils';
import {
  Button, TextField, Typography, AddressField,
  Form, SelectField, DateField, Box,
  Grid,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const RelationshipInformationVisual = ({
  options, userData, loading, updateUser, continueFunc, workflowCompletion, grid, updateMode,
}: {
  options: any, userData: any, loading: boolean, updateUser: any, continueFunc: any, grid?: boolean, updateMode?: boolean,
  workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation('client');
  const [focused, setFocused] = useState<string[]>([]);
  const [updated, setUpdated] = useState<boolean>(false);

  const requiresSpousalInfo = (data: Partial<User>): boolean => (
    [
      MaritalStatus.MARRIED,
      MaritalStatus.COMMON_LAW,
    ].includes(data?.maritalStatus as MaritalStatus)
  );
  const [invalidFieldsList, setInvalidFieldsList] = useState<string[]>([]);

  const validate = () => {
    const fields = invalidFields({
      ...options,
      spouseFirstName: { ...options.spouseFirstName, requiredIf: requiresSpousalInfo },
      spouseMiddleName: { ...options.spouseMiddleName, requiredIf: requiresSpousalInfo },
      spouseLastName: { ...options.spouseLastName, requiredIf: requiresSpousalInfo },
      spouseEmail: { ...options.spouseEmail, requiredIf: requiresSpousalInfo },
      spouseSin: { ...options.spouseSin, requiredIf: requiresSpousalInfo },
      spouseCompanyType: { ...options.spouseCompanyType, requiredIf: requiresSpousalInfo },
      spouseJobTitle: { ...options.spouseJobTitle, requiredIf: requiresSpousalInfo },
      spouseDateOfBirth: { ...options.spouseDateOfBirth, requiredIf: requiresSpousalInfo },
    }, userData);
    setInvalidFieldsList(fields);
    setFocused(fields);
    return fields.length === 0;
  };

  const submit = () => {
    if (validate()) {
      sendAnalytic(ovAnalyticsEvents.workflowsRelationshipInformationContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion.objectType,
      });
      continueFunc();
    }
  };

  const subTitleStyle = {
    display: 'inline-flex',
    alignItems: 'end',
  };

  const update = (newValue: any) => {
    setUpdated(true);
    updateUser(newValue);
  };

  const lockMessage = (): string => (options.customLockedMessage ? translateBackend(options.customLockedMessage) : t('pageConfiguration:notEditableMessage'));

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography style={subTitleStyle} variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      <Grid container spacing={2}>
        {options?.maritalStatus?.enabled && (
          <Grid item xs={12}>
            <SelectField
              testId="marital-status"
              onChange={(e: any) => update({ ...userData, maritalStatus: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.maritalStatus?.label)}
              infoTooltip={additionalInfo(options?.maritalStatus?.additionalInfo)}
              fullWidth
              locked={options?.maritalStatus?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              value={userData.maritalStatus ?? ''}
              onBlur={() => setFocused([...focused, 'maritalStatus'])}
              error={((!userData.maritalStatus && focused.includes('maritalStatus'))
                || invalidFieldsList.includes('maritalStatus')) && focused.includes('maritalStatus') && options?.maritalStatus?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid="marital-status-single" value='SINGLE'>{t('edit.maritalStatusOptions.SINGLE')}</MenuItem>
              <MenuItem data-testid="marital-status-married" value='MARRIED'>{t('edit.maritalStatusOptions.MARRIED')}</MenuItem>
              <MenuItem data-testid="marital-status-common-law" value='COMMON_LAW'>{t('edit.maritalStatusOptions.COMMON_LAW')}</MenuItem>
              <MenuItem data-testid="marital-status-divorced" value='DIVORCED'>{t('edit.maritalStatusOptions.DIVORCED')}</MenuItem>
              <MenuItem data-testid="marital-status-separated" value='SEPARATED'>{t('edit.maritalStatusOptions.SEPARATED')}</MenuItem>
              <MenuItem data-testid="marital-status-widowed" value='WIDOWED'>{t('edit.maritalStatusOptions.WIDOWED')}</MenuItem>
            </SelectField>
          </Grid>
        )}
        {options?.spouseFirstName?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="spouse-first-name"
              onChange={(e: any) => update({ ...userData, spouseFirstName: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseFirstName?.label)}
              fullWidth
              locked={options?.spouseFirstName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.spouseFirstName?.additionalInfo)}
              value={userData.spouseFirstName ?? ''}
              onBlur={() => setFocused([...focused, 'spouseFirstName'])}
              error={((!userData.spouseFirstName && requiresSpousalInfo(userData) && focused.includes('spouseFirstName'))
                || invalidFieldsList.includes('spouseFirstName')) && focused.includes('spouseFirstName') && options?.spouseFirstName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseMiddleName?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="spouse-middle-name"
              onChange={(e: any) => update({ ...userData, spouseMiddleName: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseMiddleName?.label)}
              locked={options?.spouseMiddleName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              infoTooltip={additionalInfo(options?.spouseMiddleName?.additionalInfo)}
              value={userData.spouseMiddleName ?? ''}
              onBlur={() => setFocused([...focused, 'spouseMiddleName'])}
              error={((!userData.spouseMiddleName && focused.includes('spouseMiddleName'))
                || invalidFieldsList.includes('spouseMiddleName')) && options?.spouseMiddleName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseLastName?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="spouse-last-name"
              onChange={(e: any) => update({ ...userData, spouseLastName: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseLastName?.label)}
              infoTooltip={additionalInfo(options?.spouseLastName?.additionalInfo)}
              locked={options?.spouseLastName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseLastName ?? ''}
              onBlur={() => setFocused([...focused, 'spouseLastName'])}
              error={((!userData.spouseLastName && requiresSpousalInfo(userData) && focused.includes('spouseLastName'))
                || invalidFieldsList.includes('spouseLastName')) && options?.spouseLastName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseEmail?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="spouse-email"
              onChange={(e: any) => update({ ...userData, spouseEmail: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseEmail?.label)}
              infoTooltip={additionalInfo(options?.spouseEmail?.additionalInfo)}
              locked={options?.spouseEmail?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseEmail ?? ''}
              onBlur={() => setFocused([...focused, 'spouseEmail'])}
              error={((!userData.spouseEmail && requiresSpousalInfo(userData) && focused.includes('spouseEmail'))
                || invalidFieldsList.includes('spouseEmail')) && options?.spouseEmail?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseSin?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="spouse-sin"
              onChange={(e: any) => update({ ...userData, spouseSin: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseSin?.label)}
              infoTooltip={additionalInfo(options?.spouseSin?.additionalInfo)}
              locked={options?.spouseSin?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseSin ?? ''}
              onBlur={() => setFocused([...focused, 'spouseSin'])}
              error={((!userData.spouseSin && requiresSpousalInfo(userData) && focused.includes('spouseSin'))
                || invalidFieldsList.includes('spouseSin')) && options?.spouseSin?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseDateOfBirth?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <DateField
              data-testid="spouse-date-of-birth"
              onChange={(date: any) => update({ ...userData, spouseDateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              disabled={loading}
              label={translateBackend(options?.spouseDateOfBirth?.label)}
              infoTooltip={additionalInfo(options?.spouseDateOfBirth?.additionalInfo)}
              locked={options?.spouseDateOfBirth?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseDateOfBirth}
              onBlur={() => setFocused([...focused, 'spouseDateOfBirth'])}
              error={((!userData.spouseDateOfBirth && requiresSpousalInfo(userData) && focused.includes('spouseDateOfBirth'))
                || invalidFieldsList.includes('spouseDateOfBirth')) && options?.spouseDateOfBirth?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseCompanyType?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="spouse-company-type"
              onChange={(e: any) => update({ ...userData, spouseCompanyType: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseCompanyType?.label)}
              infoTooltip={additionalInfo(options?.spouseCompanyType?.additionalInfo)}
              locked={options?.spouseCompanyType?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseCompanyType ?? ''}
              onBlur={() => setFocused([...focused, 'spouseCompanyType'])}
              error={((!userData.spouseCompanyType && requiresSpousalInfo(userData) && focused.includes('spouseCompanyType'))
                || invalidFieldsList.includes('spouseCompanyType')) && options?.spouseCompanyType?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.spouseJobTitle?.enabled && requiresSpousalInfo(userData) && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="spouse-job-title"
              onChange={(e: any) => update({ ...userData, spouseJobTitle: e.target.value })}
              disabled={loading}
              label={translateBackend(options?.spouseJobTitle?.label)}
              infoTooltip={additionalInfo(options?.spouseJobTitle?.additionalInfo)}
              locked={options?.spouseJobTitle?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.spouseJobTitle ?? ''}
              onBlur={() => setFocused([...focused, 'spouseJobTitle'])}
              error={((!userData.spouseJobTitle && requiresSpousalInfo(userData) && focused.includes('spouseJobTitle'))
                || invalidFieldsList.includes('spouseJobTitle')) && options?.spouseJobTitle?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactName?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="trusted-contact-name"
              onChange={(e: any) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, name: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactName?.label)}
              infoTooltip={additionalInfo(options?.trustedContactName?.additionalInfo)}
              locked={options?.trustedContactName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.trustedContactPerson?.name ?? ''}
              onBlur={() => setFocused([...focused, 'trustedContactName'])}
              error={((!userData.trustedContactPerson?.name && focused.includes('trustedContactName'))
                || invalidFieldsList.includes('trustedContactName')) && options?.trustedContactName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactEmail?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="trusted-contact-email"
              onChange={(e: any) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, email: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactEmail?.label)}
              infoTooltip={additionalInfo(options?.trustedContactEmail?.additionalInfo)}
              locked={options?.trustedContactEmail?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.trustedContactPerson?.email ?? ''}
              onBlur={() => setFocused([...focused, 'trustedContactEmail'])}
              error={((!userData.trustedContactPerson?.email && focused.includes('trustedContactEmail'))
                || invalidFieldsList.includes('trustedContactEmail')) && options?.trustedContactEmail?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactPhone?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="trusted-contact-phone"
              onChange={(e: any) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, phone: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactPhone?.label)}
              locked={options?.trustedContactPhone?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.trustedContactPerson?.phone ?? ''}
              infoTooltip={additionalInfo(options?.trustedContactPhone?.additionalInfo)}
              onBlur={() => setFocused([...focused, 'trustedContactPhone'])}
              error={((!userData.trustedContactPerson?.phone && focused.includes('trustedContactPhone'))
                || invalidFieldsList.includes('trustedContactPhone')) && focused.includes('trustedContactPhone') && options?.trustedContactPhone?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactAddress?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <AddressField
              data-testid="trusted-contact-address"
              onChange={(e: any) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, physicalAddress: e } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactAddress?.label)}
              infoTooltip={additionalInfo(options?.trustedContactAddress?.additionalInfo)}
              lockMessage={lockMessage()}
              fullWidth
              address={userData.trustedContactPerson?.physicalAddress}
              onFocus={() => setFocused([...focused, 'trustedContactAddress'])}
              error={((!userData.trustedContactPerson?.physicalAddress?.streetName && focused.includes('trustedContactAddress'))
                || invalidFieldsList.includes('trustedContactAddress')) && focused.includes('trustedContactAddress') && options?.trustedContactAddress?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.trustedContactRelation?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <SelectField
              data-testid="trusted-contact-relation"
              onChange={(e: any) => update({ ...userData, trustedContactPerson: { ...userData.trustedContactPerson, relation: e.target.value } })}
              disabled={loading}
              label={translateBackend(options?.trustedContactRelation?.label)}
              infoTooltip={additionalInfo(options?.trustedContactRelation?.additionalInfo)}
              locked={options?.trustedContactRelation?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.trustedContactPerson?.relation ?? ''}
              onBlur={() => setFocused([...focused, 'trustedContactRelation'])}
              error={((!userData.trustedContactPerson?.relation && focused.includes('trustedContactRelation'))
                || invalidFieldsList.includes('trustedContactRelation')) && options?.trustedContactRelation?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid="trusted-contact-relation-spouse" value='SPOUSE'>{t('edit.relationOptions.SPOUSE')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-parent" value='PARENT'>{t('edit.relationOptions.PARENT')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-sibling" value='SIBLING'>{t('edit.relationOptions.SIBLING')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-child" value='CHILD'>{t('edit.relationOptions.CHILD')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-grandparent" value='GRANDPARENT'>{t('edit.relationOptions.GRANDPARENT')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-friend" value='FRIEND'>{t('edit.relationOptions.FRIEND')}</MenuItem>
              <MenuItem data-testid="trusted-contact-relation-other" value='OTHER'>{t('edit.relationOptions.OTHER')}</MenuItem>
            </SelectField>
          </Grid>
        )}
        {options?.numberOfDependents?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid="number-of-decendants"
              onChange={(e: any) => update({ ...userData, numberOfDependents: parseInt(e.target.value, 10) })}
              disabled={loading}
              type='number'
              label={options?.numberOfDependents?.label?.en}
              infoTooltip={additionalInfo(options?.numberOfDependents?.additionalInfo)}
              lockMessage={lockMessage()}
              locked={options?.numberOfDependents?.required === 'NOT_EDITABLE'}
              fullWidth
              value={userData.numberOfDependents ?? null}
              onBlur={() => setFocused([...focused, 'numberOfDependents'])}
              error={((!userData.numberOfDependents && focused.includes('numberOfDependents'))
                || invalidFieldsList.includes('numberOfDependents')) && options?.numberOfDependents?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
      </Grid>
      <Box display='flex' justifyContent='end'>
        <Button data-testid="continue-button" label={t(updateMode ? 'update' : 'continue')} disabled={loading || (!updated && updateMode)} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default RelationshipInformationVisual;
