/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Card, CardContent, CircularProgress, Grid, Popover, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import LineChart from '../../charts/LineChart';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import StatsBlock from '../../misc/statsBlockv2';
import { useStats, ObjectType } from '../../../providers/statsHooks';
import ArrowDownRightRed from '../../../assets/images/icons-outline/arrow-down-right-red.svg';
import ArrowUpRightGreen from '../../../assets/images/icons-outline/arrow-up-right-green.svg';
import StatsTable from '../../misc/statsTable';
import { usePageState } from '../../../util/usePageState';
import { SimpleDateRangePicker } from '../../fields/simpleDateRangePicker';
import { colors } from '../../../theme/colors';
import { useLocalization } from '../../../util/useLocalization';

type HistoricalTimeFrames = 'custom' | '1M' | '3M' | '1Y' | 'YTD' | 'MAX';

const startDate = (timeFrame: HistoricalTimeFrames): string | undefined => {
  if (timeFrame === 'YTD') return dayjs().startOf('year').format('YYYY-MM-DD');
  if (timeFrame.endsWith('M')) return dayjs().subtract(Number(timeFrame.slice(0, -1)), 'month').format('YYYY-MM-DD');
  if (timeFrame.endsWith('Y')) return dayjs().subtract(Number(timeFrame.slice(0, -1)), 'year').format('YYYY-MM-DD');
  return undefined; // meaning inception date aka "MAX" time
};

const AssetsOverview = ({
  type, id, needUpdate, stack, title, onlyUserId,
}: { type: ObjectType, id: string, needUpdate?: number, stack?: boolean, title?: string, onlyUserId?: string }) => {
  const { t } = useTranslation('client');
  const { localizedDate } = useLocalization();
  const [timeFrame, setTimeFrame] = usePageState<HistoricalTimeFrames>('MAX', 'assets/timeframe');
  const [customStartDate, setCustomStartDate] = usePageState<string>('', 'assets/startdate');
  const [customEndDate, setCustomEndDate] = usePageState<string>('', 'assets/enddate');
  const [customDateRangePopover, setCustomDateRangePopover] = useState<HTMLButtonElement | null>(null);

  const queryDates = () => {
    if (timeFrame === 'MAX') return {};
    if (timeFrame !== 'custom') {
      return {
        startDate: startDate(timeFrame),
      };
    }
    if (customStartDate && customEndDate) {
      return {
        startDate: dayjs(customStartDate).format('YYYY-MM-DD'),
        endDate: dayjs(customEndDate).format('YYYY-MM-DD'),
      };
    }
    return {};
  };

  const {
    statistics, loading, history, historyLoading, refetchAll,
  } = useStats(
    {
      type,
      id,
      ...queryDates(),
      onlyUserId,
    },
  );

  /* When the needUpdate counter gets incremented */
  useEffect(() => {
    if (!loading && !historyLoading) refetchAll();
  }, [needUpdate]);

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item lg={stack ? 12 : 8} xs={12}>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <StatsBlock big
                  title={title ?? t('assetsOverview.totalAssets')}
                  value={formatMoneyValue(statistics.marketValueCents)}
                />
                {/* total assets  */}
                <Typography variant='body2' sx={{ marginTop: '0.25em' }} color={statistics.simpleReturnAmount >= 0 ? colors.success : 'error'}>
                  <img src={statistics.simpleReturnAmount >= 0 ? ArrowUpRightGreen : ArrowDownRightRed} alt="arrow-icon" />
                  {formatMoneyValue(statistics.simpleReturnAmount)} ({formatPercentValue(statistics.simpleReturnPercent)})
                </Typography>
              </Grid>
              <Grid item>
                {timeFrame !== 'custom' ? (
                  <Typography display="inline" margin={1}
                    sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => { setCustomDateRangePopover(event.currentTarget); }}
                  >
                    {t('assetsOverview.customDateRange')}
                  </Typography>
                ) : (
                  <Typography display="inline" margin={1}
                    color="primary"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => { setCustomDateRangePopover(event.currentTarget); }}
                  >
                    <>
                      {localizedDate(customStartDate)} - {localizedDate(customEndDate)}
                    </>
                  </Typography>
                )}

                <Popover
                  id={id}
                  open={!!customDateRangePopover}
                  anchorEl={customDateRangePopover}
                  onClose={() => setCustomDateRangePopover(null)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <SimpleDateRangePicker
                    date1={customStartDate ? dayjs(customStartDate) : null}
                    date2={customEndDate ? dayjs(customEndDate) : null}
                    setDates={(date1, date2) => {
                      setCustomStartDate(date1.format('YYYY-MM-DD'));
                      setCustomEndDate(date2.format('YYYY-MM-DD'));
                      setTimeFrame('custom');
                      setCustomDateRangePopover(null);
                    }}
                  />
                </Popover>

                {(['1M', '3M', '1Y', 'YTD', 'MAX'] as HistoricalTimeFrames[]).map((tf) => (
                  <Typography display="inline" margin={1}
                    key={tf}
                    color={timeFrame === tf ? 'primary' : undefined}
                    sx={{ cursor: 'pointer', textDecoration: timeFrame === tf ? 'underline' : '', '&:hover': { textDecoration: 'underline' } }}
                    onClick={() => {
                      setTimeFrame(tf);
                      setCustomStartDate('');
                      setCustomEndDate('');
                    }}
                  >
                    {t(`assetsOverview.${tf}`)}
                  </Typography>))}
              </Grid>
            </Grid>
            <Box sx={{
              height: '205px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              {historyLoading ? (<Box height='100%'><CircularProgress /></Box>) : (
                <Box sx={{ width: '100%', height: '100%', p: '4px' }}>
                  <LineChart
                    labels={history.filter((x: any) => x.marketValueCents !== 0).map((x: any) => localizedDate(x.date))}
                    datasets={[{ label: t('marketValue'), data: history.filter((x: any) => x.marketValueCents !== 0).map((x: any) => x.marketValueCents) }]}
                  />
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item lg={stack ? 12 : 4} xs={12} sx={{ mt: stack ? 2 : 0 }}>
            <Grid container pl={2}>
              <Grid item xs={6}>
                {/* cash available */}
                <StatsBlock title={t('assetsOverview.cashAvailable')} value={formatMoneyValue(statistics.moneyAvailableCents)} />
              </Grid>
              <Grid item xs={6}>
                {/* net contributions */}
                <StatsBlock title={t('assetsOverview.netContributions')} value={formatMoneyValue(statistics.netContributionCents)} />
              </Grid>
            </Grid>
            <Grid container pl={2} mt={4}>
              <Grid item xs={12}>
                <StatsTable stats={
                  [
                    { key: t('assetsOverview.timeWeightedRoR'), value: formatPercentValue(statistics.timeWeightedReturn) },
                    { key: t('assetsOverview.moneyWeightedRoR'), value: formatPercentValue(statistics.moneyWeightedReturn) },
                    { key: t('assetsOverview.cashOnHoldToWithdraw'), value: formatMoneyValue(statistics.cashOnHoldToWithdrawCents) },
                    { key: t('assetsOverview.cashOnHoldToTrade'), value: formatMoneyValue(statistics.cashOnHoldToTradeCents) },
                    { key: t('assetsOverview.newContributionsToday'), value: formatMoneyValue(statistics.newContributionCents) },
                  ]
                } />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AssetsOverview;
