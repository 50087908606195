import {
  Box as MuiBox,
  BoxProps,
} from '@mui/material';
import { MutableRefObject, useRef } from 'react';

interface OvBoxProps extends BoxProps {
  onRefSet?: (ref: MutableRefObject<any>) =>void,
}
export const Box = (props: OvBoxProps) => {
  const ref = useRef(null);
  if (props.onRefSet) {
    props?.onRefSet(ref);
  }
  return (<MuiBox {...props} ref={ref} />);
};
