import {
  Chip,
  ListItem, MenuItem, TextField, Tooltip,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectFieldEditor } from 'ovComponents/4-module/configurableOptionFields/basicOrSelectField';
import FormModal from '../../../components/modals/formModal';
import {
  CUSTOM_FIELDS_FORMAT_MAP,
  CUSTOM_FIELDS_WORKFLOW_STEP_MAP,
  CustomField, CustomFieldFormats,
  CustomFieldObjectTypes,
  CustomFieldTypes,
  CustomFieldWorkflowSteps,
} from '../../../interfaces/customField';
import TranslatableString from '../../../components/inputs/translatableString';
import { ConfirmationModal } from '../../../ovComponents';

const UPDATE_CUSTOM_FIELD = gql`
mutation updateCustomField($input: UpdateCustomFieldInput!) {
  updateCustomField(input: $input) {
    customField {
      id
    }
  }
}
`;

const TRANSITION_CUSTOM_FIELD = gql`
mutation transitionCustomField($input: TransitionCustomFieldInput!) {
  transitionCustomField(input: $input) {
    customField {
      id
    }
  }
}`;

const EditCustomField = ({
  afterUpdate, customFieldToUpdate, open, handleClose, inherited,
}: { afterUpdate: () => void, customFieldToUpdate: CustomField, open: boolean, handleClose: () => void, inherited?: boolean }) => {
  const { t } = useTranslation(['orgSettings']);
  const [customField, setCustomField] = useState(customFieldToUpdate);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [localOpen, setLocalOpen] = useState(open);
  const [allOptions, setAllOptions] = useState([{
    displayText: {
      en: '',
      fr: '',
    },
    value: '',
  }]);

  const [selectedOptions, setSelectedOptions] = useState<any[]>([{
    value: {
      selectField: {
        checked: true,
        options: customFieldToUpdate.selectOptions,
      },
    },
  }]);

  const onArchiveCustomField = () => {
    setDeleteDialogOpen(true);
  };

  const menuItems = [
    <MenuItem key='menu1' onClick={onArchiveCustomField} >{t('shared:archive')}</MenuItem>,
  ];

  const [updateCustomField, { loading }] = useMutation(UPDATE_CUSTOM_FIELD, {
    variables: {
      input: {
        translatedName: {
          en: customField.translatedName?.en,
          fr: customField.translatedName?.fr,
        },
        translatedDescription: customField.translatedDescription?.en ? {
          en: customField.translatedDescription?.en,
          fr: customField.translatedDescription?.fr,
        } : null,
        customFieldId: customField.id,
        format: customField.format,
        selectOptions: [CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes) ? allOptions.map((opt: any) => ({
          displayText: {
            en: opt?.displayText?.en,
            fr: opt?.displayText?.fr,
          },
          value: opt.value,
        })) : undefined,
        workflowStep: customField.workflowStep ?? undefined,
      },
    },
  });

  useEffect(() => {
    const val: any = selectedOptions[0].value;
    setAllOptions(val.selectField?.options ?? []);
  }, [selectedOptions]);

  useEffect(() => {
    setLocalOpen(open);
    setCustomField(customFieldToUpdate);
    setSelectedOptions([{
      value: {
        selectField: {
          checked: true,
          options: customFieldToUpdate.selectOptions,
        },
      },
    }]);
  }, [customFieldToUpdate, open]);

  const [transitionCustomField] = useMutation(TRANSITION_CUSTOM_FIELD, {
    variables: {
      input: {
        customFieldId: customFieldToUpdate?.id,
        transition: 'archive',
      },
    },
  });

  const update = async (event: any) => {
    event.preventDefault();
    await updateCustomField();
    afterUpdate();
  };

  if (!customField) {
    return <></>;
  }
  return (
    <>
      <FormModal
        disabled={inherited}
        loading={loading}
        title={t('customFieldModal.editTitle')}
        formButton={t('update')} onSubmit={update} open={localOpen} menuItems={!inherited ? menuItems : undefined} handleClose={handleClose}>
        {inherited && (<ListItem><Tooltip title={t('shared:inheritedTooltip', { model: 'Custom Field', organization: customField.organization?.name })}>
          <Chip size='medium' label={t('shared:inheritedFlag')} />
        </Tooltip></ListItem>)}
        <ListItem>
          <TranslatableString
            onChange={(value) => setCustomField({ ...customField, translatedName: value })}
            value={customField.translatedName || {}}
            label={t('customFieldModal.translatedName')}
            rows={3}
            required={true}
          />
        </ListItem>
        <ListItem>
          <TranslatableString
            onChange={(value) => setCustomField({ ...customField, translatedDescription: value })}
            value={customField.translatedDescription || {}}
            label={t('customFieldModal.translatedDescription')}
            rows={4}
          />
        </ListItem>
        <ListItem>
          <TextField
            id='type'
            label={t('customFieldModal.type')}
            fullWidth
            value={t(`customFieldModal.types.${customField.type}`)}
            disabled
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            select
            data-testid="formatField"
            value={customField.format}
            required={true}
            label={t('customFieldModal.format')}
            onChange={(e) => setCustomField({ ...customField, format: e.target.value as CustomFieldFormats })}
          >
            {CUSTOM_FIELDS_FORMAT_MAP[(customField.type as CustomFieldTypes)].map((field: string) => (
              <MenuItem value={field} key={field}>{t(`customFieldModal.formats.${field}`)}</MenuItem>
            ))}
          </TextField>
        </ListItem>
        {[CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes) && (<>
          <ListItem>
            <SelectFieldEditor
              option={selectedOptions[0]}
              key={1}
              options={selectedOptions}
              setOptions={setSelectedOptions} i={0} enableTranslation
              displayTextLabel={t('customFieldModal.displayText')}
            />
          </ListItem>
        </>)}
        <ListItem>
          <TextField
            fullWidth
            select
            data-testid="objectTypeField"
            value={customField.objectType}
            required={true}
            disabled
            label={t('customFieldModal.objectType')}
            onChange={(e) => setCustomField({ ...customField, objectType: e.target.value as CustomFieldObjectTypes })}
          >
            <MenuItem value={CustomFieldObjectTypes.ACCOUNT} key={CustomFieldObjectTypes.ACCOUNT}>{t('customFieldModal.objectTypes.ACCOUNT')}</MenuItem>
            <MenuItem value={CustomFieldObjectTypes.ENTITY} key={CustomFieldObjectTypes.ENTITY}>{t('customFieldModal.objectTypes.ENTITY')}</MenuItem>
            <MenuItem value={CustomFieldObjectTypes.GOAL} key={CustomFieldObjectTypes.GOAL}>{t('customFieldModal.objectTypes.GOAL')}</MenuItem>
            <MenuItem value={CustomFieldObjectTypes.HOUSEHOLD} key={CustomFieldObjectTypes.HOUSEHOLD}>{t('customFieldModal.objectTypes.HOUSEHOLD')}</MenuItem>
            <MenuItem value={CustomFieldObjectTypes.SUB_ACCOUNT} key={CustomFieldObjectTypes.SUB_ACCOUNT}>{t('customFieldModal.objectTypes.SUB_ACCOUNT')}</MenuItem>
          </TextField>
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            select
            data-testid="workflowStepField"
            value={customField.workflowStep}
            label={t('customFieldModal.workflowStep')}
            onChange={(e) => setCustomField({ ...customField, workflowStep: e.target.value as CustomFieldWorkflowSteps })}
          >
            {CUSTOM_FIELDS_WORKFLOW_STEP_MAP[(customField.objectType as CustomFieldObjectTypes)].map((workflow: string) => (
              <MenuItem value={workflow} key={workflow}>{t(`customFieldModal.workflowOptions.${workflow}`)}</MenuItem>
            ))}
          </TextField>
        </ListItem>
      </FormModal>
      {deleteDialogOpen && (
        <ConfirmationModal
          title={t('editCustomFieldDialog.title')}
          bodyText={t('editCustomFieldDialog.body')}
          open={deleteDialogOpen}
          maxWidth='sm'
          onConfirm={async () => {
            await transitionCustomField().then(() => {
              setDeleteDialogOpen(false);
            });
            afterUpdate();
          }}
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default EditCustomField;
