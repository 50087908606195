import { useContext, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { loadScript, loadScriptsConcurrently } from './loadScript';
import { UserContext } from '../../../../providers/userContextProvider';

const FETCH_LSEG_WIDGETS = gql`
  query fetchLsegSamlRequest($input: FetchLsegSamlRequestInput!) {
    fetchLsegSamlRequest(input: $input) {
      samlRequest
    }
  }
`;

declare global {
  interface Window {
    dory: any; // comes from refinitiv/sdk.v6.3.0.js
    fdc__widgets__: any; // comes from refinitiv/main.v2.6.7.js
  }
}
export type RefinitivWidgetType =
  | 'WMainIndex'
  | 'WMarketList'
  | 'WMarketTiles'
  | 'WTickerBand'
  | 'WNewsHeadlines'
  | 'WInterestRate'
  | 'WInstrumentInfo'
  | 'WPriceChart'
  | 'WEstimatesRatios'
  | 'WCompanyProfile'
  | 'WFundAllocation'
  | 'WFundAvgAnnualReturns'
  | 'WPriceYieldChart'
  | 'WSearchSecurities';

const COMMON_SCRIPTS_PRE = [
  '/refinitiv/main.v2.6.7.js',
  '/refinitiv/sdk.v6.3.0.js',
  '/refinitiv/runtime.js',
  '/refinitiv/shared.js',
];

const SPECIFIC_SCRIPTS: Record<RefinitivWidgetType, string[]> = {
  WMainIndex: [
    '/refinitiv/vendor-date.js',
    '/refinitiv/vendor-1~4~5~8~13~20~25~30~31~32~34~40~41~42.js',
    '/refinitiv/vendor-8~13~20~25~32~34.js',
    '/refinitiv/vendor-flags.js',
    '/refinitiv/WMainIndex.v2.6.7.refinitiv-light.css',
  ],
  WMarketList: [
    '/refinitiv/vendor-1~2~4~5~20~21~23~26~28~30~31~36~39~40~41~42.js',
    '/refinitiv/vendor-1~4~5~20~26~28~29~30~31~39~40~41~42.js',
    '/refinitiv/vendor-grid.js',
    '/refinitiv/vendor-26~39.js',
    '/refinitiv/vendor-flags.js',
    '/refinitiv/WMarketList.v2.6.7.refinitiv-light.css',
  ],
  WMarketTiles: [
    '/refinitiv/vendor-flags.js',
    '/refinitiv/WMarketTiles.v2.6.7.refinitiv-light.css',
  ],
  WTickerBand: [
    '/refinitiv/vendor-1~2~4~5~20~21~23~26~28~30~31~36~39~40~41~42.js',
    '/refinitiv/vendor-WTickerBand.js',
    '/refinitiv/WTickerBand.v2.6.7.refinitiv-light.css',
  ],
  WInterestRate: [
    '/refinitiv/vendor-1~2~4~5~20~21~23~26~28~30~31~36~39~40~41~42.js',
    '/refinitiv/vendor-grid.js',
    '/refinitiv/vendor-WInterestRate.js',
    '/refinitiv/vendor-flags.js',
    '/refinitiv/WInterestRate.v2.6.7.refinitiv-light.css',
  ],
  WNewsHeadlines: [
    '/refinitiv/vendor-1~2~4~5~20~21~23~26~28~30~31~36~39~40~41~42.js',
    '/refinitiv/vendor-1~4~5~20~26~28~29~30~31~39~40~41~42.js',
    '/refinitiv/vendor-grid.js',
    '/refinitiv/vendor-1~4~5~20~28~30~31~40~41~42.js',
    '/refinitiv/vendor-select.js',
    '/refinitiv/vendor-WNewsHeadlines.js',
    '/refinitiv/WNewsHeadlines.v2.6.7.refinitiv-light.css',
  ],
  WInstrumentInfo: [
    '/refinitiv/vendor-flags.js',
    '/refinitiv/WInstrumentInfo.v2.6.7.refinitiv-light.css',
  ],
  WPriceChart: [
    '/refinitiv/vendor-date.js',
    '/refinitiv/vendor-1~2~4~5~20~21~23~26~28~30~31~36~39~40~41~42.js',
    '/refinitiv/vendor-1~4~5~8~13~20~25~30~31~32~34~40~41~42.js',
    '/refinitiv/vendor-1~4~5~20~26~28~29~30~31~39~40~41~42.js',
    '/refinitiv/vendor-1~4~5~20~28~30~31~40~41~42.js',
    '/refinitiv/vendor-select.js',
    '/refinitiv/vendor-1~21~31~41.js',
    '/refinitiv/WPriceChart.v2.6.7.refinitiv-light.css',
  ],
  WEstimatesRatios: [
    '/refinitiv/vendor-date.js',
    '/refinitiv/vendor-1~4~5~8~13~20~25~30~31~32~34~40~41~42.js',
    '/refinitiv/vendor-8~13~20~25~32~34.js',
    '/refinitiv/WEstimatesRatios.v2.6.7.refinitiv-light.css',
  ],
  WCompanyProfile: [
    '/refinitiv/vendor-date.js',
    '/refinitiv/WCompanyProfile.v2.6.7.refinitiv-light.css',
  ],
  WFundAllocation: [
    '/refinitiv/WFundAllocation.v2.6.7.refinitiv-light.css',
  ],
  WFundAvgAnnualReturns: [
    '/refinitiv/WFundAvgAnnualReturns.v2.6.7.refinitiv-light.css',
  ],
  WPriceYieldChart: [
    '/refinitiv/vendor-date.js',
    '/refinitiv/vendor-1~4~5~8~13~20~25~30~31~32~34~40~41~42.js',
    '/refinitiv/vendor-8~13~20~25~32~34.js',
    '/refinitiv/WPriceYieldChart.v2.6.7.refinitiv-light.css',
  ],
  WSearchSecurities: [],
};

const COMMON_SCRIPTS_POST = [
  '/refinitiv/vendor.v2.6.7.refinitiv-light.css',
  '/refinitiv/refinitiv-light.v2.6.7.css',
  '/refinitiv/helpers.js',
];

/* eslint-disable no-console */
const initWidget = (selector: string, type: string, options: any) => {
  try {
    const widget = window.fdc__widgets__[type].init(selector, options);
    if (options.onClick) widget.onClick((e: any) => options.onClick(e));
  } catch (error) {
    console.error(`initWidget: Error initializing widget ${type}:`, error);
  }
};

export const useRefinitivWidget = (selector: string, type: RefinitivWidgetType, options: any): void => {
  const [fetchSamlRequest] = useLazyQuery(FETCH_LSEG_WIDGETS);
  const { activeOrganization } = useContext(UserContext);

  useEffectOnce(async () => {
    /* load scripts */
    await loadScriptsConcurrently(COMMON_SCRIPTS_PRE);
    await loadScript('/refinitiv/sdk-workaround.js');
    await loadScriptsConcurrently(SPECIFIC_SCRIPTS[type]);
    await loadScript(`/refinitiv/${type}.js`);
    await loadScriptsConcurrently(COMMON_SCRIPTS_POST);

    if (!window.dory) {
      return;
    }

    /* authentication */
    window.dory.config.setBasePath('https://lseg-widgets.financial.com');
    window.dory.Auth.isSessionValid().then(async (isSessionValid: boolean) => {
      if (isSessionValid === true) {
        initWidget(selector, type, options);
      } else {
        const response = await fetchSamlRequest({
          variables: { input: { organizationId: activeOrganization.id } },
        });
        window.dory.Auth.samlLogin(response.data.fetchLsegSamlRequest.samlRequest)
          .then(() => {
            initWidget(selector, type, options);
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    });
  });
};

function useEffectOnce(effectFn: () => void) {
  useEffect(() => {
    effectFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
