import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Button, Typography, Form, SelectionTile, Box,
} from '../../../..';

export const CreateSubAccountForAccountVisual = ({
  options, goals, loading, selectedGoal, setSelectedGoal, continueFunc,
}: {
  options: any, goals: any, loading: boolean, selectedGoal: string | null, setSelectedGoal: any, continueFunc: any,
}) => {
  const { t } = useTranslation('client');

  const submit = () => {
    if (selectedGoal) {
      continueFunc();
    }
  };
  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      <SelectionTile
        onChange={(e: any) => setSelectedGoal(e.target.value)}
        value={selectedGoal ?? ''}
        options={goals.map((goal: any) => ({ value: goal.id, label: goal.name }))}
      />
      <Box display='flex' justifyContent='end'>
        <Button label={t('continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};
