import dayjs from 'dayjs';
import { useContext } from 'react';
import { UserContext } from '../providers/userContextProvider';

/**
 * A factory for localized date/time function.
 *
 * Usage:
 * ```
 *   const { localizedDate } = useLocalization();
 *   const { localizedDateTime } = useLocalization();
 * ```
 */
export const useLocalization = () => {
  const { activeOrganization } = useContext(UserContext);

  const formattingMask = activeOrganization.applicableLocalization?.dateFormat ?? 'YYYY-MM-DD';

  /**
   * @param date date in YYYY-MM-DD format or other format that dayjs() can handle
   * @returns date formatted according to the organization's localization `dateFormat` attribute
   *
   * Usage:
   * ```
   *   const { localizedDate } = useLocalization();
   *
   *   console.log('today:', localizedDate(undefined));
   *   console.log('established at:', localizedDate('2000-01-01'));
   * ```
   */
  const localizedDate = (date: any): string => dayjs(date).format(formattingMask);

  /**
   * @param dateTime date+time in YYYY-MM-DDThh:mm:ss format or other format that dayjs() can handle
   * @returns date formatted according to the organization's localization `dateFormat` attribute and time in "hh:mma" (fixed)
   *
   * Usage:
   * ```
   *   const { localizedDateTime } = useLocalization();
   *
   *   console.log('now:', localizedDateTime(undefined)); // 01 May 2024 03:52am
   *   console.log('created at:', localizedDateTime('2000-01-01T12:34:56')); // 01 Jan 2000 12:34pm
   * ```
   */
  const localizedDateTime = (dateTime: any): string => dayjs(dateTime).format(`${formattingMask} hh:mma`);

  return {
    localizedDate,
    localizedDateTime,
  };
};
