import {
  Box, Button, CircularProgress, Dialog, DialogTitle, IconButton, List, ListItem, MenuItem, TextField, Tooltip, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../providers/userContextProvider';
import { OPTIMIZER_PAUSABLE_STATES } from '../../../interfaces/portfolioOptimizer';

const RUN_PORTFOLIO_OPTIMIZER = gql`
  mutation runPortfolioOptimizer($input: RunPortfolioOptimizerInput!) {
    runPortfolioOptimizer(input: $input) {
      portfolioOptimizer {
        id
      }
    }
  }
`;

const SelectOptimizerModal = ({ handleClose }: { handleClose: () => void }) => {
  const { t } = useTranslation(['portfolioOptimizer']);
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState<string>('TRANSFER');
  const [selectedStep, setSelectedStep] = useState<string>('COMPLETE_ALL_STEPS');

  const [runPortfolioOptimizer, { data, loading }] = useMutation(RUN_PORTFOLIO_OPTIMIZER);

  useEffect(() => {
    if (data) {
      navigate(`/portfolioOptimizers/${data.runPortfolioOptimizer.portfolioOptimizer.id}`);
    }
  }, [data, navigate]);

  const close = () => {
    setSelectedType('');
    setSelectedStep('');
    handleClose();
  };

  const run = () => runPortfolioOptimizer({
    variables: {
      input: {
        type: selectedType,
        pauseAt: selectedStep === 'COMPLETE_ALL_STEPS' ? undefined : selectedStep,
        organizationId: activeOrganization.id,
      },
    },
  });

  return (
    <>
      <Dialog
        data-testid='select-optimizer-modal'
        onClose={close}
        disableEscapeKeyDown={loading}
        open={true}
        fullWidth
        PaperProps={{
          sx: {
            minHeight: 300,
            width: 420,
            maxWidth: 450,
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '0px',
            marginTop: '8px',
          }}
        >
          <IconButton
            disabled={loading}
            sx={{ color: '#000000' }}
            onClick={close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box p={1} sx={{ minWidth: '400px' }}>
          <>
            <div style={{
              display: 'flex', justifyContent: 'center', paddingTop: 1, marginTop: 1,
            }}>
              <Typography variant='h5'>{t('selectOptimizerModal.title')}</Typography>
              <Tooltip
                title={t('selectOptimizerModal.subTitle')}
              >
                <InfoOutlinedIcon sx={{ fontSize: '0.8rem' }} />
              </Tooltip>
            </div>
            <List sx={{ paddingTop: 1, marginTop: 1 }}>
              {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress sx={{ m: 5 }} />
                </Box>
              ) : (
                <>
                  <ListItem>
                    <TextField select label={t('selectOptimizerModal.setType')} fullWidth value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                      {['TRANSFER', 'DRIFT'].map((option: any) => (
                        <MenuItem key={option} value={option}>
                          {t(`selectOptimizerModal.${option}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <ListItem>
                    <TextField select label={t('selectOptimizerModal.setStep')} fullWidth value={selectedStep} onChange={(e) => setSelectedStep(e.target.value)}>
                      {OPTIMIZER_PAUSABLE_STATES.map((option: any) => (
                        <MenuItem key={option} value={option}>
                          {t(`selectOptimizerModal.${option}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <ListItem>
                    <Button data-testid='confirmButton' type='submit' fullWidth variant='contained' onClick={run}>
                      {t('run')}
                    </Button>
                  </ListItem>
                </>
              )}
            </List>
          </>
        </Box>
      </Dialog>
    </>
  );
};

export default SelectOptimizerModal;
