import { gql, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { UserContext } from '../../../../providers/userContextProvider';
import { User } from '../../../../interfaces';
import { Box } from '../../../1-primative';
import {
  TextField, Button,
  Dialog,
  Form,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogFooter,
} from '../../../2-component';
import { OrganizationSelect } from '../../../3-pattern';

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
        dateOfBirth
      }
    }
  }
`;

const NewClient = ({
  afterCreate,
  forceOpen,
  newClient,
}: {
  afterCreate?: () => void,
  newClient?: (user: User) => void,
  forceOpen?: boolean,
}) => {
  const { t } = useTranslation(['clients']);
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState({
    firstName: '',
    lastName: '',
    primaryEmail: '',
    organizationId: activeOrganization.id,
    language: 'ENGLISH',
    timezone: 'America/Edmonton',
  });

  const [createClient, { loading }] = useMutation(CREATE_USER, {
    variables: {
      input: {
        firstName: client.firstName,
        lastName: client.lastName,
        primaryEmail: client.primaryEmail,
        organizationId: client.organizationId,
        language: client.language,
        timezone: client.timezone,
      },
    },
  });

  useEffect(() => {
    setClient({ ...client, organizationId: activeOrganization.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization]);

  const createOrg = async (event: any) => {
    const response = await createClient();
    if (forceOpen) {
      setOpen(forceOpen);
    } else if (response && response.data && !response.errors) {
      navigate(`/clients/${response.data.createUser.user.id}`);
    }
    if (afterCreate) {
      afterCreate();
    }
    if (newClient) {
      newClient(response.data.createUser.user);
    }
  };

  return (
    <>
      <Button label={t('shared:add')} onClick={() => setOpen(true)} leadingIcon={AddIcon} sx={{ ml: 1 }} />
      <Dialog maxWidth='sm' fullWidth open={open} onClose={() => setOpen(false)}>
        <Form onSubmit={createOrg}>
          <DialogTitle display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            {t('addModal.title')}
            <IconButton onClick={() => setOpen(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField label={t('addModal.firstName')} fullWidth value={client.firstName} onChange={(e: any) => setClient({ ...client, firstName: e.target.value })} sx={{ mb: 2 }} />
            <TextField label={t('addModal.lastName')} fullWidth value={client.lastName} onChange={(e: any) => setClient({ ...client, lastName: e.target.value })} sx={{ mb: 2 }} />
            <TextField label={t('addModal.email')} fullWidth value={client.primaryEmail} onChange={(e: any) => setClient({ ...client, primaryEmail: e.target.value })} sx={{ mb: 2 }} />
            <OrganizationSelect
              value={client.organizationId}
              label={t('addModal.organization')}
              onChange={(event: any) => setClient({ ...client, organizationId: event.target.value as string })}
            />
          </DialogContent>
          <DialogFooter>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant='tonal'
                label={t('shared:cancel')}
                onClick={() => setOpen(false)}
                sx={{ mr: 1 }}
              />
              <Button
                disabled={loading}
                label={t('shared:create')}
                type='submit'
              />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

export default NewClient;
