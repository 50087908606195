import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { OpenInNew } from '@mui/icons-material';
import { FETCH_ACCOUNT_STATEMENTS, FETCH_CUSTODIAN_STATEMENTS } from '../documents.queries';
import { Box, Skeleton, Typography } from '../../../../1-primative';
import { Pagination } from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { translateBackend } from '../../../../../assets/i18n/config';
import { PageObjectType } from '../../../../5-page';

export const DocumentList = ({
  objectId, objectType, documentTypes, options = {},
}: { objectId: string, objectType: PageObjectType, documentTypes: string[], options?: any }) => {
  const { t } = useTranslation(['document']);
  const { sys } = useThemeTokens();
  const [page, setPage] = useState<number>(1);

  const baseFilter = () => {
    switch (objectType) {
      case PageObjectType.INDIVIDUAL:
        return { userId: objectId };
      case PageObjectType.NON_INDIVIDUAL:
        return { userId: objectId };
      case PageObjectType.ACCOUNT:
        return options.useCustodianStatements ? { accountIds: [objectId] } : { accountId: objectId };
      case PageObjectType.HOUSEHOLD:
        return { clientGroupIds: [objectId] };
      default:
        return { userId: objectId };
    }
  };

  const QUERY = options.useCustodianStatements ? FETCH_CUSTODIAN_STATEMENTS : FETCH_ACCOUNT_STATEMENTS;

  const { data, loading } = useQuery(QUERY, {
    variables: {
      input: {
        filter: {
          ...baseFilter(),
          types: documentTypes,
        },
        pagination: {
          offSet: (page - 1) * 15,
          perPage: 15,
        },
      },
    },
  });

  if (loading) {
    return (
      <>
        <Box width='100%' pt={2}>
          <Skeleton width='120px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
          <Skeleton width='200px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
        </Box>
        <Box width='100%' pt={2}>
          <Skeleton width='120px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
          <Skeleton width='200px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
        </Box>
        <Box width='100%' pt={2}>
          <Skeleton width='120px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
          <Skeleton width='200px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
        </Box>
      </>
    );
  }

  const totalCount = options.useCustodianStatements ? data.fetchCustodianStatements.totalCount : data.fetchAccountStatements.totalCount;
  const statements = options.useCustodianStatements ? data.fetchCustodianStatements.statements : data.fetchAccountStatements.accountStatements;

  const isOpenBase64Pdf = (signedUrl: any, signedUrlFormat: any) => {
    // If signedUrl is encoded pdf, then we convert back into PDF
    if (signedUrlFormat === 'ENCODED_PDF') {
      const byteCharacters = atob(signedUrl);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl;
    }

    return signedUrl;
  };

  return (
    <Box width='100%'>
      {
        totalCount === 0 && (
          <Typography variant='bodyLarge' sx={{ textAlign: 'center', mt: 2 }}>{t('document:noDocuments')}</Typography>
        )
      }
      {
        statements.map((statement: any) => (
          <Box
            key={statement.id || ''}
            display='flex'
            pl={2}
            pt={1}
            pb={1}
            pr={2}
            justifyContent='space-between'
            alignItems='center'
            onClick={() => window.open(isOpenBase64Pdf(statement.signedUrl, statement.signedUrlFormat || ''), '_blank')}
            sx={{
              borderRadius: sys.borderRadius.md,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: sys.color.surfaceContainerVariant,
              },
            }}
          >
            <Box width='100%' display='flex'>
              <Typography variant='bodyMedium' sx={{ minWidth: '120px', color: sys.color.onSurfaceVariant }}>{dayjs(statement.referenceDate).format('MMMM YYYY')}</Typography>
              <Typography variant='bodyMedium'>{translateBackend(statement.translatedFileName)}</Typography>
            </Box>
            <OpenInNew sx={{ color: sys.color.onSurfaceVariant }} />
          </Box>
        ))
      }
      <Box display='flex' justifyContent='end' mt={2}>
        <Pagination page={page} onChange={(e: any, newPage: number) => setPage(newPage)} count={Math.ceil(totalCount / 15)} size='small' />
      </Box>
    </Box>
  );
};
