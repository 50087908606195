import { DocumentNode } from '@apollo/client';
import { FileDocumentObjectTypes, FileDocumentTypes } from '../interfaces/fileDocument';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../pages/client/components/documentUpload';

export interface CreateFileInput {
  objectType: FileDocumentObjectTypes,
  objectId: string,
  fileName: string,
  type: FileDocumentTypes,
  userId: string,
  name?: string,
  permissionType?: string,
  mediaType?: string,
  sourceId?: string,
  sourceType?: string,
}

export interface FileUploaderProps {
  file: File,
  createFileInput: CreateFileInput,
  onSuccess?: () => void,
  refetchQueries?: DocumentNode[],
  apolloClient: any,
}

export const fileUploader = async (
  {
    file,
    createFileInput,
    onSuccess,
    refetchQueries,
    apolloClient,
  }: FileUploaderProps,
): Promise<void> => {
  if (!file) return;

  /* (1) fetch the S3 upload URL from backend */
  const queryResult = await apolloClient.query({
    query: FETCH_FILE_UPLOAD_URL,
    variables: {
      input: {
        objectType: createFileInput.objectType,
        objectId: createFileInput.objectId,
        fileName: (createFileInput.type === FileDocumentTypes.ID_VERIFICATION) ? createFileInput.fileName : createFileInput.name,
        type: createFileInput.type,
        userId: createFileInput.userId,
      },
      skipErrorHandler: true,
    },
    fetchPolicy: 'no-cache',
  });

  const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
  if (!uploadUrl || queryResult?.error) {
    return;
  }

  /* (2) do the upload */
  try {
    const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: file }));
    if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
  } catch (e: any) {
    return;
  }

  /* (3) create the fileDocument within backend */
  try {
    await await apolloClient.mutate({
      mutation: CREATE_FILE_DOCUMENT,
      variables: {
        input: {
          fileName: (createFileInput.type === FileDocumentTypes.ID_VERIFICATION) ? createFileInput.fileName : createFileInput.name,
          objectId: createFileInput.objectId,
          objectType: createFileInput.objectType,
          name: createFileInput.name,
          type: createFileInput.type,
          permissionType: createFileInput.permissionType,
          sourceType: createFileInput.sourceType,
          sourceId: createFileInput.sourceId ?? undefined,
        },
      },
      refetchQueries,
    });
  } catch {
    return;
  }
  if (onSuccess) {
    onSuccess();
  }
};
