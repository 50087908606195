/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil, uniq } from 'lodash/fp';
import { Box, Skeleton } from '../../../../1-primative';
import {
  Card, Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../../2-component';
import { SelectionChip } from '../../../../3-pattern';
import { translateBackend } from '../../../../../assets/i18n/config';
import { SecurityHoldingCell } from './securityHoldingCell';
import { SecurityTotalHoldingCell } from './securityTotalHoldingCell';
import { AssetClassHoldingCell } from './assetClassHoldingCell';
import { UserContext } from '../../../../../providers/userContextProvider';
import { RefinitivSecurityModal } from '../../refinitivSecurityModal/refinitivSecurityModal';
import { Holding } from '../../../../../interfaces';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';

const DEFAULT_SECURITY_TABLE = [
  {
    label: {
      en: 'Symbol',
      fr: 'Symbole',
    },
    type: 'symbol',
  },
  {
    label: {
      en: 'Security Name',
      fr: '',
    },
    type: 'securityName',
  },
  {
    label: {
      en: 'Quantity',
      fr: 'Quantité',
    },
    type: 'quantity',
  },
  {
    label: {
      en: 'Book Cost',
      fr: 'Coût du livre',
    },
    type: 'bookCost',
  },
  {
    label: {
      en: 'Current Price',
      fr: 'Prix actuel',
    },
    type: 'currentPrice',
  },
  {
    label: {
      en: 'G/L Unrealized',
      fr: 'G/L non réalisé',
    },
    type: 'unrealizedGainLoss',
  },
  {
    label: {
      en: '1-Day Change',
      fr: 'Changement de 1 jour',
    },
    type: 'oneDayChange',
  },
  {
    label: {
      en: '% of Total',
      fr: '% du total',
    },
    type: 'percentOfTotal',
  },
  {
    label: {
      en: 'Market Value (Native)',
      fr: '',
    },
    type: 'marketValueNative',
  },
  {
    label: {
      en: 'Market Value',
      fr: 'Valeur du marché',
    },
    type: 'marketValue',
  },
];

const DEFAULT_ASSET_CLASS_TABLE = [
  {
    label: {
      en: 'Name',
      fr: 'Nom',
    },
    type: 'name',
  },
  {
    label: {
      en: 'G/L Unrealized',
      fr: 'G/L non réalisé',
    },
    type: 'unrealizedGainLoss',
  },
  {
    label: {
      en: '1-Day Change',
      fr: 'Changement de 1 jour',
    },
    type: 'oneDayChange',
  },
  {
    label: {
      en: '% of Total',
      fr: '% du total',
    },
    type: 'percentOfTotal',
  },
  {
    label: {
      en: 'Market Value (Native)',
      fr: '',
    },
    type: 'marketValueNative',
  },
  {
    label: {
      en: 'Market Value',
      fr: 'Valeur du marché',
    },
    type: 'marketValue',
  },
];

export const TableHoldings = ({
  holdings,
  history,
  loading,
  options = {},
  useCustodianData,
}: {
  holdings: Holding[];
  history: any[];
  loading?: boolean;
  options?: any;
  useCustodianData?: boolean;
}) => {
  const [totalMarketValue, setTotalMarketValue] = useState(0);
  const [groupBy, setGroupBy] = useState<'security' | 'primary' | 'secondary' | 'tertiary'>('security');
  const [data, setData] = useState<any[]>([]);
  const [missesFxRate, setMissesFxRate] = useState(false);
  const [hasMultipleCurrencies, setHasMultipleCurrencies] = useState(false);
  const [ricCode, setRicCode] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { activeCurrency } = useContext(UserContext);

  const { t } = useTranslation(['client', 'accountTypes']);
  const { showToast } = useGlobalToast();

  const filterCustodianColumns = (columns: any[], isCustodian?: boolean) => {
    if (isCustodian) {
      const removedTypes = ['threeDayChange', 'oneWeekChange', 'oneMonthChange'];
      return columns.filter((col: any) => !removedTypes.includes(col.type));
    }
    return columns;
  };

  useEffect(() => {
    if (!loading) {
      setTotalMarketValue(holdings?.reduce((acc, holding) => acc + holding.totalCents, 0));
      const currencies = uniq((holdings ?? []).map((holding) => holding?.originalCurrency?.currency ?? holding.currency));
      setHasMultipleCurrencies(currencies.length > 1);
    }
  }, [holdings, loading]);

  useEffect(() => {
    if (groupBy === 'primary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        if (holding?.financialProduct?.primaryAssetClass?.id) {
          if (d[holding.financialProduct.primaryAssetClass.id]) {
            d[holding.financialProduct.primaryAssetClass.id].totalCents += holding.totalCents;
            d[holding.financialProduct.primaryAssetClass.id].totalCentsNative += holding?.originalCurrency?.totalCents ?? holding.totalCents;
            d[holding.financialProduct.primaryAssetClass.id].originalCents += holding.quantity * holding.adjustedCostBaseCents;
            if (useCustodianData) {
              d[holding.financialProduct.primaryAssetClass.id].oneDayCents += (holding.eodPriceCents ?? 0) * holding.quantity;
            } else {
              d[holding.financialProduct.primaryAssetClass.id].oneDayCents += find1Day(holding)?.totalCents ?? 0;
              d[holding.financialProduct.primaryAssetClass.id].threeDayCents += find3Day(holding)?.totalCents ?? 0;
              d[holding.financialProduct.primaryAssetClass.id].oneWeekCents += find1Week(holding)?.totalCents ?? 0;
              d[holding.financialProduct.primaryAssetClass.id].oneMonthCents += find1Month(holding)?.totalCents ?? 0;
            }
          } else {
            d[holding.financialProduct.primaryAssetClass.id] = {
              originalCents: holding.quantity * holding.adjustedCostBaseCents,
              ...(useCustodianData
                ? {
                  oneDayCents: (holding.eodPriceCents ?? 0) * holding.quantity,
                }
                : {
                  oneDayCents: find1Day(holding)?.totalCents ?? 0,
                  threeDayCents: find3Day(holding)?.totalCents ?? 0,
                  oneWeekCents: find1Week(holding)?.totalCents ?? 0,
                  oneMonthCents: find1Month(holding)?.totalCents ?? 0,
                }),
              totalCents: holding.totalCents,
              totalCentsNative: holding?.originalCurrency?.totalCents ?? holding.totalCents,
              hasFxRate: !!holding?.originalCurrency,
              nativeCurrency: holding?.originalCurrency?.currency ?? holding.currency,
              currency: holding.currency,
              translatedName: holding.financialProduct.primaryAssetClass.translatedName,
            };
          }
        }
      });
      setData(Object.values(d));
    } else if (groupBy === 'secondary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        if (holding?.financialProduct?.secondaryAssetClass?.id) {
          if (d[holding.financialProduct.secondaryAssetClass.id]) {
            d[holding.financialProduct.secondaryAssetClass.id].totalCents += holding.totalCents;
            d[holding.financialProduct.secondaryAssetClass.id].totalCentsNative += holding?.originalCurrency?.totalCents ?? holding.totalCents;
            d[holding.financialProduct.secondaryAssetClass.id].originalCents += holding.quantity * holding.adjustedCostBaseCents;
            if (useCustodianData) {
              d[holding.financialProduct.secondaryAssetClass.id].oneDayCents += (holding.eodPriceCents ?? 0) * holding.quantity;
            } else {
              d[holding.financialProduct.secondaryAssetClass.id].oneDayCents += find1Day(holding)?.totalCents ?? 0;
              d[holding.financialProduct.secondaryAssetClass.id].threeDayCents += find3Day(holding)?.totalCents ?? 0;
              d[holding.financialProduct.secondaryAssetClass.id].oneWeekCents += find1Week(holding)?.totalCents ?? 0;
              d[holding.financialProduct.secondaryAssetClass.id].oneMonthCents += find1Month(holding)?.totalCents ?? 0;
            }
          } else {
            d[holding.financialProduct.secondaryAssetClass.id] = {
              originalCents: holding.quantity * holding.adjustedCostBaseCents,
              ...(useCustodianData
                ? {
                  oneDayCents: (holding.eodPriceCents ?? 0) * holding.quantity,
                }
                : {
                  oneDayCents: find1Day(holding)?.totalCents ?? 0,
                  threeDayCents: find3Day(holding)?.totalCents ?? 0,
                  oneWeekCents: find1Week(holding)?.totalCents ?? 0,
                  oneMonthCents: find1Month(holding)?.totalCents ?? 0,
                }),
              totalCents: holding.totalCents,
              totalCentsNative: holding?.originalCurrency?.totalCents ?? holding.totalCents,
              hasFxRate: !!holding?.originalCurrency,
              nativeCurrency: holding?.originalCurrency?.currency ?? holding.currency,
              currency: holding.currency,
              translatedName: holding.financialProduct.secondaryAssetClass.translatedName,
            };
          }
        }
      });
      setData(Object.values(d));
    } else if (groupBy === 'tertiary' && holdings) {
      const d: any = {};
      holdings.forEach((holding) => {
        if (holding?.financialProduct?.tertiaryAssetClass?.id) {
          if (holding.financialProduct?.tertiaryAssetClass?.id) {
            if (d[holding.financialProduct.tertiaryAssetClass.id]) {
              d[holding.financialProduct.tertiaryAssetClass.id].totalCents += holding.totalCents;
              d[holding.financialProduct.tertiaryAssetClass.id].totalCentsNative += holding?.originalCurrency?.totalCents ?? holding.totalCents;
              d[holding.financialProduct.tertiaryAssetClass.id].originalCents += holding.quantity * holding.adjustedCostBaseCents;
              if (useCustodianData) {
                d[holding.financialProduct.tertiaryAssetClass.id].oneDayCents += (holding.eodPriceCents ?? 0) * holding.quantity;
              } else {
                d[holding.financialProduct.tertiaryAssetClass.id].oneDayCents += find1Day(holding)?.totalCents ?? 0;
                d[holding.financialProduct.tertiaryAssetClass.id].threeDayCents += find3Day(holding)?.totalCents ?? 0;
                d[holding.financialProduct.tertiaryAssetClass.id].oneWeekCents += find1Week(holding)?.totalCents ?? 0;
                d[holding.financialProduct.tertiaryAssetClass.id].oneMonthCents += find1Month(holding)?.totalCents ?? 0;
              }
            } else {
              d[holding.financialProduct.tertiaryAssetClass.id] = {
                originalCents: holding.quantity * holding.adjustedCostBaseCents,
                ...(useCustodianData
                  ? {
                    oneDayCents: (holding.eodPriceCents ?? 0) * holding.quantity,
                  }
                  : {
                    oneDayCents: find1Day(holding)?.totalCents ?? 0,
                    threeDayCents: find3Day(holding)?.totalCents ?? 0,
                    oneWeekCents: find1Week(holding)?.totalCents ?? 0,
                    oneMonthCents: find1Month(holding)?.totalCents ?? 0,
                  }),
                totalCents: holding.totalCents,
                totalCentsNative: holding?.originalCurrency?.totalCents ?? holding.totalCents,
                hasFxRate: !!holding?.originalCurrency,
                nativeCurrency: holding?.originalCurrency?.currency ?? holding.currency,
                currency: holding.currency,
                translatedName: holding.financialProduct.tertiaryAssetClass.translatedName,
              };
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (d.NONE) {
              d.NONE.totalCents += holding.totalCents;
              d.NONE.totalCentsNative += holding?.originalCurrency?.totalCents ?? holding.totalCents;
              d.NONE.originalCents += holding.quantity * holding.adjustedCostBaseCents;
              if (useCustodianData) {
                d.NONE.oneDayCents += (holding.eodPriceCents ?? 0) * holding.quantity;
              } else {
                d.NONE.oneDayCents += find1Day(holding)?.totalCents ?? 0;
                d.NONE.threeDayCents += find3Day(holding)?.totalCents ?? 0;
                d.NONE.oneWeekCents += find1Week(holding)?.totalCents ?? 0;
                d.NONE.oneMonthCents += find1Month(holding)?.totalCents ?? 0;
              }
            } else {
              d.NONE = {
                originalCents: holding.quantity * holding.adjustedCostBaseCents,
                ...(useCustodianData
                  ? {
                    oneDayCents: (holding.eodPriceCents ?? 0) * holding.quantity,
                  }
                  : {
                    oneDayCents: find1Day(holding)?.totalCents ?? 0,
                    threeDayCents: find3Day(holding)?.totalCents ?? 0,
                    oneWeekCents: find1Week(holding)?.totalCents ?? 0,
                    oneMonthCents: find1Month(holding)?.totalCents ?? 0,
                  }),
                totalCents: holding.totalCents,
                totalCentsNative: holding?.originalCurrency?.totalCents ?? holding.totalCents,
                hasFxRate: !!holding?.originalCurrency,
                nativeCurrency: holding?.originalCurrency?.currency ?? holding.currency,
                currency: holding.currency,
                translatedName: {
                  en: t('none'),
                  fr: t('none'),
                },
              };
            }
          }
        }
      });
      setData(Object.values(d));
    }
  }, [holdings, groupBy]);

  useEffect(() => {
    if (holdings.some((holding: Holding) => isNil(holding.originalCurrency))) {
      showToast({ message: t('client:assetsOverview.missingExchangeRateMessage'), severity: 'error' });
      setMissesFxRate(true);
    } else {
      setMissesFxRate(false);
    }
  }, [holdings]);

  const find1Day = (hold: Holding) => history[history.length - 1]?.holdings?.find((h: Holding) => h.financialProduct.id === hold.financialProduct.id);
  const find3Day = (hold: Holding) => history[history.length - 3]?.holdings?.find((h: Holding) => h.financialProduct.id === hold.financialProduct.id);
  const find1Week = (hold: Holding) => history[history.length - 7]?.holdings?.find((h: Holding) => h.financialProduct.id === hold.financialProduct.id);
  const find1Month = (hold: Holding) => history[0]?.holdings?.find((h: Holding) => h.financialProduct.id === hold.financialProduct.id);

  const primaryTitle = () => (options.primaryAssetClassTitle ? translateBackend(options.primaryAssetClassTitle) : t('primaryAssetClass'));
  const secondaryTitle = () => (options.secondaryAssetClassTitle ? translateBackend(options.secondaryAssetClassTitle) : t('secondaryAssetClass'));
  const tertiaryTitle = () => (options.tertiaryAssetClassTitle ? translateBackend(options.tertiaryAssetClassTitle) : t('tertiaryAssetClass'));

  const maxNumberOfDecimalPlaces = () => Math.max(
    ...holdings.map((holding) => {
      const [, decimal] = holding.quantity.toString().split('.');
      return decimal ? decimal.length : 0;
    }),
  );

  // TODO: Allow configurable columns, this is set for the Wellington Altus POC
  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Box sx={{ pl: 4, pt: 3, pb: 2 }}>
          <SelectionChip
            onChange={(e: any) => setGroupBy(e as 'primary' | 'secondary' | 'tertiary')}
            value={groupBy}
            options={[
              { value: 'security', label: t('security'), dataTestId: 'holdings-table-selection-chip-security' },
              { value: 'primary', label: primaryTitle(), dataTestId: 'holdings-table-selection-chip-primary' },
              { value: 'secondary', label: secondaryTitle(), dataTestId: 'holdings-table-selection-chip-secondary' },
              ...(holdings?.some((elem) => elem.financialProduct?.tertiaryAssetClass) ? [{ value: 'tertiary', label: tertiaryTitle() }] : []),
            ]}
          />
        </Box>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody>
              <TableRow>
                {groupBy === 'security' ? (
                  <>
                    {filterCustodianColumns(options.securityTable || DEFAULT_SECURITY_TABLE, useCustodianData).map((x: any, idx: number) => (
                      <TableHeadCell key={x.type} right={x.type !== 'symbol' && x.type !== 'securityName'} isFirst={idx === 0}>
                        {`${translateBackend(x.label)}${x.type === 'marketValue' ? `(${activeCurrency})` : ''}`}
                      </TableHeadCell>
                    ))}
                  </>
                ) : (
                  <>
                    {filterCustodianColumns(options.assetClassTable || DEFAULT_ASSET_CLASS_TABLE, useCustodianData).map((x: any, idx: number) => (
                      <TableHeadCell key={x.type} right={x.type !== 'symbol' && x.type !== 'name'} isFirst={idx === 0}>
                        {`${translateBackend(x.label)}${x.type === 'marketValue' ? `(${activeCurrency})` : ''}`}
                      </TableHeadCell>
                    ))}
                  </>
                )}
              </TableRow>
              {loading && (
                <>
                  {[...Array(15)].map((x, i) => (
                    <TableRow key={i}>
                      {groupBy === 'security' ? (
                        <>
                          {filterCustodianColumns(options.securityTable || DEFAULT_SECURITY_TABLE, useCustodianData).map((_: any, idx: number) => (
                            <TableCell dense isFirst={idx === 0} key={idx}>
                              <Skeleton width='100%' height='16px' />
                            </TableCell>
                          ))}
                        </>
                      ) : (
                        <>
                          {filterCustodianColumns(options.assetClassTable || DEFAULT_ASSET_CLASS_TABLE, useCustodianData).map((_: any, idx: number) => (
                            <TableCell dense isFirst={idx === 0} key={idx}>
                              <Skeleton width='100%' height='16px' />
                            </TableCell>
                          ))}
                        </>
                      )}
                    </TableRow>
                  ))}
                </>
              )}
              {!loading
                && (groupBy === 'security' ? (
                  <>
                    {(holdings || []).map((holding, index) => (
                      <TableRow
                        key={`${holding.financialProduct.ticker}-${index}`}
                        hover
                        pointer={(holding.financialProduct?.ric && holding.financialProduct.ric !== '') || false}
                        onClick={() => {
                          if (holding.financialProduct?.ric && holding.financialProduct.ric !== '') {
                            setRicCode(holding.financialProduct.ric);
                            setModalOpen(true);
                          }
                        }}
                      >
                        {filterCustodianColumns(options.securityTable || DEFAULT_SECURITY_TABLE, useCustodianData).map((x: any, i: number) => (
                          <SecurityHoldingCell
                            key={x.key}
                            holding={holding}
                            type={x.type}
                            history={history}
                            totalMarketValue={totalMarketValue}
                            useCustodianData={useCustodianData}
                            displayCurrency={i === 0}
                            missesFxRate={missesFxRate}
                            isFirst={i === 0}
                            maxQuantityDecimalPlaces={maxNumberOfDecimalPlaces()}
                          />
                        ))}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {(data || []).map((group: any, i: number) => (
                      <TableRow key={group.translatedName?.en ? `${group.translatedName.en}-${i}` : i} hover>
                        {filterCustodianColumns(options.assetClassTable || DEFAULT_ASSET_CLASS_TABLE, useCustodianData).map((x: any, idx: number) => (
                          <AssetClassHoldingCell key={x.key} group={group} type={x.type} totalMarketValue={totalMarketValue} isFirst={idx === 0} />
                        ))}
                      </TableRow>
                    ))}
                  </>
                ))}
              {holdings && !loading && (
                <TableRow>
                  {groupBy === 'security' ? (
                    <>
                      {filterCustodianColumns(options.securityTable || DEFAULT_SECURITY_TABLE, useCustodianData).map((x: any, index: number) => (
                        <SecurityTotalHoldingCell
                          key={x.type}
                          holdings={holdings}
                          type={x.type}
                          history={history}
                          useCustodianData={useCustodianData}
                          hasMultipleCurrencies={hasMultipleCurrencies}
                          isFirstColumn={index === 0}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {filterCustodianColumns(options.assetClassTable || DEFAULT_ASSET_CLASS_TABLE, useCustodianData).map((x: any, index: number) => (
                        <SecurityTotalHoldingCell
                          key={x.type}
                          holdings={holdings}
                          type={x.type}
                          history={history}
                          useCustodianData={useCustodianData}
                          hasMultipleCurrencies={hasMultipleCurrencies}
                          isFirstColumn={index === 0}
                        />
                      ))}
                    </>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Card>
      {modalOpen && (
        <RefinitivSecurityModal
          onClose={() => {
            setModalOpen(false);
            setRicCode('');
          }}
          ricCode={ricCode}
        />
      )}
    </>
  );
};
