import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import {
  Typography, Box, Card, CardContent,
} from '../../../..';
import { formatMoneyValue } from '../../../../../util';

export const CALCULATE_WITHDRAWAL_FEES = gql`
  query calculateWithdrawFees($input: CalculateWithdrawFeesInput!) {
    calculateWithdrawFees(input: $input) {
      withdrawFees {
        type
        amountCents
      }
    }
  }
`;

const ILLIQUID_PORTFOLIO_ESTIMATE_AMOUNT_SPREAD = 0.05;

interface Props {
  accountId: string,
  availableToWithdrawCents: number,
  withdrawAmountCents: number,
  isIlliquidWithdrawal?: boolean,
  showDetails?: () => void,
}

const WithdrawalBreakdown = ({
  availableToWithdrawCents,
  accountId,
  withdrawAmountCents,
  isIlliquidWithdrawal,
  showDetails,
}: Props): JSX.Element => {
  const { t } = useTranslation(['workflowCompletions', 'transfer']);
  const [totalFees, setTotalFees] = useState(0);
  const [fetchingFees, setFetchingFees] = useState(false);
  const [withdrawalFees, setWithdrawalFees] = useState<{ type: string, amountCents: number, }[]>([]);
  const setCalculateWithdrawalFeesData = (data: {
    calculateWithdrawFees: {
      withdrawFees: {
        type: string,
        amountCents: number,
      }[]
    },
  }): void => {
    let feesCents = 0;
    data.calculateWithdrawFees.withdrawFees.forEach((withdrawFee) => {
      feesCents += withdrawFee.amountCents;
    });
    setTotalFees(feesCents);
    setWithdrawalFees(data.calculateWithdrawFees.withdrawFees);
    setFetchingFees(false);
  };
  const [calculateWithdrawalFees] = useLazyQuery(CALCULATE_WITHDRAWAL_FEES, {
    variables: {
      input: {
        accountId,
        amountCents: withdrawAmountCents,
        isfullWithdraw: withdrawAmountCents >= (availableToWithdrawCents * 0.98),
      },
    },
    onCompleted: (e) => setCalculateWithdrawalFeesData(e),
    onError: () => setFetchingFees(false),
    nextFetchPolicy: 'standby',
  });

  useEffect(() => {
    if (accountId) {
      setFetchingFees(true);
      calculateWithdrawalFees().then();
    }
  }, [calculateWithdrawalFees, accountId]);

  const breakdownItems = (children: JSX.Element, value: string, key?: string): JSX.Element => (
    <Box key={key} display='flex' justifyContent='space-between' alignItems="center" mb={2}>
      <Box display="flex" alignItems="center">
        {children}
      </Box>
      <Typography variant="bodyLarge" weight='bold'>{value}</Typography>
    </Box>
  );
  return (
    <Card variant='outlined'>
      <CardContent>
        {
          breakdownItems(
            <Typography variant="bodyLarge">
              {t('transfer:withdrawal.withdrawalBreakDown.availableToWithdraw')}
            </Typography>,
            formatMoneyValue(availableToWithdrawCents, '$', 2),
          )
        }
        {
          breakdownItems(
            <Typography variant="bodyLarge">
              {t('transfer:withdrawal.withdrawalBreakDown.withdrawalAmount')}
            </Typography>,
            formatMoneyValue(withdrawAmountCents, '$', 2),
          )
        }
        {
          withdrawalFees.map((withdrawalFee) => (
            breakdownItems(
              <Typography variant="bodyLarge">
                {t(`transfer:withdrawal.withdrawalBreakDown.feesAndTaxTypes.${withdrawalFee.type}`)}
              </Typography>,
              fetchingFees ? t('transfer:withdrawal.withdrawalBreakDown.loadingMessage') : formatMoneyValue(withdrawalFee.amountCents, '$', 2),
              withdrawalFee.type,
            )))
        }
        <hr />
        <Box display="flex" flexDirection='column'>
          <Typography variant="bodyLarge">
            {t('transfer:withdrawal.withdrawalBreakDown.estimatedWithdrawalAmount')}
          </Typography>
          <Typography variant="headingLarge">
            { !isIlliquidWithdrawal
              ? formatMoneyValue(withdrawAmountCents - totalFees, '$', 2)
              : (
                <>
                  {formatMoneyValue(withdrawAmountCents * (1 - ILLIQUID_PORTFOLIO_ESTIMATE_AMOUNT_SPREAD) - totalFees, '$', 2)}
                  &nbsp; - &nbsp;
                  {formatMoneyValue(withdrawAmountCents * (1 + ILLIQUID_PORTFOLIO_ESTIMATE_AMOUNT_SPREAD) - totalFees, '$', 2)}
                </>
              ) }
          </Typography>
          { availableToWithdrawCents && withdrawAmountCents > (availableToWithdrawCents || 0) * 0.97 ? (
            <Typography variant='labelSmall' color='error' mt={1}>{t('workflowCompletions:depositReview.amountExceedsWithdraw')}</Typography>
          ) : (<span></span>)}
        </Box>
      </CardContent>
    </Card>
  );
};

export default WithdrawalBreakdown;
