import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReviewBankAccountInformation as ConfigReviewBankAccountInformation } from '../workflowCompletion/subSteps';
import { Card, CardContent } from '../../2-component';
import { Typography } from '../../1-primative';
import { translateBackend } from '../../../assets/i18n/config';

export const ReviewBankAccountInformation = ({ objectId, options }: { objectId: string, options: any }) => {
  const { t } = useTranslation('client');
  const [edit] = useState(true);

  return (
    <>
      <Typography variant='headingSmall' mb={2}>{options.customTitle ? translateBackend(options.customTitle) : t('reviewBankAccountInformation.title')}</Typography>
      <Card>
        <CardContent>
          { edit ? (
            <ConfigReviewBankAccountInformation userId={objectId} options={options} onNext={() => {}} stepLoading={false} grid updateMode />
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </>
  );
};
