import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '../../../../1-primative';
import {
  Dialog, DialogTitle, DialogContent, MenuItem, TextField, Button,
} from '../../../../2-component';
import { UserContext } from '../../../../../providers/userContextProvider';

const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        name
        targetAmountCents
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

const CLOSE_GOAL = gql`
  mutation archiveGoal($input: ArchiveGoalInput!) {
    archiveGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

export const EditGoal = ({ objectId, onClose, canClose = true }: { objectId: string, onClose: () => void, canClose?: boolean }) => {
  const { t } = useTranslation('client');
  const [goal, setGoal] = useState<any>({});
  const [editOpen, setEditOpen] = useState(false);
  const [closeOpen, setCloseOpen] = useState(false);
  const { userId: paramsUserId } = useParams();
  const { activeEntity } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const navigate = useNavigate();

  const { data } = useQuery(FETCH_GOAL, {
    variables: {
      goalId: objectId,
    },
    fetchPolicy: 'no-cache',
  });

  const [updateGoal] = useMutation(UPDATE_GOAL, {
    variables: {
      input: {
        goalId: objectId,
        name: goal.name,
        targetAmountCents: goal.targetAmountCents,
      },
    },
    onCompleted: () => {
      window.location.reload();
    },
  });

  const [closeGoal, { loading }] = useMutation(CLOSE_GOAL, {
    variables: {
      input: {
        goalId: objectId,
      },
    },
    onCompleted: () => {
      navigate(`/clients/${userId}`);
    },
    onError: (e: any) => {
    },
  });

  useEffect(() => {
    if (data) {
      setGoal(data.fetchGoal.goal);
    }
  }, [data, setGoal]);

  const onEditClose = () => {
    onClose();
    setEditOpen(false);
  };

  const onCloseClose = () => {
    onClose();
    setCloseOpen(false);
  };

  return (
    <>
      <MenuItem onClick={() => setEditOpen(true)}>{t('editGoal.edit')}</MenuItem>
      { canClose
          && (<MenuItem onClick={() => setCloseOpen(true)}>{t('editGoal.close')}</MenuItem>)
      }
      <Dialog open={editOpen} onClose={onEditClose} fullWidth maxWidth='xs'>
        <DialogTitle>{t('editGoal.editGoal')}</DialogTitle>
        <DialogContent>
          <TextField
            value={goal.name}
            onChange={(e: any) => setGoal({ ...goal, name: e.target.value })}
            label={t('editGoal.goalName')}
            fullWidth
          />
          <TextField
            sx={{ mt: 2 }}
            value={goal.targetAmountCents / 100}
            type='dollar'
            leadingIcon='dollar'
            onChange={(e: any) => setGoal({ ...goal, targetAmountCents: e.target.value * 100 })}
            label={t('editGoal.targetAmount')}
            fullWidth
          />
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Button variant='text' label={t('editGoal.cancel')} onClick={onEditClose} sx={{ mr: 1 }}/>
            <Button variant='filled' label={t('editGoal.save')} onClick={updateGoal} />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={closeOpen} onClose={onCloseClose} fullWidth maxWidth='xs'>
        <DialogTitle>{t('editGoal.closeGoal')}</DialogTitle>
        <DialogContent>
          <Typography variant='bodyLarge'>{t('editGoal.closeGoalText')}</Typography>
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Button variant='text' label={t('editGoal.cancel')} onClick={onCloseClose} sx={{ mr: 1 }}/>
            <Button variant='filled' color='destructive' label={t('editGoal.close')} onClick={closeGoal} disabled={loading} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
