import { gql, useQuery } from '@apollo/client';
import {
  Box, Card, CircularProgress, Stack, Tab, Tabs,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { TabPanel } from 'components/tabs/ovTabs';
import AssetsOverview from '../../components/layout/client/assetsOverview';
import TransactionsTable from '../../components/tables/transactionsTable/transactionsTable';
import { TransfersTable } from '../../components/tables/transfersTable/transfersTable';
import {
  Goal, Organization, PortfolioReport, Relationship,
} from '../../interfaces';
import { ObjectType } from '../../providers/statsHooks';
import { usePageState } from '../../util/usePageState';
import { GoalSummary } from './components/goalSummary';
import SubAccounts from '../../components/cards/subAccounts';
import EditGoal from '../../components/layout/goals/components/editGoal';
import CollapseHoldings from '../../components/layout/client/collapseHoldings';
import { UserContext, usePermissions } from '../../providers/userContextProvider';
import PortfolioTable from '../../components/tables/portfolioTable/portfolioTable';
import TradesTable from '../../components/tables/tradesTable/tradesTable';
import { ViewPage } from '../../ovComponents';
import { delay, generateClientInitials, generateClientNameString } from '../../util';
import { ClientContext } from '../client';
import { HouseholdContext } from '../household';

export const FETCH_GOAL = (permissions: string[]) => gql`
  query fetchGoal($id: ObjectID!) {
    fetchGoal(goalId: $id) {
      goal {
        id
        type
        name
        state
        riskQuestion1
        timeHorizon
        targetAmountCents
        suitabilityScore
        statistics {
          marketValueCents
          simpleReturnAmount
          simpleReturnPercent
          pendingContributionCents
          holdings{
            currentPriceCents
            expectedTotalCents
            financialProduct {
              id
            }
            quantity
            totalCents
          }
        }
        financialProduct {
          id
          name
          theme {
            id
          }
          translatedName {
            en
          }
        }
        subAccounts {
          id
          state
          account {
            id
            type
            overrideTaxRank
            defaultTaxRank
            ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
          }
        }
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'middleName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'primaryEmail' : ''}
          statistics {
            marketValueCents
          }
          goals {
            id
            type
            statistics {
                marketValueCents
                simpleReturnAmount
                simpleReturnPercent
              }
          }
          accounts {
            id
            type
            statistics {
              marketValueCents
              simpleReturnAmount
              simpleReturnPercent
            }
          }
          households {
            id
            name
          }
          statistics {
          marketValueCents
        }
        }
        householdClientGroup {
          relationships {
          type
          user {
            id
            state
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName primaryEmail' : ''}
            ${permissions.includes('read:client_high_risk_pii') ? 'phone' : ''}
            incompleteFields
            iDVerified
            ${permissions.includes('read:client_low_risk_pii') ? 'complianceState' : ''}
            ${permissions.includes('read:client_suitability') ? 'lastSuitabilityReviewAt' : ''}
            ${permissions.includes('read:client_suitability') ? 'financialLiquidAssetsCents financialFixedAssetsCents totalDebtCents' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
            type
          }
          accessType
        }
        }
        organization { allowPortfolioPerGoal, availableFeatureFlags }
        tradingProcess
      }
    }
  }
`;

export const FETCH_PORTFOLIO_REPORTS = gql`
  query fetchPortfolioReports($input: FetchPortfolioReportsInput!) {
    fetchPortfolioReports(input: $input) {
      portfolioReports {
        id
        lastOptimizedBy {
          id
          createdAt
        }
        subAccounts {
          subAccount {
            id
            state
            account {
              taxRank
              type
              user {
                firstName
              }
            }
          }
          expectedCashCents
          cashAvailableForTradeCents
          marketValueCents
          pendingWithdrawCents
        }
        expectedCashCents
        cashAvailableForTradeCents
        holdings {
          expectedValueCents
          expectedPercentage
          financialProduct {
            id
            ticker
            isCash
            currentPriceCents
            name
            taxRank
            translatedName {
              en
              fr
            }
          }
        }
      }
    }
  }
`;

const GoalsDetail = () => {
  const { t } = useTranslation('goalsDetails');
  const params = useParams();
  const navigate = useNavigate();
  const { permissions, accessiblePages } = usePermissions();
  const [statsNeedUpdate, setStatsNeedUpdate] = useState(0);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<string>('');
  const [goal, setGoal] = useState<Goal>();
  const [portfolioReport, setPortfolioReport] = useState<PortfolioReport>();
  const { userContext } = useContext(UserContext);
  const [tab, setTab] = usePageState('overview', 'tab');
  const { activeOrganization } = useContext(UserContext);
  const [organizationId, setOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');
  const [isRefetchRequired, setIsRefetchRequired] = useState<boolean>(false);

  const { loading, refetch } = useQuery(FETCH_GOAL(permissions), {
    skip: permissions.length === 0,
    variables: { id: params.goalId },
    onCompleted: (data: any) => setGoal(data.fetchGoal.goal),
  });

  const fetchPortfolioReportsEnabled = permissions.some((x) => ['read:rebalance_basic', 'read:portfolio_optimizer'].includes(x));
  const { loading: loadingPortfolioReport, refetch: refetchPortfolioReports } = useQuery(FETCH_PORTFOLIO_REPORTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          goalId: goal?.id,
          organizationId: organizationId && organizationId?.length > 0 ? organizationId : activeOrganization.id,
        },
      },
    },
    skip: !goal?.id || !fetchPortfolioReportsEnabled,
    onCompleted: (data: any) => setPortfolioReport(data?.fetchPortfolioReports?.portfolioReports?.[0]),
  });

  useEffect(() => {
    if (activeOrganization.id) {
      setOrganizationId(activeOrganization.id);
    }

    if (isRefetchRequired) {
      refetchPortfolioReports();
      setIsRefetchRequired(false);
    }
  }, [activeOrganization.id, setOrganizationId, isRefetchRequired, refetchPortfolioReports, setIsRefetchRequired]);

  if (userContext.role?.goalPageConfiguration) {
    return <ViewPage pageConfiguration={userContext.role.goalPageConfiguration} />;
  }

  if (loading || loadingPortfolioReport) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ marginTop: 50 }} />
      </Box>
    );
  }

  if (!goal) return <></>;

  const subAccountIds = goal.subAccounts?.map((x) => x.id);
  const linkedToClientGroup = !!goal.householdClientGroup;

  return (
    <Box>
      <ClientContext.Provider
        value={{
          orgSettings: goal.organization as Organization,
          totalMarketValueCents: goal.user.statistics?.marketValueCents ?? 0,
        }}
      >
        <HouseholdContext.Provider
          value={{
            orgSettings: goal.organization as Organization,
            totalMarketValueCents: goal.user.statistics?.marketValueCents ?? 0,
            members: goal.householdClientGroup ? goal.householdClientGroup.relationships.map((rel: Relationship) => ({
              id: rel.user.id,
              initials: generateClientInitials(rel.user),
              name: generateClientNameString(rel.user),
            })) : [],
            indexedMembers: goal.householdClientGroup ? Object.fromEntries(goal.householdClientGroup.relationships.map((rel: Relationship, index: number) => [rel.user.id, index])) : {},
          }}
        >
          <GoalSummary
            goal={goal}
            popNavigate={() => navigate(-1)}
            onHighlightClicked={(prop) => {
              setEditOpen(true);
              setSelectedHighlight(prop);
            }}
            refetch={refetch}
            updateGoal={() => setEditOpen((prevState) => !prevState)}
          />
          <Box mt={4} mb={3}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => {
                setTab(newValue);
              }}
              variant='scrollable'
              scrollButtons='auto'
            >
              <Tab label={t('tabs.overview')} value='overview' />
              {goal.financialProduct && permissions.includes('read:bulk_trade_request') && <Tab label={t('tabs.portfolio')} value='portfolio' />}
              <Tab label={t('tabs.subaccount')} value='subaccount' />
              <Tab label={t('tabs.transfers')} value='transfers' />
              {permissions.includes('read:trade_basic') && <Tab label={t('tabs.trades')} value='trades' />}
              {accessiblePages.includes('TRANSACTIONS') && <Tab label={t('tabs.ledger')} value='ledger' />}
            </Tabs>
          </Box>
          <TabPanel value={tab} index='overview'>
            <Stack spacing={2}>
              <AssetsOverview id={goal.id} type={ObjectType.GOAL} needUpdate={statsNeedUpdate} />
              <CollapseHoldings id={goal.id} type={ObjectType.GOAL} isExpanded />
            </Stack>
          </TabPanel>
          {goal.financialProduct && permissions.includes('read:bulk_trade_request') && (
            <TabPanel value={tab} index='portfolio'>
              <PortfolioTable type={ObjectType.GOAL} id={goal.id} portfolioReport={portfolioReport} setIsRefetchRequired={setIsRefetchRequired} />
            </TabPanel>
          )}
          <TabPanel value={tab} index='subaccount'>
            <SubAccounts disabled={goal.state !== 'ACTIVE'} ofType='GOAL' ofId={goal.id} hasClientGroup={linkedToClientGroup} />
          </TabPanel>
          <TabPanel value={tab} index='transfers'>
            <Card>
              <TransfersTable
                filter={{
                  ...(goal?.householdClientGroup?.id ? { clientGroupId: goal?.householdClientGroup?.id, goalId: goal.id } : { userId: goal.user.id, goalId: goal.id }),
                }}
                showClient={linkedToClientGroup}
                onTransition={refetch}
                disabled={goal.state !== 'ACTIVE'}
                onNewTransfer={() => {
                  refetch();
                  setStatsNeedUpdate(statsNeedUpdate + 1);
                }}
                level='GOAL'
              />
            </Card>
          </TabPanel>
          <TabPanel value={tab} index='trades'>
            <Card>
              <TradesTable objectType='SUB_TRADE' filter={{ userId: goal.user.id, goalId: goal.id }} />
            </Card>
          </TabPanel>
          <TabPanel value={tab} index='ledger'>
            <Card>
              <TransactionsTable
                disabled={goal.state !== 'ACTIVE'}
                filter={{ userId: goal.user.id, subAccountIds }}
                onChange={() => {
                  refetch();
                  setStatsNeedUpdate(statsNeedUpdate + 1);
                }}
              />
            </Card>
          </TabPanel>
          <EditGoal
            afterUpdate={async () => {
              setEditOpen(false);
              setSelectedHighlight('');
              // await the update as overriding tax ranks take some time to sync across services
              await delay(1000);
              refetch();
            }}
            highlight={selectedHighlight}
            goalToUpdate={goal}
            open={editOpen}
            handleClose={() => {
              setEditOpen(false);
              setSelectedHighlight('');
            }}
          />
        </HouseholdContext.Provider>
      </ClientContext.Provider>
    </Box>
  );
};

export default GoalsDetail;
export { GoalBreadcrumb } from './goalBreadcrumb';
