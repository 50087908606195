import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  AccountTypes,
  EntityTypes,
  GoalRiskLevels,
  GoalTimeHorizons,
  GoalTypes,
  IntegrationProvider,
  IntegrationType,
  TranslatedString,
  User,
  getAccountTypes,
} from 'interfaces';
import { colors } from 'ovComponents/0-tokens';
import { Box, Grid } from 'ovComponents/1-primative';
import {
  Autocomplete,
  Button,
  Dialog, IconButton,
  Switch,
  TranslatableTextField,
} from 'ovComponents/2-component';
import {
  AdditionalInfoEdit,
  BasicFieldEdit,
  BasicOrSelectFieldEdit,
  BooleanEdit,
  DataFieldsEdit,
  DefaultValue,
  InvestmentKnowledgeOptionsEdit,
  RiskQuestionOptionsEdit,
  SelectFieldEdit,
  TranslatedStringEdit,
  TimeHorizonOptionsEdit,
  Attachment,
  WorkflowSelectFieldEdit,
  CountrySpecificTaxIdEdit,
  DisclaimerInfoEdit,
  ExtendedBooleanEdit,
} from 'ovComponents/4-module/configurableOptionFields';

import { FieldTypes } from 'ovComponents/4-module/configurableOptionFields/optionEditComponent';
import { UserContext, usePermissions } from 'providers/userContextProvider';
import { FETCH_CUSTOM_FIELDS } from 'pages/orgSettings/components/customFields';
import { CUSTOM_FIELDS_AVIALABLE_SUB_STEP, CustomField } from 'interfaces/customField';
import { FinancialProjectionVisual } from 'ovComponents/4-module/workflowCompletion/subSteps/financialProjection/financialProjection.visual';
import { isNil } from 'lodash';
import { CountryCodes } from '@onevesthq/ov-enums';
import { NotificationDefinitionForm } from '../../../4-module/notificationDefinitionForm/notificationDefinitionForm';

import { ApprovalVisual } from '../../../4-module/workflowCompletion/subSteps/approval/approval.visual';
import { DocumentsVisual } from '../../../4-module/workflowCompletion/subSteps/documents/documents.visual';
import { EmploymentInformationVisual } from '../../../4-module/workflowCompletion/subSteps/employmentInformation/employmentInformation.visual';
import { GoalsVisual } from '../../../4-module/workflowCompletion/subSteps/goals/goals.visual';
import { HouseholdVisual } from '../../../4-module/workflowCompletion/subSteps/household/household.visual';
import { IdVerificationVisual } from '../../../4-module/workflowCompletion/subSteps/idVerification/idVerification.visual';
import { IncomeAssetsAndDebtsVisual } from '../../../4-module/workflowCompletion/subSteps/incomeAssetsAndDebts/incomeAssetsAndDebts.visual';
import { InvestmentKnowledgeVisual } from '../../../4-module/workflowCompletion/subSteps/investmentKnowledge/investmentKnowledge.visual';
import { PersonalInformationVisual } from '../../../4-module/workflowCompletion/subSteps/personalInformation/personalInformation.visual';
import { NonIndividualInformationVisual } from '../../../4-module/workflowCompletion/subSteps/nonIndividualInformation/nonIndividualInformation.visual';
import { NonIndividualSetupVisual } from '../../../4-module/workflowCompletion/subSteps/nonIndividualSetup/nonIndividualSetup.visual';
import { PersonsOfInterestVisual } from '../../../4-module/workflowCompletion/subSteps/personsOfInterest/personsOfInterest.visual';
import { RelationshipInformationVisual } from '../../../4-module/workflowCompletion/subSteps/relationshipInformation/relationshipInformation.visual';
import { ResidencyInformationVisual } from '../../../4-module/workflowCompletion/subSteps/residencyInformation/residencyInformation.visual';
import { RiskQuestionVisual } from '../../../4-module/workflowCompletion/subSteps/riskQuestion/riskQuestion.visual';
import { RelatedEntitiesVisual, relatedEntitesVisualPropsExample } from '../../../4-module/workflowCompletion/subSteps/relatedEntities';
import { SubAccountsVisual, subAccountsVisualPropsExample } from '../../../4-module/workflowCompletion/subSteps/subAcounts';
import { CreateGoalVisual } from '../../../4-module/workflowCompletion/subSteps/createGoal/createGoal.visual';
import { EditGoalVisual } from '../../../4-module/workflowCompletion/subSteps/editGoal/editGoal.visual';
import { GoalRiskQuestionVisual } from '../../../4-module/workflowCompletion/subSteps/goalRiskQuestion/goalRiskQuestion.visual';
import { CreateAccountForGoalVisual } from '../../../4-module/workflowCompletion/subSteps/createAccountForGoal/createAccountForGoal.visual';
import { EditAccountVisual } from '../../../4-module/workflowCompletion/subSteps/editAccount/editAccount.visual';
import { AssignPortfolioToSubAccountVisual } from '../../../4-module/workflowCompletion/subSteps/assignPortfolioToSubAccount/assignPortfolioToSubAccount.visual';
import { ReviewVisual } from '../../../4-module/workflowCompletion/subSteps/review/review.visual';
import { RiskProfileVisual } from '../../../4-module/workflowCompletion/subSteps/riskProfile/riskProfile.visual';
import { ListTileOptionsEdit } from '../../../4-module/configurableOptionFields/listTileOptions';
import { CustomLabelsEdit } from './customLabelsEdit';
import { translateBackend } from '../../../../assets/i18n/config';
import { SelectCustomField } from '../../../4-module/configurableOptionFields/selectCustomField';
import { ScheduleFrequencyOptionsEdit } from '../../../4-module/configurableOptionFields/scheduleFrequencyOptions';

const FETCH_SUBSTEP_OPTIONS = gql`
  query fetchSubStepOptions($subStepType: SubStepTypes!) {
    fetchSubStepOptions(subStepType: $subStepType) {
      subStepOptions {
        key
        label {
          en
          fr
        }
        optionType
        options
        showIf
        default
      }
    }
  }
`;

export const FETCH_ROLE_PROFILES = gql`
  query fetchRoleProfiles($input: FetchRoleProfilesInput!) {
    fetchRoleProfiles(input: $input) {
      roleProfiles {
        id
        organization {
          id
        }
        translatedName {
          en
        }
        translatedDescription {
          en
        }
        template {
          permissions
          accessiblePages
          dashboards {
            id
            name
          }
        }
      }
    }
  }
`;

export const EditSubStepDialog = ({
  activeSubStep,
  open,
  setOpen,
  updateStep,
  removeStep,
  organizationId,
}: {
  activeSubStep: any;
  open: boolean;
  setOpen: (x: boolean) => void;
  updateStep: (x: any) => void;
  removeStep: () => void;
  organizationId: string;
}) => {
  const { t } = useTranslation('workflowCompletions');
  const [options, setOptions] = useState<any>([]);
  const { permissions } = usePermissions();
  const { activeOrganization, integrations, custodianConnection } = useContext(UserContext);
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const [subStepOptions, setSubStepOptions] = useState<any>({});
  const [formattedOptions, setFormattedOptions] = useState<any>({});
  const [optionList, setOptionList] = useState<any>(undefined);
  const [excludedFields, setExcludedFields] = useState<string[]>([]);

  useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization?.id ?? undefined,
          workflowStep: activeSubStep?.type,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    skip: !CUSTOM_FIELDS_AVIALABLE_SUB_STEP.includes(activeSubStep?.type) || !permissions.includes('read:custom_fields'),
    fetchPolicy: 'no-cache',
    onCompleted: (d: any) => {
      if (activeSubStep && d) {
        const customOptions: any[] = [];
        setDerivedCustomFields(d?.fetchCustomFields?.customFields ?? []);
        d?.fetchCustomFields?.customFields.forEach((customField: CustomField) => {
          const optionObj = {
            __typename: 'SubStepOption',
            label: customField.translatedName,
            key: customField.key,
            optionType: FieldTypes.BASIC_FIELD,
            additionalInfo: activeSubStep?.options[customField.key]?.additionalInfo,
            options: {
              allowAdditionalInfo: true,
              isMarkdown: true,
            },
            default: {
              enabled: activeSubStep?.options[customField.key]?.enabled,
              label: customField.translatedName,
              required: activeSubStep?.options[customField.key]?.required,
            },
            value: {
              enabled: activeSubStep?.options[customField.key]?.enabled,
              label: customField.translatedName,
              required: activeSubStep?.options[customField.key]?.required,
              additionalInfo: activeSubStep?.options[customField.key]?.additionalInfo,
              customField,
            },
          };
          customOptions.push(optionObj);
        });
        setOptions([...options, ...customOptions]);
      }
    },
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  const { data, loading } = useQuery(FETCH_SUBSTEP_OPTIONS, {
    variables: { subStepType: activeSubStep?.type },
    skip: !activeSubStep,
  });

  const roleProfiles = useQuery(FETCH_ROLE_PROFILES, {
    variables: {
      input: {
        filter: { organizationId },
      },
    },
    skip: !organizationId,
  });

  useEffect(() => {
    if (!custodianConnection?.enableFetchCustodianSuitability) {
      setExcludedFields((prevState) => [...prevState, 'useExternalSuitabilityScore']);
    }
  }, [custodianConnection]);

  useEffect(() => {
    if (activeSubStep && data) {
      const newOptions = [...(data?.fetchSubStepOptions?.subStepOptions || [])];
      newOptions.forEach((x: any, i: number) => {
        newOptions[i] = { ...x, value: activeSubStep.options[x.key] ?? x.default };
      });
      setOptions(newOptions);
      setSubStepOptions({
        id: activeSubStep.id,
        type: activeSubStep.type,
        skippable: activeSubStep.skippable !== undefined ? activeSubStep.skippable : false,
        canGoBack: activeSubStep.canGoBack !== undefined ? activeSubStep.canGoBack : true,
        completeableBy: activeSubStep.completableBy || ['ORGANIZATION_USER'],
        rolesCompleteableBy: activeSubStep.rolesCompleteableBy || [],
      });
      setOptionList(activeSubStep.options?.options || newOptions.find((x: any) => x.key === 'options')?.default);
    }
  }, [activeSubStep, data]);

  useEffect(() => {
    if (options) {
      const newFormattedOptions: any = {};
      options.forEach((x: any) => {
        newFormattedOptions[x.key] = x.value;
      });
      setFormattedOptions({ ...newFormattedOptions, options: optionList });
    }
  }, [options, optionList]);

  const getRoleProfileNames = (roleProfileIds: any): string[] => roleProfileIds.map((profile: any) => {
    const fetchedProfile = roleProfiles?.data?.fetchRoleProfiles?.roleProfiles?.find((fetched: any) => fetched?.id === (profile.id || profile));
    return { id: fetchedProfile?.id, name: fetchedProfile?.translatedName?.en };
  }).filter((obj: any) => Object.keys(obj).length !== 0);

  const [exampleUserData, setExampleUserData] = useState<Partial<User>>({
    type: EntityTypes.INDIVIDUAL,
  });

  const exampleScreen = () => {
    switch (activeSubStep?.type) {
      case 'APPROVAL':
        return (<ApprovalVisual options={formattedOptions} approvalData={{}} loading={false} updateApproval={() => { }} continueFunc={() => { }} activeCustomFields={derivedCustomFieldsKeys} />);
      case 'DOCUMENTS':
        return (
          <DocumentsVisual
            options={formattedOptions}
            signableDocuments={[{ id: 'test', translatedDisplayName: { en: 'Test Document' }, signed: false }]}
            uploadableDocuments={[]}
            loading={false}
            previewLoading={false}
            signAgreement={() => { }}
            downloadTemplatePDF={() => { }}
            doTheUpload={() => { }}
            continueFunc={() => { }}
            setSignableDocumentIndex={() => { }}
            setUploadableDocumentIndex={() => { }}
            html='This is a test document'
            viewNext={() => { }}
            signAll={async () => { }}
          />
        );
      case 'EMPLOYMENT_INFORMATION':
        return (
          <EmploymentInformationVisual
            options={formattedOptions}
            userData={{ employmentStatus: 'EMPLOYED' }}
            loading={false}
            updateUser={() => { }}
            continueFunc={() => { }}
            activeCustomFields={derivedCustomFieldsKeys}
          />
        );
      case 'GOALS':
        return (
          <GoalsVisual
            options={formattedOptions}
            loading={false}
            goals={[]}
            draftGoals={[]}
            updateDraftGoals={() => { }}
            continueFunc={() => { }}
            generateDraftGoal={() => ({
              userId: 'test',
              type: GoalTypes.BUILD_WEALTH,
              riskQuestion1: GoalRiskLevels.LEVEL_3,
              timeHorizon: GoalTimeHorizons.MID_TERM,
            })}
          />
        );
      case 'SUB_ACCOUNTS':
        return <SubAccountsVisual {...subAccountsVisualPropsExample} options={formattedOptions} />;
      case 'HOUSEHOLD':
        return (
          <HouseholdVisual
            options={formattedOptions}
            loading={false}
            continueFunc={() => { }}
            householdAction={formattedOptions?.defaultAction?.value || 'createHousehold'}
            feeTierOptions={[]}
            billingScheduleOptions={[]}
            householdOptions={[]}
            updateHouseholdAction={() => { }}
            updateNewHouseholdData={() => { }}
            updateJoinHouseholdData={() => { }}
          />
        );
      case 'ID_VERIFICATION':
        return <IdVerificationVisual options={formattedOptions} idData={{}} loading={false} updateId={() => { }} continueFunc={() => { }} openPersona={() => { }} />;
      case 'INCOME_ASSETS_AND_DEBTS':
        return <IncomeAssetsAndDebtsVisual options={formattedOptions} userData={{}} loading={false} updateUser={() => { }} continueFunc={() => { }} />;
      case 'INVESTMENT_KNOWLEDGE':
        return <InvestmentKnowledgeVisual options={formattedOptions} userData={{}} loading={false} updateUser={() => { }} continueFunc={() => { }} />;
      case 'PERSONAL_INFORMATION':
        return <PersonalInformationVisual
          options={formattedOptions} userData={exampleUserData} loading={false} updateUser={setExampleUserData} continueFunc={() => { }} activeCustomFields={derivedCustomFieldsKeys}
          applicableJurisdictions={activeOrganization.jurisdictions}
        />;
      case 'PERSONS_OF_INTEREST':
        return <PersonsOfInterestVisual options={formattedOptions} userData={{}} loading={false} updateUser={() => { }} continueFunc={() => { }} activeCustomFields={derivedCustomFieldsKeys} />;
      case 'PROJECTIONS':
        return <FinancialProjectionVisual readonly options={formattedOptions} onNext={() => { }} stepLoading={false} loading={false} />;
      case 'FINANCIAL_PROJECTIONS':
        return <FinancialProjectionVisual readonly options={formattedOptions} onNext={() => { }} stepLoading={false} loading={false} />;
      case 'RELATED_ENTITIES':
        return <RelatedEntitiesVisual {...relatedEntitesVisualPropsExample} options={formattedOptions} />;
      case 'RELATIONSHIP_INFORMATION':
        return <RelationshipInformationVisual options={formattedOptions} userData={{}} loading={false} updateUser={() => { }} continueFunc={() => { }} />;
      case 'RESIDENCY_INFORMATION':
        return (<ResidencyInformationVisual
          options={formattedOptions}
          userData={exampleUserData}
          applicableCountries={activeOrganization.applicableLocalization?.countries ?? [CountryCodes.CA, CountryCodes.US]}
          updateUser={setExampleUserData}
          loading={false}
          updating={false}
          continueFunc={() => { }}
        />);
      case 'RISK_QUESTION_1':
        return <RiskQuestionVisual question={1} options={formattedOptions} userData={{}} loading={false} updateUser={() => { }} continueFunc={() => { }} />;
      case 'RISK_QUESTION_2':
        return <RiskQuestionVisual question={2} options={formattedOptions} userData={{}} loading={false} updateUser={() => { }} continueFunc={() => { }} />;
      case 'NON_INDIVIDUAL_INFORMATION':
        return <NonIndividualInformationVisual options={formattedOptions} userData={{}} loading={false} updateUser={() => { }} continueFunc={() => { }} activeCustomFields={derivedCustomFieldsKeys} />;
      case 'NON_INDIVIDUAL_SETUP':
        return <NonIndividualSetupVisual options={formattedOptions} loading={loading} onNext={() => { }} />;
      case 'CREATE_GOAL':
        return <CreateGoalVisual options={formattedOptions} goalData={{}} loading={false} updateGoal={() => { }} continueFunc={() => { }} />;
      case 'EDIT_GOAL':
        return <EditGoalVisual options={formattedOptions} goalData={{}} loading={false} updateGoal={() => { }} continueFunc={() => { }} activeCustomFields={derivedCustomFieldsKeys} />;
      case 'GOAL_RISK_QUESTION_1':
        return <GoalRiskQuestionVisual options={formattedOptions} goalData={{}} loading={false} updateGoal={() => { }} continueFunc={() => { }} />;
      case 'CREATE_ACCOUNT_FOR_GOAL':
        return <CreateAccountForGoalVisual
          suggestedTypes={[]}
          otherTypes={getAccountTypes(EntityTypes.INDIVIDUAL, activeOrganization?.availableFeatureFlags, activeOrganization.applicableLocalization?.countries)}
          options={formattedOptions} accountType={''} loading={false} continueFunc={async () => { }}
        />;
      case 'EDIT_ACCOUNT':
        return (
          <EditAccountVisual
            options={formattedOptions}
            accountData={{ type: AccountTypes.TFSA, user: { physicalAddress: { province: 'AB' } } }}
            loading={false}
            loadingData={false}
            updateAccount={() => { }}
            continueFunc={() => { }}
            skip={() => { }}
            activeCustomFields={derivedCustomFieldsKeys}
          />
        );
      case 'ASSIGN_PORTFOLIO_TO_SUB__ACCOUNT':
        return (<AssignPortfolioToSubAccountVisual options={formattedOptions} objectData={{}} loading={false} updateObject={() => { }} continueFunc={() => { }} />);
      case 'REVIEW':
        return (<ReviewVisual options={formattedOptions} loading={false} continueFunc={() => { }} />);
      case 'RISK_PROFILE':
        return (<RiskProfileVisual />);
      default:
        return null;
    }
  };

  const exampleScreenExists = !!exampleScreen();

  const opt = (x: any, k: any): any => {
    const opts = { ...x?.options?.[k], value: x?.value?.[k] ?? x?.options?.[k]?.default };
    if (k === 'defaultAddressProvider') {
      const addressProviders = integrations?.filter((a) => a?.type === IntegrationType.ADDRESS).map((b) => b?.provider);
      const defaultProvider = addressProviders.length === 0 ? [IntegrationProvider.GOOGLE] : addressProviders;
      return { ...opts, options: opts.options.filter((op: any) => defaultProvider.includes(op.value)) };
    }
    return opts;
  };

  const setSubOption = (key: string, opts: any[], i: number): void => {
    const newOptions = [...options];
    newOptions[i] = { ...options[i], value: { ...options[i].value, [key]: opts[0].value } };
    setOptions(newOptions);
  };

  const independentlyScrollingSurfacesSx = {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 64px)', // to fit the MUI Dialog
    overflowY: 'scroll',
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={exampleScreenExists ? 'xl' : 'sm'} fullWidth>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <Grid container>
          {exampleScreenExists && (
            <Grid item xs={6} p={12} sx={independentlyScrollingSurfacesSx}>
              {exampleScreen()}
            </Grid>
          )}
          <Grid
            item
            xs={exampleScreenExists ? 6 : 12}
            p={4}
            sx={{
              background: colors.neutral200,
              ...independentlyScrollingSurfacesSx,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            {activeSubStep?.type === 'ACTION_NOTIFY' ? (
              <NotificationDefinitionForm
                baseNotificationDefinition={{ id: activeSubStep?.options?.notificationDefinitionId }}
                fetch
                action={activeSubStep?.options?.notificationDefinitionId ? 'edit' : 'create'}
                onRemove={() => {
                  removeStep();
                  setOpen(false);
                }}
                afterSubmit={(id?: string) => {
                  updateStep({ ...subStepOptions, options: { ...formattedOptions, options: optionList, notificationDefinitionId: id } });
                  setOpen(false);
                }}
                triggerEvent={false}
              />
            ) : (
              <>
                {options.map((x: any, i: number) => (
                  <Box key={i} display='flex' flexDirection='column'>
                    {x.optionType === FieldTypes.TRANSLATED_STRING || x.optionType === FieldTypes.OPTIONAL_TRANSLATED_STRING ? (
                      <TranslatedStringEdit option={x} options={options} setOptions={setOptions} i={i} />
                    ) : x.optionType === FieldTypes.BASIC_FIELD ? (
                      <BasicFieldEdit option={x} options={options} setOptions={setOptions} i={i} />
                    ) : x.optionType === FieldTypes.BASIC_OR_SELECT_FIELD ? (
                      <BasicOrSelectFieldEdit option={x} options={options} setOptions={setOptions} i={i} />
                    ) : x.optionType === FieldTypes.INVESTMENT_KNOWLEDGE_OPTIONS ? (
                      <InvestmentKnowledgeOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                    ) : x.optionType === FieldTypes.RISK_QUESTION_OPTIONS ? (
                      <RiskQuestionOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                    ) : x.optionType === FieldTypes.SCHEDULE_FREQUENCY_OPTIONS
                      && (isNil(x.showIf) || options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value === Object.values(x.showIf)[0])
                      ? (
                        <ScheduleFrequencyOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                      ) : x.optionType === FieldTypes.LIST_TILE ? (
                        <ListTileOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                      ) : x.optionType === FieldTypes.BOOLEAN && !excludedFields.includes(x.key) ? (
                        <BooleanEdit option={x} options={options} setOptions={setOptions} i={i} />
                      ) : x.optionType === FieldTypes.DATA_FIELDS ? (
                        <DataFieldsEdit option={x} options={options} setOptions={setOptions} i={i} />
                      ) : x.optionType === FieldTypes.SELECT_FIELD ? (
                        <SelectFieldEdit option={x} options={options} setOptions={setOptions} i={i} />
                      ) : x.optionType === FieldTypes.ATTACHMENT ? (
                        <Attachment option={x} options={options} setOptions={setOptions} i={i} />
                      ) : x.optionType === FieldTypes.EXTENDED_BOOLEAN ? (
                        <ExtendedBooleanEdit option={x} options={options} setOptions={setOptions} i={i} />
                      ) : x.optionType === FieldTypes.SELECT_CUSTOM_FIELDS ? (
                        <SelectCustomField />
                      ) : x.optionType === FieldTypes.WORKFLOW_SELECT_FIELD
                        && (isNil(x.showIf) || options.find((elem: any) => elem.key === Object.keys(x.showIf)[0])?.value === Object.values(x.showIf)[0])
                        ? (
                          <WorkflowSelectFieldEdit option={x} options={options} setOptions={setOptions} i={i} />
                        )
                        : x.optionType === FieldTypes.COUNTRY_SPECIFIC_TAX_ID ? (
                          <CountrySpecificTaxIdEdit option={x} options={options} setOptions={setOptions} i={i} />
                        ) : null
                    }

                    {x?.options && Object.keys(x?.options)?.map((k: any, index: number) => (
                      <Box key={index}>
                        {k === 'allowAdditionalInfo' ? (
                          <Box sx={{ mb: 2 }}><AdditionalInfoEdit option={x} options={options} setOptions={setOptions} i={i} /></Box>
                        ) : k === 'allowDefaultValue' ? (
                          <Box sx={{ mb: 2 }}><DefaultValue option={x} options={options} setOptions={setOptions} i={i} /></Box>
                        ) : x?.options?.[k]?.optionType === FieldTypes.BOOLEAN ? (
                          <BooleanEdit option={opt(x, k)} options={[opt(x, k)]} setOptions={(v) => setSubOption(k, v, i)} i={0} />
                        ) : x?.options?.[k]?.optionType === FieldTypes.SELECT_FIELD ? (
                          <SelectFieldEdit option={opt(x, k)} options={[opt(x, k)]} setOptions={(v) => setSubOption(k, v, i)} i={0} />
                        ) : x?.options?.[k]?.optionType === 'TRANSLATED_STRING' ? (
                          <TranslatableTextField
                            value={options[i].value?.[k] ?? { en: '' }}
                            label={translateBackend(x?.options?.[k]?.label)}
                            dialogLabel={translateBackend(x?.options?.[k]?.label)}
                            fullWidth
                            multiline
                            rows={3}
                            onChange={(e: TranslatedString) => {
                              const newOptions = [...options];
                              newOptions[i] = { ...options[i], value: { ...options[i].value, [k]: e } };
                              setOptions(newOptions);
                            }}
                          />
                        ) : null}
                        {k === 'allowTimeHorizonOptions' ? (
                          <TimeHorizonOptionsEdit optionList={optionList} setOptionList={setOptionList} />
                        ) : null}
                        {k === 'allowDisclaimer' ? (
                          <DisclaimerInfoEdit option={x} options={options} setOptions={setOptions} i={i} />
                        ) : null}
                        {k === 'customLabels' ? (
                          <CustomLabelsEdit option={x} options={options} setOptions={setOptions} i={i} />
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                ))}
                <Box sx={{ mb: 2 }}>
                  <Switch
                    checked={subStepOptions.skippable}
                    label={t('skippable')}
                    size='medium'
                    onChange={(e: any) => {
                      setSubStepOptions({ ...subStepOptions, skippable: e });
                    }}
                  ></Switch>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Switch
                    checked={subStepOptions.canGoBack}
                    label={t('canGoBack')}
                    size='medium'
                    onChange={(e: any) => {
                      setSubStepOptions({ ...subStepOptions, canGoBack: e });
                    }}
                  ></Switch>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Autocomplete
                    label={t('addRoleProfileToWorkflow')}
                    options={
                      roleProfiles?.data?.fetchRoleProfiles?.roleProfiles?.map((x: any) => ({
                        key: x.id,
                        label: x.translatedName.en,
                      })) || []
                    }
                    value={
                      getRoleProfileNames(subStepOptions?.rolesCompleteableBy || [])?.map((x: any) => ({
                        key: x.id,
                        label: x.name,
                      })) || []
                    }
                    isOptionEqualToValue={(option: any, value: any) => option.key === value.key}
                    multiple
                    fullWidth
                    onChange={(e: React.SyntheticEvent, value: any) => setSubStepOptions({ ...subStepOptions, rolesCompleteableBy: value.map((item: any) => item.key) })}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant='outlined'
                      color='destructive'
                      label={t('remove')}
                      onClick={() => {
                        removeStep();
                        setOpen(false);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      label={t('update')}
                      onClick={() => {
                        updateStep({ ...subStepOptions, options: { ...formattedOptions, options: optionList } });
                        setOpen(false);
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};
