import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  ScriptableContext,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import convert from 'color-convert';
import { useEffect, useRef } from 'react';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useLineChartTokens } from './lineChart.tokens';
import { formatMoneyValue } from '../../../util';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
);

const abbreviateNumber = (value: number) => {
  let newValue = value.toString();
  if (value >= 1000) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(((`${value}`).length - 1) / 3);
    let shortValue = 0;
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? (value / 1000 ** suffixNum) : value).toPrecision(precision));
      const dotLessShortValue = (`${shortValue}`).replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 3) { break; }
    }
    let shortValueString = shortValue.toString();
    if (shortValue % 1 !== 0) shortValueString = shortValue.toFixed(1);
    newValue = shortValueString + suffixes[suffixNum];
  }
  return newValue;
};

export const LineChart = ({
  labels, datasets, format = 'CENTS', showAxis = false, yAxisPosition = 'right', plugins,
  mouseMove, mouseOut, overrideData, overrideOptions,
}: {
  labels: string[], datasets?: any[], format?: 'CENTS' | 'FLOAT', showAxis?: boolean, yAxisPosition?: 'left' | 'right',
  plugins?: any, mouseMove?: () => void, mouseOut?: () => void, overrideData?: any, overrideOptions?: any,
}) => {
  const chartRef = useRef<any>(null);
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.canvas.addEventListener('mousemove', () => mouseMove && mouseMove());
      chart.canvas.addEventListener('mouseout', () => mouseOut && mouseOut());
    }
    return () => {
      if (chart) {
        chart.canvas.removeEventListener('mousemove', () => mouseMove && mouseMove());
        chart.canvas.removeEventListener('mouseout', () => mouseOut && mouseOut());
      }
    };
  }, [mouseMove, mouseOut]);

  const { comp } = useThemeTokens(useLineChartTokens());

  const axis = showAxis || comp.lineChart.showAxis;

  const rgb = convert.hex.rgb(comp.lineChart.color || '#000000');
  const NEAREST = 'nearest';

  const INDEX = 'index';
  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index' as typeof INDEX,
    },
    plugins: {
      tooltip: {
        position: 'nearest' as typeof NEAREST,
        callbacks: {
          label: (data: any) => `${data.dataset.label} - ${format === 'CENTS' ? formatMoneyValue(data.raw) : data.raw}`,
        },
      },
    },
    hover: {
      intersect: false,
      mode: 'point' as const,
    },
    tooltips: {
      mode: 'index',
    },
    scales: {
      x: {
        display: axis,
        grid: {
          display: false,
        },
        border: {
          display: axis,
        },
        ticks: {
          enabled: axis,
          maxTicksLimit: 5,
          autoSkip: true,
          maxRotation: 0,
          align: 'start' as const,
        },
      },
      y: {
        position: yAxisPosition,
        display: axis,
        grid: {
          display: false,
        },
        border: {
          display: axis,
        },
        ticks: {
          callback: (value: any, index: number, all: any) => (format === 'CENTS' ? `$${abbreviateNumber(Math.floor(value / 100))}` : value),
          maxTicksLimit: 4,
        },
      },
    },
    layout: {
      padding: {
        top: 25,
        right: axis ? 16 : 0,
        bottom: axis ? 16 : 0,
      },
    },
    pointRadius: 0,
    borderCapStyle: 'round',
  };

  const fullData = {
    labels,
    datasets: (datasets || []).map((x: any, index: number) => ({
      fill: true,
      label: x?.label,
      data: x?.data,
      borderColor: comp.lineChart.color,
      borderDash: [],
      backgroundColor: (context: ScriptableContext<'line'>) => {
        const { ctx } = context.chart;
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.3)`);
        gradient.addColorStop(1, `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0)`);
        return gradient;
      },
      tension: comp.lineChart.tension,
    })),
  };

  return (
    <Line ref={chartRef} options={{ ...options, ...overrideOptions }} data={overrideData ?? fullData} plugins={plugins} />
  );
};
