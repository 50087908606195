import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DragDropContext, Draggable, Droppable, DroppableProvided,
} from 'react-beautiful-dnd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { isBoolean, pick } from 'lodash/fp';
import { useGlobalToast } from 'providers/globalToastProvider';
import {
  SubStepTypes,
  TriggerType,
  Workflow,
  WorkflowStates,
  workflowTransitions,
} from 'interfaces/workflow';
import {
  Badge, Box, Button, Card, CardContent, IconButton,
  Menu, MenuItem, SelectField, TextField, Typography, colors,
} from '../..';
import { EditSubStepDialog } from './components/editSubStepDialog';
import BuildEventTriggerRules from './components/buildEventTriggerRules';
import { reorder } from './utils';
import { deleteFile, doUpload } from '../../4-module/configurableOptionFields/attachment';
import { UserContext } from '../../../providers/userContextProvider';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../../3-pattern/addBankAccount/addBankAccountManually/addBankAccountManually';
import { DELETE_FILE_DOCUMENT } from '../../../pages/client/components/documents/documentViewer';

const STEP_TYPES = [
  'PERSONAL_INFORMATION',
  'NON_INDIVIDUAL_INFORMATION',
  'NON_INDIVIDUAL_SETUP',
  'RELATIONSHIP_INFORMATION',
  'EMPLOYMENT_INFORMATION',
  'RESIDENCY_INFORMATION',
  'PERSONS_OF_INTEREST',
  'INCOME_ASSETS_AND_DEBTS',
  'INVESTMENT_KNOWLEDGE',
  'RISK_QUESTION_1',
  'RISK_QUESTION_2',
  'ID_VERIFICATION',
  'APPROVAL',
  'DOCUMENTS',
  'ACCOUNTS',
  'GOALS',
  'SUB_ACCOUNTS',
  'TRANSFERS',
  'HOUSEHOLD',
  'RELATED_ENTITIES',
  'ACTION_UPDATE_USER',
  'ACTION_ACTIVATE_ALL_SUB_ACCOUNTS',
  'ACTION_NOTIFY',
  'CREATE_GOAL',
  'EDIT_GOAL',
  'REVIEW_GOAL',
  'GOAL_RISK_QUESTION_1',
  'CREATE_ACCOUNT_FOR_GOAL',
  'EDIT_ACCOUNT',
  'REVIEW_ACCOUNT',
  'ASSIGN_PORTFOLIO_TO_SUB_ACCOUNT',
  'FINANCIAL_PROJECTIONS',
  'REVIEW',
  'REVIEW_PORTFOLIO_CHANGES',
  'BANK_ACCOUNT_INFORMATION',
  'REVIEW_BANK_ACCOUNT_INFORMATION',
  'RISK_PROFILE',
];

const eventTypes = {
  USER: [
    'CREATED',
    'PERSONAL_INFORMATION_UPDATED',
    'SUITABILITY_SCORE_UPDATED',
    'SUITABILITY_REVIEW_DUE',
  ],
};

const getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  padding: '16px',
  margin: '0 0 8px 0',
  background: 'white',
  borderRadius: '4px',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? colors.primary300 : colors.neutral300,
  padding: '8px',
  width: 250,
  borderRadius: '4px',
});

const getValidTransitions = (state: WorkflowStates | undefined) => (
  workflowTransitions.filter((transition) => (state ? transition.from.includes(state) : false))
);

export const ConfigureWorkflowPageVisual = ({
  data,
  loading,
  updateWorkflow,
  transitionWorkflow,
}: {
  data: any,
  loading: boolean,
  updateWorkflow: (input: any) => void,
  transitionWorkflow: (input: any) => void,
}) => {
  const { showToast } = useGlobalToast();
  const { t } = useTranslation(['workflowCompletions', 'orgSettings']);
  const { activeOrganization, userContext } = useContext(UserContext);
  const [open, setOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [activeSubStep, setActiveSubStep] = useState<any>(null);
  const [workflow, setWorkflow] = useState<Workflow>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);
  const [deleteFileDocument] = useMutation(DELETE_FILE_DOCUMENT);
  const subStepWithAttachment = [SubStepTypes.RISK_QUESTION_1, SubStepTypes.RISK_QUESTION_2];

  useEffect(() => {
    if (data) {
      setColumns(data.fetchWorkflow.workflow.steps);
      setWorkflow(data.fetchWorkflow.workflow);
    }
  }, [data]);

  const onDragEnd = (result: any) => {
    const newColumns = reorder(result, columns);
    setColumns(newColumns);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleWorkflowTransition = (transition: string) => {
    if (!workflow) return;
    transitionWorkflow({
      variables: {
        input: {
          workflowId: workflow.id,
          transition,
        },
      },
      onCompleted: () => (showToast({ severity: 'success', message: t('savedWorkflowMessage') })),
    });
  };

  const uploadOrDeleteDocument = async (subStep: any): Promise<any> => {
    const newSubStep = { ...subStep };
    if (subStep?.options?.attachment?.file) {
      await doUpload({
        objectId: activeOrganization.id, userId: userContext.id, subStep: newSubStep, fetchFileUploadUrl, createFileDocument,
      });
    }
    if (subStep?.options?.attachment?.docToDelete) {
      await deleteFile({ subStep: newSubStep, deleteFileDocument });
    }
    return newSubStep;
  };

  const handleReviewAttachments = async (subStep: any): Promise<any> => {
    const newSubStep = { ...subStep };
    await Promise.all(newSubStep?.options?.options.map(async (item: any, i: number) => {
      if (item?.value?.file) {
        await doUpload({
          objectId: activeOrganization.id, userId: userContext.id, subStep: item, fetchFileUploadUrl, createFileDocument, key: 'value',
        });
      }
      if (item?.value?.docToDelete) {
        await deleteFile({ subStep: item, deleteFileDocument });
      }
    }));
    return newSubStep;
  };

  const handleDocumentChange = async () => {
    const newColumns = await Promise.all(columns.map(async (column: any) => {
      const newSubSteps = await Promise.all(column.subSteps.map(async (subStep: any) => {
        if (subStepWithAttachment.includes(subStep.type)) {
          const newSubStep = await uploadOrDeleteDocument(subStep);
          return newSubStep;
        }
        if (subStep.type === SubStepTypes.REVIEW) {
          const newSubStep = await handleReviewAttachments(subStep);
          return newSubStep;
        }
        return subStep;
      }));
      return { ...column, subSteps: newSubSteps };
    }));
    setColumns(newColumns);
  };

  const update = async () => {
    await handleDocumentChange();
    updateWorkflow({
      variables: {
        input: {
          workflowId: workflow.id,
          name: pick(['en', 'fr'], workflow.name),
          triggerType: workflow.triggerType,
          eventType: workflow.eventType,
          steps: columns.map((column: any) => ({
            name: pick(['en', 'fr'], column.name),
            subSteps: column.subSteps.map((subStep: any) => ({
              type: subStep.type,
              options: subStep.options,
              canGoBack: isBoolean(subStep.canGoBack) ? subStep.canGoBack : true,
              completeableBy: subStep.completeableBy || ['ORGANIZATION_USER'],
              rolesCompleteableBy: subStep.rolesCompleteableBy?.map((x: any) => x.id ?? x) || [],
              skippable: subStep.skippable || false,
            })),
          })),
          triggerRules: (
            workflow.triggerType === TriggerType.EVENT && workflow.triggerRules
          ) ? workflow.triggerRules.filter((r) => !!r.field && !!r.value).map((r) => pick(['field', 'comparison', 'value'], r)) : [],
        },
      },
      onCompleted: () => (showToast({ severity: 'success', message: t('savedWorkflowMessage') })),
    });
  };

  const updateStep = (updatedStep: any) => {
    const newColumns = columns.map((column: any) => {
      const newSubSteps = column.subSteps.map((subStep: any) => {
        if (subStep.id === updatedStep.id) {
          return updatedStep;
        }
        return subStep;
      });

      return { ...column, subSteps: newSubSteps };
    });
    setColumns(newColumns);
  };

  const removeStep = () => {
    const newColumns = columns.map((column: any) => {
      const newSubSteps = column.subSteps.filter((subStep: any) => subStep.id !== activeSubStep.id);
      return { ...column, subSteps: newSubSteps };
    });
    setColumns(newColumns);
  };

  return (
    <>
      {workflow ? (
        <>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                <Box display='flex' flexDirection='row' alignItems='end' gap={2}>
                  <TextField label={t('workflowName')} value={workflow.name?.en ?? ''} onChange={(e: any) => {
                    setWorkflow({ ...workflow, name: { en: e.target.value, fr: e.target.value } });
                  }} />
                  <TextField
                    label={t('workflowState')}
                    value={t(`orgSettings:workflowStates.${workflow.state}`)}
                    InputProps={{ readOnly: true }}
                  />
                  <SelectField label={t('trigger')} value={workflow.triggerType || ''} onChange={(e: any) => {
                    setWorkflow({ ...workflow, triggerType: e.target.value });
                  }}>
                    <MenuItem value='MANUAL'>{t('triggerType.MANUAL')}</MenuItem>
                    <MenuItem value='EVENT'>{t('triggerType.EVENT')}</MenuItem>
                  </SelectField>
                  {workflow.triggerType === 'EVENT' && (
                    <SelectField label={t('event')} value={workflow.eventType || ''} onChange={(e: any) => {
                      setWorkflow({ ...workflow, eventType: e.target.value });
                    }}>
                      {eventTypes.USER.map((type) => (
                        <MenuItem key={type} value={type}>{t(`eventType.${type}`)}</MenuItem>
                      ))}
                    </SelectField>
                  )}
                  <Button label={t('saveWorkflow')} onClick={() => {
                    update();
                  }} />
                </Box>
                <IconButton
                  onClick={handleMenuClick}
                  title='Workflow Menu'
                  aria-label='workflow-menu'
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
          {workflow.triggerType === 'EVENT' && (<BuildEventTriggerRules workflow={workflow} setWorkflow={setWorkflow} />)}
          <DragDropContext onDragEnd={onDragEnd}>
            <EditSubStepDialog open={open} setOpen={setOpen} activeSubStep={activeSubStep} updateStep={updateStep} removeStep={removeStep} organizationId={workflow?.organization?.id ?? ''} />
            <Box display='flex' flexDirection='row' overflow='auto' height='calc(100vh - 200px)'>
              {columns.map((column: any) => (
                <Box
                  key={column.id}
                  sx={{
                    mr: 2,
                  }}
                >
                  <Droppable droppableId={column.id}>
                    {(provided: DroppableProvided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        <TextField fullWidth label='' value={column.name.en} sx={{ mb: 1 }} onChange={(e: any) => {
                          const newColumns = columns.map((x: any) => {
                            if (x.id === column.id) {
                              return { ...x, name: { en: e.target.value, fr: e.target.value } };
                            }
                            return x;
                          });
                          setColumns(newColumns);
                        }} />
                        {column.subSteps.map((subStep: any, index: number) => (
                          <Draggable key={subStep.id} draggableId={subStep.id} index={index}>
                            {(dragProvided: any, dragSnapshot: any) => (
                              <div
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                                style={getItemStyle(
                                  dragSnapshot.isDragging,
                                  dragProvided.draggableProps.style,
                                )}
                                onClick={() => {
                                  setActiveSubStep(subStep);
                                  setOpen(true);
                                }}
                              >
                                <Badge label={subStep.type.replaceAll('_', ' ')} />
                                <Typography sx={{ mt: 1 }}>{subStep.options.title?.en}</Typography>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <SelectField label={t('addStep')} fullWidth value='' onChange={(e: any) => {
                          const newSubStep = {
                            id: `subStep-${Math.random()}`,
                            type: e.target.value,
                            options: {},
                          };
                          const newColumns = columns.map((x: any) => {
                            if (x.id === column.id) {
                              return { ...x, subSteps: [...x.subSteps, newSubStep] };
                            }
                            return x;
                          });
                          setColumns(newColumns);
                          setActiveSubStep(newSubStep);
                          setOpen(true);
                        }}>
                          {STEP_TYPES.map((x: any) => (
                            <MenuItem key={x} value={x}>{x.replaceAll('_', ' ')}</MenuItem>
                          ))}
                        </SelectField>
                        <Button sx={{ mt: 1 }} variant='text' color='destructive' label={t('remove')} onClick={() => {
                          const newColumns = columns.filter((x: any) => x.id !== column.id);
                          setColumns(newColumns);
                        }} fullWidth />
                      </div>
                    )}
                  </Droppable>
                </Box>
              ))}
              <Box sx={{ mr: 2 }}>
                <Box sx={getListStyle(false)}>
                  <Button label={t('addSection')} sx={{ width: 250 }} fullWidth onClick={() => {
                    const newColumns = [...columns, {
                      id: `step-${Math.random()}`,
                      name: { en: 'New Section', fr: 'New Section' },
                      subSteps: [],
                    }];
                    setColumns(newColumns);
                  }} />
                </Box>
              </Box>
            </Box>
          </DragDropContext>
        </>
      ) : <></>}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {getValidTransitions(workflow.state).map((transition) => (
          <MenuItem
            key={transition.name}
            onClick={() => {
              handleWorkflowTransition(transition.name);
            }}
          >
            {t(`orgSettings:workflowTransitions.${transition.name}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ConfigureWorkflowPageVisual;
