import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle, Error, ChangeCircle } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { isNil } from 'lodash/fp';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { Box, Skeleton, Typography } from '../../../1-primative';
import { FETCH_ACCOUNT } from './accountHighlights.queries';
import { Card, CardContent, StatusTag } from '../../../2-component';
import { AccountHighlightsConfig } from './accountHighlightsConfig';
import { AccountHighlightsContribution } from './accountHighLightsContribution';
import { generateClientNameString } from '../../../../util';

export interface Section {
  enabledBy?: 'displayContributions' | 'displayWithdrawals' | 'displaySimplifiedAccountStatus',
  label: string,
  key: string,
  showIf?: () => boolean,
  useCustodianData?: boolean,
  isContribution: boolean,
}

export const AccountHighlights = ({ objectId, options, useCustodianData }: { objectId: string, options: any, useCustodianData?: boolean }) => {
  const { t } = useTranslation(['components', 'accountsDetail']);
  const { sys } = useThemeTokens();
  const [accountSections, setAccountSections] = useState<Section[]>([]);

  const { loading, data } = useQuery(FETCH_ACCOUNT, {
    variables: {
      accountId: objectId,
    },
  });

  const accountHighlightsConfig = AccountHighlightsConfig();

  useEffect(() => {
    let sections: Section[] = [
      {
        label: t('accountOwners'),
        key: 'accountOwners',
        isContribution: false,
      },
      {
        label: t('accountNo'),
        key: 'accountNumber',
        isContribution: false,
      },
      {
        enabledBy: 'displaySimplifiedAccountStatus',
        label: t('status'),
        key: 'displaySimplifiedAccountStatus',
        isContribution: false,
      },
    ];
    if (data?.fetchAccount?.account?.type) {
      const { type } = data.fetchAccount.account;
      let typeSecs = accountHighlightsConfig[type];
      if (!typeSecs) typeSecs = accountHighlightsConfig.default;
      sections = [
        ...sections,
        ...typeSecs.sections,
      ].filter((sec) => (!sec.enabledBy || options[sec.enabledBy]) && (!sec.showIf || sec.showIf()) && (isNil(sec.useCustodianData) || sec.useCustodianData === !!useCustodianData));
    }
    setAccountSections(sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options, t, useCustodianData]);

  const accountState = useMemo(() => {
    switch (data?.fetchAccount?.account?.state) {
      case 'ACTIVE':
        return 'ACTIVE';
      case 'CANCELED':
      case 'INACTIVE':
        return 'CLOSED';
      default:
        return 'PENDING';
    }
  }, [data]);

  const ownerString = () => {
    const jointUser = data?.fetchAccount?.account?.affiliations?.find((x: any) => x.type === 'JOINT');
    if (jointUser) {
      return `${generateClientNameString(data?.fetchAccount?.account?.user)}, ${generateClientNameString(jointUser.user)}`;
    }
    return generateClientNameString(data?.fetchAccount?.account?.user);
  };

  const getHighlight = (element: Section) => {
    if (!data?.fetchAccount?.account?.type) return <></>;
    if (element.key === 'accountOwners') {
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
          <Typography variant='bodyMedium' weight='bold'>{ownerString() ?? '-'}</Typography>
        </Box>
      );
    }
    if (element.key === 'accountNumber') {
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
          <Typography variant='bodyMedium' weight='bold'>{data?.fetchAccount?.account?.custodianAccountNumber ?? '-'}</Typography>
        </Box>
      );
    }
    if (element.key === 'displaySimplifiedAccountStatus') {
      return (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{element.label}</Typography>
          <Box display='flex'>
            <StatusTag
              size='small'
              variant='bodyMedium'
              weight='bold'
              color={accountState === 'ACTIVE' ? 'positive' : accountState === 'CLOSED' ? 'negative' : 'neutral'}
              label={t(`accountsDetail:accountState.${accountState}`)}
              icon={accountState === 'ACTIVE' ? CheckCircle : accountState === 'CLOSED' ? Error : ChangeCircle }
            />
          </Box>
        </Box>
      );
    }
    if (element.isContribution) {
      return (
        <AccountHighlightsContribution accountId={objectId} section={element} useCustodianData={useCustodianData} />
      );
    }
    return <></>;
  };

  if (loading) {
    return (
      <Skeleton width='100%' height='80px' variant='rectangular'></Skeleton>
    );
  }

  return (
    <Card sx={{ display: accountSections.length > 0 ? undefined : 'none' }}>
      <CardContent sx={{ px: 0, pb: '0 !important', backgroundColor: sys.color.surface }}>
        <Box sx={{ pb: 1.5, pt: 0.5, px: 2 }}>
          <Typography variant='titleMedium'>{t('accountHighlights')}</Typography>
        </Box>
        {accountSections.map((elem, index) => (
          <Box
          key={index}
          sx={{ backgroundColor: index % 2 === 0 ? sys.color.surfaceContainer : undefined, px: 2, py: 1 }}
        >
          {getHighlight(elem)}
        </Box>
        ))}
      </CardContent>
    </Card>
  );
};
