import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '../../../1-primative';
import {
  Card, Table,
  TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../2-component';
import { TransferModal } from './components/transferModal';
import { translateBackend } from '../../../../assets/i18n/config';
import { PendingTransferCell } from './components/pendingTransferCell';

const DEFAULT_TABLE = [
  {
    label: {
      en: 'Account',
      fr: 'Compte',
    },
    type: 'account',
  },
  {
    label: {
      en: 'Type',
      fr: 'Type',
    },
    type: 'type',
  },
  {
    label: {
      en: 'Source',
      fr: '',
    },
    type: 'source',
  },
  {
    label: {
      en: 'State',
      fr: '',
    },
    type: 'state',
  },
  {
    label: {
      en: 'Value',
      fr: 'Valeur',
    },
    type: 'value',
  },
  {
    label: {
      en: 'Date',
      fr: '',
    },
    type: 'scheduledAt',
  },
];

export const PendingTransfersVisual = ({
  pendingTransfers, scheduledTransfers, loading, transition, options, refetchAll, upcomingTransactions,
}: {
  pendingTransfers: any[], scheduledTransfers: any[], loading: boolean,
  transition: (transferId: string, isScheduled: boolean, variables: any) => void, options: any, refetchAll: () => void,
  upcomingTransactions: any[],
}) => {
  const { t } = useTranslation(['components', 'accountTypes']);
  const [activeTransfer, setActiveTransfer] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Typography variant='headingSmall' mb={2}>{t('components:pendingTransfers.title')}</Typography>
      <Card sx={{ overflowX: 'auto' }}>
        <Table>
          <TableBody>
            <TableRow sx={{ height: '40px' }}>
              {(options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                <TableHeadCell key={x} isFirst={idx === 0} right={![
                  'account', 'type', 'createdAt',
                  'scheduledAt', 'source', 'state',
                  'securityName', 'frequency', 'bankAccount',
                ].includes(x.type)}>{translateBackend(x.label)}</TableHeadCell>
              ))}
            </TableRow>
            { loading ? (
              <>
                {[...Array(5)].map((x, i) => (
                  <TableRow key={i}>
                    { (options.table || DEFAULT_TABLE).map((_: any, idx: number) => (
                        <TableCell dense isFirst={idx === 0}><Skeleton width='100%' height='16px' /></TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {pendingTransfers.map((transaction: any) => (
                  <TableRow key={transaction.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                    setActiveTransfer(transaction);
                    setOpen(true);
                  }}>
                    { (options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                      <PendingTransferCell isFirst={idx === 0} key={x.key} transaction={transaction} type={x.type} />
                    ))}
                  </TableRow>
                ))}
                {scheduledTransfers.map((transaction: any) => (
                  <TableRow key={transaction.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                    setActiveTransfer(transaction);
                    setOpen(true);
                  }}>
                    { (options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                      <PendingTransferCell isFirst={idx === 0} key={x.key} transaction={transaction} type={x.type} />
                    ))}
                  </TableRow>
                ))}
                  {upcomingTransactions.map((transaction: any, idx: number) => (
                  <TableRow key={idx} hover sx={{ cursor: 'pointer' }}>
                    { (options.table || DEFAULT_TABLE).map((x: any, index: number) => (
                      <PendingTransferCell isFirst={index === 0} key={x.key} transaction={transaction} type={x.type}/>
                    ))}
                  </TableRow>
                  ))}
              </>
            )}
            {pendingTransfers.length === 0 && scheduledTransfers.length === 0 && upcomingTransactions.length === 0 && !loading && (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>{t('components:transaction.noTransactions')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Card>
      { activeTransfer && (
        <TransferModal open={open} setOpen={setOpen} transfer={activeTransfer} transition={transition} options={options} />
      )}
    </>
  );
};
