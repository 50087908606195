import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { gql, useMutation } from '@apollo/client';
import { WorkflowCompletionVisual } from './workflowCompletion.visual';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../providers/analyticsProvider';

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        incompleteFields
        iDCheckCompleted
        iDVerified
        inProvinceSince
        hasCheckedPolicyAndCondition
      }
    }
  }
`;

export const WORKFLOW_COMPLETION = `#graphql
  workflowCompletion {
    id
    objectId
    objectType
    context
    workflow {
      id
      name {
        en
        fr
      }
      steps {
        id
        name {
          en
          fr
        }
        subSteps {
          id
          type
        }
      }
    }
    currentStep {
      id
      name {
        en
        fr
      }
      subSteps {
        id
        type
      }
    }
    currentSubStep {
      id
      options
      type
      canGoBack
      skippable
      rolesCompleteableBy {
        id
      }
    }
  }
`;

const NEXT_STEP = gql`
  mutation nextSubStep($input: NextSubStepInput!) {
    nextSubStep(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

const PREV_STEP = gql`
  mutation previousSubStep($input: PreviousSubStepInput!) {
    previousSubStep(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

const UPDATE_WORKFLOW_COMPLETION = gql`
  mutation updateWorkflowCompletion($input: UpdateWorkflowCompletionInput!) {
    updateWorkflowCompletion(input: $input) {
      ${WORKFLOW_COMPLETION}
    }
  }
`;

export const WorkflowContext = createContext<any>({
  workflowData: {},
  setWorkflowData: () => { },
});

export const WorkflowCompletion = ({
  workflowCompletion,
  open,
  onClose,
  isInnerWorkflow,
  updateParent,
}: {
  workflowCompletion: any,
  open: boolean,
  onClose: () => void,
  isInnerWorkflow?: boolean,
  updateParent?: (activeCompletion?: any) => void,
}) => {
  const [workflowData, setWorkflowData] = useState<any>(null);
  const [activeWorkflowCompletion, setActiveWorkflowCompletion] = useState<any>(null);
  const { sendAnalytic } = useContext(AnalyticsContext);

  const checkIfItIsFirstWorkflow = (activeWorkflowCompletionItem: any) => {
    if (Object.keys(activeWorkflowCompletionItem).length === 0) return;
    if ((activeWorkflowCompletionItem.currentStep.id === activeWorkflowCompletionItem.workflow.steps[0].id)
        && (activeWorkflowCompletionItem.currentSubStep.id === activeWorkflowCompletionItem.workflow.steps[0].subSteps[0].id)) {
      sendAnalytic(
        ovAnalyticsEvents.workflowsCompletionStart,
        {
          workflowStepTitle: activeWorkflowCompletionItem.currentSubStep.options?.title,
          workflowId: activeWorkflowCompletionItem?.workflow?.id,
          workflowName: activeWorkflowCompletionItem?.workflow?.name,
          activeWorkflowCompletionId: activeWorkflowCompletionItem?.id,
          objectId: activeWorkflowCompletionItem?.objectId,
          objectType: activeWorkflowCompletionItem.objectType,
        },
      );
    }
  };
  const handleClose = () => {
    onClose?.();
  };

  const [updateIdCheckWithComplianceReview] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        userId: workflowCompletion.objectId,
        iDCheckCompleted: true,
        complianceState: 'NEEDS_REVIEW',
        complianceIssueSource: 'User opted out of ID verification',
      },
    },
    onCompleted: (data: { updateUser: { user: { iDCheckCompleted: boolean, iDVerified: boolean } } }): void => {},
  });

  const [nextStep, { loading }] = useMutation(NEXT_STEP, {
    variables: {
      input: {
        workflowCompletionId: workflowCompletion.id,
      },
    },
    onCompleted: (data) => {
      if (data.nextSubStep.workflowCompletion.currentSubStep.id === activeWorkflowCompletion.currentSubStep.id) {
        sendAnalytic(
          ovAnalyticsEvents.workflowsCompletionEnd,
          {
            workflowStepTitle: data.nextSubStep.workflowCompletion.currentSubStep.options?.title,
            workflowId: data.nextSubStep.workflowCompletion?.workflow?.id,
            workflowName: data.nextSubStep.workflowCompletion?.workflow?.name,
            activeWorkflowCompletionId: data.nextSubStep.workflowCompletion?.id,
            objectId: data.nextSubStep.workflowCompletion?.objectId,
            objectType: data.nextSubStep.workflowCompletion.objectType,
          },
        );
        handleClose();
        if (updateParent) {
          updateParent();
        }
      }
      if (updateParent) {
        updateParent({ ...data.nextSubStep.workflowCompletion });
      }
      setActiveWorkflowCompletion({
        ...data.nextSubStep.workflowCompletion,
        direction: 'FORWARD',
      });
    },
  });

  const [previousStep, { loading: prevLoading }] = useMutation(PREV_STEP, {
    variables: {
      input: {
        workflowCompletionId: workflowCompletion.id,
      },
    },
    onCompleted: (data) => {
      setActiveWorkflowCompletion({
        ...data.previousSubStep.workflowCompletion,
        direction: 'BACKWARD',
      });
    },
  });

  const [updateWorkflowCompletion] = useMutation(UPDATE_WORKFLOW_COMPLETION);

  useEffect(() => {
    setActiveWorkflowCompletion(workflowCompletion);
    checkIfItIsFirstWorkflow(workflowCompletion);
    if (workflowCompletion.context) {
      setWorkflowData(workflowCompletion.context);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowCompletion]);

  useEffect(() => {
    if (workflowData && workflowCompletion?.id) {
      updateWorkflowCompletion({
        variables: {
          input: {
            workflowCompletionId: workflowCompletion.id,
            context: workflowData,
          },
        },
      });
    }
  }, [workflowData, updateWorkflowCompletion, workflowCompletion?.id]);
  return (
    <WorkflowContext.Provider value={{
      workflowData,
      setWorkflowData,
    }}>
      <WorkflowCompletionVisual
        workflowCompletion={activeWorkflowCompletion}
        open={open}
        onClose={handleClose}
        onNext={nextStep}
        previousStep={previousStep}
        loading={loading || prevLoading}
        setActiveWorkflowCompletion={setActiveWorkflowCompletion}
        isInnerWorkflow={isInnerWorkflow}
        handleIdSkip={() => updateIdCheckWithComplianceReview()}
      />
    </WorkflowContext.Provider>
  );
};
