import { Maybe } from 'graphql/jsutils/Maybe';
import jp from 'jsonpath';
import { isNaN } from 'lodash/fp';
import { round } from 'lodash';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { RelationUser } from '../pages/accountsDetail/components/newAffiliate';
import { User, PhysicalAddress, ValidateRule } from '../interfaces';
import { availableFeatureFlagsVar } from './localVariables';

export const parseJwt = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  } catch {
    return {};
  }
};

// eslint-disable-next-line no-promise-executor-return
export const delay = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

export const formatMoneyValue = (cents: number | undefined, currency = '$', fractionDigits = 2, standard = true): string => {
  if (!cents) return `${currency}${(cents ?? 0).toFixed(fractionDigits)}`;

  if (standard) {
    if ((cents / 100) % 1 === 0) {
      return `${currency}${(cents / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }
    return `${currency}${(cents / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  return `${currency}${(Math.round(cents) / 100).toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatAmountValueCents = (cents: number, fractionDigits = 2): string => {
  const regex = /\B(?=(\d{3})+(?!\d))/g;

  if ((cents / 100) % 1 === 0) {
    return `${(cents / 100).toString().replace(regex, ',')}`;
  }

  return `${(Math.round(cents) / 100)
    .toFixed(fractionDigits)
    .toString()
    .replace(regex, ',')}`;
};

export const formatDecimalMoneyValue = (cents?: number, decimalPlaces = 4, currency = '$'): string => {
  if (!cents) return `${currency}0`;
  const value = round(cents / 100, decimalPlaces).toString().split('.');
  return `${currency}${value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${value[1] ? `.${value[1]}` : ''}`;
};

export const formatDecimalNumber = (number?: number, decimalPlaces?: number): string => {
  if (!number) return '0';
  let value = number.toString().split('.');
  let decimals = value[1];
  if (decimalPlaces) {
    value = round(number, decimalPlaces).toString().split('.');
    decimals = value[1] ? value[1].padEnd(decimalPlaces, '0') : ''.padEnd(decimalPlaces, '0');
  }
  return `${value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${decimals ? `.${decimals}` : ''}`;
};

export const generateAddressString = (address: Maybe<PhysicalAddress>): string => {
  if (!address) return '';

  const numbersSeparatpr = address.unitNumber && address.houseNumber ? '-' : '';
  return [
    [address.unitNumber, numbersSeparatpr, address.houseNumber, address.streetName].join(' ').trim(),
    address.city,
    address.jurisdiction ? address.jurisdiction.substring(3, 5) : address.province,
    address.postal,
    countryName(address.country || ''),
  ].filter(Boolean).join(', ');
};

export const generateShortAddressString = (address: PhysicalAddress): string => {
  if (!address) return '';

  return [address.city, address.jurisdiction ? address.jurisdiction.substring(3, 5) : address.province, countryName(address.country || 'CA')].filter(Boolean).join(', ');
};

function countryName(country: string): string {
  if (country === 'CA') return 'Canada';
  if (country === 'US') return 'USA';
  return country;
}

export const generateBankDetailString = (
  bankAccount: { name: string, institutionNumber: string, bankAccountNumber: string },
): string => `${bankAccount?.name} ${bankAccount?.institutionNumber}-${bankAccount?.bankAccountNumber}`;

export const generateClientNameString = (user: User | undefined | RelationUser | any, onlyFirstName?: boolean): string => {
  if (!user) return '';
  if (user.entityName) return user.entityName;
  if (!user?.firstName) return user?.id ?? '';

  return onlyFirstName ? user.firstName : [user.firstName, user.middleName, user.lastName].filter(Boolean).join(' ');
};

export const generateClientInitials = (user: User | undefined): string => {
  if (!user) return '';
  if (user.entityName) return (user.entityName ?? '').substring(0, 1).toLocaleUpperCase();

  return [
    (user.firstName ?? '').substring(0, 1).toLocaleUpperCase(),
    (user.lastName ?? '').substring(0, 1).toLocaleUpperCase(),
  ].join('');
};

export const formatPercentValue = (percent: number, fractionDigits = 2): string => (
  isNaN(percent) ? '0%' : `${(percent * 100).toFixed(fractionDigits).toString()}%`
);

export const validateForm = (rules: ValidateRule[], formObject: Record<string, unknown>): boolean => {
  let validate = false;
  for (const attr of rules) { // implement rules that you need to compare
    if (attr.rule === 'SHOULD_EXIST') {
      if (attr.pattern) {
        const destinationValues = jp.query(formObject, attr?.pattern)[0]; // use destination  value to check
        validate = !!destinationValues;
      } else {
        const attributeValue = formObject[attr.key];
        const isExist = Object.prototype.hasOwnProperty.call(formObject, attr.key);
        validate = isExist && (attributeValue !== '');
      }
      if (!validate) {
        return validate;
      }
    }
  }
  return validate;
};

export const formatPhoneNumber = (phone: string | undefined): string => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const PNF = PhoneNumberFormat;

  if (!phone) return '';
  if (phone.length < 10) return phone;

  const parsed = phoneUtil.parse(phone, 'CA');
  const isNANP = parsed.getCountryCode() === 1;

  return phoneUtil.format(parsed, isNANP ? PNF.NATIONAL : PNF.INTERNATIONAL);
};

export const entityName = (entity: any, full = false) => {
  if (entity?.firstName && entity?.lastName) {
    return `${entity?.firstName}${full && entity?.middleName ? ` ${entity.middleName}` : ''} ${entity.lastName}`;
  }
  return entity?.entityName;
};

export const entityNameWithGreeting = (greeting: string, firstName?: string) => {
  if (firstName) return `${greeting}, ${firstName}`;
  return greeting;
};

export const entityLink = (entity: Pick<User, 'id' | 'type'>): string => {
  if (entity.type === 'INDIVIDUAL') {
    return (`/clients/${entity.id}`);
  }
  return (`/nonIndividualClients/${entity.id}`);
};

export const isFeatureEnabled = (flag: string): boolean => availableFeatureFlagsVar().includes(flag);

export const validateUrl = (url: string): boolean => {
  const regex = /(mailto:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.([-a-zA-Z0-9@:%_+.~#?&//=]*)+$)|^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
  return regex.test(url);
};

export const parseLink = (url: string) => {
  try {
    return new URL(url).href;
  } catch (error) {
    return `https://${url}`;
  }
};
