import { IntegrationProvider, IntegrationType } from 'interfaces';

export interface IntegrationConfigInterface {
  [key: string]: {
    integration: {
      type: IntegrationType | string;
      provider: IntegrationProvider | string;
      configuration: string[];
    }[];
  };
}

export const integrationConfig: IntegrationConfigInterface = {
  BANKING: {
    integration: [
      {
        type: IntegrationType.BANKING,
        provider: IntegrationProvider.FLINKS,
        configuration: ['clientIframeUrl', 'customerId', 'instanceId'],
      },
    ],
  },
  ID_VERIFICATION: {
    integration: [
      {
        type: IntegrationType.ID_VERIFICATION,
        provider: IntegrationProvider.PERSONA,
        configuration: ['templateId', 'verificationPassedWebhookSecret', 'inquiryUpdateWebhookSecret'],
      },
    ],
  },
  ADDRESS: {
    integration: [
      {
        type: IntegrationType.ADDRESS,
        provider: IntegrationProvider.GOOGLE,
        configuration: ['apiKey'],
      },
      {
        type: IntegrationType.ADDRESS,
        provider: IntegrationProvider.CANADA_POST,
        configuration: ['apiKey'],
      },
    ],
  },
  MARKET_DATA: {
    integration: [
      {
        type: IntegrationType.MARKET_DATA,
        provider: IntegrationProvider.IEX_CLOUD,
        configuration: ['apiToken'],
      },
      {
        type: IntegrationType.MARKET_DATA,
        provider: IntegrationProvider.LSEG,
        configuration: ['widgetUsername', 'widgetName', 'widgetPrivateKey', 'widgetPublicKey', 'rdpUsername', 'rdpPassword', 'rdpClientId'],
      },
    ],
  },
  FINANCIAL_DATA_WIDGETS: {
    integration: [
      {
        type: IntegrationType.FINANCIAL_DATA_WIDGETS,
        provider: IntegrationProvider.LSEG,
        configuration: ['privateKey', 'publicKey', 'username', 'name'],
      },
    ],
  },
  ANALYTICS: {
    integration: [
      {
        type: IntegrationType.ANALYTICS,
        provider: IntegrationProvider.FIREBASE,
        configuration: ['apiKey', 'authDomain', 'projectId', 'messagingSenderId', 'appId', 'measurementId'],
      },
      {
        type: IntegrationType.ANALYTICS,
        provider: IntegrationProvider.MIXPANEL,
        configuration: ['TBD'],
      },
    ],
  },
};
