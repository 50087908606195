import { CSSProperties, useState } from 'react';
import { Button as MuiButton, SxProps } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import {
  colors,
} from '../../0-tokens';
import { Icon } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useButtonTokens } from './button.tokens';

interface ButtonProps {
  color?: 'primary' | 'destructive',
  variant?: 'filled' | 'tonal' | 'outlined' | 'text',
  label: string,
  size?: 'xs' | 'sm' | 'lg',
  fullWidth?: boolean,
  disabled?: boolean,
  sx?: SxProps,
  leadingIcon?: SvgIconComponent,
  trailingIcon?: SvgIconComponent,
  type?: 'button' | 'submit' | 'reset',
  onClick?: (event: any) => void,
  children?: any,
  component?:any,
  href?: string,
  target?: string,
  to?: string,
}

export const Button = ({
  color = 'primary', variant = 'filled', label, size, fullWidth,
  onClick, disabled = false, sx, leadingIcon, trailingIcon, type = 'button',
  component, children, href, target, to,
}: ButtonProps) => {
  const tokens = useThemeTokens(useButtonTokens());

  const [isHover, setIsHover] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMouseDown = (e: any) => {
    e.preventDefault();
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  let variantStyle: CSSProperties = {};

  const backgroundColor = () => {
    if (isPressed) {
      return tokens.comp.button[variant][color].pressed.container.color;
    }
    if (isFocused) {
      return tokens.comp.button[variant][color].focused.container.color;
    }
    if (isHover) {
      return tokens.comp.button[variant][color].hovered.container.color;
    }
    if (disabled) {
      return tokens.comp.button[variant][color].disabled.container.color;
    }
    return tokens.comp.button[variant][color].enabled.container.color;
  };

  switch (variant) {
    case 'filled':
      if (color === 'destructive') {
        const { destructive } = tokens.comp.button.filled;
        variantStyle = {
          backgroundColor: backgroundColor(),
          color: !disabled ? destructive.enabled.text.color : destructive.disabled.text.color,
          border: '1px solid transparent',
        };
        break;
      } else {
        const { primary } = tokens.comp.button.filled;
        variantStyle = {
          backgroundColor: backgroundColor(),
          color: !disabled ? primary.enabled.text.color : primary.disabled.text.color,
          border: '1px solid transparent',
        };
        break;
      }
    case 'tonal':
      if (color === 'destructive') {
        const { destructive } = tokens.comp.button.tonal;
        variantStyle = {
          backgroundColor: backgroundColor(),
          color: !disabled ? destructive.enabled.text.color : destructive.disabled.text.color,
          border: '1px solid transparent',
        };
        break;
      } else {
        const { primary } = tokens.comp.button.tonal;
        variantStyle = {
          backgroundColor: backgroundColor(),
          color: !disabled ? primary.enabled.text.color : primary.disabled.text.color,
          border: '1px solid transparent',
        };
        break;
      }
    case 'outlined':
      if (color === 'destructive') {
        const { destructive } = tokens.comp.button.outlined;
        variantStyle = {
          backgroundColor: backgroundColor(),
          color: !disabled ? (isHover || isFocused ? destructive.hovered.text.color : destructive.enabled.text.color) : destructive.disabled.text.color,
          border: '1px solid',
          borderColor: !disabled
            ? (isHover || isFocused ? destructive.hovered.container.borderColor : destructive.enabled.container.borderColor)
            : destructive.disabled.container.borderColor,
        };
        break;
      } else {
        const { primary } = tokens.comp.button.outlined;
        variantStyle = {
          backgroundColor: backgroundColor(),
          color: !disabled ? (isHover || isFocused ? primary.hovered.text.color : primary.enabled.text.color) : primary.disabled.text.color,
          border: '1px solid',
          borderColor: !disabled
            ? (isHover || isFocused ? primary.hovered.container.borderColor : primary.enabled.container.borderColor)
            : primary.disabled.container.borderColor,
        };
        break;
      }
    case 'text':
      if (color === 'destructive') {
        const { destructive } = tokens.comp.button.text;
        variantStyle = {
          backgroundColor: backgroundColor(),
          color: !disabled ? destructive.enabled.text.color : destructive.disabled.text.color,
          border: '1px solid transparent',
        };
        break;
      } else {
        const { primary } = tokens.comp.button.text;
        variantStyle = {
          backgroundColor: backgroundColor(),
          color: !disabled ? primary.enabled.text.color : primary.disabled.text.color,
          border: '1px solid transparent',
        };
        break;
      }
    default:
      variantStyle = {
        backgroundColor: !disabled ? (isHover ? colors.primary600 : colors.primary) : colors.neutral400,
        color: !disabled ? colors.white : colors.neutral500,
        border: '1px solid transparent',
      };
  }

  const style: CSSProperties = {
    ...variantStyle,
    height: size === 'xs' ? tokens.comp.button.xs.height : size === 'sm' ? tokens.comp.button.sm.height : tokens.comp.button.lg.height,
    fontSize: size === 'xs' ? tokens.comp.button.xs.fontSize : size === 'sm' ? tokens.comp.button.sm.fontSize : tokens.comp.button.lg.fontSize,
    lineHeight: `${tokens.sys.spacing.xxl}px`,
    width: fullWidth ? '100%' : 'auto',
    padding: size === 'xs' ? tokens.comp.button.xs.padding : size === 'sm' ? tokens.comp.button.sm.padding : tokens.comp.button.lg.padding,
    borderRadius: tokens.comp.button.borderRadius,
    cursor: !disabled ? 'pointer' : 'auto',
    textTransform: 'none',
    outlineOffset: '2px',
    outline: isFocused ? `2px solid ${colors.focus}` : 'none',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  };

  return (
    <MuiButton
      href={href}
      target={target}
      onClick={onClick}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      sx={sx}
      disableFocusRipple
      disableRipple
      startIcon={leadingIcon ? <Icon icon={leadingIcon} size='small' /> : null}
      endIcon={trailingIcon ? <Icon icon={trailingIcon} size='small' /> : null}
      type={type}
      component={component}
      to={to}
    >
      { label }
      { children }
    </MuiButton>
  );
};
