/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { DroppableFileInputAlt } from '../../../2-component/droppableFileInputAlt/droppableFileInputAlt';
import { Box, Grid, Typography } from '../../../1-primative';
import { Form } from '../../../2-component/form/form';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import voidChequeImage from '../../../../assets/images/void-cheque.svg';
import { Button, TextField, IconButton } from '../../../2-component';
import { Divider } from '../../../2-component/divider/divider';
import { UserContext } from '../../../../providers/userContextProvider';
import { InputProp } from './addBankAccountManually';

const styles = {
  input: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    marginBottom: '32px',
  },
};

const AddBankAccountManuallyVisual = ({
  loading, open, handleClose, onCreateBank,
  voidChequeFile, setVoidChequeFile, bankAccountName, setBankAccountName,
  institutionNumber, setInstitutionNumber, transitNumber, setTransitNumber,
  bankAccount, setBankAccount, onNext,
  options,
}: {
  voidChequeFile?: File,
  options?: any,
  setVoidChequeFile: Dispatch<SetStateAction<File | undefined>>,
  bankAccountName: InputProp,
  setBankAccountName: Dispatch<SetStateAction<InputProp>>,
  institutionNumber: InputProp,
  setInstitutionNumber: Dispatch<SetStateAction<InputProp>>,
  transitNumber: InputProp,
  setTransitNumber: Dispatch<SetStateAction<InputProp>>,
  bankAccount: InputProp,
  setBankAccount: Dispatch<SetStateAction<InputProp>>,
  loading: boolean,
  open: boolean,
  handleClose: () => void,
  onNext: (index: number) => void,
  onCreateBank: (bankInfo: {
    bankAccountName: string,
    institutionNumber: string,
    transitNumber: string,
    bankAccountNumber: string,
  }, file?: File) => void }) => {
  const { t } = useTranslation(['components']);
  const { sys } = useThemeTokens();
  const [fileHasError, setFileHasError] = useState(false);
  const { activeOrganization } = useContext(UserContext);
  const enableFlinks: boolean = (activeOrganization.availableFeatureFlags?.includes('AUTOMATED_BANK_ACCOUNT_LINKING') || options?.automatedBankAccountLinking) ?? false;

  const validateInstitutionNumber = (value?: string) => {
    let isValid = true;
    const number = value ?? institutionNumber.value;
    if (number.length === 0 || number.length !== 3) {
      isValid = false;
      const errorMessage = number.length === 0
        ? t('components:bankAccountSelect.addBankModal.inputErrors.institutionNumberCannotBeEmpty')
        : t('components:bankAccountSelect.addBankModal.inputErrors.institutionNumberLength');
      setInstitutionNumber({ value: value ?? '', hasError: true, message: errorMessage });
    } else {
      setInstitutionNumber({ value: value!, hasError: false, message: '' });
    }
    return isValid;
  };

  const validateTransitNumber = (value?: string) => {
    let isValid = true;
    const number = value ?? transitNumber.value;
    if (number.length === 0 || number.length !== 5) {
      isValid = false;
      const errorMessage = number.length === 0
        ? t('components:bankAccountSelect.addBankModal.inputErrors.transitNumberCannotBeEmpty')
        : t('components:bankAccountSelect.addBankModal.inputErrors.transitNumberLength');
      setTransitNumber({ value: value ?? '', hasError: true, message: errorMessage });
    } else {
      setTransitNumber({ value: value!, hasError: false, message: '' });
    }
    return isValid;
  };

  const validateBankAccountNumber = (value?: string) => {
    let isValid = true;
    const number = value ?? bankAccount.value;
    if (number.length === 0 || number.length < 5 || number.length > 17) {
      isValid = false;
      const errorMessage = bankAccount.value.length === 0
        ? t('components:bankAccountSelect.addBankModal.inputErrors.bankAccountNumberCannotBeEmpty')
        : t('components:bankAccountSelect.addBankModal.inputErrors.bankAccountNumberRange');
      setBankAccount({ value: value ?? '', hasError: true, message: errorMessage });
    } else {
      setBankAccount({ value: value!, hasError: false, message: '' });
    }
    return isValid;
  };

  const areFieldsValid = (): boolean => {
    let isValid = true;

    if (!validateInstitutionNumber(institutionNumber.value)) {
      isValid = false;
    }
    if (!validateTransitNumber(transitNumber.value)) {
      isValid = false;
    }
    if (!validateBankAccountNumber(bankAccount.value)) {
      isValid = false;
    }
    if (showFileUpload && !voidChequeFile) {
      isValid = false;
      setFileHasError(true);
    }

    return isValid;
  };

  const createBankAccount = () => {
    if (!areFieldsValid()) return;
    onCreateBank(
      {
        bankAccountName: bankAccountName.value,
        institutionNumber: institutionNumber.value,
        transitNumber: transitNumber.value,
        bankAccountNumber: bankAccount.value,
      },
      voidChequeFile,
    );
  };
  const showFileUpload = options ? !!options.allowDocumentUpload : true;
  return (
    <Form
      onSubmit={() => createBankAccount()}
      // disabled={false}
      // maxWidth='sm'
    >
      <Box display='flex' justifyContent='space-between' padding={sys.spacing.xxl}>
        <Typography variant='headingLarge'>{t('components:bankAccountSelect.addBankManuallyModal.title')}</Typography>
        <IconButton size='small' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider sx={{ marginBottom: sys.spacing.xxl }} />
      <div style={{ paddingLeft: sys.spacing.xxl, paddingRight: sys.spacing.xxl, marginBottom: '32px' }}>
        <Typography variant='bodyLarge' sx={{ marginBottom: '32px', color: sys.color.onSurfaceVariant }}>
          {t('components:bankAccountSelect.addBankManuallyModal.subTitle')}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '32px' }}>
          <img src={voidChequeImage} alt='void-cheque' />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              data-testid='transitNumber-text-field'
              sx={styles.input}
              error={transitNumber.hasError}
              value={transitNumber.value}
              errorText={transitNumber.message}
              label={t('components:bankAccountSelect.addBankManuallyModal.transitNumber')}
              fullWidth
              onChange={(e: any) => {
                validateTransitNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              data-testid='institutionNumber-text-field'
              sx={styles.input}
              error={institutionNumber.hasError}
              value={institutionNumber.value}
              label={t('components:bankAccountSelect.addBankManuallyModal.institutionNumber')}
              fullWidth
              errorText={institutionNumber.message}
              onChange={(e: any) => {
                validateInstitutionNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              data-testid='bankAccount-text-field'
              sx={styles.input}
              error={bankAccount.hasError}
              value={bankAccount.value}
              label={t('components:bankAccountSelect.addBankManuallyModal.accountNumber')}
              errorText={bankAccount.message}
              fullWidth
              onChange={(e: any) => {
                validateBankAccountNumber(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <TextField
          data-testid='bankAccountName-text-field'
          value={bankAccountName.value}
          label={t('components:bankAccountSelect.addBankManuallyModal.accountNickname')}
          sx={{ marginBottom: '32px' }}
          fullWidth
          onChange={(e: any) => {
            setBankAccountName({ ...bankAccountName, value: e.target.value });
          }}
        />
        {showFileUpload && <DroppableFileInputAlt onFileChosen={(file) => setVoidChequeFile(file)} error={fileHasError} />}
      </div>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} mb={3}>
        <Button
          variant='filled'
          type='submit'
          disabled={loading}
          label={t('components:bankAccountSelect.addBankManuallyModal.addBankAccount')}
          sx={{ marginBottom: '8px', borderRadius: '50px !important', width: '285px !important' }}
        />
        { enableFlinks ? (
          <Button onClick={() => onNext(2)} variant='text' label={t('components:bankAccountSelect.addBankManuallyModal.linkFlinksButton')} />
        ) : undefined }
      </Box>
    </Form>
  );
};

export default AddBankAccountManuallyVisual;
