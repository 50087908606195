import { useState } from 'react';
import Filter from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
} from '../../2-component';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

export const FilterModal = ({
  children, filterExists, sx = {}, title,
}: {
  children: any, filterExists?: boolean, sx?: any, title?: string,
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const [open, setOpen] = useState(false);
  const { sys } = useThemeTokens();

  return (
    <>
      <Box position='relative' sx={sx}>
        <IconButton onClick={() => setOpen(true)} label={t('filter')}>
          <Filter />
        </IconButton>
        { filterExists && (
          <Box
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              backgroundColor: sys.color.negative,
            }}
          />
        )}
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='xs'>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='headingLarge'>{title ?? t('filter')}</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='flex-end'>
            <Button label={t('shared:done')} onClick={() => setOpen(false)} />
          </Box>
        </DialogFooter>
      </Dialog>
    </>
  );
};
