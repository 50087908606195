import {
  Box, Button, FormControlLabel, ListItem, MenuItem, Switch, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HouseholdMemberSelect } from 'components/inputs/householdMemberSelect';
import { find, isEmpty } from 'lodash';
import { applicableProvinces, provinces } from 'ovComponents/resources';
import { CorporationSelect } from 'components/inputs/corporationSelect';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { AccountTypes, CORPORATE_ACCOUNTS_PRE_FIX, EntityTypes } from '../../../../interfaces';
import { StepProps } from '../wizard';
import AccountTypeSelect from '../../../inputs/accountTypeSelect';

export const visibleFields = (accountType: AccountTypes, userType?: EntityTypes | undefined) => {
  if ([
    AccountTypes.RESP_FAMILY,
    AccountTypes.RESP_ADULT,
    AccountTypes.RESP_SINGLE,
    AccountTypes.RESP_FAMILY_JOINT,
    AccountTypes.RESP_SINGLE_JOINT,
  ].includes(accountType)
  ) {
    return ['ADDITIONAL_CESG', 'BASIC_CESG', 'CLB'];
  }
  if ([
    AccountTypes.LIRA,
    AccountTypes.LRSP,
    AccountTypes.RLSP,
    AccountTypes.LIF,
    AccountTypes.PRIF,
    AccountTypes.LRIF,
    AccountTypes.RLIF,
  ].includes(accountType)
  ) {
    return ['sourceOfFunds', 'jurisdiction'];
  }
  if ([
    AccountTypes.USA_IRA,
    AccountTypes.USA_RO_IRA,
    AccountTypes.USA_RT_IRA,
  ].includes(accountType)
  ) {
    return ['sourceOfFunds'];
  }

  if ([AccountTypes.CASH_JOINT,
    AccountTypes.TFSA,
    AccountTypes.FHSA,
    AccountTypes.RRSP,
    AccountTypes.PERSONAL,
    AccountTypes.RRSP_SPOUSAL,
    AccountTypes.RDSP,
  ].includes(accountType)
  ) {
    return [];
  }
  if (accountType.startsWith(CORPORATE_ACCOUNTS_PRE_FIX) && userType === EntityTypes.INDIVIDUAL) {
    return ['corporateClientGroup'];
  }
  return [];
};

export function ChooseAccountTypeStep({
  context, state, setState, continueTo,
}: StepProps) {
  const { t } = useTranslation('createAccountWizard');
  const [userType, setUserType] = useState<EntityTypes>();

  useQuery(FETCH_USER_TYPE, {
    skip: context.forObject !== 'USER',
    variables: { id: context.forId },
    onCompleted: (data) => setUserType(data.fetchUser.user.type),
  });
  const formIsComplete = (): boolean => {
    if (!state.accountType) return false;
    if (context.forObject === 'CLIENT_GROUP' && !state.householdMember) return false;
    if ([
      AccountTypes.RESP_FAMILY,
      AccountTypes.RESP_ADULT,
      AccountTypes.RESP_SINGLE,
      AccountTypes.RESP_FAMILY_JOINT,
      AccountTypes.RESP_SINGLE_JOINT,
    ].includes(state.accountType as AccountTypes)
    ) {
      return isEmpty(state.applyForGovFunds);
    }
    if ([
      AccountTypes.LIRA,
      AccountTypes.LRSP,
      AccountTypes.RLSP,
      AccountTypes.LIF,
      AccountTypes.PRIF,
      AccountTypes.LRIF,
      AccountTypes.RLIF,
    ].includes(state.accountType as AccountTypes)
    ) {
      return !!state.sourceOfFunds && !!state.jurisdiction;
    }
    if ([
      AccountTypes.USA_IRA,
      AccountTypes.USA_RO_IRA,
      AccountTypes.USA_RT_IRA,
    ].includes(state.accountType as AccountTypes)
    ) {
      return !!state.sourceOfFunds;
    }
    if ([AccountTypes.CASH_JOINT,
      AccountTypes.TFSA,
      AccountTypes.FHSA,
      AccountTypes.RRSP,
      AccountTypes.GRSP,
      AccountTypes.PERSONAL,
      AccountTypes.RRSP_SPOUSAL,
      AccountTypes.RDSP,
      AccountTypes.RRIF,
      AccountTypes.RIF_SPOUSAL,
      AccountTypes.USA_BROKERAGE,
      AccountTypes.USA_ESA,
    ].includes(state.accountType as AccountTypes)
    ) {
      return true;
    }
    if (state.accountType.startsWith(CORPORATE_ACCOUNTS_PRE_FIX)) {
      if (userType !== EntityTypes.INDIVIDUAL) return true;
      if (context.forObject === 'CLIENT_GROUP') return true;
      return !!state.corporationClientGroupId;
    }
    return false;
  };

  const filterApplicableProvinces = (province: { key: string, value: string }) => {
    const currentAccountType = state.accountType;
    const availabileRestriction: any = find(applicableProvinces, { accountType: currentAccountType });
    if (availabileRestriction) {
      return availabileRestriction.availability.includes(province.key);
    }
    return true;
  };
  const federalAvailableAccountTypes = [
    AccountTypes.LRSP,
    AccountTypes.RLSP,
    AccountTypes.RLIF,
    AccountTypes.LIF,
    AccountTypes.RRIF,
    AccountTypes.RIF_SPOUSAL,
  ];

  const sourceOfFunds = () => {
    const currentAccountType = state.accountType;
    if (currentAccountType.startsWith('USA_')) {
      return ['USA_EMPLOYMENT_WAGES',
        'USA_INHERITANCE_TRUST',
        'USA_LOTTERY_GAMING',
        'USA_RETIREMENT_FUNDS',
        'USA_INVESTMENTS',
        'USA_SPOUSAL_PARENTAL_SUPPORT',
        'USA_GIFT',
        'USA_SAVINGS',
        'USA_UNEMPLOYMENT_DISABILITY',
        'USA_LEGAL_SETTLEMENT',
        'USA_OTHER'];
    }
    return ['SPOUSE', 'SELF'];
  };

  const clientGroup = context.forObject === 'CLIENT_GROUP';
  return (
    <>
      <ListItem>
        <Typography variant='wizardTitle'>{t('selectAccount')}</Typography>
      </ListItem>
      <Box>
        <ListItem key='accountType'>
          <AccountTypeSelect
            userType={clientGroup ? 'CLIENT_GROUP' : userType || ''}
            size='medium'
            omitAny
            value={state.accountType}
            onChange={(value) => setState({ ...state, accountType: value })}
          />
        </ListItem>
      </Box>
      <ListItem>
        { context.forObject === 'CLIENT_GROUP' && (
          <HouseholdMemberSelect
            label={t('accountHolder')}
            clientGroupId={context.forId}
            value={state.householdMember ?? ''}
            onChange={(e) => setState({ ...state, householdMember: e.target.value })}
          />
        )}
      </ListItem>

      {visibleFields(state.accountType as AccountTypes).includes('sourceOfFunds') && (<ListItem key='sourceOfFunds'>
        <TextField required select label={t('createAccountWizard:sourceOfFunds')} fullWidth value={state.sourceOfFunds} onChange={(e) => setState({ ...state, sourceOfFunds: e.target.value })}>
          {sourceOfFunds().map((x: string) => (
            <MenuItem key={x} value={x}>{t(`createAccountWizard:sourceOfFundsOptions.${x}`)}</MenuItem>
          ))}
        </TextField>
      </ListItem>)}

      {visibleFields(state.accountType as AccountTypes).includes('jurisdiction') && (<ListItem key='jurisdiction'>
        <TextField required select label={t('createAccountWizard:jurisdiction')} fullWidth value={state.jurisdiction} onChange={(e) => setState({ ...state, jurisdiction: e.target.value })}>
          {provinces.filter(filterApplicableProvinces).map((province: { key: string, value: string }) => (
            <MenuItem key={province.key} value={province.key}>{province.value}</MenuItem>
          ))}
          {federalAvailableAccountTypes.includes(state.accountType as AccountTypes)
            && (<MenuItem key='FEDERAL' value='FEDERAL'>{t('createAccountWizard:federal')}</MenuItem>)}
        </TextField>
      </ListItem>)}

      {visibleFields(state.accountType as AccountTypes, userType).includes('corporateClientGroup') && (
        <ListItem key='clientGroup'>
          <CorporationSelect
            setClientGroupId={(newId) => setState({ ...state, corporationClientGroupId: newId })}
          />
        </ListItem>
      )}

      {visibleFields(state.accountType as AccountTypes).includes('BASIC_CESG') && (<ListItem key='BASIC_CESG'>
        <FormControlLabel
          control={<Switch
            checked={state.isBasicCesg}
            onChange={() => {
              setState({ ...state, isBasicCesg: !state.isBasicCesg });
            }}
          />}
          label={t('createAccountWizard:govFundOptions.BASIC_CESG')}
        />
      </ListItem>)}

      {visibleFields(state.accountType as AccountTypes).includes('ADDITIONAL_CESG')
        && (state.accountType !== AccountTypes.RESP_ADULT)
        && (<ListItem key='ADDITIONAL_CESG'>
        <FormControlLabel
          control={<Switch
            checked={state.isAdditionalCesg}
            onChange={() => {
              setState({ ...state, isAdditionalCesg: !state.isAdditionalCesg });
            }}
          />}
          label={t('createAccountWizard:govFundOptions.ADDITIONAL_CESG')}
        />
      </ListItem>)}

      {visibleFields(state.accountType as AccountTypes).includes('CLB') && (<ListItem key='CLB'>
        <FormControlLabel
          control={<Switch
            checked={state.isClb}
            onChange={() => {
              setState({ ...state, isClb: !state.isClb });
            }}
          />}
          label={t('createAccountWizard:govFundOptions.CLB')}
        />
      </ListItem>)}

      <ListItem sx={{ flex: 1, alignItems: 'end', marginBottom: 1 }}>
        <Button disabled={!formIsComplete()} data-testid='create-new-account' type='submit'
          onClick={() => continueTo('CREATE_NEW_ACCOUNT')} fullWidth variant='contained'>
          {t('actions.createNewAccount')}
        </Button>
      </ListItem>
    </>
  );
}

const FETCH_USER_TYPE = gql`
  query fetchUserType($id: ObjectID!) {
    fetchUser(userId: $id) {
      user {
        type
      }
    }
  }
`;
