import { CurrencyCodes } from '@onevesthq/ov-enums';
import CAD from '../assets/images/country-flags/Canada.svg';
import USD from '../assets/images/country-flags/US.svg';
import CNY from '../assets/images/country-flags/China.svg';
import EUR from '../assets/images/country-flags/Europe.svg';
import GBP from '../assets/images/country-flags/UK.svg';
import JPY from '../assets/images/country-flags/Japan.svg';

export const currencyFlags = {
  CAD,
  USD,
  CNY,
  EUR,
  GBP,
  JPY,
};

export const currencySymbol: { [key in CurrencyCodes ]?: string } = {
  CAD: '$',
  USD: '$',
  CNY: '￥',
  EUR: '€',
  GBP: '£',
  JPY: '￥',
};

export const getCurrencyFlag = (currencyCode: CurrencyCodes) => {
  const flags = Object.keys(currencyFlags);
  if (flags.includes(currencyCode)) return currencyFlags[currencyCode as keyof typeof currencyFlags];
  return undefined;
};
