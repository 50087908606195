import { CurrencyCodes } from '@onevesthq/ov-enums';
import { formatMoneyValue } from '../../../util';
import { Box, Typography, TypographyPropsVariants } from '../../1-primative';
import { currencySymbol } from '../../../util/currency';

interface ValueSuperscriptedCentsProps {
  value?: number,
  mainVariant: TypographyPropsVariants['variant'],
  centsVariant: TypographyPropsVariants['variant'],
  currency?: CurrencyCodes,
  color?: string,
}

export const ValueSuperscriptedCents = ({
  value, mainVariant, centsVariant, currency, color,
}: ValueSuperscriptedCentsProps) => {
  const formattedValue = formatMoneyValue(value, currencySymbol[currency ?? CurrencyCodes.CAD], undefined, false);
  const [mainValue, centsValue] = formattedValue.split('.');
  return (
    <Box display='flex'>
      <Typography variant={mainVariant} sx={{ fontVariant: 'tabular-nums', color }}>
        { mainValue }
      </Typography>
      <Typography variant={centsVariant} sx={{ fontVariant: 'tabular-nums', color }}>
        .{ centsValue ?? '00'}
      </Typography>
    </Box>
  );
};
