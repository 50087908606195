import {
  Pagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash/fp';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../../1-primative';

import { useClientContext } from '../../../../../pages/client';
import { useHouseholdContext } from '../../../../../pages/household';
import AccountItem from '../../../accountItem/accountItem';
import { Account } from '../../../../../interfaces';
import { PageObjectType } from '../../../../5-page';
import { EmptyState } from '../../../../2-component/emptyState/emptyState';

export const AccountCards = ({
  accounts,
  singleColumn,
  totalCount,
  page,
  setPage,
  loading,
  objectType,
  objectId,
  displayCurrency,
  useCustodianData,
}: {
  accounts: Account[],
  totalCount: number,
  singleColumn?: boolean,
  loading?: boolean,
  useCustodianData?: boolean,
  objectType: PageObjectType,
  objectId: string,
  page: number,
  displayCurrency?: boolean,
  setPage: (page: number) => void,
}) => {
  const PAGE_SIZE = singleColumn ? 3 : 15;

  const { t } = useTranslation(['client']);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const householdLinkedAccounts = accounts.filter((a) => a.householdClientGroup);
  const householdIds = uniq(householdLinkedAccounts.map((a) => a.householdClientGroup?.id));
  const indexedHouseholdIds = Object.fromEntries(householdIds.map((id, index) => [id, index === 0 ? 'HOUSEHOLD' : index + 1]));

  const getTag = (account: Account) => {
    if (clientContext && objectType !== PageObjectType.HOUSEHOLD) {
      if (!account.householdClientGroup) return undefined;

      return {
        sequenceNumber: indexedHouseholdIds[account.householdClientGroup.id],
        text: `${account.householdClientGroup.name} ${t('shared:household')}`,
      };
    }

    if (householdContext) {
      return {
        sequenceNumber: householdContext.indexedMembers[account.user.id],
        text: account.user.firstName ?? account.user.id,
      };
    }

    return undefined;
  };

  return (
    <>
      <Grid container spacing={2}>
        {loading
          ? <Grid item xs={12} sm={singleColumn ? 12 : 6}><Skeleton width='100%' height='114px' variant='rectangular' /></Grid>
          : accounts.map((account) => (
            <Grid item xs={12} sm={singleColumn ? 12 : 6} key={account.id}>
              <AccountItem
                data-testid={`account-${account?.id}`}
                objectId={objectId}
                account={account}
                objectType={objectType}
                tag={getTag(account)}
                enableTooltip={false}
                displayCurrency={displayCurrency}
                useCustodianData={useCustodianData}
              />
            </Grid>
          ))
        }
        {!loading && accounts.length === 0 && (
          <Grid item xs={12} sm={singleColumn ? 12 : 6}>
            <EmptyState data-testid='accounts-card-empty-state'>
              <Typography variant='bodyLarge'>{t('accountsSummary.noAccounts')}</Typography>
            </EmptyState>
          </Grid>
        )}
      </Grid>
      {
        totalCount > PAGE_SIZE ? (
          <Box display='flex' flexDirection='column' alignItems='end' mt={1}>
            <Pagination
              count={Math.ceil(totalCount / PAGE_SIZE)}
              sx={{ marginBottom: '10px' }}
              page={page}
              onChange={(_e, newPage) => {
                setPage(newPage);
              }}
            />
          </Box>
        ) : undefined
      }
    </>
  );
};
