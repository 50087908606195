import { ListItem } from '@mui/material';
import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import CreateNewModal from 'components/modals/createNewModal';
import IntegrationsTypeSelect from 'components/inputs/integrationsTypeSelect';
import IntegrationsProviderSelect from 'components/inputs/integrationsProviderSelect';
import IntegrationsConfiguration from 'components/inputs/integrationsConfiguration';
import { NewIntegrationType, IntegrationProvider, IntegrationType } from 'interfaces/integrations';
import { FETCH_INTEGRATIONS } from './integrations';

const CREATE_INTEGRATION = gql`
  mutation createIntegration($input: CreateIntegrationInput!) {
    createIntegration(input: $input) {
      integration {
        id
      }
    }
  }
`;

const NewIntegration = ({ defaultOrg, integrationsList }: { defaultOrg: string; integrationsList: [any] }) => {
  const { t } = useTranslation(['devSettings']);
  const [integration, setIntegration] = useState<NewIntegrationType>({
    configuration: {},
    provider: '',
    type: '',
    organizationId: defaultOrg,
  });
  const [initialState] = useState({
    configuration: {},
    provider: '',
    type: '',
    organizationId: defaultOrg,
  });

  const [createIntegration, { loading }] = useMutation(CREATE_INTEGRATION, {
    variables: {
      input: integration,
    },
    refetchQueries: [FETCH_INTEGRATIONS],
  });

  const create = async () => {
    const response = await createIntegration();
    if (response.data) {
      setIntegration(initialState);
    }
  };

  const formCompleted = () => {
    const { provider, type, configuration } = integration;

    if (!provider) {
      return true;
    }
    if (!type) {
      return true;
    }

    if (type === IntegrationType.BANKING && provider === IntegrationProvider.FLINKS) {
      if (!configuration.clientIframeUrl || !configuration.instanceId || !configuration.customerId) {
        return true;
      }
    }

    if (type === IntegrationType.ID_VERIFICATION && provider === IntegrationProvider.PERSONA) {
      if (!configuration.templateId || !configuration.verificationPassedWebhookSecret || !configuration.inquiryUpdateWebhookSecret) {
        return true;
      }
    }

    if (type === IntegrationType.ADDRESS && provider === IntegrationProvider.GOOGLE) {
      if (!configuration.apiKey) {
        return true;
      }
    }

    if (type === IntegrationType.ADDRESS && provider === IntegrationProvider.CANADA_POST) {
      if (!configuration.apiKey) {
        return true;
      }
    }

    if (type === IntegrationType.MARKET_DATA && provider === IntegrationProvider.IEX_CLOUD) {
      if (!configuration.apiToken) {
        return true;
      }
    }

    if (type === IntegrationType.MARKET_DATA && provider === IntegrationProvider.LSEG) {
      if (!configuration.widgetUsername || !configuration.widgetName || !configuration.widgetPrivateKey || !configuration.widgetPublicKey
        || !configuration.rdpUsername || !configuration.rdpPassword || !configuration.rdpClientId) {
        return true;
      }
    }

    if (type === IntegrationType.FINANCIAL_DATA_WIDGETS && provider === IntegrationProvider.LSEG) {
      if (!configuration.privateKey || !configuration.publicKey || !configuration.username || !configuration.name) {
        return true;
      }
    }

    if (type === IntegrationType.ANALYTICS && provider === IntegrationProvider.FIREBASE) {
      if (
        !configuration.apiKey
        || !configuration.authDomain
        || !configuration.projectId
        || !configuration.appId
        || !configuration.measurementId
        || !configuration.messagingSenderId
      ) {
        return true;
      }
    }

    if (type === IntegrationType.ANALYTICS && provider === IntegrationProvider.MIXPANEL) {
      if (!configuration.TBD) {
        return true;
      }
    }

    return false;
  };

  return (
    <CreateNewModal
      disabled={formCompleted()}
      initialState={initialState}
      state={integration}
      loading={loading}
      title={t('integrationsModal.title')}
      onSubmit={create}
      sx={{ m: 1 }}
      onClose={() => setIntegration(initialState)}
    >
      <ListItem>
        <IntegrationsTypeSelect
          label={t('integrationsModal.type')}
          value={integration.type}
          setIntegrationType={(e: any) => setIntegration({
            ...integration,
            type: e.target.value,
            configuration: {},
            provider: '',
          })
          }
        />
      </ListItem>
      <ListItem>
        <IntegrationsProviderSelect
          label={t('integrationsModal.provider')}
          value={integration.provider}
          currentType={integration.type}
          integrationsList={integrationsList}
          setIntegrationProvider={(event: any) => setIntegration({ ...integration, provider: event.target.value, configuration: {} })}
        />
      </ListItem>
      <IntegrationsConfiguration integration={integration} setIntegrationConfiguration={(newConfig: any) => setIntegration({ ...integration, configuration: newConfig })} />
    </CreateNewModal>
  );
};

export default NewIntegration;
