import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AssetClasses from './components/assetClasses';

const AssetClassesPage = () => {
  useNavigate();

  return (
    <Paper sx={{ width: '100%', typography: 'body1' }}>
      <AssetClasses />
    </Paper>
  );
};

export default AssetClassesPage;
