import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TextField, Grid,
  MenuItem, Pagination, Chip, Menu,
  Link as MuiLink,
  IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { DateTime } from '../../misc/dateTime/dateTime';
import { formatMoneyValue } from '../../../util';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import TransferModal from '../../modals/transferModal';
import ScoreBlock from '../../misc/scoreBlock';
import RiskReasonsModal from '../../modals/riskReasonsModal';
import { usePageState } from '../../../util/usePageState';
import { EntityTypes } from '../../../interfaces';
import { LocalizedDatePicker } from '../../fields/localizedDatePicker';

const FETCH_ALERT_REPORTS = gql`
  query fetchAlertReports($input: FetchAlertReportsInput!) {
    fetchAlertReports(input: $input) {
      reports {
        id
        state
        rules {
          type
          translatedDescription {
            en
          }
        }
        transfer {
          id
          type
          source
          amountCents
          state
          createdAt
          stateChanges
          bankAccount {
            institutionNumber
          }
          isSuspicious
          alertReport {
            rules {
              type
              translatedDescription {
                en
                fr
              }
            }
          }
          subAccount {
            id
            goal {
              id
              name
            }
            statistics {
              marketValueCents
              moneyAvailableCents
              availableFundsToWithdrawCents
            }
            account {
              id
              type
              user {
                id
                firstName
                lastName
                entityName
                type
                fraudRisk {
                  score
                  lastUpdatedBy
                  notes
                  riskReasons {
                    translatedDisplayName {
                      en
                      fr
                    }
                    translatedDescription {
                      en
                      fr
                    }
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

const TRANSITION_REPORT = gql`
  mutation transitionReport($input: TransitionReportInput!) {
    transitionReport(input: $input) {
      report {
        id
      }
    }
  }
`;

enum ReportStates {
  INITIATED = 'INITIATED',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

const transitions = [
  {
    name: 'review',
    from: [ReportStates.INITIATED],
  },
  {
    name: 'approved',
    from: [ReportStates.INITIATED, ReportStates.REVIEW],
  },
  {
    name: 'denied',
    from: [ReportStates.INITIATED, ReportStates.REVIEW],
  },
];

const SuspiciousTransfersTable = (
  { filter }: { filter: any },
) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [currentReport, setCurrentReport] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [riskModalOpen, setRiskModalOpen] = useState(false);
  const [transitionReport] = useMutation(TRANSITION_REPORT);
  const [filterState, setFilterState] = usePageState('ANY', 'state');
  const [filterTransferType, setFilterTransferType] = usePageState('ANY', 'type');
  const [filterCreatedAfter, setFilterCreatedAfter] = usePageState<string | undefined>(undefined, 'after');
  const [filterCreatedBefore, setFilterCreatedBefore] = usePageState<string | undefined>(undefined, 'before');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const pageSize = 15;
  const INDIVIDUAL_PATH = 'clients';
  const NON_INDIVIDUAL_PATH = 'nonIndividualClients';
  const {
    loading, error, data, previousData, refetch,
  } = useQuery(FETCH_ALERT_REPORTS, {
    variables: {
      input: {
        filter: {
          ...filter,
          organizationId: activeOrganization.id,
          transferType: filterTransferType === 'ANY' ? undefined : filterTransferType,
          state: filterState === 'ANY' ? undefined : filterState,
          createdAtAfter: filterCreatedAfter === null ? undefined : filterCreatedAfter,
          createdAtBefore: filterCreatedBefore === null ? undefined : filterCreatedBefore,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const isIndividualUser = (user?: any): boolean => user && (!user.type || user.type === EntityTypes.INDIVIDUAL);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stateColor = (state: any) => (state === 'RECONCILED' || state === 'APPROVED' ? 'success' : ['FAILED', 'CANCELED', 'DENIED'].includes(state) ? 'error' : 'warning');

  const scoreColor = (score: any) => (score === 1 ? 'success' : [3, 4].includes(score) ? 'error' : 'warning');

  if (error) (<Typography>Error</Typography>);

  return (
    <Box>
      <Grid container sx={{ p: 2 }} spacing={1}>
        <Grid item xs={2}>
          <TextField
            select
            value={filterTransferType}
            label={t('activityTable.type')}
            onChange={(e: any) => setFilterTransferType(e.target.value)}
            size='small'
            fullWidth
            placeholder={t('any')}
          >
            <MenuItem value={'ANY'} key={'ANY'}>{t('ANY')}</MenuItem>
            <MenuItem value='DEPOSIT' key={'DEPOSIT'}>{t('DEPOSIT')}</MenuItem>
            <MenuItem value='WITHDRAW' key={'WITHDRAW'}>{t('WITHDRAW')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            select
            value={filterState}
            label={t('activityTable.alertState')}
            onChange={(e: any) => setFilterState(e.target.value)}
            size='small'
            fullWidth
            placeholder={t('any')}
          >
            <MenuItem value={'ANY'} key={'ANY'}>{t('ANY')}</MenuItem>
            <MenuItem value={'INITIATED'} key={'INITIATED'}>{t('alertReportStates.INITIATED')}</MenuItem>
            <MenuItem value={'REVIEW'} key={'REVIEW'}>{t('alertReportStates.REVIEW')}</MenuItem>
            <MenuItem value={'APPROVED'} key={'APPROVED'}>{t('alertReportStates.APPROVED')}</MenuItem>
            <MenuItem value={'DENIED'} key={'DENIED'}>{t('alertReportStates.DENIED')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <LocalizedDatePicker
            label={t('components:fromDate')}
            value={filterCreatedAfter || null}
            onChange={(createdAt) => setFilterCreatedAfter(dayjs(createdAt).format('YYYY-MM-DD'))}
            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
            InputProps={{ endAdornment: filterCreatedAfter && (<IconButton onClick={() => setFilterCreatedAfter(undefined)}><ClearIcon /></IconButton>) }}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizedDatePicker
            label={t('components:toDate')}
            value={filterCreatedBefore || null}
            onChange={(createdAt) => setFilterCreatedBefore(dayjs(createdAt).format('YYYY-MM-DD'))}
            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
            InputProps={{ endAdornment: filterCreatedBefore && (<IconButton onClick={() => setFilterCreatedBefore(undefined)}><ClearIcon /></IconButton>) }}
          />
        </Grid>
        <Grid item xs={1}>
          {loading && previousData ? (<CircularProgress size='30px' sx={{ marginTop: '6px', float: 'right' }} />) : <></>}
        </Grid>
      </Grid>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container sx={{ overflow: 'hidden' }}>
            <Grid item xs={12} sx={{ overflow: 'auto' }}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant='overline'>{t('activityTable.client')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('activityTable.account')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('activityTable.type')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('activityTable.source')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('activityTable.amount')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('activityTable.state')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('activityTable.createdAt')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('activityTable.alertState')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('activityTable.fraudRisk')}</Typography></TableCell>
                    {permissions.includes('transition:alert_report') && (
                      <TableCell align='right'><Typography variant='overline'>{t('activityTable.actions')}</Typography></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {((data || previousData)?.fetchAlertReports?.reports || []).map((report: any) => (
                    <TableRow
                      hover
                      onClick={() => {
                        setCurrentReport(report);
                        setModalOpen(true);
                      }}
                      key={report.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                    >
                      <TableCell component="th" scope="row">
                        <MuiLink
                          component={Link}
                          to={`/${isIndividualUser(report.transfer?.subAccount?.account?.user) ? INDIVIDUAL_PATH : NON_INDIVIDUAL_PATH}/${report.transfer?.subAccount?.account?.user?.id}`}>

                          {isIndividualUser(report.transfer?.subAccount?.account?.user)
                            ? `${report.transfer?.subAccount?.account?.user?.firstName} ${report.transfer?.subAccount?.account?.user?.lastName}`
                            : `${report.transfer?.subAccount?.account?.user?.entityName}`}
                        </MuiLink>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {report.transfer?.subAccount?.goal?.name} - {report.transfer?.subAccount?.account?.type}
                      </TableCell>
                      <TableCell>
                        <Chip size='small' label={report.transfer?.type} />
                      </TableCell>
                      <TableCell>
                        {t(`transferSources.${report.transfer?.source}`)}
                      </TableCell>
                      <TableCell>
                        {formatMoneyValue(report.transfer?.amountCents)}
                      </TableCell>
                      <TableCell>
                        <Chip
                          size='small'
                          label={report.transfer?.state}
                          color={stateColor(report.transfer?.state)}
                        />
                      </TableCell>
                      <TableCell>
                        <DateTime variant='subtitle2' date={report.transfer?.createdAt} />
                      </TableCell>
                      <TableCell>
                        <Chip
                          size='small'
                          label={report.state}
                          color={stateColor(report.state)}
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          size='small'
                          onClick={(e) => {
                            setCurrentReport(report);
                            e.stopPropagation();
                            setRiskModalOpen(true);
                          }}
                          label={report.transfer?.subAccount?.account?.user?.fraudRisk?.score || 'null'}
                          color={scoreColor(report.transfer?.subAccount?.account?.user?.fraudRisk?.score)}
                        />
                      </TableCell>
                      {permissions.includes('transition:alert_report') && (
                        <TableCell align='right'>
                          <IconButton size='small'
                            onClick={(e) => {
                              setCurrentReport(report);
                              e.stopPropagation();
                              handleClick(e);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchAlertReports?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </Grid>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {transitions.map((transition: any) => (
              transition.from.includes(currentReport.state) && (
                <MenuItem key={transition.name} onClick={async () => {
                  await transitionReport({ variables: { input: { reportId: currentReport.id, transition: transition.name } } });
                  refetch();
                }}>
                  {t(`reportTransitions.${transition.name}`)}
                </MenuItem>
              )
            ))}
          </Menu>
          {modalOpen
            && <TransferModal transfer={currentReport.transfer} handleClose={() => setModalOpen(false)} />
          }
          <RiskReasonsModal
            title={t('activityTable.fraudRisk')}
            open={riskModalOpen}
            handleClose={() => setRiskModalOpen(false)}
            description={t('activityTable.alertDescription')}
            riskReasons={currentReport.transfer?.subAccount?.account?.user?.fraudRisk?.riskReasons}
          >
            <Grid container justifyContent='space-evenly'>
              <Grid item sx={{ textAlign: 'center' }}>
                <ScoreBlock
                  title={t('fraudRiskScore')}
                  value={(currentReport.transfer?.subAccount?.account?.user?.fraudRisk?.score)}
                  color={scoreColor(currentReport.transfer?.subAccount?.account?.user?.fraudRisk?.score)}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='space-evenly'>
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography variant='overline'>{t('lastUpdatedBy')}</Typography>
                <Typography variant='overline'>{t(`: ${currentReport.transfer?.subAccount?.account?.user?.fraudRisk?.lastUpdatedBy}`)}</Typography>
              </Grid>
            </Grid>
          </RiskReasonsModal>
        </>
      )}
    </Box>
  );
};

export default SuspiciousTransfersTable;
