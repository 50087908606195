import {
  Box, Button, Dialog, DialogTitle, Grid, IconButton, List, ListItem, Menu, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationModal from './confirmationModal';
import { colors } from '../../theme/colors';

export const styles = {
  confirmation: {
    dialogContainer: {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '416px', // Set Optional Note confirmation Dialog Width
        },
      },
    },
    dialogContentStyles: {
      width: '368px',
      title: {
        fontWeight: 700,
      },
    },
    cancelButton: {
      minWidth: '180px',
      fontWeight: 700,
      color: colors.gray800,
      background: colors.gray300,
    },
    dialogButton: {
      minWidth: '180px',
      fontWeight: 700,
    },
    dalogClose: {
      color: colors.noteAuthor,
    },
  },
  titleText: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '34px',
  },
  subText: {
    color: colors.gray500,
    fontSize: '14px',
  },
};

const FormModal = ({
  onSubmit,
  loading,
  children,
  title,
  open,
  handleClose,
  formButton,
  maxWidth = 'sm',
  disabled = false,
  hideSubmitButton = false,
  menuItems,
  subTitle,
  withCloseBtn = false,
  state,
  initialState,
}: {
  onSubmit: (event: any) => void;
  loading: boolean;
  children: any | any[];
  title: string;
  open: boolean;
  handleClose: () => void;
  formButton: string;
  maxWidth?: any;
  disabled?: boolean;
  hideSubmitButton?: boolean;
  menuItems?: any[];
  subTitle?: string;
  withCloseBtn?: boolean;
  state?: any;
  initialState?: any;
}) => {
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState(false);
  const { t } = useTranslation('shared');
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
  const contextMenuOpen = Boolean(contextMenuAnchorEl);
  const openContextMenu = (onElement: HTMLElement) => {
    setContextMenuAnchorEl(onElement);
  };
  const closeContextMenu = () => setContextMenuAnchorEl(null);
  const deepEquality = (a: Record<string, any>, b: Record<string, any>) => JSON.stringify(a) === JSON.stringify(b);
  return (
    <>
      <Dialog onClose={() => {
        if (!state || !initialState) {
          handleClose();
        } else if (state && initialState && deepEquality(state, initialState)) {
          handleClose();
        } else {
          setExitConfirmationOpen(true);
        }
      }} open={open} maxWidth={maxWidth} fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {subTitle && (
            <Grid container>
              <Grid item md={12} justifyContent='space-between' display='flex'>
                {title}
                <div style={{ display: 'flex' }}>
                  {menuItems && (
                    <IconButton
                      size='small'
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        openContextMenu(event.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}
                  {(!menuItems || withCloseBtn) && <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', padding: '5px' }} />}
                </div>
              </Grid>
              <Grid item md={12}>
                <Typography variant='subtitle1'>{subTitle}</Typography>
              </Grid>
            </Grid>
          )}
          {!subTitle && (
            <>
              {title}
              <div style={{ display: 'flex' }}>
                {menuItems && (
                  <IconButton
                    size='small'
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      openContextMenu(event.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                {(!menuItems || withCloseBtn) && <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', padding: '5px' }} />}
              </div>
            </>
          )}
        </DialogTitle>
        <Box component='form' noValidate autoComplete='off' sx={{ minWidth: '400px' }} onSubmit={onSubmit}>
          <List>
            {children}
            <ListItem>
              {!hideSubmitButton ? (
                <Button data-testid='confirmButton' type='submit' disabled={loading || disabled} fullWidth variant='contained'>
                  {formButton}
                </Button>
              ) : null}
            </ListItem>
          </List>
        </Box>
        {menuItems && (
          <Menu
            anchorEl={contextMenuAnchorEl}
            open={contextMenuOpen}
            onClose={closeContextMenu}
            onClick={closeContextMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{ cursor: 'pointer' }}
          >
            {menuItems}
          </Menu>
        )}
      </Dialog>
      <ConfirmationModal
        open={exitConfirmationOpen}
        title={t('shared:wizardTitle', { wizardTitle: title })}
        bodyText={t('shared:wizardInturruptionMsg')}
        onConfirm={() => {
          setExitConfirmationOpen(false);
          handleClose();
        }}
        onCancel={() => setExitConfirmationOpen(false)}
        confirmButton={t('shared:yesExit')}
        cancelButton={t('shared:cancel')}
        dialogStyles={styles.confirmation}
        confirmColor='error'
      />
    </>
  );
};

export default FormModal;
