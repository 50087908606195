import { useContext, useEffect, useState } from 'react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { applicableProvinces, provinces } from 'ovComponents/resources';
import { find, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { EditAccountVisual } from './editAccount.visual';
import { WorkflowContext } from '../../workflowCompletion';
import { UPDATE_AFFILIATIONS } from './components/affiliateModal';
import { CustomField, FETCH_CUSTOM_FIELDS, modifyUpdatingCustomFields } from '../../../../../interfaces/customField';
import { SubStepTypes } from '../../../../../interfaces';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { getEnabledCustomFields } from '../utils';

const ACCOUNT = `
  id
  type
  applyForGovFunds
  jurisdiction
  sourceOfFunds
  subAccounts {
    id
  }
  user {
    physicalAddress {
      country
      city
      province
      streetName
      postal
      unitNumber
      houseNumber
      neighborhood
    }
    maritalStatus
  }
  scheduledIncomeFundTransfer {
    id
    scheduledDate
    minimumAnnualAmountCents
    annualAmountCents
    state
    subAccount {
      id
      theme {
        id
        translatedName {
          en
          fr
        }
      }
    }
    bankAccount {
      id
      name
      bankAccountNumber
    }
    frequency
    amountPayableType
    dateOfBirth
  }
  householdClientGroup {
    id
    name
  }
  affiliations {
    id
    allocation
    relation
    type
    user {
      id
      firstName
      middleName
      lastName
      primaryEmail
      sinExists
      dateOfBirth
      physicalAddress {
        country
        city
        province
        streetName
        postal
        unitNumber
        houseNumber
        neighborhood
      }
    }
  }
  custodianAffiliates{
    allocation
    relation
    type
    user {
      dateOfBirth
      lastName
      firstName
    }
  }
`;

const FETCH_USER_ACCOUNT = (derivedCustomFields?: string[]) => gql`
  query fetchUserAccount($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        accounts {
          ${ACCOUNT}
          ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
            key
            value
            customField { id translatedName { en fr } translatedDescription{ en fr} type format}
            selectedOptions { value displayText { en fr } }
          }` : ''}
        }
      }
    }
  }
`;

const FETCH_ACCOUNT = (derivedCustomFields?: string[]) => gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        ${ACCOUNT}
        ${derivedCustomFields ? `customFields(keys: ${JSON.stringify(derivedCustomFields)}) {
          key
          value
          customField { id translatedName { en fr } translatedDescription{ en fr} type format}
          selectedOptions { value displayText { en fr } }
        }` : ''}
      }
    }
  }
`;

const UPDATE_ACCOUNT = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

export const EditAccount = ({
  options, userId, onNext, previousStep, stepLoading, grid = false, updateMode = false, accountId, direction,
  workflowCompletion,
}: {
  options: any, userId?: string, onNext: () => void, previousStep: () => void, stepLoading: boolean, grid?: boolean, updateMode?: boolean, accountId?: string,
  direction: 'FORWARD' | 'BACKWARD', workflowCompletion?: any,
}) => {
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const { activeOrganization } = useContext(UserContext);
  const [derivedCustomFields, setDerivedCustomFields] = useState<CustomField[]>();
  const [derivedCustomFieldsKeys, setDerivedCustomFieldsKeys] = useState<string[]>();
  const { permissions } = usePermissions();
  const { t } = useTranslation(['client', 'accountsDetail']);
  const enabledCustomFields = getEnabledCustomFields(options);
  const [accountData, setAccountData] = useState<any>({
    accountId: null,
    type: '',
    customFields: [],
  });

  const { loading: customFieldLoading } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          workflowStep: SubStepTypes.EDIT_ACCOUNT,
          organizationId: activeOrganization?.id ?? undefined,
          keys: !isEmpty(enabledCustomFields) ? enabledCustomFields : undefined,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    skip: !permissions.includes('read:custom_fields') || enabledCustomFields.length === 0,
    fetchPolicy: 'no-cache',
    onCompleted: async (res: any) => {
      const customFieldKeys = res?.fetchCustomFields?.customFields?.map((a: any) => (a.key));
      setDerivedCustomFields(res?.fetchCustomFields?.customFields);

      if (accountId || workflowData?.currentAccountId) {
        await fetchAccount({
          variables: {
            accountId: accountId || workflowData?.currentAccountId,
          },
          query: customFieldKeys ? FETCH_ACCOUNT(customFieldKeys) : undefined,
        });
      } else {
        await fetchUserAccount({
          variables: {
            userId,
          },
          query: customFieldKeys ? FETCH_USER_ACCOUNT(customFieldKeys) : undefined,
        });
      }
    },
  });

  const [fetchAccount, { data, loading }] = useLazyQuery(FETCH_ACCOUNT(), {
    variables: { accountId: accountId || workflowData?.currentAccountId },
    fetchPolicy: 'no-cache',
  });

  const setAccountDataFunc = (response: any): void => {
    setAccountData({
      accountId: response.fetchAccount.account.id,
      type: response.fetchAccount.account.type,
      applyForGovFunds: response.fetchAccount.account.applyForGovFunds,
      user: response.fetchAccount.account.user,
      affiliations: response.fetchAccount.account.affiliations,
      custodianAffiliates: response.fetchAccount.account.custodianAffiliates || [],
      subAccounts: response.fetchAccount.account.subAccounts,
      scheduledIncomeFundTransfer: response.fetchAccount.account.scheduledIncomeFundTransfer,
      sourceOfFunds: response.fetchAccount.account.sourceOfFunds ?? undefined,
      jurisdiction: response.fetchAccount.account.jurisdiction ?? undefined,
      householdClientGroupId: response.fetchAccount.account.householdClientGroup?.id ?? undefined,
      customFields: response.fetchAccount.account?.customFields || [],
    });
  };

  const { data: defaultdata, refetch } = useQuery(FETCH_ACCOUNT(), {
    variables: { accountId: accountId || workflowData?.currentAccountId },
    fetchPolicy: 'network-only',
    skip: (!accountId && !workflowData?.currentAccountId) || (permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0),
    onCompleted: (response) => setAccountDataFunc(response),
  });

  const [fetchUserAccount, { data: fetchedUserAccountData }] = useLazyQuery(FETCH_USER_ACCOUNT(), {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const { data: userData, refetch: userRefetch, loading: userLoading } = useQuery(FETCH_USER_ACCOUNT(), {
    variables: { userId },
    fetchPolicy: 'network-only',
    skip: workflowData?.currentAccountId || accountId || (permissions.includes('read:custom_fields') && enabledCustomFields.length !== 0),
    onCompleted: (response) => setAccountDataFunc(response),
  });

  useEffect(() => {
    if (derivedCustomFields) {
      const fields = derivedCustomFields.map((a) => a.key);
      setDerivedCustomFieldsKeys(fields);
    }
  }, [derivedCustomFields]);

  const [updateAccount, { loading: updateAccountLoading }] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: () => {
      onNext();
    },
    variables: {
      input: {
        accountId: accountData.accountId,
        applyForGovFunds: accountData.applyForGovFunds,
        jurisdiction: accountData.jurisdiction ?? undefined,
        sourceOfFunds: accountData.sourceOfFunds ?? undefined,
        householdClientGroupId: accountData.householdClientGroupId ?? undefined,
        customFields: modifyUpdatingCustomFields(accountData?.customFields, derivedCustomFields || [], derivedCustomFieldsKeys),
      },
    },
  });

  const [updateAffiliations] = useMutation(UPDATE_AFFILIATIONS, {
    variables: {
      input: {
        accountId: accountData.accountId,
        affiliations: accountData.affiliations?.map((item: any) => ({
          allocation: item.allocation,
          relation: item.relation,
          type: item.type,
          userId: item.user.id,
        })) || [],
      },
    },
    onCompleted: () => {
      setWorkflowData({
        ...workflowData,
        currentAccountId: accountData.accountId,
        currentAccountType: accountData.type,
      });
      updateAccount();
    },
  });
  useEffect(() => {
    if (data || defaultdata) {
      setAccountDataFunc(data ?? defaultdata);
    }
  }, [data, defaultdata]);

  useEffect(() => {
    if (userData || fetchedUserAccountData) {
      const firstAccount = (userData ?? fetchedUserAccountData).fetchUser.user.accounts[0];
      if (!firstAccount) {
        return;
      }
      setAccountData({
        accountId: firstAccount.id,
        type: firstAccount.type || undefined,
        applyForGovFunds: firstAccount.applyForGovFunds || [],
        user: firstAccount.user,
        affiliations: firstAccount.affiliations || [],
        custodianAffiliates: firstAccount.custodianAffiliates || [],
        subAccounts: firstAccount.subAccounts,
        scheduledIncomeFundTransfer: firstAccount.scheduledIncomeFundTransfer,
        sourceOfFunds: firstAccount.sourceOfFunds ?? undefined,
        jurisdiction: firstAccount.jurisdiction ?? undefined,
        householdClientGroupId: firstAccount.householdClientGroupId ?? undefined,
        customFields: firstAccount?.customFields || [],
      });
    }
  }, [userData, onNext, fetchedUserAccountData]);

  const filterApplicableProvinces = (province: { key: string, value: string }) => {
    const currentAccountType = accountData?.type;
    const availabileRestriction: any = find(applicableProvinces, { accountType: currentAccountType });
    if (availabileRestriction) {
      return availabileRestriction.availability.includes(province.key);
    }
    return true;
  };

  function getJurisdictionOptions(): Array<{ id?: string; label?: string }> {
    return (
      provinces.filter(filterApplicableProvinces).map((a: any) => ({ id: a.key, label: a.value }))
    );
  }

  function getSourceOfFundsOptions(): Array<{ id?: string; label?: string, key?: string }> {
    const currentAccountType = accountData?.type;
    if (currentAccountType.startsWith('USA_')) {
      return [{
        id: 'USA_EMPLOYMENT_WAGES',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_EMPLOYMENT_WAGES'),
      },
      {
        id: 'USA_INHERITANCE_TRUST',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_INHERITANCE_TRUST'),
      },
      {
        id: 'USA_LOTTERY_GAMING',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_LOTTERY_GAMING'),
      },
      {
        id: 'USA_RETIREMENT_FUNDS',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_RETIREMENT_FUNDS'),
      },
      {
        id: 'USA_INVESTMENTS',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_INVESTMENTS'),
      },
      {
        id: 'USA_SPOUSAL_PARENTAL_SUPPORT',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_SPOUSAL_PARENTAL_SUPPORT'),
      },
      {
        id: 'USA_GIFT',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_GIFT'),
      },
      {
        id: 'USA_SAVINGS',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_SAVINGS'),
      },
      {
        id: 'USA_UNEMPLOYMENT_DISABILITY',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_UNEMPLOYMENT_DISABILITY'),
      },
      {
        id: 'USA_LEGAL_SETTLEMENT',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_LEGAL_SETTLEMENT'),
      },
      {
        id: 'USA_OTHER',
        label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.USA_OTHER'),
      },
      ];
    }
    return [{
      id: 'SELF',
      label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.SELF'),
    },
    {
      id: 'SPOUSE',
      label: t('workflowCompletions:updateAccount.sourceOfFundsOptions.SPOUSE'),
    }];
  }

  const getCurrentJurisdictionOption = (key: string) => {
    if (key === '') return undefined;
    const current = provinces.filter((k: any) => (k.key === key));
    if (current && current[0]) {
      return current[0].value;
    }
    return undefined;
  };

  return (
    <EditAccountVisual
      userId={userId}
      options={options}
      accountData={accountData}
      updateAccount={setAccountData}
      continueFunc={updateAffiliations}
      loadingData={customFieldLoading || loading || userLoading}
      loading={customFieldLoading || loading || userLoading || updateAccountLoading || stepLoading}
      refetch={workflowData?.currentAccountId || accountId ? refetch : userRefetch}
      grid={grid}
      updateMode={updateMode}
      skip={direction === 'FORWARD' ? onNext : previousStep}
      filterApplicableProvinces={filterApplicableProvinces}
      getJurisdictionOptions={getJurisdictionOptions}
      getSourceOfFundsOptions={getSourceOfFundsOptions}
      getCurrentJurisdictionOption={getCurrentJurisdictionOption}
      activeCustomFields={derivedCustomFieldsKeys}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default EditAccount;
