import {
  ApolloClient, ApolloProvider,
  createHttpLink, from, InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import React, {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalToast } from './globalToastProvider';

type AuthContextType = {
  authToken: string,
  setAuthToken: (token: string) => void,
};
const AuthContext = createContext<AuthContextType>({ authToken: '', setAuthToken: () => {} });

const OvApolloProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { showToast } = useGlobalToast();
  const [authToken, setAuthToken] = useState('');
  const { t } = useTranslation(['shared']);
  const client = useMemo(() => new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      onError(({ graphQLErrors, networkError, operation }) => {
        if (networkError) {
          showToast({ message: t('shared:validationError.sessionTimeout'), severity: 'error' });
        }
        if (graphQLErrors) {
          graphQLErrors.forEach(({
            message, locations, path, extensions,
          }) => {
            // eslint-disable-next-line max-len, no-console
            console.log(`[GraphQL error]: Code: ${extensions?.code}, Message: ${message}, Operation: ${JSON.stringify(operation.query)}, Variables: ${JSON.stringify(operation.variables)}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
          });
          if (!operation.variables.skipErrorHandler && graphQLErrors.length > 0 && (graphQLErrors[0].message || operation.variables.errorMessage)) {
            const readableData: any = graphQLErrors[0].extensions?.readableData;
            let uniqueFields: any[] = [];
            if (readableData?.fields) {
              uniqueFields = readableData.fields.filter((f: any) => Object.prototype.hasOwnProperty.call(f, 'kind') && f.kind === 'unique');
            }
            if (graphQLErrors[0].extensions?.code === 'VALIDATION' && readableData?.code === 11000) {
              showToast({ message: `${t('shared:validationError.unique')} ${Object.keys(readableData?.keyValue)[0]}.`, severity: 'error' });
            } else if (graphQLErrors[0].extensions?.code === 'VALIDATION' && readableData?.fields && uniqueFields.length > 0) {
              showToast({ message: `${t('shared:validationError.unique')} ${uniqueFields.map((a) => a.path).join(', ').replace(/, ([^,]*)$/, ' or $1')}.`, severity: 'error' });
            } else {
              showToast({ message: operation.variables.errorMessage ?? graphQLErrors[0].message, severity: 'error' });
            }
          }
        }
      }),
      createHttpLink({
        uri: process.env.REACT_APP_GRAPHQL_CLIENT,
        ...(authToken ? {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        } : {}),
      }),
    ]),
  }), [authToken, showToast, t]);

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken }}>
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default OvApolloProvider;
