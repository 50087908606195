import { useContext, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { translateBackend } from 'assets/i18n/config';
import { Box, Typography } from 'ovComponents/1-primative';
import {
  Badge,
  Card, IconButton, MenuItem, Segment, SegmentValue, SegmentedControl, SelectField, TranslatableTextField,
} from 'ovComponents/2-component';
import { useThemeTokens } from 'providers/themeTokenProvider';
import {
  DragDropContext, Draggable, Droppable, DroppableProvided,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { CustomField, FETCH_CUSTOM_FIELDS } from '../../../interfaces/customField';
import { UserContext } from '../../../providers/userContextProvider';

enum TableView {
  BROWSER_VIEW = 'BROWSER_VIEW',
  MOBILE_VIEW = 'MOBILE_VIEW',
}

export const TableEdit = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number,
}) => {
  const { t } = useTranslation('shared');
  const { sys } = useThemeTokens();
  const { activeOrganization } = useContext(UserContext);
  const [tableView, setTableView] = useState<TableView>(TableView.BROWSER_VIEW);

  const [fetchCustomFields] = useLazyQuery(FETCH_CUSTOM_FIELDS);

  useEffect(() => {
    if (option?.showCustomField) {
      fetchCustomFields({
        variables: {
          input: {
            filter: {
              organizationId: activeOrganization?.id ?? undefined,
              ...option?.customFieldFilter ? option.customFieldFilter : {},
            },
          },
        },
        fetchPolicy: 'no-cache',
        onCompleted: async (res: any) => {
          const customOptions: any[] = [];
          if (res) {
            res?.fetchCustomFields?.customFields.forEach((customField: CustomField) => {
              const optionObj = {
                label: customField.translatedName,
                value: customField.key,
                customField,
              };
              customOptions.push(optionObj);
            });
          }

          const newOptions = [...options];
          newOptions[i].options = [...newOptions[i].options, ...customOptions];
          if (newOptions[i]?.mobileTableView) newOptions[i].mobileTableView.options = [...newOptions[i].mobileTableView.options, ...customOptions];
          if (JSON.stringify(options) !== JSON.stringify(newOptions)) {
            setOptions(newOptions);
          }
        },
      });
    }
  }, [option, options, activeOrganization?.id, i, setOptions, fetchCustomFields]);

  const onDragEnd = (result: any) => {
    const newOptions = [...options];
    const { destination, source } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    if (tableView === TableView.BROWSER_VIEW) {
      const newValue = [...newOptions[i].value];
      const [removed] = newValue.splice(source.index, 1);
      newValue.splice(destination.index, 0, removed);
      newOptions[i].value = newValue;
    } else {
      const newValue = [...newOptions[i].mobileTableView.value];
      const [removed] = newValue.splice(source.index, 1);
      newValue.splice(destination.index, 0, removed);
      newOptions[i].mobileTableView.value = newValue;
    }
    setOptions(newOptions);
  };

  const segments: Segment[] = [
    { value: TableView.BROWSER_VIEW, label: t('browserTable') },
    { value: TableView.MOBILE_VIEW, label: t('mobileTable') },
  ];

  return (
    <>
      <Typography variant='bodyMedium' weight='bold' sx={{ mb: 1 }}>
        {translateBackend(option.label)}
      </Typography>
      <Typography variant='bodyMedium' sx={{ mb: 1.5, mt: 0.5, fontWeight: 400 }}>
        {t('tableDefinition')}
      </Typography>
      {option.mobileTableView && <SegmentedControl
        sx={{
          mb: 1.5,
          '.MuiToggleButtonGroup-grouped': {
            minWidth: 0,
          },
        }}
        value={tableView}
        segments={segments}
        fullWidth
        exclusive
        enforceActive
        onChange={(value: SegmentValue) => setTableView(value as TableView)}
      />}
      <Box sx={{ mb: 1.5 }}>
        <Badge label={t('fixedColumn')} color='neutral' variant='text' />
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`${JSON.stringify(option.label)}${tableView === TableView.BROWSER_VIEW ? '' : '-mobileTableView'}`}>
          {(provided: DroppableProvided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              { (tableView === TableView.BROWSER_VIEW ? option.value : option?.mobileTableView?.value)?.map((x: any, idx: number) => (
                <>
                  <Draggable key={x.type} draggableId={x.type} index={idx}>
                    {(dragProvided: any, dragSnapshot: any) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                      >
                        <Card sx={{
                          p: 1, pb: 1, mb: 1, background: sys.color.surfaceContainerVariant,
                        }}>
                          <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography variant='bodyMedium' weight='bold'>
                              {x.type}
                            </Typography>
                            <Box display='flex' justifyContent='end'>
                              <TranslatableTextField
                                value={x.label}
                                fullWidth
                                onChange={(value) => {
                                  const newOptions = [...options];
                                  const newValues = tableView === TableView.BROWSER_VIEW ? [...newOptions[i].value] : [...newOptions[i].mobileTableView.value];
                                  const newValue = { ...newValues[idx] };
                                  newValue.label = value;
                                  newValues[idx] = newValue;
                                  if (tableView === TableView.BROWSER_VIEW) {
                                    newOptions[i].value = newValues;
                                  } else {
                                    newOptions[i].mobileTableView.value = newValues;
                                  }
                                  setOptions(newOptions);
                                }}
                              />
                              <IconButton sx={{ ml: 1 }} onClick={() => {
                                const newOptions = [...options];
                                const newValue = tableView === TableView.BROWSER_VIEW ? [...newOptions[i].value] : [...newOptions[i].mobileTableView.value];
                                if (newValue.length > 1) {
                                  newValue.splice(idx, 1);
                                  if (tableView === TableView.BROWSER_VIEW) {
                                    newOptions[i].value = newValue;
                                  } else {
                                    newOptions[i].mobileTableView.value = newValue;
                                  }
                                  setOptions(newOptions);
                                }
                              }}>
                                <Close />
                              </IconButton>
                            </Box>
                          </Box>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                  {idx === 0 && (
                    <Box
                      sx={{
                        borderBottom: `1px ${sys.color.outline} solid`,
                        marginY: 1.5,
                      }}
                    />
                  )}
                </>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <SelectField label={t('addNewColumn')} value={tableView === TableView.BROWSER_VIEW ? option.value : option.mobileTableView.value} sx={{ mb: 2 }} fullWidth onChange={(e: any) => {
        const newOptions = [...options];
        const newOpt = (tableView === TableView.BROWSER_VIEW ? option.options : option.mobileTableView.options).find((x: any) => x.value === e.target.value);
        const newValue = tableView === TableView.BROWSER_VIEW ? [...newOptions[i].value] : [...newOptions[i].mobileTableView.value];
        newValue.push({ label: newOpt.label, type: newOpt.value, ...newOpt?.customField ? { customField: newOpt.customField } : {} });
        if (tableView === TableView.BROWSER_VIEW) {
          newOptions[i].value = newValue;
        } else {
          newOptions[i].mobileTableView.value = newValue;
        }
        setOptions(newOptions);
      }}>
        {(tableView === TableView.BROWSER_VIEW ? option.options : option.mobileTableView.options).map((item: any, idx: number) => (
          <MenuItem key={idx} value={item.value}>{translateBackend(item.label)}</MenuItem>
        ))}
      </SelectField>
      <hr />
    </>
  );
};
