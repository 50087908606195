/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Chip, Link as MuiLink, Button, Grid, TextField, MenuItem,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { floor } from 'lodash/fp';
import { formatMoneyValue } from '../../../util';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { useGlobalStats } from '../../../providers/globalStatsHooks';
import { EntityTypes, SubAccount, User } from '../../../interfaces';
import { UserContext } from '../../../providers/userContextProvider';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import { usePageState } from '../../../util/usePageState';
import { stickyColumn, stickyPosition } from '../../../util/styles';
import AccountTypeSelect from '../../../components/inputs/accountTypeSelect';

const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      subAccounts {
        state
        stateChanges
        account {
          type
          user {
            id
            firstName
            lastName
            dateOfBirth
            maritalStatus
            suitabilityScore
            annualIncomeCents
            annualDebtPaymentsCents
            totalDebtCents
            financialLiquidAssetsCents
            financialFixedAssetsCents
            riskQuestion1
            riskQuestion2
            investmentKnowledge
            employmentStatus
            jobTitle
            companyType
            politicallyExposedDomesticPerson
            politicallyExposedForeignPerson
            isMemberOfIiroc
            isOwnerOfPublicCompany
            isOfficerOfPublicCompany
            closeAssociateOfPEP
            headOfInternationalOrganization
            complianceState
            complianceIssueSource
            isVulnerablePerson
            isOwnerOfEntity
            createdAt
            organization {
              name
            }
            iDVerified
            type
            entityName
            isNonProfit
            isRegisteredWithCRA
            receivesDonationsFromPublic
            isOwnerOfPublicCompany
            isEntityRegulated
            isReportingIssuer
            isForThirdParty
            isAFinancialEntity
            isAffiliateOfFinancialEntity
            instructionsFromFinancialEntity
            isPublicBody
            isLargeCorporation
          }
        }
        id
        createdAt
        completedAt
        availableFinancialProducts{
          id
        }
        goal {
          retirementAge
          expectedAnnualIncomeCents
          riskQuestion1
          type
          timeHorizon
          targetAmountCents
          targetDate
          suitabilityScore
          financialProduct {
            id
            translatedName{
              en
            }
          }
        }
        suitabilityScore
        suggestedFinancialProduct {
          id
          translatedName {
            en
          }
        }
        acceptableFinancialProduct
        financialProduct {
          id
          translatedName {
            en
          }
        }
      }
      totalCount
    }
  }
`;

const TRANSITION_SUB_ACCOUNT = gql`
  mutation transitionSubAccount($input: TransitionSubAccountInput!) {
    transitionSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

const age = (dob: string | undefined) => {
  if (!dob) { return ''; }
  const today: any = new Date();
  const date: any = new Date(dob);
  return floor((today - date) / 60 / 60 / 24 / 365 / 1000);
};

const isEntityOfInterest = (user: User) => (user.politicallyExposedDomesticPerson
  || user.politicallyExposedForeignPerson
  || user.isMemberOfIiroc
  || user.isOwnerOfPublicCompany
  || user.isOfficerOfPublicCompany
  || user.closeAssociateOfPEP
  || user.headOfInternationalOrganization
  || user.isNonProfit
  || user.isRegisteredWithCRA
  || user.receivesDonationsFromPublic
  || user.isOwnerOfPublicCompany
  || user.isEntityRegulated
  || user.isReportingIssuer
  || user.isForThirdParty
  || user.isAFinancialEntity
  || user.isAffiliateOfFinancialEntity
  || user.instructionsFromFinancialEntity
  || user.isPublicBody
  || user.isLargeCorporation
  || user.isVulnerablePerson
  || user.isOwnerOfEntity
);

const ClientsTable = () => {
  const { subAccountsRefetch } = useGlobalStats();
  const { t } = useTranslation(['accountsReview']);
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const INDIVIDUAL_PATH = 'clients';
  const NON_INDIVIDUAL_PATH = 'nonIndividualClients';
  const [transitionSubAccount] = useMutation(TRANSITION_SUB_ACCOUNT);
  const [complianceState, setComplianceState] = usePageState<string | undefined>('ANY', 'complianceState');
  const [accountType, setAccountType] = usePageState<string | undefined>('ANY', 'accountType');
  const [idVerified, setIdVerified] = usePageState<string | undefined>('YES', 'idVerified');
  const [state, setState] = usePageState<string | undefined>('AWAITING_APPROVAL', 'state');
  const [organizationId, setOrganizationId] = usePageState(activeOrganization.id ?? '', 'org');
  const {
    loading, error, data, previousData, refetch,
  } = useQuery(FETCH_SUB_ACCOUNTS, {
    variables: {
      input: {
        filter: {
          complianceState: complianceState === 'ANY' ? undefined : complianceState,
          accountType: accountType === 'ANY' ? undefined : accountType,
          state: state === 'ANY' ? undefined : state,
          isComplete: true,
          iDVerified: idVerified === 'YES' ? true : idVerified === 'NO' ? false : undefined,
          organizationId: organizationId && organizationId?.length > 0 ? organizationId : activeOrganization.id,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const deriveClientName = (user: User): string => {
    if (user && user.firstName) {
      return `${user.firstName} ${user.lastName}`;
    }
    if (user && user.entityName) {
      return user.entityName;
    }
    return '';
  };

  const isIndividualUser = (user: User): boolean => !user.type || user.type === EntityTypes.INDIVIDUAL;

  const generatePortfolioLabel = (subAccount: SubAccount): string => {
    let portfolioName = subAccount.financialProduct?.translatedName?.en || '';
    if (subAccount?.goal?.financialProduct?.id) {
      portfolioName = subAccount?.goal?.financialProduct?.translatedName?.en || '';
      return `${t('inherited')}${portfolioName}`;
    }
    return portfolioName;
  };

  if (error) (<Typography>Error</Typography>);

  useEffect(() => {
    if (!organizationId && activeOrganization.id) setOrganizationId(activeOrganization.id);
  }, [activeOrganization.id]);

  return (
    <Box sx={{ overflowX: 'scroll' }}>
      <Grid container sx={[{ p: 2 }, stickyPosition]} spacing={1}>
        <Grid item xs={2}>
          <TextField
            select
            value={complianceState}
            label={t('table.complianceState')}
            onChange={(e: any) => {
              setComplianceState(e.target.value);
            }}
            size='small'
            fullWidth
            placeholder={t('any')}
          >
            <MenuItem value='ANY'>{t('table.complianceStateOptions.ANY')}</MenuItem>
            <MenuItem value='APPROVED'>{t('table.complianceStateOptions.APPROVED')}</MenuItem>
            <MenuItem value='NEEDS_REVIEW'>{t('table.complianceStateOptions.NEEDS_REVIEW')}</MenuItem>
            <MenuItem value='REJECTED'>{t('table.complianceStateOptions.REJECTED')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <AccountTypeSelect
          userType=''
            label={t('table.accountType')}
            value={accountType}
            onChange={(value) => setAccountType(value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            select
            value={state}
            label={t('table.state')}
            onChange={(e: any) => {
              setState(e.target.value);
            }}
            size='small'
            fullWidth
            placeholder={t('any')}
          >
            <MenuItem value={'ANY'} key={'ANY'}>{t('table.stateOptions.ANY')}</MenuItem>
            <MenuItem value={'ACTIVE'} key={'ACTIVE'}>{t('table.stateOptions.ACTIVE')}</MenuItem>
            <MenuItem value={'AWAITING_APPROVAL'} key={'AWAITING_APPROVAL'}>{t('table.stateOptions.AWAITING_APPROVAL')}</MenuItem>
            <MenuItem value={'INACTIVE'} key={'INACTIVE'}>{t('table.stateOptions.INACTIVE')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            select
            value={idVerified}
            label={t('table.idVerified')}
            onChange={(event) => {
              setIdVerified(event.target.value);
            }}
            size='small'
            fullWidth
            placeholder={t('any')}
          >
            <MenuItem value='ANY' key={'ANY'}>{t('table.idVerifiedOptions.ANY')}</MenuItem>
            <MenuItem value='YES' key={'YES'}>{t('table.idVerifiedOptions.YES')}</MenuItem>
            <MenuItem value='NO' key={'NO'}>{t('table.idVerifiedOptions.NO')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <OrganizationSelect
            label={t('table.organization')}
            onChange={(event) => setOrganizationId(event.target.value)}
            value={organizationId}
            childrenFor={activeOrganization.id}
            size='small'
          />
        </Grid>
        <Grid item xs={1}>
          {loading && previousData ? (<CircularProgress size='30px' sx={{ marginTop: '6px', float: 'right' }} />) : <></>}
        </Grid>
      </Grid>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell sx={stickyColumn}><Typography variant='overline'>{t('table.client')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.completedAt')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.organization')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.account')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.age')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.annualIncome')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.annualDebtPayments')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.totalDebt')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.liquidAssets')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.totalAssets')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.netWorth')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.debtAssetRatio')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.debtServiceRatio')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.employment')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.jobTitle')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.companyType')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.iDVerified')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.entityOfInterest')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.complianceState')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.userSuitablitiy')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.goalSuitability')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.suggestedPortfolio')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.actualPortfolio')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.subAccountStatus')}</Typography></TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Typography variant='overline'>{t('table.actions')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchSubAccounts?.subAccounts?.map((subAccount: SubAccount) => (
                <TableRow
                  hover
                  key={subAccount.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                >
                  <TableCell component="th" scope="row" sx={stickyColumn}>
                    <MuiLink
                      component={Link}
                      to={`/${isIndividualUser(subAccount.account.user) ? INDIVIDUAL_PATH : NON_INDIVIDUAL_PATH}/${subAccount.account.user.id}`}>
                        {deriveClientName(subAccount.account.user)}
                    </MuiLink>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <DateTime variant='subtitle2' date={subAccount.completedAt} />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {subAccount.account.user.organization.name}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {subAccount.account.type}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {age(subAccount.account.user.dateOfBirth)}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {formatMoneyValue(subAccount.account.user.annualIncomeCents)}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {formatMoneyValue(subAccount.account.user.annualDebtPaymentsCents)}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {formatMoneyValue(subAccount.account.user.totalDebtCents)}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {formatMoneyValue(subAccount.account.user.financialLiquidAssetsCents)}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {formatMoneyValue((subAccount.account.user.financialLiquidAssetsCents || 0) + (subAccount.account.user.financialFixedAssetsCents || 0))}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {formatMoneyValue(
                      (subAccount.account.user.financialLiquidAssetsCents || 0) + (subAccount.account.user.financialFixedAssetsCents || 0) - (subAccount.account.user.totalDebtCents || 0),
                    )
                    }
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {(
                      ((subAccount.account.user.totalDebtCents || 0) / ((subAccount.account.user.financialLiquidAssetsCents || 0) + (subAccount.account.user.financialFixedAssetsCents || 0)))
                      * 100
                    ).toFixed(2)}
                    %
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {subAccount.account.user.annualDebtPaymentsCents
                      && subAccount.account.user.annualIncomeCents ? `${((subAccount.account.user.annualIncomeCents / subAccount.account.user.annualDebtPaymentsCents) * 100).toFixed(2)}%` : 'N/A'}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {subAccount.account.user.employmentStatus}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {subAccount.account.user.jobTitle}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {subAccount.account.user.companyType}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {subAccount.account.user.iDVerified ? t('table.yes') : <Chip label={t('table.no')} size='small' color='error' />}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {isEntityOfInterest(subAccount.account.user) ? <Chip label={t('table.yes')} size='small' color='error' /> : t('table.no')}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Chip
                      label={subAccount.account.user.complianceState} size='small'
                      color={subAccount.account.user.complianceState === 'REJECTED' ? 'error' : subAccount.account.user.complianceState === 'NEEDS_REVIEW' ? 'warning' : 'success'}
                    />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Chip label={subAccount.account.user.suitabilityScore} size='small' />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Chip label={subAccount.goal.suitabilityScore} size='small' />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {subAccount.suggestedFinancialProduct?.translatedName?.en}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Chip label={generatePortfolioLabel(subAccount)} color={subAccount?.acceptableFinancialProduct ? 'success' : 'error'} size='small' />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {(subAccount.stateChanges ?? []).map((x) => x.to).includes('ACTIVE') ? t('table.updated') : t('table.new')}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Button
                      variant='outlined'
                      size='small'
                      disabled={subAccount.state !== 'AWAITING_APPROVAL' || subAccount.account.user.complianceState === 'NEEDS_REVIEW' || subAccount.account.user.iDVerified === false}
                      onClick={async (e) => {
                        e.stopPropagation();
                        await transitionSubAccount({
                          variables: {
                            input: { subAccountId: subAccount.id, transition: 'activate' },
                          },
                        });
                        refetch();
                        subAccountsRefetch();
                      }}>{t('approveAndSign')}</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchSubAccounts?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'start',
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default ClientsTable;

export const testableFunctions = {
  age,
  isEntityOfInterest,
};
