/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import {
  useContext,
  useEffect, useRef, useState,
} from 'react';
import { pick } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Radio, RadioGroup } from 'ovComponents';
import { UserContext } from 'providers/userContextProvider';
import CreateNewModal from '../../modals/createNewModal';
import { SubAccountSelect, SubAccountSelectData } from '../../inputs/subAccountSelect';
import { BankAccountSelect } from '../../inputs/bankAccountSelect';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { colors } from '../../../theme/colors';
import ExternalInstitutionSelect from '../../inputs/externalInstitutionSelect';
import AmountField from '../../inputs/amountField';
import { FETCH_TRANSFERS } from './oneoffTransfersTable';
import { usePermissions } from '../../../providers/userContextProvider';
import { FETCH_SCHEDULED_TRANSFERS } from './scheduledTransfersTable';
import { TransferType, WithdrawalMode } from '../../../interfaces';
import CalculateFeesForNewTransfer, { WithdrawFee } from './calculateFeesForNewTransfer';
import { LocalizedDatePicker } from '../../fields/localizedDatePicker';
import { fetchAuthorizedIndividual } from '../../../util/fetchAuthorizedIndividual';

dayjs.extend(utc);

export const CREATE_ADJUSTMENT_TRANSFER = gql`
  mutation createAdjustmentTransfer($input: CreateAdjustmentTransferInput!) {
    createAdjustmentTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_EFT_DEPOSIT_TRANSFER = gql`
  mutation createDepositTransfer($input: CreateDepositTransferInput!) {
    createDepositTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_EFT_WITHDRAW_TRANSFER = gql`
  mutation createWithdrawTransfer($input: CreateWithdrawTransferInput!) {
    createWithdrawTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_EFT_WITHDRAW_ALL_TRANSFER = gql`
  mutation withdrawAllFromSubAccount($input: WithdrawAllSubAccountInput!) {
    withdrawAllFromSubAccount(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_TRANSFER_BETWEEN_SUB_ACCOUNT = gql`
  mutation transferBetweenSubAccounts($input: TransferBetweenSubAccountsInput!) {
    transferBetweenSubAccounts(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_TRANSFER_ALL_BETWEEN_SUB_ACCOUNT = gql`
  mutation transferAllBetweenSubAccounts($input: TransferAllBetweenSubAccountsInput!) {
    transferAllBetweenSubAccounts(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_EXTERNAL_TRANSFER = gql`
  mutation createExternalTransfer($input: CreateExternalTransferInput!) {
    createExternalTransfer(input: $input) {
      manualProcess {
        id
      }
    }
  }
`;

export const CREATE_SCHEDULED_DEPOSIT_TRANSFER = gql`
  mutation createScheduledDepositTransfer($input: CreateScheduledDepositTransferInput!) {
    createScheduledDepositTransfer(input: $input) {
      scheduledTransfer {
        id
        scheduledDate
      }
    }
  }
`;

export const CREATE_SCHEDULED_WITHDRAW_TRANSFER = gql`
  mutation createScheduledWithdrawTransfer($input: CreateScheduledWithdrawTransferInput!) {
    createScheduledWithdrawTransfer(input: $input) {
      scheduledTransfer {
        id
      }
    }
  }
`;

export const CREATE_CHEQUE_TRANSFER = gql`
  mutation createChequeTransfer($input: CreateChequeTransferInput!) {
    createChequeTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_SCHEDULED_TRANSFER_BETWEEN_SUB_ACCOUNT = gql`
  mutation createScheduledTransferBetweenSubAccounts($input: CreateScheduledTransferBetweenSubAccountsInput!) {
    createScheduledTransferBetweenSubAccounts(input: $input) {
      scheduledTransfer {
        id
      }
    }
  }
`;

export interface TransferProp {
  type: string,
  source: string,
  frequency: string,
  scheduledDate?: string,
  subAccountId: string,
  subAccountMarketValueCents: number,
  subAccountUserId?: string,
  toSubAccountId: string,
  bankAccountId: string,
  amountCents?: number,
  clbAmountCents?: number,
  bctesgAmountCents?: number,
  adjustmentReason: string,
  isWithdrawAll: boolean,
  institution: {
    id: string,
  },
  transferAccountNumber: string,
  transferMethod: string,
  withdrawalMode: string,
  netAmountCents?: number,
}

const emptyFormState: TransferProp = {
  type: 'DEPOSIT',
  source: 'ADJUSTMENT',
  frequency: 'ONE_TIME',
  scheduledDate: undefined,
  subAccountId: '',
  subAccountMarketValueCents: 0,
  subAccountUserId: undefined,
  toSubAccountId: '',
  bankAccountId: '',
  amountCents: undefined,
  clbAmountCents: undefined,
  bctesgAmountCents: undefined,
  adjustmentReason: '',
  isWithdrawAll: false,
  institution: {
    id: '',
  },
  transferAccountNumber: '',
  transferMethod: 'ALL_IN_CASH',
  withdrawalMode: 'GROSS',
  netAmountCents: undefined,
};

const NewTransfer = ({
  afterCreate,
  forObject,
  forId,
  longLabel = false,
  presetSourceEFT = false,
  presetSubaccount,
  presetAccount,
  presetSubAccountMarketValueCents,
  disableButton,
}: {
  afterCreate: () => void,
  forObject: 'USER' | 'CLIENT_GROUP',
  forId: string,
  longLabel?: boolean,
  presetSourceEFT?: boolean,
  presetSubaccount?: string,
  presetAccount?: string,
  presetSubAccountMarketValueCents?: number,
  disableButton?: boolean,
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();
  const [starting, setStarting] = useState<'now' | 'futureDate'>('now');
  const [transfer, setTransfer] = useState<TransferProp>(emptyFormState);
  const [initialState] = useState(emptyFormState);
  const graphqlClient = useApolloClient();
  const [selectedPortfolioAccountType, setSelectedPortfolioAccountType] = useState('');
  const [accountId, setAccountId] = useState<string>('');
  const [fee, setFee] = useState<number>(0);
  const { activeOrganization, userContext } = useContext(UserContext);
  const enableInKind: boolean = activeOrganization.availableFeatureFlags?.includes('IN_KIND_TRANSFERS') ?? false;
  const minRecurringDeposit = (activeOrganization.minRecurringDepositCents && (activeOrganization.minRecurringDepositCents));

  const checkMinimumRecurringDeposit: boolean = ((starting === 'futureDate' || starting !== 'now' || transfer.frequency !== 'ONE_TIME')
    && transfer.amountCents
    && minRecurringDeposit
    && (transfer.amountCents < minRecurringDeposit)) || false;

  const displayWithdrawAll = (transfer.source === 'EFT' && transfer.type === 'WITHDRAW') || (transfer.source === 'TRANSFER_TO_SUB_ACCOUNT');
  const exceedWithdrawAllLimit: boolean = displayWithdrawAll
    && transfer.subAccountMarketValueCents > 0
    && !!(
      (transfer.amountCents || transfer.netAmountCents)
      && ((transfer.amountCents || transfer.netAmountCents) ?? 0) > 0
      && ((transfer.amountCents || transfer.netAmountCents) ?? 0) > (0.98 * transfer.subAccountMarketValueCents)
    );

  const isRespAndIsPartial = (): boolean => transfer.source === 'EXTERNAL_TRANSFER_IN' && ['RESP_SINGLE', 'RESP_FAMILY', 'RESP_ADULT', 'RESP_SINGLE_JOINT', 'RESP_FAMILY_JOINT']
    .includes(selectedPortfolioAccountType) && transfer.transferMethod === 'PARTIAL_CASH';

  const onFeesReceived = (fees: WithdrawFee[]) => {
    if (transfer.type === TransferType.Withdraw && transfer.withdrawalMode === WithdrawalMode.Net) {
      const withholdingTax = fees.find((item: WithdrawFee) => item.type === 'WITHHOLDING_TAX')?.amountCents ?? 0;

      setFee(withholdingTax);
    }
  };

  const getNetAmountWithFees = () => {
    if (transfer.type === TransferType.Withdraw && transfer.withdrawalMode === WithdrawalMode.Net && transfer.netAmountCents) {
      return transfer.netAmountCents + (fee ?? 0);
    }

    return null;
  };

  const [createAdjustmentTransfer, opt1] = useMutation(CREATE_ADJUSTMENT_TRANSFER, {
    variables: {
      input: pick(['adjustmentReason', 'type', 'subAccountId', 'amountCents'], transfer),
    },
    refetchQueries: [FETCH_TRANSFERS(permissions)],
  });

  const [createEtfDepositTransfer, opt2] = useMutation(CREATE_EFT_DEPOSIT_TRANSFER, {
    variables: {
      input: pick(['subAccountId', 'bankAccountId', 'amountCents'], transfer),
    },
    refetchQueries: [FETCH_TRANSFERS(permissions)],
  });

  const [createEtfWithdrawTransfer, opt3] = useMutation(CREATE_EFT_WITHDRAW_TRANSFER, {
    variables: {
      input: {
        ...pick(['subAccountId', 'bankAccountId', 'amountCents'], transfer),
        ...(getNetAmountWithFees() && { amountCents: getNetAmountWithFees() }),
        ...(transfer?.withdrawalMode && { withdrawalMode: transfer.withdrawalMode }),
      },
    },
    refetchQueries: [FETCH_TRANSFERS(permissions)],
  });

  const [createEtfWithdrawAllTransfer, opt4] = useMutation(CREATE_EFT_WITHDRAW_ALL_TRANSFER, {
    variables: {
      input: pick(['subAccountId', 'bankAccountId'], transfer),
    },
    refetchQueries: [FETCH_TRANSFERS(permissions)],
  });

  const [createTransferBetweenSubAccounts, opt5] = useMutation(CREATE_TRANSFER_BETWEEN_SUB_ACCOUNT, {
    variables: {
      input: { ...pick(['toSubAccountId', 'amountCents'], transfer), fromSubAccountId: transfer.subAccountId },
    },
    refetchQueries: [FETCH_TRANSFERS(permissions)],
  });

  const [createTransferAllBetweenSubAccounts, opt6] = useMutation(CREATE_TRANSFER_ALL_BETWEEN_SUB_ACCOUNT, {
    variables: {
      input: { ...pick(['toSubAccountId'], transfer), fromSubAccountId: transfer.subAccountId },
    },
    refetchQueries: [FETCH_TRANSFERS(permissions)],
  });

  const [createExternalTransfer, opt7] = useMutation(CREATE_EXTERNAL_TRANSFER);

  const [createEtfScheduledDepositTransfer, opt8] = useMutation(CREATE_SCHEDULED_DEPOSIT_TRANSFER, {
    variables: {
      input: pick(['amountCents', 'bankAccountId', 'frequency', 'scheduledDate', 'subAccountId'], transfer),
    },
    refetchQueries: [FETCH_SCHEDULED_TRANSFERS(permissions)],
  });

  const [createEtfScheduledWithdrawTransfer, opt9] = useMutation(CREATE_SCHEDULED_WITHDRAW_TRANSFER, {
    variables: {
      input: { ...pick(['amountCents', 'bankAccountId', 'frequency', 'scheduledDate', 'subAccountId'], transfer), ...(getNetAmountWithFees() && { amountCents: getNetAmountWithFees() }) },
    },
    refetchQueries: [FETCH_SCHEDULED_TRANSFERS(permissions)],
  });

  const [createChequeTransfer, opt10] = useMutation(CREATE_CHEQUE_TRANSFER, {
    variables: {
      input: pick(['subAccountId', 'amountCents', 'type'], transfer),
    },
    refetchQueries: [FETCH_TRANSFERS(permissions)],
  });

  const [createScheduledTransferBetweenSubAccounts, opt11] = useMutation(CREATE_SCHEDULED_TRANSFER_BETWEEN_SUB_ACCOUNT, {
    variables: {
      input: {
        ...pick(['toSubAccountId', 'amountCents', 'frequency', 'scheduledDate'], transfer),
        fromSubAccountId: transfer.subAccountId,
        transferAll: transfer.isWithdrawAll,
      },
    },
    refetchQueries: [FETCH_SCHEDULED_TRANSFERS(permissions)],
  });

  const presetTransfer = () => {
    if (presetAccount) setAccountId(presetAccount);

    setTransfer({
      ...emptyFormState,
      ...(presetSourceEFT && { source: 'EFT' }),
      ...(presetSubaccount && { subAccountId: presetSubaccount }),
      ...(presetSubAccountMarketValueCents && { subAccountMarketValueCents: presetSubAccountMarketValueCents }),
    });

    setStarting('now');
  };

  const create = async () => {
    let response;

    if (transfer.source === 'ADJUSTMENT') {
      response = await createAdjustmentTransfer();
    } else if (transfer.source === 'EFT') {
      if (transfer.type === 'DEPOSIT' && starting === 'futureDate') {
        response = await createEtfScheduledDepositTransfer();
      } else if (transfer.type === 'DEPOSIT') {
        response = await createEtfDepositTransfer();
      } else if (transfer.type === 'WITHDRAW' && starting === 'futureDate') {
        response = await createEtfScheduledWithdrawTransfer();
      } else if (!transfer.isWithdrawAll) {
        response = await createEtfWithdrawTransfer();
      } else {
        response = await createEtfWithdrawAllTransfer();
      }
    } else if (transfer.source === 'TRANSFER_TO_SUB_ACCOUNT') {
      if (starting === 'futureDate') {
        response = await createScheduledTransferBetweenSubAccounts();
      } else if (!transfer.isWithdrawAll) {
        response = await createTransferBetweenSubAccounts();
      } else {
        response = await createTransferAllBetweenSubAccounts();
      }
    } else if (transfer.source === 'EXTERNAL_TRANSFER_IN') {
      let responsibleUserId = null;
      if (forObject === 'USER' && userContext.id) {
        responsibleUserId = await fetchAuthorizedIndividual({
          userId: forId,
          apolloClient: graphqlClient,
          userContext,
        });
      }
      const transferInput = isRespAndIsPartial() ? pick(['subAccountId', 'transferMethod', 'amountCents', 'institution', 'transferAccountNumber', 'clbAmountCents', 'bctesgAmountCents'], transfer)
        : pick(['subAccountId', 'transferMethod', 'amountCents', 'institution', 'transferAccountNumber'], transfer);
      response = await createExternalTransfer({
        variables: {
          input: {
            ...transferInput,
            createdByUserId: (responsibleUserId && responsibleUserId !== forId) ? responsibleUserId : undefined,
          },
        },
        refetchQueries: [FETCH_TRANSFERS(permissions)],
      });
    } else if (transfer.source === 'CHEQUE') {
      response = await createChequeTransfer();
    }

    if (response?.data) {
      showToast({ severity: 'info', message: t('components:transferModal.createdToastMessage') });
    }

    afterCreate();
    presetTransfer();
  };

  const close = () => {
    presetTransfer();
  };

  useEffect(() => {
    presetTransfer();
  }, [presetSourceEFT, presetSubaccount, presetAccount, presetSubAccountMarketValueCents]);

  const loading = opt1.loading || opt2.loading || opt3.loading || opt4.loading || opt5.loading || opt6.loading || opt7.loading || opt8.loading || opt9.loading || opt10.loading || opt11.loading;
  const disabled = !(transfer.subAccountId && !exceedWithdrawAllLimit && ((transfer.amountCents || transfer.netAmountCents) || transfer.isWithdrawAll) && (
    (transfer.source === 'ADJUSTMENT' && transfer.adjustmentReason)
    || (transfer.source === 'EFT' && transfer.bankAccountId)
    || (transfer.source === 'EXTERNAL_TRANSFER_IN' && transfer.transferMethod && transfer.institution && transfer.transferAccountNumber)
    || (transfer.source === 'TRANSFER_TO_SUB_ACCOUNT' && transfer.toSubAccountId)
    || (transfer.source === 'CHEQUE')))
    || checkMinimumRecurringDeposit;

  const scheduledDateInputRef = useRef<HTMLInputElement>(null);

  /* pre-open date picker for frequencies = weekly, monthly, etc. */
  useEffect(() => {
    if (transfer.frequency !== 'ONE_TIME') {
      setStarting('futureDate');
      scheduledDateInputRef.current?.click();
    }
  }, [transfer.frequency]);

  /* pre-open date picker for "just this once" with future date */
  useEffect(() => {
    if (starting === 'futureDate') {
      setTransfer((previous) => ({ ...previous, scheduledDate: dayjs().add(1, 'day').format('YYYY-MM-DD') }));
      scheduledDateInputRef.current?.click();
    }
    if (starting === 'now') {
      setTransfer((previous) => ({ ...previous, scheduledDate: undefined }));
    }
  }, [starting]);

  const isInitiatedByRepresentative = () => (permissions?.includes('write:transfer_obo_clients')
    && !permissions?.includes('write:transfer_basic'));

  return (
    <CreateNewModal
      loading={loading}
      state={transfer}
      initialState={initialState}
      disableButton={disableButton}
      disabled={disabled}
      title={t('components:transferModal.title')}
      onSubmit={create}
      {...(longLabel && { modalButton: t('transfersTable.addTransfer') })}
      onClose={close}
    >
      <ListItem>
        <TextField
          select
          value={transfer.source}
          label={t('components:transferModal.source')}
          fullWidth
          onChange={(e: any) => {
            setTransfer({ ...transfer, source: e.target.value });
          }}
        >
          <MenuItem value='ADJUSTMENT'>{t('components:transferModal.sources.adjustment')}</MenuItem>
          <MenuItem value='EFT'>{t('components:transferModal.sources.eft')}</MenuItem>
          <MenuItem value='EXTERNAL_TRANSFER_IN' data-testid='EXTERNAL_TRANSFER_IN'>
            {t('components:transferModal.sources.externalTransferIn')}
          </MenuItem>
          <MenuItem value='TRANSFER_TO_SUB_ACCOUNT'>{t('components:transferModal.sources.transferToSubAccount')}</MenuItem>
          <MenuItem value='CHEQUE'>{t('components:transferModal.sources.cheque')}</MenuItem>
        </TextField>
      </ListItem>
      {transfer.source === 'EXTERNAL_TRANSFER_IN' && (
        <ListItem>
          <ExternalInstitutionSelect
            setInstitution={(e) => setTransfer({ ...transfer, institution: { id: e?.id ?? '' } })}
            value={transfer.institution.id}
            label={t('components:transferModal.externalTransfer.institution')}
          />
        </ListItem>
      )}
      {transfer.source === 'EXTERNAL_TRANSFER_IN' && (
        <ListItem>
          <TextField
            select
            value={transfer.transferMethod}
            label={t('components:transferModal.externalTransfer.method')}
            fullWidth
            onChange={(e: any) => {
              setTransfer({ ...transfer, transferMethod: e.target.value });
            }}
          >
            <MenuItem value='ALL_IN_CASH'>{t('components:transferModal.externalTransfer.allInCash')}</MenuItem>
            <MenuItem value='PARTIAL_CASH'>{t('components:transferModal.externalTransfer.partialCash')}</MenuItem>
            {enableInKind && (<MenuItem value='ALL_IN_KIND'>{t('components:transferModal.externalTransfer.allInKind')}</MenuItem>)}
          </TextField>
        </ListItem>
      )}
      {['ADJUSTMENT', 'EFT', 'CHEQUE'].includes(transfer.source) && (
        <ListItem>
          <TextField select value={transfer.type} label={t('components:transferModal.type')} fullWidth onChange={(e: any) => setTransfer({ ...transfer, type: e.target.value })}>
            <MenuItem value='DEPOSIT'>{t('components:transferModal.deposit')}</MenuItem>
            <MenuItem value='WITHDRAW'>{t('components:transferModal.withdraw')}</MenuItem>
          </TextField>
        </ListItem>
      )}
      {transfer.type && transfer.type === TransferType.Withdraw && transfer.source === 'EFT' && (
        <ListItem>
          <TextField
            select
            value={transfer.withdrawalMode}
            label={t('components:transferModal.withdrawalMode')}
            fullWidth
            onChange={(e: any) => {
              setTransfer({ ...transfer, withdrawalMode: e.target.value, amountCents: 0 });
            }}
          >
            <MenuItem value={WithdrawalMode.Gross}>{t('components:transferModal.gross')}</MenuItem>
            <MenuItem value={WithdrawalMode.Net}>{t('components:transferModal.net')}</MenuItem>
          </TextField>
        </ListItem>
      )}
      <ListItem>
        <SubAccountSelect
          setSubAccount={(e) => setTransfer((prev) => ({
            ...prev,
            subAccountId: e.target.value,
            toSubAccountId: prev.toSubAccountId === e.target.value ? '' : prev.toSubAccountId,
          }))
          }
          setSubAccountData={(data: SubAccountSelectData) => {
            setTransfer((prev) => ({
              ...prev,
              subAccountMarketValueCents: data.statistics.marketValueCents,
              subAccountUserId: data.account.user?.id,
              bankAccountId: transfer.subAccountUserId !== data.account.user?.id ? '' : transfer.bankAccountId,
            }));
            setSelectedPortfolioAccountType(data.account.type);
            setAccountId(data.account.id);
          }}
          value={transfer.subAccountId}
          label={transfer.source === 'TRANSFER_TO_SUB_ACCOUNT' ? t('components:transferModal.fromPortfolio') : t('components:transferModal.portfolio')}
          forObject={forObject}
          forId={forId}
        />
      </ListItem>
      {transfer.source === 'TRANSFER_TO_SUB_ACCOUNT' && (
        <ListItem>
          <SubAccountSelect
            setSubAccount={(e) => setTransfer({ ...transfer, toSubAccountId: e.target.value })}
            value={transfer.toSubAccountId}
            label={t('components:transferModal.toPortfolio')}
            forObject={forObject}
            forId={forId}
            excludedIds={[transfer.subAccountId]}
          />
        </ListItem>
      )}
      {transfer.source === 'EFT' && (
        <ListItem>
          <BankAccountSelect
            setBankAccount={(value: string) => setTransfer({ ...transfer, bankAccountId: value })}
            value={transfer.bankAccountId}
            label={t('components:transferModal.bankAccount')}
            disabled={forObject === 'CLIENT_GROUP' && transfer.subAccountUserId === undefined}
            userId={forObject === 'USER' ? forId : transfer.subAccountUserId ?? ''}
          />
        </ListItem>
      )}
      {transfer.source === 'EXTERNAL_TRANSFER_IN' && (
        <ListItem>
          <TextField
            label={t('components:transferModal.externalTransfer.transferAccountNumber')}
            variant='outlined'
            data-testid='transfer-acc-input'
            value={transfer.transferAccountNumber}
            fullWidth
            onChange={(e) => setTransfer({ ...transfer, transferAccountNumber: e.target.value })}
          />
        </ListItem>
      )}
      {transfer.type === TransferType.Withdraw && transfer.withdrawalMode === WithdrawalMode.Net && !transfer.isWithdrawAll && (
        <ListItem>
          <AmountField
            label={t('components:transferModal.netAmount')}
            variant='outlined'
            value={transfer.netAmountCents}
            fullWidth
            onChange={(e: any) => {
              const { valueCents } = e.target;

              setTransfer({ ...transfer, netAmountCents: valueCents });
            }}
            error={checkMinimumRecurringDeposit}
            helperText={checkMinimumRecurringDeposit && minRecurringDeposit && `${t('components:transferModal.minimumRecurringDepositAmount')} ${minRecurringDeposit / 100} $`}
          />
        </ListItem>
      )}

      {(transfer.type !== TransferType.Withdraw || transfer.withdrawalMode !== WithdrawalMode.Net) && (
        <ListItem>
          <AmountField
            label={t(`components:transferModal.${isRespAndIsPartial() ? 'totalTransferAmount' : 'amount'}`)}
            variant='outlined'
            value={transfer.amountCents}
            fullWidth
            onChange={(e: any) => {
              setTransfer({ ...transfer, amountCents: e.target.valueCents });
            }}
            error={checkMinimumRecurringDeposit}
            helperText={checkMinimumRecurringDeposit && minRecurringDeposit && `${t('components:transferModal.minimumRecurringDepositAmount')} $${minRecurringDeposit / 100}`}
            disabled={displayWithdrawAll && transfer.isWithdrawAll}
          />
        </ListItem>
      )}

      {['EFT'].includes(transfer.source) && transfer.type === TransferType.Withdraw && (
        <CalculateFeesForNewTransfer transfer={transfer} accountId={accountId} exceedWithdrawAllLimit={exceedWithdrawAllLimit} onFeesReceived={(fees: WithdrawFee[]) => onFeesReceived(fees)} />
      )}

      {isRespAndIsPartial() && (
        <ListItem key='clbTransferAmount'>
          <AmountField
            label={t('components:transferModal.clbTransferAmount')}
            variant='outlined'
            value={transfer.clbAmountCents}
            fullWidth
            onChange={(e: any) => {
              setTransfer({ ...transfer, clbAmountCents: e.target.valueCents });
            }}
          />
        </ListItem>
      )}
      {isRespAndIsPartial() && (
        <ListItem key='bcGrantTransferAmount'>
          <AmountField
            label={t('components:transferModal.bcGrantTransferAmount')}
            variant='outlined'
            value={transfer.bctesgAmountCents}
            fullWidth
            onChange={(e: any) => {
              setTransfer({ ...transfer, bctesgAmountCents: e.target.valueCents });
            }}
          />
        </ListItem>
      )}
      {exceedWithdrawAllLimit && (
        <ListItem>
          <Typography variant='body2' sx={{ color: colors.warning, lineHeight: 1 }}>
            {transfer.source === 'TRANSFER_TO_SUB_ACCOUNT' ? t('components:transferModal.exceedTransferAllWarning') : t('components:transferModal.exceedWithdrawAllWarning')}
          </Typography>
        </ListItem>
      )}
      {displayWithdrawAll && (
        <ListItem>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={transfer.isWithdrawAll}
                  onChange={(e) => setTransfer({
                    ...transfer,
                    isWithdrawAll: e.target.checked,
                    amountCents: 0,
                    netAmountCents: 0,
                  })
                  }
                />
              }
              label={transfer.source === 'TRANSFER_TO_SUB_ACCOUNT' ? t('components:transferModal.transferAll') : t('components:transferModal.withdrawAll')}
            />
          </FormGroup>
        </ListItem>
      )}
      {transfer.source === 'ADJUSTMENT' && (
        <ListItem>
          <TextField
            fullWidth
            label={t('components:transferModal.adjustmentReason')}
            variant='outlined'
            value={transfer.adjustmentReason}
            onChange={(e) => setTransfer({ ...transfer, adjustmentReason: e.target.value })}
          />
        </ListItem>
      )}
      {(transfer.source === 'EFT' || transfer.source === 'TRANSFER_TO_SUB_ACCOUNT') && (
        <>
          <ListItem>
            <TextField
              select
              value={transfer.frequency ?? ''}
              label={t('components:transferModal.schedule')}
              fullWidth
              data-testid='frequency-dropdown'
              onChange={(e: any) => {
                setTransfer({ ...transfer, frequency: e.target.value });
              }}
            >
              <MenuItem value='ONE_TIME'>{t('components:transferModal.scheduleOptions.ONE_TIME')}</MenuItem>
              <MenuItem value='WEEKLY'>{t('components:transferModal.scheduleOptions.WEEKLY')}</MenuItem>
              <MenuItem value='BI_WEEKLY'>{t('components:transferModal.scheduleOptions.BI_WEEKLY')}</MenuItem>
              <MenuItem value='MONTHLY'>{t('components:transferModal.scheduleOptions.MONTHLY')}</MenuItem>
              <MenuItem value='QUARTERLY'>{t('components:transferModal.scheduleOptions.QUARTERLY')}</MenuItem>
            </TextField>
          </ListItem>
          {transfer.frequency === 'ONE_TIME' && (
            <ListItem>
              <RadioGroup label={t('components:transferModal.starting')} value={starting} onChange={(e: any) => { setStarting(e.target.value); }}>
                <Stack direction={'row'}>
                  {!isInitiatedByRepresentative() && (<Radio value='now' label={t('components:transferModal.startingOptions.now')} />)}
                  <Radio value='futureDate' label={t('components:transferModal.startingOptions.futureDate')} />
                </Stack>
              </RadioGroup>
            </ListItem>
          )}
          {(starting === 'futureDate' || transfer.frequency !== 'ONE_TIME') && (
            <ListItem>
              <LocalizedDatePicker
                label={
                  transfer.frequency === 'ONE_TIME'
                    ? t('components:transferModal.startingOptions.futureDate')
                    : t('components:transferModal.starting')
                }
                minDate={dayjs().add(1, 'day')}
                value={transfer.scheduledDate}
                onChange={(date) => {
                  setTransfer({ ...transfer, scheduledDate: date ? dayjs(date.toString()).format('YYYY-MM-DD') : undefined });
                }}
                renderInput={(params) => <TextField fullWidth {...params} error={!transfer.scheduledDate} autoFocus />}
                InputProps={{ inputRef: scheduledDateInputRef }}
              />
            </ListItem>
          )}
        </>
      )}
    </CreateNewModal>
  );
};

export default NewTransfer;
