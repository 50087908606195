import {
  Pagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import { uniq } from 'lodash/fp';
import { useClientContext } from 'pages/client';
import { useHouseholdContext } from 'pages/household';
import { useSearchParams } from 'react-router-dom';
import {
  Typography, Box, Grid, Skeleton,
} from '../../1-primative';
import {
  IconButton,
  MenuItem,
  SelectField,
} from '../../2-component';
import GoalItem from '../goalItem/goalItem';
import { Goal, GoalStates } from '../../../interfaces';
import { usePageState } from '../../../util/usePageState';
import { ActionContext, PageObjectType } from '../../5-page';
import { CreateGoalWorkflow } from '../createGoalWorkflow/createGoalWorkflow';
import { translateBackend } from '../../../assets/i18n/config';
import { EmptyState } from '../../2-component/emptyState/emptyState';
import { CREATE_WORKFLOW_COMPLETION } from '../../../components/layout/client/clientSummary';
import { UserContext } from '../../../providers/userContextProvider';
import { FilterModal } from '../../3-pattern';

type SequenceAndId = { sequence: number, id: string };
export const FETCH_GOALS = (forUserId?: string, withMemberStats?: SequenceAndId[]) => gql`
  query fetchGoals($input: FetchGoalsInput!, $currency: StatisticsCurrencyTypes) {
    fetchGoals(input: $input) {
      goals {
        id
        name
        type
        state
        targetAmountCents
        timeHorizon
        riskQuestion1
        subAccounts {
          state
          account {
            user {
              id
            }
          }
        }
        statistics(input: {currency: $currency},${forUserId ? `forUserId:"${forUserId}"` : ''}) {
          marketValueCents
          currency
          simpleReturnPercent
          simpleReturnAmount
        }
        ${(withMemberStats || []).map(({ id, sequence }) => `
          user${sequence}statistics:statistics(forUserId:"${id}") {
            marketValueCents
            simpleReturnPercent
            simpleReturnAmount
          }
        `)}
        householdClientGroup {
          id
          name
        }
      }
      totalCount
    }
  }
`;

export const Goals = ({
  objectType, objectId, onlyUserId, singleColumn = false, options,
}: {
  objectType: PageObjectType, objectId: string, onlyUserId?: string, singleColumn?: boolean, options: any,
}) => {
  const PAGE_SIZE = singleColumn ? 3 : 15;

  const { t } = useTranslation(['client']);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = usePageState(1, 'goalsPage');
  const [filterByState, setFilterByState] = useState<GoalStates[]>([GoalStates.ACTIVE, GoalStates.COMPLETED]);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const { activeOrganization, activeCurrency } = useContext(UserContext);
  const { activeWorkflows, refetch: refetchWorkflow } = useContext(ActionContext);
  const [params, setSearchParams] = useSearchParams();

  const [createWorkflowCompletion] = useMutation(CREATE_WORKFLOW_COMPLETION, {
    onCompleted: (data: any) => {
      refetchWorkflow();
      updateWorkflowCompletion(data.createWorkflowCompletion.workflowCompletion.id);
    },
  });

  const updateWorkflowCompletion = (workflowCompletionId?: string) => {
    if (!workflowCompletionId) return;
    const newParams = new URLSearchParams(params);
    newParams.set('workflowCompletion', workflowCompletionId);
    setSearchParams(newParams);
  };

  const organizationId = clientContext?.orgSettings.id || householdContext?.orgSettings.id || activeOrganization?.id;
  const forUserId = [PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) ? objectId : undefined;
  const withMemberStats: SequenceAndId[] | undefined = householdContext
    && Object.entries(householdContext.indexedMembers).map(([id, index]) => ({ id, sequence: index }));

  const { loading, refetch } = useQuery(FETCH_GOALS(forUserId, withMemberStats), {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          ...([PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && { userId: objectId }),
          ...(objectType === PageObjectType.HOUSEHOLD && { clientGroupId: objectId }),
          states: filterByState,
        },
        pagination: {
          offSet: (page - 1) * PAGE_SIZE,
          perPage: PAGE_SIZE,
        },
      },
      currency: activeCurrency,
    },
    onCompleted: (e) => {
      setGoals([...e.fetchGoals.goals]);
      setTotalCount(e.fetchGoals.totalCount);
    },
  });

  const goalsSorted = goals.sort((a, b) => (b.statistics?.marketValueCents ?? 0) - (a.statistics?.marketValueCents ?? 0));

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByState]);

  const householdLinkedGoals = goals.filter((g) => g.householdClientGroup);
  const householdIds = uniq(householdLinkedGoals.map((g) => g.householdClientGroup?.id));
  const indexedHouseholdIds = Object.fromEntries(householdIds.map((id, index) => [id, index === 0 ? 'HOUSEHOLD' : index + 1]));

  const getTag = (goal: Goal) => {
    if (clientContext) {
      if (!goal.householdClientGroup || objectType === PageObjectType.HOUSEHOLD) return undefined;

      return {
        sequenceNumber: indexedHouseholdIds[goal.householdClientGroup.id],
        text: `${goal.householdClientGroup.name} ${t('shared:household')}`,
      };
    }

    return undefined;
  };

  const createGoal = (workflowId?: string) => {
    if (!workflowId || workflowId === 'default') {
      setOpen(true);
      return;
    }
    const activeWorkflow = activeWorkflows.find((x: any) => x?.workflow?.id === workflowId);
    if (activeWorkflow) {
      updateWorkflowCompletion(activeWorkflow?.id);
      return;
    }
    createWorkflowCompletion({
      variables: {
        input: {
          objectId,
          objectType: 'USER',
          workflowId,
          organizationId,
        },
      },
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" pb={2} minHeight='40px'>
        <Box display="flex" alignItems={'center'}>
          <Typography variant='headingSmall'>
            {options.customTitle || options.goalsCustomTitle ? translateBackend(options.customTitle ?? options.goalsCustomTitle) : t('goalsSummary.header')}
          </Typography>
        </Box>
        <Box display='flex'>
          {options.filterEnabled && (
            <FilterModal filterExists={Object.values(filterByState).some((x) => !x)}>
              <SelectField
                value={filterByState}
                multiple
                fullWidth
                label={t('goalsSummary.goalStateLabel')}
                onChange={(e: any) => setFilterByState(e.target.value)}
              >
                <MenuItem value={GoalStates.ACTIVE}>{t('goalsSummary.goalState.ACTIVE')}</MenuItem>
                <MenuItem value={GoalStates.COMPLETED}>{t('goalsSummary.goalState.ARCHIVED')}</MenuItem>
              </SelectField>
            </FilterModal>
          )}
          {options.addNewGoalEnabled && (
            <>
              {[PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && (
                <>
                  <IconButton data-testid='add-new-goals' onClick={() => createGoal(options?.goalWorkflow)}>
                    <Add />
                  </IconButton>
                  <CreateGoalWorkflow open={open} setOpen={setOpen} objectId={objectId} objectType={objectType} refetch={refetch} />
                </>
              )}
              {/* TODO: Bring back household goal creation */}
              {/* { objectType === PageObjectType.HOUSEHOLD && (
                <CreateGoalWizardButton forObject='CLIENT_GROUP' forId={objectId}/>
              )} */}
            </>
          )}
        </Box>
      </Box>
      <Grid container spacing={2}>
        {loading
          ? <Grid item xs={12} sm={singleColumn ? 12 : 6}><Skeleton width='100%' height='114px' variant='rectangular' /></Grid>
          : goalsSorted.map((goal) => (
            <Grid item xs={12} sm={singleColumn ? 12 : 6} key={goal.id}>
              <GoalItem
                data-testid={`goal-${goal?.id}`}
                goal={goal}
                tag={getTag(goal)}
                onlyUserId={onlyUserId}
                objectId={objectId}
                enableTooltip={false}
              />
            </Grid>
          ))
        }
        {!loading && goals.length === 0 && (
          <Grid item xs={12} sm={singleColumn ? 12 : 6}>
            <EmptyState>
              <Typography variant='bodyLarge'>{t('goalsSummary.noGoals')}</Typography>
            </EmptyState>
          </Grid>
        )}
      </Grid>

      {
        totalCount > PAGE_SIZE ? (
          <Box display='flex' flexDirection='column' alignItems='end' mt={1}>
            <Pagination
              count={Math.ceil(totalCount / PAGE_SIZE)}
              sx={{ marginBottom: '10px' }}
              page={page}
              onChange={(e, newPage) => {
                setPage(newPage);
              }}
            />
          </Box>
        ) : undefined
      }
    </>
  );
};

export default Goals;
