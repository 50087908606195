import { useContext, useState } from 'react';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AddIcon from '@mui/icons-material/AddRounded';
import { useTranslation } from 'react-i18next';
import {
  Typography, Form,
  Box,
  Button,
  IconButton,
} from '../../../..';
import AddBankAccount from '../../../../3-pattern/addBankAccount/addBankAccount';
import { WorkflowContext } from '../../workflowCompletion';
import BankAccountCard from '../../../bankAccountCard/bankAccountCard';

export const ReviewBankAccountInformationVisual = ({
  options, userId, loading, continueFunc, bankAccounts, refetchBankAccounts,
  onEdit, onRemove, grid = false, updateMode = false,
}: {
  options: any, userId: string, loading: boolean, refetchBankAccounts: any, continueFunc: any,
  bankAccounts?: any[],
  onEdit?: any,
  onRemove?: any,
  grid?: boolean,
  updateMode?: boolean,
}) => {
  const { t } = useTranslation(['client', 'accountTypes', 'accountsDetail']);
  const [addBankAccount, setAddBankAccount] = useState(false);
  const { workflowData } = useContext(WorkflowContext);
  const submit = () => {
    continueFunc();
  };

  if (!options) return (<></>);

  return (
    <>
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>
        {translateBackend(options?.title)}
      </Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget='_blank' remarkPlugins={[remarkGfm]}>
          {translateBackend(options?.subtitle)}
        </ReactMarkdown>
      </Typography>

      {bankAccounts
        && bankAccounts.map((bankAccount: any) => (
          <BankAccountCard onRemove={onRemove} onEdit={onEdit} bankAccount={bankAccount} options={options} />
        ))}
      <AddBankAccount
        data-testid='add-bank-account'
        handleClose={() => setAddBankAccount(false)}
        open={addBankAccount}
        bankAccounts={bankAccounts ?? []}
        userId={userId ?? workflowData.currentUserId}
        options={options}
        afterCreate={async () => {
          await refetchBankAccounts();
          setAddBankAccount(false);
        }}
      />

      {options.createAnotherBankAccount && (<Box display='flex' justifyContent='left' mt={2}>
        <IconButton onClick={() => setAddBankAccount(true)}><AddIcon data-testid="add-icon" /></IconButton>
        <Typography sx={{ position: 'relative', top: '12px' }} variant='bodySmall'>{t('client:bankAccount.addAnotherbankAccount')}</Typography>
      </Box>)}

      <Box display='flex' justifyContent='end'>
        <Button data-testid="continue-button" label={t('continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
    </>
  );
};

export default ReviewBankAccountInformationVisual;
