import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Chip,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import NewAssetClass from './newAssetClass';
import EditAssetClass from './editAssetClass';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { AssetClass } from '../../../interfaces';
import { usePageState } from '../../../util/usePageState';

const FETCH_ASSET_CLASSES = gql`
  query fetchAssetClasses($input: FetchAssetClassesInput) {
    fetchAssetClasses(input: $input) {
      assetClasses {
        id
        key
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
        state
      }
    }
  }
`;

const AssetClasses = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['configureModels']);
  const [assetClassOpen, setAssetClassOpen] = useState(false);
  const [selectedAssetClass, setSelectedAssetClass] = useState<any>(null);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_ASSET_CLASSES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ overflowX: 'auto' }}>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          { permissions.includes('write:asset_classes') && (
            <Grid container justifyContent='flex-end'>
              <Grid item sx={{ marginRight: 1 }}>
                <NewAssetClass afterCreate={refetch} />
              </Grid>
            </Grid>
          )}
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('table.key')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.description')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchAssetClasses?.assetClasses?.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize)?.map((assetClass: AssetClass) => (
                <TableRow
                  hover
                  key={assetClass.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => {
                    if (permissions.includes('write:asset_classes')) {
                      setSelectedAssetClass(assetClass);
                      setAssetClassOpen(true);
                    }
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Chip size='small' label={assetClass.key} />
                  </TableCell>
                  <TableCell>{assetClass.translatedName?.en}</TableCell>
                  <TableCell>{assetClass.translatedDescription?.en}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchAssetClasses?.assetClasses.length ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
          { permissions.includes('write:asset_classes') && (
            <EditAssetClass afterUpdate={() => {
              setAssetClassOpen(false);
              refetch();
            }} assetClassToUpdate={selectedAssetClass} open={assetClassOpen} handleClose={() => setAssetClassOpen(false)} />
          )}
        </>
      )}
    </Box >
  );
};

export default AssetClasses;
