import { Organization } from './organization';

export interface CurrentIntegrationType {
  configuration: any;
  provider: IntegrationProvider;
  type: IntegrationType;
  state: IntegrationState
  id: string;
}

export interface NewIntegrationType {
  configuration: any;
  provider: IntegrationProvider | string;
  type: IntegrationType | string;
  organizationId: string;
}

export interface Integration {
  id?: string
  configuration: any;
  provider: IntegrationProvider;
  type: IntegrationType;
  organization: Organization;
}

export enum IntegrationProvider {
  CANADA_POST = 'CANADA_POST',
  FLINKS = 'FLINKS',
  GOOGLE = 'GOOGLE',
  FIREBASE = 'FIREBASE',
  IEX_CLOUD = 'IEX_CLOUD',
  LSEG = 'LSEG',
  MIXPANEL = 'MIXPANEL',
  PERSONA = 'PERSONA',
}

export enum IntegrationType {
  ADDRESS = 'ADDRESS',
  ANALYTICS = 'ANALYTICS',
  BANKING = 'BANKING',
  FINANCIAL_DATA_WIDGETS = 'FINANCIAL_DATA_WIDGETS',
  ID_VERIFICATION = 'ID_VERIFICATION',
  MARKET_DATA = 'MARKET_DATA',
}

export enum IntegrationState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE,',
}

export enum IntegrationTransitionState {
  ACTIVATE = 'activate',
  ARCHIVE = 'archive',
  DEACTIVATE = 'deactivate',
}
