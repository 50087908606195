import {
  useState,
  ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'assets/i18n/config';
import {
  getRelatedEntityTypes,
  RelatedEntityTypes,
  User,
} from 'interfaces';
import { Grid } from 'ovComponents/1-primative';
import {
  ClientSelectField,
  MenuItem,
  SelectField,
} from 'ovComponents/2-component';

interface RelatedEntitySelectProps {
  entitySelectLabel: string;
  relationSelectLabel: string;
  entity?: Partial<User>;
  relation?: RelatedEntityTypes;
  onEntityChange?: (entity?: User) => void;
  onRelationChange?: (relation?: RelatedEntityTypes) => void;
}

interface RelatedEntityData {
  entity?: Partial<User>;
  relation?: RelatedEntityTypes;
}

export const RelatedEntitySelect = (props: RelatedEntitySelectProps) => {
  const { t } = useTranslation(['relatedEntityTypes']);
  const [relatedEntityData, setRelatedEntityData] = useState<RelatedEntityData>({ entity: props.entity, relation: props.relation });
  const [candidateRelations, setCandidateRelations] = useState<RelatedEntityTypes[]>([]);

  const handleEntityChange = (entity?: User) => {
    setRelatedEntityData((prevState) => {
      let relation = entity ? prevState?.relation : undefined;
      if (prevState?.entity?.type !== entity?.type) {
        setCandidateRelations(getRelatedEntityTypes(entity?.type ?? ''));
        // Reset relation to avoid persisting RelatedEntityTypes incompatible with EntityType
        relation = undefined;
      }
      // The order of updating entity after relation is significant to ensure the
      // changes persist in a parent component
      props.onRelationChange?.(relation);
      props.onEntityChange?.(entity);
      return ({ entity, relation });
    });
  };

  const handleRelationChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const relation = e.target.value as RelatedEntityTypes;
    setRelatedEntityData({ ...relatedEntityData, relation });
    props.onRelationChange?.(relation);
  };

  return (<Grid container spacing={3}>
    <Grid item xs={12}>
      <ClientSelectField
        user={relatedEntityData.entity}
        setUser={handleEntityChange}
        label={props.entitySelectLabel}
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <SelectField
        label={props.relationSelectLabel}
        value={relatedEntityData.relation || ''}
        disabled={!relatedEntityData?.entity}
        onChange={handleRelationChange}
        fullWidth
      >
        { candidateRelations.map((relation: RelatedEntityTypes) => (
          <MenuItem value={relation}>{t(`relatedEntityTypes:${relation}`)}</MenuItem>
        )) }
      </SelectField>
    </Grid>
  </Grid>);
};

RelatedEntitySelect.defaultProps = {
  entitySelectLabel: i18n.t('relatedEntitySelect:defaultEntitySelectLabel'),
  relationSelectLabel: i18n.t('relatedEntitySelect:defaultRelationSelectLabel'),
};
