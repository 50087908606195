import {
  Box, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, FormGroup, ListItem, Menu, Pagination, Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactElement, useEffect, useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { colors } from 'ovComponents';
import { RoundButton } from 'components/inputs/roundButton';
import { ObjectType } from 'providers/statsHooks';
import { usePermissions } from 'providers/userContextProvider';
import { gql, useQuery } from '@apollo/client';
import { uniq } from 'lodash/fp';
import { useClientContext } from 'pages/client';
import { useHouseholdContext } from 'pages/household';
import { generateClientNameString } from '../../../util';
import AccountItem from './accountItem';
import { Account, AccountStates } from '../../../interfaces';
import { usePageState } from '../../../util/usePageState';

const PAGE_SIZE = 10;

export const FETCH_ACCOUNTS = (permissions: string[]) => gql`
  query fetchAccounts($input: FetchAccountsInput!) {
    fetchAccounts(input: $input) {
      totalCount
      accounts {
        id
        type
        state
        stateChanges
        tlhEnabled
        ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
        statistics {
          marketValueCents
          simpleReturnPercent
          simpleReturnAmount
        }
        subAccounts {
          id
          state
        }
        incompleteFormAgreements { id }
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
        }
        householdClientGroup {
          id name
        }
      }
    }
  }
`;

const AccountsSummary = ({
  objectType,
  objectId,
  newAccountComponent,
}: {
  objectType: ObjectType.USER | ObjectType.CLIENT_GROUP,
  objectId: string
  newAccountComponent?: ReactElement
}) => {
  const { t } = useTranslation(['client']);
  const { permissions } = usePermissions();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = usePageState(1, 'accountsPage');
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [filterByState, setFilterByState] = useState<Record<string, boolean>>({
    [AccountStates.ACTIVE]: true,
    [AccountStates.REQUESTED]: true,
    [AccountStates.FROZEN]: true,
    [AccountStates.FAILED]: true,
    [AccountStates.INITIATED]: true,
    [AccountStates.READY]: true,
    [AccountStates.INACTIVE]: false,
    [AccountStates.CANCELED]: false,
  });
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();

  const { loading } = useQuery(FETCH_ACCOUNTS(permissions), {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filter: {
          ...(objectType === ObjectType.USER && { userId: objectId }),
          ...(objectType === ObjectType.CLIENT_GROUP && { clientGroupId: objectId }),
          states: Object.keys(filterByState).filter((key) => filterByState[key]),
        },
        pagination: {
          perPage: 1000,
        },
      },
    },
    onCompleted: (e) => {
      setAccounts([...e.fetchAccounts.accounts]);
      setTotalCount(e.fetchAccounts.totalCount);
    },
  });

  const accountsSorted = accounts.sort((a, b) => (b.statistics?.marketValueCents ?? 0) - (a.statistics?.marketValueCents ?? 0));
  const accountsPaginated = accountsSorted.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);

  useEffect(() => {
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByState]);

  const householdLinkedAccounts = accounts.filter((a) => a.householdClientGroup);
  const householdIds = uniq(householdLinkedAccounts.map((a) => a.householdClientGroup?.id));
  const indexedHouseholdIds = Object.fromEntries(householdIds.map((id, index) => [id, index === 0 ? 'HOUSEHOLD' : index + 1]));

  const getTag = (account:Account) => {
    if (clientContext) {
      if (!account.householdClientGroup) return undefined;

      return {
        sequenceNumber: indexedHouseholdIds[account.householdClientGroup.id],
        text: `${account.householdClientGroup.name} ${t('shared:household')}`,
      };
    }

    if (householdContext) {
      return {
        sequenceNumber: householdContext.indexedMembers[account.user.id],
        text: generateClientNameString(account.user) ?? account.user.id,
      };
    }

    return undefined;
  };

  const AccountsFilterMenu = () => (
    <ListItem>
      <Stack>
        <Typography fontWeight={600} fontSize="20px">{t('accountsSummary.accountStateLabel')}</Typography>
        { Object.values(AccountStates).map((state, index) => <FormGroup key={index}>
            <FormControlLabel
              control={<Checkbox checked={filterByState[state]}/>}
              onClick={() => setFilterByState({ ...filterByState, [state]: !filterByState[state] })}
              label={t(`accountsDetail:accountState.${state}`)}
            />
          </FormGroup>)}
        </Stack>
    </ListItem>
  );

  return (
    <Card>
      <CardContent>
        <Box width='100%'>
          <Box display="flex" justifyContent="space-between" marginBottom="9px">
            <Box display="flex" alignItems={'center'}>
              <Typography fontWeight={600} fontSize="20px">{t('accountsSummary.header')}</Typography>
            </Box>
            <div>
              <RoundButton color='secondary'
                style={{ backgroundColor: filterMenuAnchorEl && colors.neutral300 }}
                onClick={(e:React.MouseEvent<HTMLElement>) => setFilterMenuAnchorEl(filterMenuAnchorEl ? null : e.currentTarget)}
              >
                <TuneIcon/>
              </RoundButton>
              <Menu
                open={!!filterMenuAnchorEl}
                anchorEl={filterMenuAnchorEl}
                onClose={() => setFilterMenuAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <AccountsFilterMenu/>
              </Menu>
              {newAccountComponent}
            </div>
          </Box>

          {loading
            ? <CircularProgress />
            : accountsPaginated.map((account) => (
              <AccountItem
                key={account.id}
                account={account}
                tag={getTag(account)}
              />))
          }

          {
            totalCount > PAGE_SIZE ? (
              <Box display='flex' flexDirection='column' alignItems='end'>
                <Pagination
                  count={Math.ceil(totalCount / PAGE_SIZE)}
                  sx={{ marginBottom: '10px' }}
                  page={page}
                  onChange={(_e, newPage) => {
                    setPage(newPage);
                  }}
                />
              </Box>
            ) : undefined
          }
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountsSummary;
