import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Box, CircularProgress, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}, &.${tableCellClasses.head}`]: {
    borderRight: '1px solid black',
    borderLeft: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'bottom',
    borderBottom: 'none',
    borderTop: 'none',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  'td:first-of-type, th:first-of-type': {
    borderLeft: 'none',
  },
  'td:last-child, th:last-child': {
    borderRight: 'none',
  },
}));

// todo: stats will be added in a separate ticket
const PortfolioOptimizerStatsTable = ({ stats }: { stats: { numPortfolios: number } }) => {
  const { t } = useTranslation('portfolioOptimizer');

  let loading = true;
  if (stats) {
    loading = false;
  }

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 5 }} />
        </Box>
      ) : (
        <>
          <Table className='test-table' sx={{ m: 2 }} aria-label='stats table' data-testid='stats-table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography variant='overline'>{t('stats.buys')}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant='overline'>{t('stats.sells')}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant='overline'>{t('stats.portfoliosAdjusted')}</Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>
                  <div>
                    <strong>{t('stats.shares')}</strong>: 123
                  </div>
                  <div>
                    <strong>{t('stats.amount')}</strong>: 123
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    <strong>{t('stats.shares')}</strong>: 123
                  </div>
                  <div>
                    <strong>{t('stats.amount')}</strong>: 123
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div>
                    <strong>{stats.numPortfolios}</strong>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};

export default PortfolioOptimizerStatsTable;
