import { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import {
  Button, Typography, Form, Box, SelectField, MenuItem,
} from '../../../..';
import { translateBackend } from '../../../../../assets/i18n/config';
import { AccordionHoldings } from '../../../widgets/holdings/components/accordionHoldings';
import { calculatePrimaryAssetClassHoldings } from '../../../widgets/holdings/holdings.resources';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const AssignPortfolioToSubAccountVisual = ({
  options, objectData, loading, updateObject, continueFunc, suggestedFinancialProduct, workflowCompletion,
}: {
  options: any, objectData: any, loading: boolean, updateObject: any, continueFunc: any,
  suggestedFinancialProduct?: any, workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'accountTypes']);
  const [assetClassHoldings, setAssetClassHoldings] = useState<any[]>([]);
  const submit = () => {
    if (!objectData.themeId) return;
    sendAnalytic(
      ovAnalyticsEvents.workflowsAssignPortfolioToSubAccountContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    continueFunc();
  };

  useEffect(() => {
    if (suggestedFinancialProduct) {
      const holdings = calculatePrimaryAssetClassHoldings(suggestedFinancialProduct.children.map((x: any) => ({ ...x, totalCents: x.percentage })), 100);
      setAssetClassHoldings(holdings);
    }
  }, [suggestedFinancialProduct, setAssetClassHoldings]);

  /* pre-select if there's exatcly one theme available */
  useEffect(() => {
    const updateThemeId = async () => {
      await updateObject({ ...objectData, themeId: objectData.availableThemes[0].id });
    };
    if (objectData.availableThemes.length === 1 && objectData.themeId !== objectData.availableThemes[0].id) {
      updateThemeId();
    }
  }, [objectData, objectData.availableThemes, objectData.themeId, updateObject]);

  if (!options) return (<></>);

  const showUrlModelPortfolioUrl = (options?.displayModelPortfolioUrl && suggestedFinancialProduct?.url !== null)
    ? <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{`[Portfolio fund facts](${suggestedFinancialProduct?.url})`}
    </ReactMarkdown>
    : <></>;

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      <SelectField
        testId="theme-selection"
        onChange={(e: any) => updateObject({ ...objectData, themeId: e.target.value })}
        value={objectData.themeId || ''}
        label={t('theme')}
        fullWidth
      >
        {objectData.availableThemes?.map((theme: any) => (<MenuItem key={theme.id} value={theme.id}>{translateBackend(theme.translatedName)}</MenuItem>))}
      </SelectField>
      {suggestedFinancialProduct && (
        <>
          <Typography variant='bodyLarge' sx={{ mt: 2, mb: 3 }}>
            {translateBackend(objectData.availableThemes.find((theme: any) => theme.id === objectData.themeId)?.translatedDescription)}
          </Typography>
          {showUrlModelPortfolioUrl}
          <AccordionHoldings data-testid="accordion-holding" assetClassHoldings={assetClassHoldings} loading={false} variant='outlined' />
        </>
      )}
      <Box display='flex' justifyContent='end'>
        <Button data-testid="continue-btn" label={t('continue')} disabled={loading || !objectData.themeId} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default AssignPortfolioToSubAccountVisual;
