import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Box, Typography } from '../../../1-primative';
import {
  Avatar, Card, CardContent, IconButton, MenuItem, Pagination, SelectField,
} from '../../../2-component';
import { ConfirmationModal } from '../../../3-pattern';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { UserContext, usePermissions } from '../../../../providers/userContextProvider';
import { useLocalization } from '../../../../util/useLocalization';
import { Note, NoteObjectTypes, NoteStates } from '../../../../interfaces/note';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
// TODO: Replace with new component
import RichTextEditor from '../../../../components/inputs/richTextEditor';
import NewNote from './components/newNote';

export const FETCH_NOTES = gql`
  query fetchNotes($input: FetchNotesInput!) {
    fetchNotes(input: $input) {
      notes {
        id
        state
        type
        content
        createdAt
        updatedAt
        author {
          id
          email
          firstName
          lastName
        }
      }
      totalCount
    }
  }
`;

const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        id
      }
    }
  }
`;

const TRANSITION_NOTE = gql`
  mutation transitionNote($input: TransitionNoteInput!) {
    transitionNote(input: $input) {
      note {
        id
      }
    }
  }
`;

export const Notes = ({ objectType, objectId, options }: { objectType: PageObjectType, objectId: string, options: any }) => {
  const { permissions } = usePermissions();
  const { userContext } = useContext(UserContext);
  const { t } = useTranslation();
  const { localizedDateTime } = useLocalization();
  const [page, setPage] = useState(1);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [toDeleteId, setToDeleteId] = useState<string | null>(null);
  const { sys } = useThemeTokens();
  const pageSize = 10;
  const {
    loading, data, refetch, previousData,
  } = useQuery(FETCH_NOTES, {
    variables: {
      input: {
        filter: {
          ...(objectId && { objectId }),
          objectType: objectType === PageObjectType.HOUSEHOLD ? 'CLIENT_GROUP' : 'USER',
          state: NoteStates.PUBLISHED,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  const [deleteNote] = useMutation(TRANSITION_NOTE);

  const onCancelDelete = () => {
    setConfirmationOpen(false);
  };

  const onConfirmDelete = async () => {
    await deleteNote({
      variables: {
        input: {
          noteId: toDeleteId,
          transition: 'delete',
        },
      },
    });
    refetch();
    setConfirmationOpen(false);
  };
  const [updateNote] = useMutation(UPDATE_NOTE);

  const updateNoteTypeClick = async (type: string, id: string) => {
    await updateNote({
      variables: {
        input: {
          noteId: id,
          type,
        },
      },
    });
    refetch();
  };

  const allowAddNoteForm = permissions.includes('write:notes') && objectId && options.addNotes;
  const notes = (data || previousData)?.fetchNotes.notes ?? [];

  return (
    <>
      { options.customTitle && (<Typography variant='headingSmall' mb={2}>{translateBackend(options.customTitle)}</Typography>) }
      { allowAddNoteForm && (<NewNote objectId={objectId} afterUpdate={refetch} noteObjectType={objectType === PageObjectType.HOUSEHOLD ? NoteObjectTypes.CLIENT_GROUP : NoteObjectTypes.USER} />)}
      {notes.map((note: Note) => (
        <Card key={note.id} sx={{ mb: 2 }}>
          <CardContent>
            <Box display='flex' justifyContent='space-between'>
              <Box display='flex' alignItems='center'>
                <Avatar>
                  {`${note.author.firstName.charAt(0).toUpperCase()}${note.author.lastName.charAt(0).toUpperCase()}`}
                </Avatar>
                <Typography variant='titleMedium' sx={{ mr: 1, ml: 1 }}>{note.author.firstName} {note.author.lastName}</Typography>
                <Typography variant='bodyMedium' colorVariant='variant'>{localizedDateTime(note.createdAt)}</Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                { options.editNotes && (
                  <SelectField size='small' label='' value={note.type} onChange={(e: any) => updateNoteTypeClick(e.target.value, note.id || '')}>
                    <MenuItem value='ORGANIZATIONAL'>{t('client:notes.types.ORGANIZATIONAL')}</MenuItem>
                    <MenuItem value='PRIVATE'>{t('client:notes.types.PRIVATE')}</MenuItem>
                    <MenuItem value='PUBLIC'>{t('client:notes.types.PUBLIC')}</MenuItem>
                  </SelectField>
                )}
                { options.deleteNotes && (
                  <IconButton
                    data-testid="edit-button"
                    edge="end"
                    size="small"
                    sx={{ fontSize: '12px', ml: 1 }}
                    onClick={async () => {
                      if (userContext?.id === note.author.id) {
                        setToDeleteId(note.id || '');
                        setConfirmationOpen(true);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </CardContent>
          <CardContent sx={{ borderTop: `1px solid ${sys.color.outlineVariant}` }}>
            <RichTextEditor
              value={note.content || ''}
              label=''
              readonly
              key={note?.id}
              customEditorStyle={{ border: 'none', padding: 0, minHeight: 'auto' }}
            />
          </CardContent>
          <ConfirmationModal
            open={confirmationOpen}
            loading={loading}
            title={t('buildModels:newNote.deleteTitle')}
            bodyText={t('buildModels:newNote.deleteSubText')}
            onConfirm={() => onConfirmDelete()}
            onCancel={() => onCancelDelete()}
            confirmButtonLabel={t('buildModels:newNote.delete')}
            cancelButtonLabel={t('buildModels:newNote.cancelDelete')}
          />
        </Card>
      ))}
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchNotes?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
    </>
  );
};
