import {
  Typography, TextField, MenuItem, Box, IconButton,
} from '@mui/material';
import { isNull } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import EditSchedule from '../../pages/schedules/components/editSchedule';

export const FETCH_SCHEDULES = gql`
  query fetchSchedules($input: FetchSchedulesInput!) {
    fetchSchedules(input: $input) {
      schedules {
        id
        name
        dates
        nextDate
        organization { id }
      }
    }
  }
`;

const ScheduleSelect = ({
  setSchedule, value, label, size, disabledWith = null, readonly = false, setScheduleData,
}: {
  setSchedule: (event: any) => void, value: string, label: string, size?: 'small' | 'medium' | undefined, disabledWith?: string | null, readonly?: boolean, setScheduleData?: (event: any) => void,
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization, organizationIds } = useContext(UserContext);
  const [editOpen, setEditOpen] = useState(false);
  const [activeSchedule, setActiveSchedule] = useState({});

  const {
    loading, error, data, refetch,
  } = useQuery(FETCH_SCHEDULES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
        pagination: { perPage: 100 },
      },
    },
  });

  const canUpdateSchedule = (scheduleItem: { organization: { id: string } }) => (
    permissions.includes('write:schedules') && organizationIds.includes(scheduleItem.organization.id)
  );

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      {permissions.includes('read:schedules') ? (
        <TextField
          select
          value={data ? value : ''}
          label={label}
          sx={{ width: '100%' }}
          onChange={(e: any) => {
            setSchedule(e);
            if (setScheduleData && data?.fetchSchedules?.schedules) {
              const schedule = data.fetchSchedules.schedules.find((s: any) => s.id === e.target.value);
              setScheduleData(schedule);
            }
          }}
          size={size}
          disabled={!isNull(disabledWith)}
          InputProps={{
            readOnly: readonly,
          }}

        >
          {
            loading ? <MenuItem>...</MenuItem> : (
              disabledWith ? (
                <MenuItem key={value} value={value}><b>{disabledWith}</b></MenuItem>
              ) : (data?.fetchSchedules?.schedules || [])?.map((x: any) => (
                <MenuItem key={x.id} value={x.id}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant='subtitle2' pt={1}>{x.name} - {x.id}</Typography>
                    {canUpdateSchedule(x) && (
                      <IconButton
                        size='small'
                        sx={{ marginRight: 1 }}
                        onClick={() => {
                          setActiveSchedule(x);
                          setEditOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </Box>
                </MenuItem>
              ))
            )
          }
        </TextField>
      ) : (<></>)}
      <EditSchedule
        afterUpdate={() => {
          setActiveSchedule({});
          setEditOpen(false);
          refetch();
        }}
        scheduleToUpdate={activeSchedule}
        open={editOpen}
        handleClose={() => setEditOpen(false)}
      />
    </>
  );
};

export default ScheduleSelect;
