/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert, Box, Button, ListItem, MenuItem, TextField, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientSelect } from 'components/inputs/clientSelect';
import { StepProps } from '../wizard';
import { RelatedEntityTypes, User, nonIndividualRelationTypes } from '../../../../interfaces';

export function ChooseClientStep({
  context, state, setState, continueTo,
}: StepProps) {
  const { t } = useTranslation('addRelatedEntityWizard');
  const [alreadyRelated, setAlreadyRelated] = useState(false);

  /* check: is the chosen entity already related to this client? */
  useEffect(() => {
    if (state.newEntity && state.newEntity.id) {
      const isTheSame = state.newEntity.id === context.userId;
      const isAmongRelated = context.existingRelatedEntities.find((e) => e.entity.id === state.newEntity?.id) !== undefined;
      setAlreadyRelated(isTheSame || isAmongRelated);
    }
  }, [state.newEntity]);

  return (
    <Box sx={{
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box>
        <ListItem>
          <Typography variant='wizardSubTitle'>{t('title')}</Typography>
        </ListItem>
        <ListItem>
          <ClientSelect
            label={t('shared:entity')}
            autoFocus
            user={state.newEntity}
            setUser={(newValue) => setState({ ...state, newEntity: newValue as User })}
          />
        </ListItem>
        <ListItem>
          <TextField select fullWidth
            label={t('relationshipTo', { whom: context.name })}
            value={state.relation}
            onChange={(event) => setState({ ...state, relation: event.target.value as RelatedEntityTypes })}
          >
            {nonIndividualRelationTypes.map((relationType, index) => (
              <MenuItem key={index} value={relationType}>
                {t(`relatedEntityTypes:${relationType}`)}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
      </Box>

      <Box m={2}>
        {alreadyRelated && (
          <Alert severity="error">
            {t('alreadyRelatedTo')} {t('thisClient')}
          </Alert>
        )}
      </Box>

      <Box m={2}>
        <Button disabled={!state.newEntity || !state.relation || alreadyRelated} type='submit'
          onClick={() => continueTo('SUMMARY')} fullWidth variant='contained'>
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
}
