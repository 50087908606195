import {
  ListItem, MenuItem, TextField,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectFieldEditor } from 'ovComponents/4-module/configurableOptionFields/basicOrSelectField';
import CreateNewModal from '../../../components/modals/createNewModal';
import TranslatableString from '../../../components/inputs/translatableString';
import {
  CUSTOM_FIELDS_FORMAT_MAP,
  CUSTOM_FIELDS_WORKFLOW_STEP_MAP,
  CustomFieldFormats,
  CustomFieldObjectTypes,
  CustomFieldTypes,
  CustomFieldWorkflowSteps,
} from '../../../interfaces/customField';
import { FETCH_CUSTOM_FIELDS } from './customFields';

const CREATE_CUSTOM_FIELD = gql`
  mutation createCustomField($input: CreateCustomFieldInput!) {
    createCustomField(input: $input) {
      customField {
        id
      }
    }
  }
`;

export const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_]+$/;

const NewCustomField = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg?: string }) => {
  const { t } = useTranslation(['orgSettings']);
  const [allOptions, setAllOptions] = useState([{
    displayText: {
      en: '',
      fr: '',
    },
    value: '',
  }]);
  const initialCustomField = {
    format: '',
    type: '',
    key: '',
    objectType: '',
    translatedDescription: {
      en: '',
      fr: '',
    },
    translatedName: {
      en: '',
      fr: '',
    },
    workflowStep: '',
    organizationId: defaultOrg ?? '',
  };
  const initialOptions = [{
    value: {
      selectField: {
        options: [{
          value: '',
          displayText: { fr: '', en: '' },
        }],
        checked: true,
      },
    },
    displayText: { fr: '', en: '' },
  }];
  const [customField, setCustomField] = useState(initialCustomField);
  const [options, setOptions] = useState<any[]>(initialOptions);
  useEffect(() => {
    const val: any = options[0].value;
    setAllOptions(val.selectField?.options ?? []);
  }, [options, setAllOptions]);
  const [createCustomField, { loading }] = useMutation(CREATE_CUSTOM_FIELD, {
    variables: {
      input: {
        ...customField,
        selectOptions: [CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes) ? allOptions.map((opt: any) => ({
          displayText: {
            en: opt?.displayText?.en,
            fr: opt?.displayText?.fr,
          },
          value: opt.value,
        })) : undefined,
        translatedDescription: (customField?.translatedDescription?.en || customField?.translatedDescription?.fr) ? customField?.translatedDescription : undefined,
        workflowStep: customField.workflowStep || undefined,
      },
    },
    refetchQueries: [FETCH_CUSTOM_FIELDS],
  });

  const create = async (event: any) => {
    await createCustomField();
    setCustomField(initialCustomField);
    setOptions(initialOptions);
    afterCreate();
  };
  const checkOptionsAvailable = () => {
    if ([CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes)) {
      if (allOptions && allOptions.length > 0) {
        if (allOptions[0].value) return true;
      }
      return false;
    }
    return true;
  };
  const disabled = !customField.translatedName
    || !customField.organizationId
    || !customField.format
    || !customField.objectType
    || !customField.type
    || !customField.key
    || !checkOptionsAvailable();
  return (
    <CreateNewModal loading={loading} disabled={disabled} title={t('customFieldModal.title')} onSubmit={create} sx={{ float: 'right', m: 1 }} maxWidth='xs'>
      <ListItem>
        <TextField
          fullWidth
          value={customField?.key}
          label={t('customFieldModal.key')}
          onChange={(e) => setCustomField({ ...customField, key: e.target.value.toLowerCase().trim() })}
          helperText={t('customFieldModal.keyHelp')}
          required={true}
          placeholder={t('customFieldModal.keyPlaceholder')}
          onKeyDown={(event) => {
            if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </ListItem>
      <ListItem>
        <TranslatableString
          onChange={(value) => setCustomField({ ...customField, translatedName: value })}
          value={customField.translatedName || {}}
          label={t('customFieldModal.translatedName')}
          rows={3}
          required={true}
        />
      </ListItem>
      <ListItem>
        <TranslatableString
          onChange={(value) => setCustomField({ ...customField, translatedDescription: value })}
          value={customField.translatedDescription || {}}
          label={t('customFieldModal.translatedDescription')}
          rows={4}
        />
      </ListItem>
      <ListItem>
        <TextField
          fullWidth
          select
          data-testid="customFieldType"
          value={customField.type}
          required={true}
          label={t('customFieldModal.type')}
          onChange={(e) => setCustomField({ ...customField, type: e.target.value })}
        >
          <MenuItem value='BOOLEAN' key='BOOLEAN'>{t('customFieldModal.types.BOOLEAN')}</MenuItem>
          <MenuItem value='CUSTOM_OBJECT' key='CUSTOM_OBJECT'>{t('customFieldModal.types.CUSTOM_OBJECT')}</MenuItem>
          <MenuItem value='DATE' key='DATE'>{t('customFieldModal.types.DATE')}</MenuItem>
          <MenuItem value='DECIMAL' key='DECIMAL'>{t('customFieldModal.types.DECIMAL')}</MenuItem>
          <MenuItem value='INTEGER' key='INTEGER'>{t('customFieldModal.types.INTEGER')}</MenuItem>
          <MenuItem value='MULTIPLE_SELECT' key='MULTIPLE_SELECT'>{t('customFieldModal.types.MULTIPLE_SELECT')}</MenuItem>
          <MenuItem value='SINGLE_SELECT' key='SINGLE_SELECT'>{t('customFieldModal.types.SINGLE_SELECT')}</MenuItem>
          <MenuItem value='TEXT' key='TEXT'>{t('customFieldModal.types.TEXT')}</MenuItem>
        </TextField>
      </ListItem>
      {customField.type !== '' && (<ListItem>
        <TextField
          fullWidth
          select
          data-testid="formatField"
          value={customField.format}
          required={true}
          label={t('customFieldModal.format')}
          onChange={(e) => setCustomField({ ...customField, format: e.target.value as CustomFieldFormats })}
        >
          {CUSTOM_FIELDS_FORMAT_MAP[(customField.type as CustomFieldTypes)].map((field: string) => (
            <MenuItem value={field} key={field}>{t(`customFieldModal.formats.${field}`)}</MenuItem>
          ))}
        </TextField>
      </ListItem>)}
      {[CustomFieldTypes.MULTIPLE_SELECT, CustomFieldTypes.SINGLE_SELECT].includes(customField.type as CustomFieldTypes) && (<>
        <ListItem>
          {options.map((x: any, i: number) => (
            <SelectFieldEditor
              option={x} key={i}
              options={options} setOptions={setOptions}
              i={i} enableTranslation
              displayTextLabel={t('customFieldModal.displayText')}
            />
          ))}
        </ListItem>
      </>)}
      <ListItem>
        <TextField
          fullWidth
          select
          data-testid="objectTypeField"
          value={customField.objectType}
          required={true}
          label={t('customFieldModal.objectType')}
          onChange={(e) => setCustomField({ ...customField, objectType: e.target.value })}
        >
          <MenuItem value={CustomFieldObjectTypes.ACCOUNT} key={CustomFieldObjectTypes.ACCOUNT}>{t('customFieldModal.objectTypes.ACCOUNT')}</MenuItem>
          <MenuItem value={CustomFieldObjectTypes.ENTITY} key={CustomFieldObjectTypes.ENTITY}>{t('customFieldModal.objectTypes.ENTITY')}</MenuItem>
          <MenuItem value={CustomFieldObjectTypes.GOAL} key={CustomFieldObjectTypes.GOAL}>{t('customFieldModal.objectTypes.GOAL')}</MenuItem>
          <MenuItem value={CustomFieldObjectTypes.HOUSEHOLD} key={CustomFieldObjectTypes.HOUSEHOLD}>{t('customFieldModal.objectTypes.HOUSEHOLD')}</MenuItem>
          <MenuItem value={CustomFieldObjectTypes.SUB_ACCOUNT} key={CustomFieldObjectTypes.SUB_ACCOUNT}>{t('customFieldModal.objectTypes.SUB_ACCOUNT')}</MenuItem>
        </TextField>
      </ListItem>
      {customField.objectType !== '' && (<ListItem>
        <TextField
          fullWidth
          select
          data-testid="workflowStepField"
          value={customField.workflowStep}
          label={t('customFieldModal.workflowStep')}
          onChange={(e) => setCustomField({ ...customField, workflowStep: e.target.value as CustomFieldWorkflowSteps })}
        >
          {CUSTOM_FIELDS_WORKFLOW_STEP_MAP[(customField.objectType as CustomFieldObjectTypes)].map((workflow: string) => (
            <MenuItem value={workflow} key={workflow}>{t(`customFieldModal.workflowOptions.${workflow}`)}</MenuItem>
          ))}
        </TextField>
      </ListItem>)}
    </CreateNewModal>
  );
};

export default NewCustomField;
