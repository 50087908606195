import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Pagination, Tooltip, Chip,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { translateBackend } from '../../../assets/i18n/config';
import NewCustomField from './newCustomField';
import { CustomField } from '../../../interfaces/customField';
import EditCustomField from './editCustomField';

export const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields($input: FetchCustomFieldsInput!) {
    fetchCustomFields(input: $input) {
      customFields {
        id
        format
        key
        objectType
        selectOptions {
          displayText { en fr }
          value
        }
        state
        translatedDescription { en fr }
        type
        translatedName { en fr }
        organization {
          name
          id
        }
        workflowStep
      }
    }
  }
`;

const CustomFields = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 20;
  const { t } = useTranslation(['orgSettings', 'shared']);
  const [selectedCustomField, setSelectedCustomField] = useState<CustomField>();

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_CUSTOM_FIELDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: 50 },
      },

    },
    fetchPolicy: 'no-cache',
  });

  if (error) (<Typography>Error</Typography>);
  const isInheirited = (customField: CustomField) => customField.organization?.id !== activeOrganization.id;

  return (
    <>
      <Box sx={{ m: '-24px -24px' }}>
        {loading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ m: 100 }} />
          </Box>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12}>
                {permissions.includes('write:custom_fields') && (
                  <NewCustomField afterCreate={refetch} defaultOrg={activeOrganization.id} />
                )}
              </Grid>
            </Grid>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                <TableCell><Typography variant='overline'>{t('customFieldTable.key')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('customFieldTable.fieldLabel')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('customFieldTable.inputFormat')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('customFieldTable.assiciateTo')}</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.fetchCustomFields?.customFields?.map((customField: CustomField) => (
                  <TableRow
                    key={customField.id}
                    sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => {
                      setSelectedCustomField(customField);
                      setUpdateDialogOpen(true);
                    }}
                  >
                    <TableCell component="th" scope="row">{customField.key}</TableCell>
                    <TableCell>{translateBackend(customField.translatedName)}</TableCell>
                    <TableCell>{t(`customFieldModal.formats.${customField.format}`)}</TableCell>
                    <TableCell>{t(`customFieldModal.objectTypes.${customField.objectType}`)}</TableCell>
                    <TableCell>{isInheirited(customField) ? (<Tooltip title={t('shared:inheritedTooltip', { model: 'Custom Field', organization: customField.organization?.name })}>
                      <Chip size='medium' label={t('shared:inheritedFlag')} />
                    </Tooltip>) : (customField.organization?.name)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchPageConfigurations?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </>
        )}
      </Box>
      {selectedCustomField && (
        <EditCustomField
          open={updateDialogOpen}
          customFieldToUpdate={selectedCustomField}
          afterUpdate={() => {
            setUpdateDialogOpen(false);
            refetch();
          }}
          handleClose={() => setUpdateDialogOpen(false)}
          inherited={isInheirited(selectedCustomField)}
        />
      )}
    </>
  );
};

export default CustomFields;
