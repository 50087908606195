/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  List, ListItem, ListItemButton, ListItemText, Drawer,
  Box, Collapse, Grid, ListItemIcon,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import convert from 'color-convert';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import PeopleIcon from '@mui/icons-material/People';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InsightsIcon from '@mui/icons-material/Insights';
import { usePermissions, UserContext } from '../../providers/userContextProvider';

import { useGlobalStats } from '../../providers/globalStatsHooks';
import NavOrganizationSelect from './navOrganizationSelect';
import NotificationChip from '../misc/notificationChip';
import { ovAnalyticsEvents } from '../../util/analytics/analytics';
import { AnalyticsContext } from '../../providers/analyticsProvider';

const drawerWidth = 344;

interface SubSection {
  name: string,
  path: string,
  accessKey: string,
  permission?: string,
  stats?: 'flagsCount'
  | 'subAccountsToReviewCount'
  | 'complianceReviewCount'
  | 'alertsCount' | 'statementsToReviewCount' | 'annualSuitabilityReviewCount' | 'bankAccountAwaitingReviewCount' | 'institutionAwaitingReviewCount',
}
interface Section {
  name: string,
  icon: React.ReactElement,
  subSections: SubSection[],
}
const staticSections:Section[] = [
  {
    name: 'menu.workflows',
    icon: <InsightsIcon fontSize='small' />,
    subSections: [
      {
        name: 'menu.workflows',
        path: '/workflows',
        accessKey: 'WORKFLOWS',
      },
    ],
  },
  {
    name: 'menu.clientManagement',
    icon: <PeopleIcon fontSize='small' />,
    subSections: [
      {
        name: 'menu.households',
        path: '/households',
        accessKey: 'HOUSEHOLDS',
      },
      {
        /* superseded by INDIVIDUAL_CLIENTS, TODO: remove this once OV-8830 finished */
        name: 'menu.individuals',
        path: '/clients',
        accessKey: 'CLIENTS',
      },
      {
        name: 'menu.individuals',
        path: '/clients',
        accessKey: 'INDIVIDUAL_CLIENTS',
      },
      {
        /* superseded by NON_INDIVIDUAL_CLIENTS, TODO: remove this once OV-8830 finished */
        name: 'menu.corporations',
        path: '/corporations',
        accessKey: 'CORPORATIONS',
      },
      {
        name: 'menu.nonIndividualClients',
        path: '/nonIndividualClients',
        accessKey: 'NON_INDIVIDUAL_CLIENTS',
      },
    ],
  },
  {
    name: 'menu.portfolioManagement',
    icon: <DonutSmallIcon fontSize='small' />,
    subSections: [
      {
        name: 'menu.modelPortfolios',
        path: '/buildModels',
        accessKey: 'BUILD_MODELS',
      },
      {
        name: 'menu.modelPortfoliosV2',
        path: '/modelPortfolios',
        accessKey: 'MODEL_PORTFOLIOS',
      },
      {
        name: 'menu.productShelfPage',
        path: '/productShelf',
        accessKey: 'PRODUCT_SHELF',
      },
      {
        name: 'menu.assetClassesPage',
        path: '/assetClasses',
        accessKey: 'ASSET_CLASSES',
      },
      {
        name: 'menu.tradingSchedules',
        path: '/schedules',
        accessKey: 'SCHEDULES',
      },
    ],
  },
  {
    name: 'menu.trading',
    icon: <SsidChartIcon fontSize='small' />,
    subSections: [
      {
        name: 'menu.rebalancing',
        path: '/rebalances',
        accessKey: 'REBALANCING',
      },
      {
        name: 'menu.rebalanceReport',
        path: '/rebalanceReport',
        accessKey: 'REBALANCE_REPORT',
      },
      {
        name: 'menu.portfolioOptimizer',
        path: '/portfolioOptimizers',
        accessKey: 'PORTFOLIO_OPTIMIZER',
      },
      {
        name: 'menu.bulkTrader',
        path: '/bulkTrader',
        accessKey: 'BULK_TRADER',
      },
      {
        name: 'menu.taxLossHarvesting',
        path: '/taxLossHarvesting',
        accessKey: 'TAX_LOSS_HARVESTING',
      },
    ],
  },
  {
    name: 'menu.compliance',
    icon: <SummarizeIcon fontSize='small' />,
    subSections: [
      {
        name: 'menu.accountsReview',
        path: '/accountsReview',
        accessKey: 'ACCOUNTS_REVIEW',
        stats: 'subAccountsToReviewCount',
      },
      {
        name: 'menu.complianceReview',
        path: '/complianceReview',
        accessKey: 'COMPLIANCE_REVIEW',
        stats: 'complianceReviewCount',
      },
      {
        name: 'menu.suspiciousTransfers',
        path: '/suspiciousTransfers',
        accessKey: 'SUSPICIOUS_TRANSACTIONS',
        stats: 'alertsCount',
      },
      {
        name: 'menu.transactions',
        path: '/transactions',
        accessKey: 'TRANSACTIONS',
      },
      {
        name: 'menu.elderlyClients',
        path: '/elderlyClients',
        accessKey: 'ELDERLY_CLIENTS',
      },
      {
        name: 'menu.annualSuitabilityReview',
        path: '/annualSuitabilityReview',
        accessKey: 'ANNUAL_SUITABILITY_REVIEW',
        stats: 'annualSuitabilityReviewCount',
      },
      {
        name: 'menu.bankAccountsToReview',
        path: '/BankAccountsToReview',
        accessKey: 'BANK_ACCOUNTS_TO_REVIEW',
        stats: 'bankAccountAwaitingReviewCount',
      },
      {
        name: 'menu.institutionsToReview',
        path: '/institutionsToReview',
        accessKey: 'INSTITUTIONS_TO_REVIEW',
        stats: 'institutionAwaitingReviewCount',
      },
    ],
  },
  {
    name: 'menu.reports',
    icon: <AssessmentIcon fontSize='small' />,
    subSections: [
      {
        name: 'menu.reports',
        path: '/reports',
        accessKey: 'REPORTS',
      },
    ],
  },
  {
    name: 'menu.feeAndBilling',
    icon: <MonetizationOnIcon fontSize='small' />,
    subSections: [
      {
        name: 'menu.billingManagement',
        path: '/billingManagement',
        accessKey: 'BILLING_MANAGEMENT',
      },
      {
        name: 'menu.feeGrids',
        path: '/feeGrids',
        accessKey: 'FEE_GRIDS',
      },
      {
        name: 'menu.billingSchedules',
        path: '/billingSchedules',
        accessKey: 'BILLING_SCHEDULES',
      },
      {
        name: 'menu.revenueShareReport',
        path: '/revenueShareReport',
        accessKey: 'ORGANIZATION_REVENUE_SHARE',
      },
    ],
  },
  {
    name: 'menu.systemManagement',
    icon: <SettingsIcon fontSize='small' />,
    subSections: [
      {
        name: 'menu.reconciliation',
        path: '/reconciliation',
        accessKey: 'RECONCILIATION',
        stats: 'flagsCount',
      },
      {
        name: 'menu.statementsReview',
        path: '/statementsReview',
        accessKey: 'STATEMENTS_REVIEW',
        stats: 'statementsToReviewCount',
      },
      {
        name: 'menu.bulkImport',
        path: '/bulkImport',
        accessKey: 'BULK_IMPORT',
      },
      {
        name: 'menu.newsAndInsights',
        path: '/newsAndInsights',
        permission: 'read:news_and_insights',
        accessKey: 'NEWS_AND_INSIGHT',
      },
      {
        name: 'menu.devSettings',
        path: '/devSettings',
        accessKey: 'DEVELOPER_SETTINGS',
      },
      {
        name: 'menu.orgSettings',
        path: '/orgSettings',
        accessKey: 'ORGANIZATION_SETTINGS',
      },
    ],
  },
];

export const useMenuSections = () => {
  const { userContext } = useContext(UserContext);

  const dashboardsSection = {
    name: 'menu.dashboard',
    icon: <HomeIcon fontSize='small' />,
    subSections: userContext.role?.dashboards?.map((x: any) => ({
      name: x.name,
      path: `/dashboard/${x.id}`,
      accessKey: 'HOME',
    })) ?? [],
  };

  return [dashboardsSection, ...staticSections];
};

const NavMenu = (props: any) => {
  const { activeOrganization, setActiveOrganizationId, userContext } = useContext(UserContext);
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { accessiblePages, permissions } = usePermissions();
  const globalStats = useGlobalStats();
  const { t } = useTranslation(['navMenu']);
  const location = useLocation();
  const navigate = useNavigate();
  const { window } = props;
  const { mobileOpen } = props;
  const { setMobileOpen } = props;
  const [sectionsOpen, setSectionsOpen] = useState<any[]>([]);

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleAnalytics = (name: string) => {
    switch (name) {
      case 'Organization': {
        sendAnalytic(ovAnalyticsEvents.dashboardOrganizationSelect);
        break;
      }
      case 'Basic': {
        sendAnalytic(ovAnalyticsEvents.dashboardBasicSelect);
        break;
      }
      case 'Portfolio Manager':
      case 'Portfolio Manager v2':
      case 'Portfolio Manager v3': {
        sendAnalytic(ovAnalyticsEvents.dashboardPortfolioManagerSelect);
        break;
      }
      case 'Compliance': {
        sendAnalytic(ovAnalyticsEvents.dashboardComplianceSelect);
        break;
      }
      case 'Operations': {
        sendAnalytic(ovAnalyticsEvents.dashboardOperationsSelect);
        break;
      }
      case 'menu.individuals': {
        sendAnalytic(ovAnalyticsEvents.clientManagementClientsSelect);
        break;
      }
      case 'menu.households': {
        sendAnalytic(ovAnalyticsEvents.clientManagementHouseholdSelect);
        break;
      }
      case 'menu.corporations': {
        // superseded by clientManagementNonIndividualClientsSelect (below), TODO: remove this once OV-8830 finished
        sendAnalytic(ovAnalyticsEvents.clientManagementCorporationsSelect);
        break;
      }
      case 'menu.nonIndividualClients': {
        sendAnalytic(ovAnalyticsEvents.clientManagementNonIndividualClientsSelect);
        break;
      }
      case 'menu.modelPortfolios': {
        sendAnalytic(ovAnalyticsEvents.portfolioManagementModelPortfoliosSelect);
        break;
      }
      case 'menu.productShelfPage': {
        sendAnalytic(ovAnalyticsEvents.portfolioManagementProductShelfSelect);
        break;
      }
      case 'menu.assetClassesPage': {
        sendAnalytic(ovAnalyticsEvents.portfolioManagementAssetClassesSelect);
        break;
      }
      case 'menu.tradingSchedules': {
        sendAnalytic(ovAnalyticsEvents.portfolioManagementTradingSchedulesSelect);
        break;
      }
      case 'menu.rebalancing': {
        sendAnalytic(ovAnalyticsEvents.tradingRebalancingSelect);
        break;
      }
      case 'menu.rebalanceReportV1': {
        sendAnalytic(ovAnalyticsEvents.tradingRebalanceReportV1Select);
        break;
      }
      case 'menu.rebalanceReport': {
        sendAnalytic(ovAnalyticsEvents.tradingRebalanceReportV1Select);
        break;
      }
      case 'menu.portfolioOptimizer': {
        sendAnalytic(ovAnalyticsEvents.tradingPortfolioOptimizerSelect);
        break;
      }
      case 'menu.bulkTrader': {
        sendAnalytic(ovAnalyticsEvents.tradingBulkTraderSelect);
        break;
      }
      case 'menu.bulkImport': {
        sendAnalytic(ovAnalyticsEvents.systemManagementBulkImportSelect);
        break;
      }
      case 'menu.taxLossHarvesting': {
        sendAnalytic(ovAnalyticsEvents.tradingTLHSelect);
        break;
      }
      case 'menu.accountsReview': {
        sendAnalytic(ovAnalyticsEvents.complianceAccountsReviewSelect);
        break;
      }
      case 'menu.complianceReview': {
        sendAnalytic(ovAnalyticsEvents.complianceComplianceReviewSelect);
        break;
      }
      case 'menu.suspiciousTransfers': {
        sendAnalytic(ovAnalyticsEvents.complianceSuspiciousTransfersSelect);
        break;
      }
      case 'menu.transactions': {
        sendAnalytic(ovAnalyticsEvents.complianceAllTransactionsSelect);
        break;
      }
      case 'menu.elderlyClients': {
        sendAnalytic(ovAnalyticsEvents.complianceElderlyClientsSelect);
        break;
      }
      case 'menu.annualSuitabilityReview': {
        sendAnalytic(ovAnalyticsEvents.complianceAnnualProfileUpdateSelect);
        break;
      }
      case 'menu.bankAccountsToReview': {
        sendAnalytic(ovAnalyticsEvents.complianceNewBankAccountApprovalSelect);
        break;
      }
      case 'menu.institutionsToReview': {
        sendAnalytic(ovAnalyticsEvents.complianceInstitutionsApprovalSelect);
        break;
      }
      case 'menu.reports': {
        sendAnalytic(ovAnalyticsEvents.reportsSelect);
        break;
      }
      case 'menu.feeGrids': {
        sendAnalytic(ovAnalyticsEvents.feesAndBillingFeeGridSelect);
        break;
      }
      case 'menu.billingSchedules': {
        sendAnalytic(ovAnalyticsEvents.feesAndBillingBillingSchedulesSelect);
        break;
      }
      case 'menu.revenueShareReport': {
        sendAnalytic(ovAnalyticsEvents.feesAndBillingRevenueShareSelect);
        break;
      }
      case 'menu.billingManagement': {
        sendAnalytic(ovAnalyticsEvents.feesAndBillingIncurredFeeSelect);
        break;
      }
      case 'menu.reconciliation': {
        sendAnalytic(ovAnalyticsEvents.systemManagementReconciliationSelect);
        break;
      }
      case 'menu.statementsReview': {
        sendAnalytic(ovAnalyticsEvents.systemManagementStatementsReviewSelect);
        break;
      }
      case 'menu.devSettings': {
        sendAnalytic(ovAnalyticsEvents.systemManagementIntegrationSettingsSelect);
        break;
      }
      case 'menu.orgSettings': {
        sendAnalytic(ovAnalyticsEvents.systemManagementOrganizationSettingsSelect);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleDrawerClick = (name: string) => {
    handleAnalytics(name);
    setMobileOpen(false);
  };

  const finalSections = useMenuSections();

  useEffect(() => {
    const initiallyOpenSections: any[] = Array(finalSections.length).fill(false);
    finalSections.forEach((sec, idx) => {
      if (sec.subSections && sec.subSections.length > 1) {
        const matchingPaths = sec.subSections.filter((subsec) => location.pathname.includes(subsec.path));
        if (matchingPaths.length > 0) {
          initiallyOpenSections[idx] = true;
        }
      }
    });
    setSectionsOpen(initiallyOpenSections);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [userContext.role?.dashboards]);

  const textColor = () => {
    if (convert.hex.hsl(activeOrganization.theme?.sideBarColor || '#22394F')[2] < 80) {
      return 'white';
    }

    return 'black';
  };

  const hoverColor = () => {
    const hsl = convert.hex.hsl(activeOrganization.theme?.sideBarColor || '#22394F');
    if (hsl[2] >= 15) {
      hsl[2] -= 10;
    } else {
      hsl[2] += 15;
    }

    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
  };

  const buttonStyle = {
    padding: '8px 24px',
    color: textColor(),
    '&:hover,&.Mui-selected': { backgroundColor: hoverColor() },
    '&:hover&.Mui-selected': { backgroundColor: hoverColor() },
  };

  const pageIsAccessible = (key:string):boolean => accessiblePages.includes(key);
  const hasPermissionToViewPage = (permission?: string):boolean => {
    if (permission) {
      return permissions.includes(permission);
    }
    return true;
  };

  const displayStats = (s: SubSection) => !!(s.stats && globalStats[s.stats] && globalStats[s.stats] > 0);

  const displaySection = (section:Section, index: number, showSection = false) => {
    const subSections = section.subSections.filter((x: SubSection) => (pageIsAccessible(x.accessKey) && hasPermissionToViewPage(x.permission)));
    if (subSections.length === 0) {
      return false;
    }
    if (subSections.length === 1) {
      const s0 = subSections[0];
      return (
        <ListItem key={t(s0.name) as string} component={Link} to={s0.path} onClick={() => handleDrawerClick(s0.name)} disablePadding secondaryAction={ displayStats(s0) && (
          <NotificationChip label={globalStats[s0.stats!].toString()} />
        )}>
          <ListItemButton
            selected={s0.path === '/' ? s0.path === location.pathname : location.pathname.startsWith(s0.path)}
            sx={buttonStyle}
          >
            <ListItemIcon sx={{ minWidth: 32, color: textColor() }}>{ section.icon }</ListItemIcon>
            <ListItemText primary={showSection ? t(section.name) : t(s0.name)} sx={{ color: textColor() }} />
          </ListItemButton>
        </ListItem>
      );
    }
    return (
      <span key={section.name}>
        <ListItemButton onClick={() => setSectionsOpen(sectionsOpen.map((val, idx) => ((idx === index) ? !val : val))) } sx={buttonStyle}>
          <ListItemIcon sx={{ minWidth: 32, color: textColor() }}>{ section.icon }</ListItemIcon>
          <ListItemText primary={t(section.name)} sx={{ color: textColor() }} />
          {sectionsOpen[index] ? <ExpandLess sx={{ color: textColor() }}/> : <ExpandMore sx={{ color: textColor() }}/>}
        </ListItemButton>
        <Collapse in={sectionsOpen[index]} timeout="auto" unmountOnExit>
          <List component="div" sx={{ padding: 0 }}>
            { section.subSections.filter((y: SubSection) => pageIsAccessible(y.accessKey) && hasPermissionToViewPage(y.permission)).map((sub: SubSection) => (
              <ListItem disablePadding key={sub.name} component={Link} to={sub.path} onClick={() => handleDrawerClick(sub.name)} secondaryAction={ displayStats(sub) && (
                <NotificationChip label={globalStats[sub.stats!].toString()} />
              )}>
                <ListItemButton
                  sx={{ ...buttonStyle }}
                  selected={sub.path === '/' ? sub.path === location.pathname : location.pathname.startsWith(sub.path)}
                >
                  <ListItemText primary={t(sub.name)} sx={{ color: textColor(), pl: 4 }} primaryTypographyProps={{ sx: { fontSize: '14px !important' } }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </span>
    );
  };

  const drawer = (
    <Grid container direction='column' justifyContent='space-between' sx={{ height: '100%', overflowX: 'hidden', flexWrap: 'inherit' }}>
      <Grid item>
        <List disablePadding>
          <NavOrganizationSelect value={activeOrganization.id!}
            onChange={(e) => {
              setActiveOrganizationId(e);
              const firstAccessiblePath = accessiblePages[0].toLocaleLowerCase();
              navigate(`/${firstAccessiblePath}`);
            }}
            textColor={textColor()} backgroundColor={hoverColor()}
          />
          {finalSections.map((section, index) => (
            <Box key={section.name}>
              { displaySection(section, index) }
            </Box>
          ))}
        </List>
      </Grid>
    </Grid>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: activeOrganization.theme?.sideBarColor || '#22394F' },

        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: activeOrganization.theme?.sideBarColor || '#22394F' },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default NavMenu;
