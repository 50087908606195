import { TranslatedString } from './shared';

export enum FinancialProductState {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum Exchanges {
  TSX = 'TSX',
  NASDAQ = 'NASDAQ',
  NYSE = 'NYSE',
  FUNDSERV = 'FUNDSERV',
  PTF = 'PTF',
  PRIVATE_SECURITY = 'PRIVATE_SECURITY',
  CANNEX = 'CANNEX',
  CBOE = 'CBOE',
  CME = 'CME',
  CHX = 'CHX',
  ISE = 'ISE',
  PHLX = 'PHLX',
  MS4X = 'MS4X',
  TSX_V = 'TSX_V',
}

export const EXCHANGE_COUNTRIES = {
  US: 'US',
  CA: 'CA',
};

export const EXCHANGES_COUNTRIES_MAP = {
  [Exchanges.NASDAQ.toString()]: EXCHANGE_COUNTRIES.US,
  [Exchanges.NYSE.toString()]: EXCHANGE_COUNTRIES.US,
  [Exchanges.FUNDSERV.toString()]: EXCHANGE_COUNTRIES.CA,
  [Exchanges.CANNEX.toString()]: EXCHANGE_COUNTRIES.CA,
  [Exchanges.CBOE.toString()]: EXCHANGE_COUNTRIES.US,
  [Exchanges.CME.toString()]: EXCHANGE_COUNTRIES.US,
  [Exchanges.CHX.toString()]: EXCHANGE_COUNTRIES.US,
  [Exchanges.ISE.toString()]: EXCHANGE_COUNTRIES.US,
  [Exchanges.PHLX.toString()]: EXCHANGE_COUNTRIES.US,
  [Exchanges.MS4X.toString()]: EXCHANGE_COUNTRIES.US,
  [Exchanges.TSX.toString()]: EXCHANGE_COUNTRIES.CA,
  [Exchanges.PTF.toString()]: EXCHANGE_COUNTRIES.CA,
  [Exchanges.PRIVATE_SECURITY.toString()]: EXCHANGE_COUNTRIES.CA,
  [Exchanges.TSX_V.toString()]: EXCHANGE_COUNTRIES.CA,
};

export enum ThemeState {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export interface Theme {
  id?: string
  name?: string
  description?: string
  translatedName?: TranslatedString
  translatedDescription?: TranslatedString
  translatedShortDescription?: TranslatedString
  key?: string
  core?: boolean
  state?: ThemeState
  iconKey?: string
}

export interface AssetClass {
  id?: string
  key?: string
  name?: string
  description?: string
  translatedName?: TranslatedString
  translatedDescription?: TranslatedString
  state?: string;
}

export interface Branch {
  id?: string
  percentage: number
  financialProduct: FinancialProduct
}

export interface TargetAllocation {
  secondaryAssetClass: AssetClass
  min: number
  max: number
}

export interface FinancialProduct {
  id?: string
  name?: string
  url?: string
  translatedName?: TranslatedString
  translatedPortfolioDisclaimer?: TranslatedString
  state?: FinancialProductState
  suggestedMinScore?: number
  suggestedMaxScore?: number
  lowThresholdScore?: number
  tradeable?: boolean
  suggestable?: boolean
  autoUpdatePrices?: boolean
  assignable?: boolean
  ticker?: string | null
  exchange?: Exchanges
  ric?: string
  forecastedRateOfReturn?: number
  forecastedStandardDeviation?: number
  currentPriceCents?: number
  children?: Branch[]
  theme?: Theme
  primaryAssetClass?: AssetClass
  secondaryAssetClass?: AssetClass
  tertiaryAssetClass?: AssetClass
  portfolioDescription?: string
  translatedPortfolioDescription?: TranslatedString
  investmentObjective?: string
  translatedInvestmentObjective?: TranslatedString
  translatedLiquidity?: TranslatedString
  targetAllocations?: TargetAllocation[]
  updatedAt?: Date
  riskReturnLevel?: number
  reasonForUpdate?: string
  translatedReasonForUpdate?: TranslatedString
  isCash?: boolean
  substituteFinancialProduct?: FinancialProduct
  isPartial?: boolean
  taxRank?: number
  settlementDays?: number
}
