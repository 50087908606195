import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Typography, Box, Skeleton,
} from '../../../1-primative';
import {
  Table,
  TableRow, TableCell, TableBody, Pagination,
  Dialog, DialogTitle, Switch,
  Card, CardContent, TableHeadCell, Badge,
  IconButton,
} from '../../../2-component';
import { CopyId, FilterModal } from '../../../3-pattern';
import { PageObjectType } from '../../../5-page';
import { usePageState } from '../../../../util/usePageState';
import { usePermissions } from '../../../../providers/userContextProvider';
import { useLocalization } from '../../../../util/useLocalization';
import { translateBackend } from '../../../../assets/i18n/config';

export const FETCH_USER_AUDIT_LOG = (permissions: string[]) => gql`
  query fetchUserAuditLog($userId: ObjectID!, $input: UserLogsInput!) {
    fetchUser(userId: $userId) {
      user {
        logs(input: $input) {
          logs {
            id
            type
            initiator
            organizationUser {
              firstName
              lastName
            }
            ${permissions.includes('read:api_tokens') ? 'apiToken { id name organizationUser { firstName lastName } }' : ''}
            objectType
            changes
            timestamp
            traceId
          }
          totalCount
        }
      }
    }
  }
`;

const FETCH_CLIENT_GROUP_AUDIT_LOG = (permissions: string[]) => gql`
  query fetchClientGroupAuditLog($clientGroupId: ObjectID!, $input: ClientGroupLogsInput!) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup {
        logs(input: $input) {
          logs {
            id
            type
            initiator
            organizationUser {
              firstName
              lastName
            }
            ${permissions.includes('read:api_tokens') ? 'apiToken { id name organizationUser { firstName lastName } }' : ''}
            objectType
            changes
            timestamp
            traceId
          }
          totalCount
        }
      }
    }
  }
`;

export const AuditLog = (
  { objectId, objectType, options }: { objectId: string, objectType: PageObjectType, options: any },
) => {
  const { t } = useTranslation(['client', 'household']);
  const { localizedDateTime } = useLocalization();
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'page');
  const [activeLog, setActiveLog] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [objectOnly, setObjectOnly] = usePageState(false, 'oo');
  const [logs, setLogs] = useState<{ logs: any[], totalCount: number }>();

  const pageSize = 15;

  const { loading, error, previousData } = useQuery(FETCH_USER_AUDIT_LOG(permissions), {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    skip: objectType === PageObjectType.HOUSEHOLD,
    variables: {
      userId: objectId,
      input: {
        filter: { userOnly: objectOnly },
        pagination: {
          sortField: 'timestamp', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted(data) {
      if (data) setLogs(data?.fetchUser?.user?.logs);
    },
  });

  const { loading: loading2, error: error2, previousData: previousData2 } = useQuery(FETCH_CLIENT_GROUP_AUDIT_LOG(permissions), {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    skip: objectType !== PageObjectType.HOUSEHOLD,
    variables: {
      clientGroupId: objectId,
      input: {
        filter: { clientGroupOnly: objectOnly },
        pagination: {
          sortField: 'timestamp', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted(data) {
      if (data) setLogs(data?.fetchClientGroup?.clientGroup?.logs);
    },
  });

  const changedBy = (log: any) => {
    if (log.initiator === 'ORGANIZATION_USER') {
      return `${log.organizationUser?.firstName} ${log.organizationUser?.lastName}`;
    }
    if (log.initiator === 'API_TOKEN' && log.apiToken?.name) {
      const tokenCreator = log.apiToken.organizationUser;
      return `${log.apiToken?.name}${tokenCreator ? ` (${tokenCreator.firstName} ${tokenCreator.lastName})` : ''}`;
    }

    return log.initiator;
  };

  const changedText = (log: any) => {
    const keys = Object.keys(log.changes || {});

    if (keys.length === 1) {
      return keys[0];
    }

    if (keys.length === 2) {
      return `${keys[0]} & ${keys[1]}`;
    }

    return `${keys[0]}, ${keys[1]} & ${keys.length - 2} ${t('moreFields')}`;
  };

  if (error || error2) (<Typography>Error</Typography>);
  const label = objectType === PageObjectType.HOUSEHOLD ? t('household:houseHoldChangesOnly') : t('userChangesOnly');
  const isLoading = objectType === PageObjectType.HOUSEHOLD ? loading2 : loading;
  const hasPreviousData = objectType === PageObjectType.HOUSEHOLD ? previousData2 : previousData;

  return (
    <>
      { options.customTitle && (<Typography variant='headingSmall' mb={2}>{translateBackend(options.customTitle)}</Typography>) }
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <FilterModal>
              <Switch checked={objectOnly} onChange={async (e) => {
                setObjectOnly(e);
                setPage(1);
              }} label={label} />
            </FilterModal>
          </Box>
        </CardContent>
        <Table aria-label="table">
          <TableBody>
            <TableRow>
              <TableHeadCell>{t('audit.changedBy')}</TableHeadCell>
              <TableHeadCell>{t('audit.type')}</TableHeadCell>
              <TableHeadCell>{t('audit.changedObject')}</TableHeadCell>
              <TableHeadCell>{t('audit.changedFields')}</TableHeadCell>
              <TableHeadCell>{t('audit.timestamp')}</TableHeadCell>
            </TableRow>
            {isLoading && !hasPreviousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell dense><Skeleton /></TableCell>
                <TableCell dense><Skeleton /></TableCell>
                <TableCell dense><Skeleton /></TableCell>
                <TableCell dense><Skeleton /></TableCell>
                <TableCell dense><Skeleton /></TableCell>
              </TableRow>
            ))}
            {(logs?.logs || []).map((log: any) => (
              <TableRow
                hover
                onClick={(e) => {
                  setActiveLog(log);
                  setOpen(true);
                }}
                selected={log.id === activeLog?.id}
                key={log.id}
              >
                <TableCell dense>{changedBy(log)}</TableCell>
                <TableCell dense><Badge label={t(`clients:auditTypes.${log.type}`)} color={log.type === 'DELETE' ? 'negative' : log.type === 'CREATE' ? 'positive' : 'neutral'} /></TableCell>
                <TableCell dense>{log.objectType}</TableCell>
                <TableCell dense>{changedText(log)}</TableCell>
                <TableCell dense>{localizedDateTime(log.timestamp)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil((logs?.totalCount || 0) / pageSize)}
          page={page}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Card>
      {activeLog && (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md' fullWidth>
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }} alignItems='center'>
              {activeLog.objectType} - {localizedDateTime(activeLog.timestamp)}
              <Box display='flex' justifyContent='end' alignItems='center'>
                <CopyId id={activeLog.traceId} />
                <Box ml={1} />
                <IconButton onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <Table sx={{ display: 'table', overflowX: 'scroll' }} aria-label="table">
            <TableBody>
              <TableRow>
                <TableHeadCell>{t('audit.field')}</TableHeadCell>
                <TableHeadCell>{t('audit.from')}</TableHeadCell>
                <TableHeadCell>{t('audit.to')}</TableHeadCell>
              </TableRow>
              {Object.keys(activeLog.changes || {}).map((key: string) => (
                <TableRow
                  hover
                  key={key}
                >
                  <TableCell>{key}</TableCell>
                  <TableCell>{activeLog.changes[key][0] ? JSON.stringify(activeLog.changes[key][0]) : '-'}</TableCell>
                  <TableCell>{activeLog.changes[key][1] ? JSON.stringify(activeLog.changes[key][1]) : '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Dialog>
      )}
    </>
  );
};

export default AuditLog;
