import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Grid, MenuItem,
} from '@mui/material';
import { Transfer } from 'interfaces';
import { colors } from 'ovComponents/0-tokens';
import {
  Box,
  Typography,
} from 'ovComponents/1-primative';
import {
  Button,
  Dialog,
  Form,
  SelectField,
} from 'ovComponents/2-component';
import { translateBackend } from 'assets/i18n/config';
import ExternalTransferDialogContent, { ExternalTransfer } from './types/externalTransfer/externalTransfer.dialogContent';
import ExternalTransferReviewItem from './types/externalTransfer/externalTransfer.reviewItem';
import BankAccountTransferDialogContent, { BankAccountTransfer } from './types/bankAccountTransfer/bankAccountTransfer.dialogContent';
import BankAccountTransferReviewItem from './types/bankAccountTransfer/bankAccountTransfer.reviewItem';
import SubAccountTransferDialogContent, { SubAccountTransfer } from './types/subAccountTransfer/subAccountTransfer.dialogContent';
import SubAccountTransferReviewItem from './types/subAccountTransfer/subAccountTransfer.reviewItem';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const TransferVisual = ({
  options, userData, isLoading, updateTransfers, transfers, continueFunc, updateUser,
  workflowCompletion,
}: {
  options: any, userData: any, isLoading: boolean, transfers: any, updateTransfers: any, updateUser: any, continueFunc: any,
  workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'accountTypes', 'workflowCompletions']);
  const [open, setOpen] = useState<boolean>(false);
  const [transferSource, setTransferSource] = useState<string>('');
  const openModal = (value = true) => {
    setOpen(value);
  };
  const handleAccountCreated = (transfer: Transfer | BankAccountTransfer | ExternalTransfer | SubAccountTransfer): void => {
    updateTransfers([...transfers, transfer]);
    setTransferSource('');
    setOpen(false);
  };
  const getTransferContent = (value: string): JSX.Element => {
    switch (value) {
      case 'EXTERNAL_TRANSFER_IN': {
        return (
          <ExternalTransferDialogContent
          onDialogClose={() => openModal(false)}
          userData={userData}
          onTransferCreated={(item) => handleAccountCreated(item)}
          disclaimer= {options.enableExternalTransfer?.disclaimer?.en}
          />);
      }
      case 'BANK_ACCOUNT': {
        return (
        <BankAccountTransferDialogContent
        onDialogClose={() => openModal(false)}
        userData={userData}
        onTransferCreated={(item) => handleAccountCreated(item)}
        disclaimer= {options.enableEft?.disclaimer?.en}
        />);
      }
      case 'TRANSFER_TO_SUB_ACCOUNT': {
        return (
        <SubAccountTransferDialogContent
        onDialogClose={() => openModal(false)}
        userData={userData}
        onTransferCreated={(item) => handleAccountCreated(item)}
        disclaimer= {options.enableTransferPortfolio?.disclaimer?.en}
          />);
      }
      default: {
        return <></>;
      }
    }
  };
  const deleteTransfer = (index: number): void => {
    // slice not working for useState array
    const tempTransfer: Transfer[] = [];
    transfers.forEach((item: Transfer, index2: number) => {
      if (index2 !== index) tempTransfer.push(item);
    });
    updateTransfers([...tempTransfer]);
  };
  const getTransferReviewItem = (transfer: Transfer | ExternalTransfer | BankAccountTransfer, index: number): JSX.Element => {
    if ('institution' in transfer || ('source' in transfer && transfer.source === 'EXTERNAL_TRANSFER_IN')) {
      return (<ExternalTransferReviewItem key={index} transfer={transfer} onDeleteTransfer={() => deleteTransfer(index)} />);
    }
    if ('bankAccount' in transfer) {
      return (<BankAccountTransferReviewItem key={index} transfer={transfer} onDeleteTransfer={() => deleteTransfer(index)} />);
    }
    if ('subAccount' in transfer || ('source' in transfer && transfer.source === 'TRANSFER_TO_SUB_ACCOUNT')) {
      return (<SubAccountTransferReviewItem key={index} transfer={transfer} onDeleteTransfer={() => deleteTransfer(index)} />);
    }
    return <></>;
  };
  const transfersToBeCreated = transfers.filter((transfer: Transfer) => !transfer.id);
  const onSubmit = () => {
    sendAnalytic(
      ovAnalyticsEvents.workflowsTransfersContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion.objectType,
      },
    );
    continueFunc();
  };
  return (
    <Form onSubmit={onSubmit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      {transfers.map((item: Transfer, index: number) => getTransferReviewItem(item, index))}
      {transfers.length > 0
        && <Typography variant='headingOverline' sx={{ color: colors.neutral600, mb: 2, mt: 3 }}>{t('workflowCompletions:addAnotherTransfer')}</Typography>
      }
      <Grid
        container
        display='flex'
        flexDirection='row'
        sx={{ mb: 2 }}
        justifyContent='space-between'
        spacing={1}
        alignItems='end'
      >
        <Grid item xs={8}>
          <SelectField
            onChange={(e: any) => setTransferSource(e.target.value)}
            label={t('workflowCompletions:source')}
            fullWidth
            value={transferSource}
          >
            {options?.enableEft?.value && (
              <MenuItem value='BANK_ACCOUNT'>{t('workflowCompletions:sourceOfTransfer.bankAccount')}</MenuItem>
            )}
            {options?.enableExternalTransfer?.value && (
              <MenuItem value='EXTERNAL_TRANSFER_IN'>{t('workflowCompletions:sourceOfTransfer.externalTransferIn')}</MenuItem>
            )
            }
            {options?.enableTransferPortfolio?.value && (
              <MenuItem value='TRANSFER_TO_SUB_ACCOUNT'>{t('workflowCompletions:sourceOfTransfer.transferToSubAccount')}</MenuItem>
            )
            }
          </SelectField>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='outlined'
            onClick={() => openModal()}
            label={t('workflowCompletions:start')}
            sx={{ textAlign: 'center' }}
            type='button'
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="end">
      {
        transfersToBeCreated.length > 0 && transfersToBeCreated.length === 1 ? (
          <Button
            label={t('workflowCompletions:createTransferButtonTitle', { number: transfersToBeCreated.length })}
            sx={{
              mt: 3,
            }}
            type='submit'
          />
        ) : transfersToBeCreated.length > 1 ? (
          <Button
          label={t('workflowCompletions:createTransfersButtonTitle', { number: transfersToBeCreated.length })}
          sx={{
            mt: 3,
          }}
          type='submit'
        />
        ) : undefined
      }
      </Box>
      <Dialog
        sx={{
          '.MuiDialog-container': {
            '.MuiPaper-root': {
              padding: '20px',
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth='sm'
        fullWidth
      >
        {getTransferContent(transferSource)}
      </Dialog>
    </Form>
  );
};

export default TransferVisual;
