import { ClientReportTemplate, ClientReportTemplate as ClientReportTemplateType, ReportVariableMeta } from 'interfaces';
import {
  Box, Skeleton, Stack, Typography,
} from 'ovComponents/1-primative';
import {
  Badge, Button, Card, CardContent, IconButton, Tab, TabContext, TabList, TabPanel, TextField,
} from 'ovComponents/2-component';
import EditIcon from '@mui/icons-material/Edit';
import { translateBackend } from 'assets/i18n/config';
import { useTranslation } from 'react-i18next';
import { usePageState } from 'util/usePageState';
import SimpleCodeEditor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';
import { useEffect, useMemo, useState } from 'react';
import { SelectionTile } from 'ovComponents/3-pattern';
import { useThemeTokens } from 'providers/themeTokenProvider';
import {
  Grid, List, ListItem, ListItemText, SxProps,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ref } from '../../0-tokens/ref';

const editorHeight = 'calc(100vh - 400px)';

export const ClientReportTemplateVisual = ({
  template,
  setTemplate,
  templateHtml,
  setTemplateHtml,
  availableVariables,
  save,
  saving,
}: {
  template?: ClientReportTemplate,
  setTemplate: (newTemplate: ClientReportTemplateType) => void,
  templateHtml?: string,
  setTemplateHtml: (newValue: string) => void,
  availableVariables?: ReportVariableMeta[],
  save: () => void
  saving: boolean
}) => {
  const { t } = useTranslation('clientReportTemplate');
  const [tab, setTab] = usePageState<'editor' | 'frequency' | 'rules'>('editor', 'tab');
  const [formIsValid, setFormIsValid] = useState(true);

  const setFrequency = (newFreq: ClientReportTemplateType['scheduler']['frequency']) => {
    if (!template) return;
    setTemplate({
      ...template,
      scheduler: {
        ...template.scheduler,
        frequency: newFreq,
      },
    });
  };

  const setDay = (newDay: ClientReportTemplateType['scheduler']['dayOfMonthOrWeek']) => {
    if (!template) return;
    setTemplate({
      ...template,
      scheduler: {
        ...template.scheduler,
        dayOfMonthOrWeek: newDay,
      },
    });
  };

  return <>
    <Card>
      <CardContent>
        {template
          ? <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                <Typography variant='titleLarge'>{translateBackend(template.translatedName)}</Typography>
                <Badge label={template.type} color='neutral' />
                {template.state === 'DRAFT' && <Badge label={t('orgSettings:clientReportTemplates.states.DRAFT')} color='negative' />}
                {template.state === 'ACTIVE' && <Badge label={t('orgSettings:clientReportTemplates.states.ACTIVE')} color='neutral' />}
                {template.state === 'INACTIVE' && <Badge label={t('orgSettings:clientReportTemplates.states.INACTIVE')} color='warning' />}
              </Stack>
              <IconButton disabled /* TODO */>
                <EditIcon />
              </IconButton>
            </Box>
            <Typography variant='bodyMedium'>{translateBackend(template.translatedDescription)}</Typography>
          </>
          : <Skeleton height={60} animation='wave' />
        }
      </CardContent>
    </Card>

    <Box style={{ minHeight: '50vh' }}>
      <TabContext value={tab} >
        <TabList onChange={(event, newValue) => { setTab(newValue); }}>
          <Tab value={'editor'} label={t('editor')} />
          <Tab value={'frequency'} label={t('frequency')} />
          <Tab value={'rules'} label={t('rules')} />
        </TabList>
        <Card>
          <CardContent>
            <TabPanel value={'editor'} sx={{ padding: 0 }}>
              {templateHtml !== undefined
                ? <Editor {...{
                  templateHtml, setTemplateHtml, disabled: saving, availableVariables,
                }} />
                : <Skeleton height={editorHeight} animation='wave' />
              }
            </TabPanel>
            <TabPanel value={'frequency'} sx={{ padding: 0 }}>
              {template
                && <Frequency template={template} setFrequency={setFrequency} setDay={setDay} setFormIsValid={setFormIsValid} />
              }
            </TabPanel>
            <TabPanel value={'rules'} sx={{ padding: 0 }}>
              {template
                && <Rules template={template} />
              }
            </TabPanel>
          </CardContent>
        </Card>
      </TabContext>
    </Box>

    <hr />

    <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'end' }}>
      <Button label={t('shared:save')} variant='filled' onClick={save} disabled={!formIsValid || saving} />
      <Button label={t('preview')} variant='tonal' disabled /* TODO */ />
    </Stack>
  </>;
};

export const SearchableListOfVariables = ({ availableVariables }: { availableVariables: ReportVariableMeta[] }) => {
  const { t } = useTranslation('clientReportTemplate');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filteredAvailableVariables = useMemo(
    () => availableVariables.filter((v) => {
      const lcSearchTerm = searchTerm.toLocaleLowerCase();
      return searchTerm === ''
        || v.name.toLocaleLowerCase().indexOf(lcSearchTerm) !== -1
        || v.description.toLocaleLowerCase().indexOf(lcSearchTerm) !== -1;
    }),
    [availableVariables, searchTerm],
  );

  return <Box
    style={{
      height: editorHeight,
      overflow: 'auto',
      fontSize: '66%',
    }}
  >
    <Box style={{ marginLeft: '1em' }}>
      <Typography variant='bodyMedium' align='center'>
        <HelpOutlineIcon fontSize='small' /> &nbsp;
        <span style={{ position: 'relative', top: '-5px' }}>
          {t('variablesAvailableForThisTemplate')}
        </span>
      </Typography>
      <TextField
        leadingIcon='search'
        fullWidth
        value={searchTerm}
        onChange={(e: any) => setSearchTerm(e.target.value)}
      />
    </Box>
    <List>
      {(filteredAvailableVariables ?? []).map((variable) => <ListItem>
        <ListItemText
          primary={<>
            <code style={{ fontSize: '75%' }}>{variable.name}</code> &nbsp;
            <Badge color='neutral' label={variable.type} />
          </>}
          secondary={<small>{variable.description}</small>}
        />
      </ListItem>)}
    </List>
  </Box>;
};

export const Editor = ({
  templateHtml, setTemplateHtml, disabled, availableVariables,
}: {
  templateHtml: string, setTemplateHtml: (newValue: string) => void, disabled: boolean, availableVariables?: ReportVariableMeta[]
}) => <Grid container>
    <Grid item xs={8}>
      <div
        style={{
          height: editorHeight,
          overflow: 'auto',
        }}
      >
        <SimpleCodeEditor
          value={templateHtml}
          onValueChange={setTemplateHtml}
          highlight={(htmlCode) => highlight(htmlCode, languages.html, 'html')}
          padding={5}
          style={{
            backgroundColor: ref.palette.neutral20,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 11,
          }}
          disabled={disabled}
        />
      </div>
    </Grid>
    <Grid item xs={4}>
      {availableVariables
        && <SearchableListOfVariables availableVariables={availableVariables} />
      }
    </Grid>
  </Grid >;

export const Frequency = ({
  template,
  setFrequency,
  setDay,
  setFormIsValid,
}: {
  template: ClientReportTemplate,
  setFrequency: (newFreq: ClientReportTemplateType['scheduler']['frequency']) => void
  setDay: (newDay: ClientReportTemplateType['scheduler']['dayOfMonthOrWeek']) => void
  setFormIsValid: (newValidity: boolean) => void
}) => {
  const { t } = useTranslation('clientReportTemplate');

  let dayLabel = '';
  let dayError: string | undefined;

  switch (template.scheduler.frequency) {
    case 'WEEKLY':
      dayLabel = t('frequencies.dayOfNextWeek');
      if (template.scheduler.dayOfMonthOrWeek < 1 || template.scheduler.dayOfMonthOrWeek > 7) dayError = t('frequencies.outOfBounds');
      break;
    case 'MONTHLY':
      dayLabel = t('frequencies.dayOfNextMonth');
      if (template.scheduler.dayOfMonthOrWeek < 1 || template.scheduler.dayOfMonthOrWeek > 28) dayError = t('frequencies.outOfBounds');
      break;
    case 'QUARTERLY':
      dayLabel = t('frequencies.dayOfNextQuarter');
      if (template.scheduler.dayOfMonthOrWeek < 1 || template.scheduler.dayOfMonthOrWeek > 28) dayError = t('frequencies.outOfBounds');
      break;
    case 'YEARLY':
      dayLabel = t('frequencies.dayOfNextYear');
      if (template.scheduler.dayOfMonthOrWeek < 1 || template.scheduler.dayOfMonthOrWeek > 28) dayError = t('frequencies.outOfBounds');
  }

  useEffect(() => {
    setFormIsValid(dayError === undefined);
  }, [dayError, setFormIsValid]);

  return <>
    <Typography variant='titleMedium'>Report generation frequency</Typography>
    <Box py={1} my={1}>
      <SelectionTile
        value={template.scheduler.frequency}
        direction='row'
        options={[
          { label: t('frequencies.weekly'), subtitle: t('frequencies.onceAWeek'), value: 'WEEKLY' },
          { label: t('frequencies.monthly'), subtitle: t('frequencies.onceAMonth'), value: 'MONTHLY' },
          { label: t('frequencies.quarterly'), subtitle: t('frequencies.onceAQuarter'), value: 'QUARTERLY' },
          { label: t('frequencies.yearly'), subtitle: t('frequencies.onceAYear'), value: 'YEARLY' },
        ]}
        onChange={(e: any) => setFrequency(e.target.value)}
      />
    </Box>
    <Typography variant='titleMedium'>Report generation date</Typography>
    <Box py={1} my={1}>
      <TextField
        type="number"
        label={dayLabel}
        value={template.scheduler.dayOfMonthOrWeek}
        onChange={(e: any) => setDay(e.target.value)}
        error={!!dayError}
        errorText={dayError}
      />
    </Box>
  </>;
};

export const Rules = ({ template }: { template: ClientReportTemplate }) => {
  const { t } = useTranslation('clientReportTemplate');

  const rules: ClientReportTemplateType['scheduler']['triggerRules'] = template.scheduler.triggerRules || [];

  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const boxStyle: SxProps = {
    p: 2,
    border: `1px solid ${sys.color.outline}`,
    borderRadius: sys.borderRadius.xl,
    // TODO editing
    // cursor: 'pointer',
    // '&:hover': {
    //   borderColor: sys.color.outline,
    //   backgroundColor: sys.color.surfaceContainer,
    // },
  };

  return <>
    <Stack spacing={1}>
      {rules.map((rule, index) => <>
        <Box key={index} sx={boxStyle}>
          <Typography variant='bodyMedium'>
            {rule.field}
          </Typography>
          <Typography variant='bodyMedium'>
            <span style={{ color: 'gray' }}>
              {t(`comparisons.${rule.comparison}`)}
            </span>
            &nbsp;
            {`${rule.value}`}
          </Typography>
        </Box>
      </>)}
      <Button variant='filled' disabled
        leadingIcon={AddIcon}
        label={t('addRule')}
        onClick={() => { }}
      />
    </Stack>
  </>;
};
