/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { WorkflowCompletionVisual } from '../workflowCompletion/workflowCompletion.visual';
import workflow from './depositWorkflow.workflow.json';
import { updateNextWorkflowStep, updatePreviousWorkflowStep } from '../../../util/hardCodedWorkflow';
import { ActionContext, PageObjectType } from '../../5-page';

const initialWorkflowCompletion = {
  id: '123',
  currentStep: workflow.steps[0],
  currentSubStep: workflow.steps[0].subSteps[0],
  workflow,
};

const initialTransfer = {
  type: '',
  bankAccount: {},
  subAccount: {},
  fromSubAccount: {},
  institution: {},
  accountNumber: '',
  accountType: '',
  transferType: 'ALL_IN_CASH',
  amountCents: 0,
  schedule: 'ONE_TIME',
  scheduledDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
  skipDocuments: true,
};

const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        financialProduct {
          translatedName { en fr }
        }
        goal {
          name
          type
        }
        account {
          id
          type
          user {
            id
          }
        }
        id
      }
    }
  }
`;

export const TransferContext = createContext<any>({
  transferData: {},
  setTransferData: () => {},
});

export const DepositWorkflow = ({
  open, setOpen, objectId, objectType,
}: {
  open: boolean, setOpen: (open: boolean) => void, objectId: string, objectType: PageObjectType
}) => {
  const { refetch } = useContext(ActionContext);

  const [transferData, setTransferData] = useState<any>({
    ...initialTransfer, objectType, objectId,
  });
  const [workflowCompletion, setWorkflowCompletion] = useState<any>({
    objectId,
    ...initialWorkflowCompletion,
  });

  const [fetchSubAccount] = useLazyQuery(FETCH_SUB_ACCOUNTS, {
    variables: {
      subAccountId: objectId,
    },
  });

  useEffect(() => {
    if (objectType === PageObjectType.SUB_ACCOUNT) {
      fetchSubAccount({
        onCompleted: (d: any) => {
          setTransferData({
            ...transferData,
            subAccount: d.fetchSubAccount.subAccount,
          });
        },
      });
    }
  }, [setTransferData, objectId, objectType]);

  const updateNext = () => {
    updateNextWorkflowStep(workflowCompletion, setWorkflowCompletion, () => {
      setOpen(false);
      setTransferData(initialTransfer);
      setWorkflowCompletion({
        objectId,
        ...initialWorkflowCompletion,
      });
    });
  };

  const updatePrevious = () => {
    updatePreviousWorkflowStep(workflowCompletion, setWorkflowCompletion);
  };

  return (<TransferContext.Provider value={{
    transferData,
    setTransferData,
  }}>
      <WorkflowCompletionVisual workflowCompletion={workflowCompletion} open={open} onClose={async () => {
        await refetch();
        setOpen(false);
        setTransferData({
          ...transferData, objectType, objectId,
        });
        setWorkflowCompletion({
          objectId,
          ...initialWorkflowCompletion,
        });
      }} onNext={updateNext} previousStep={updatePrevious} loading={false} />
    </TransferContext.Provider>
  );
};
