import { Auth0Client } from '@auth0/auth0-spa-js';
import dayjs from 'dayjs';

const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  authorizationParams: {
    scope: 'client_credentials',
    audience: 'onevest',
  },
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  useCookiesForTransactions: true,
  useRefreshTokensFallback: true,
});

export const JWT_CLAIMS_NAMESPACE = 'https://onevest.com';

export const getOrganizationKeyFromURL = (organizationName?: string) => {
  let organizationKey: string | null = null;
  let auth0RedirectUri = process.env.REACT_APP_BASE_PATH || '';
  if (typeof window !== 'undefined' && ['staging', 'production'].includes(process.env.REACT_APP_ENV ?? '')) {
    const { host, origin } = window.location;
    const key = host.split('.')?.[0];
    if (key && !['dev-onevest-app', 'onevest-app'].includes(key)) {
      organizationKey = key;
      auth0RedirectUri = origin;
    } else if (key && organizationName && process.env.REACT_APP_ORG_BASE_PATH) {
      organizationKey = organizationName;
      auth0RedirectUri = process.env.REACT_APP_ORG_BASE_PATH.replace('organization', organizationName);
    }
    // eslint-disable-next-line
    console.log({
      event: 'ORGANIZATION_AUTH',
      organizationKey,
      auth0RedirectUri,
      url: origin,
    });
  }
  return { organizationKey, auth0RedirectUri };
};

export const auth0Logout = async () => {
  if (window && window.localStorage) {
    const { localStorage } = window;
    localStorage.removeItem('activeOrganizationData');
    localStorage.removeItem('activeOrganizationId');
    localStorage.removeItem('permissionsHash');
  }
  const { auth0RedirectUri } = getOrganizationKeyFromURL();
  await auth0.logout({
    logoutParams: {
      returnTo: auth0RedirectUri,
    },
  });
};

export const auth0RefreshToken = async ({ redirectToPath, orgSubdomain }: { redirectToPath?: string, orgSubdomain?: string } = {}) => {
  const locationPath = redirectToPath ?? window.location.pathname;
  const shouldRedirectToPath = locationPath?.trim() !== '/';
  if (shouldRedirectToPath) {
    const auth0RedirectPath = {
      locationPath,
      expireAt: dayjs().add(5, 'minutes').toDate(),
    };
    localStorage.setItem('auth0RedirectPath', JSON.stringify(auth0RedirectPath));
  }
  const { auth0RedirectUri, organizationKey } = getOrganizationKeyFromURL();

  const orgKey = orgSubdomain ?? organizationKey;

  await auth0.loginWithRedirect({
    authorizationParams: {
      redirect_uri: auth0RedirectUri,
      ...(orgKey ? { organization: orgKey } : {}),
    },
  });
};

export const auth0RefreshTokenSilently = async ({ redirectToPath, orgSubdomain }: { redirectToPath?: string, orgSubdomain?: string } = {}): Promise<string> => {
  let token = '';
  const { organizationKey } = getOrganizationKeyFromURL();

  const orgKey = orgSubdomain ?? organizationKey;

  await auth0.getTokenSilently({
    cacheMode: 'off',
    authorizationParams: {
      ...(orgKey ? { organization: orgKey } : {}),
    },
  }).then(async (newToken: string) => {
    token = newToken;
  }).catch(async (e: any) => {
    auth0RefreshToken({ redirectToPath, orgSubdomain });
    throw new Error(e);
  });

  return token;
};

export default auth0;
