export const reorder = (result: any, columns: any[]): any[] => {
  const { destination, source } = result;

  if (!destination) {
    return columns;
  }

  if (
    destination.droppableId === source.droppableId
    && destination.index === source.index
  ) {
    return columns;
  }

  if (destination.droppableId === source.droppableId) {
    const column = columns.find((c: any) => c.id === source.droppableId);
    const newSubSteps = Array.from(column.subSteps);
    const movedObject = newSubSteps.splice(source.index, 1)[0];
    newSubSteps.splice(destination.index, 0, movedObject);

    const newColumns = columns.map((c: any) => {
      if (c.id === source.droppableId) {
        return {
          ...c,
          subSteps: newSubSteps,
        };
      }
      return c;
    });

    return newColumns;
  }

  const oldColumn = columns.find((c: any) => c.id === source.droppableId);
  const oldSubSteps = Array.from(oldColumn.subSteps);
  const movedObject = oldSubSteps.splice(source.index, 1)[0];

  const newColumn = columns.find((c: any) => c.id === destination.droppableId);
  const newSubSteps = Array.from(newColumn.subSteps);
  newSubSteps.splice(destination.index, 0, movedObject);

  const newColumns = columns.map((c: any) => {
    if (c.id === source.droppableId) {
      return {
        ...c,
        subSteps: oldSubSteps,
      };
    }
    if (c.id === destination.droppableId) {
      return {
        ...c,
        subSteps: newSubSteps,
      };
    }
    return c;
  });

  return newColumns;
};
