import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle,
  DialogProps,
  DialogActionsProps,
  DialogContentProps,
  DialogContentTextProps,
  DialogTitleProps,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import convert from 'color-convert';
import { MutableRefObject, useRef } from 'react';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box } from '../../1-primative';
import { IconButton } from '../iconButton/iconButton';

interface OvDialogTitleProps extends DialogTitleProps {
  onClose?: () =>void,
}
interface OvDialogContentProps extends DialogContentProps {
  onRefSet?: (ref: MutableRefObject<any>) =>void,
}
export const Dialog = (props: DialogProps) => {
  const { sys } = useThemeTokens();
  const rgb = convert.hex.rgb(sys.color.background || '#000000');

  return (<MuiDialog
    sx={{
      '.MuiDialog-paper': {
        borderRadius: sys.borderRadius.xxl,
      },
      '.MuiBackdrop-root': {
        background: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.85)`,
      },
      ...props?.sx,
    }}
    {...props}
  />);
};
export const DialogTitle = (props: OvDialogTitleProps) => {
  const { sys } = useThemeTokens();
  if (props.onClose) {
    return (
      <MuiDialogTitle sx={{ borderBottom: `1px solid ${sys.color.outlineVariant}` }} {...props}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {props.children}
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </Box>
      </MuiDialogTitle>
    );
  }

  return (<MuiDialogTitle sx={{ borderBottom: `1px solid ${sys.color.outlineVariant}` }} {...props} />);
};

export const DialogFooter = (props: DialogActionsProps) => {
  const { sys } = useThemeTokens();

  return (<MuiDialogActions sx={{ borderTop: `1px solid ${sys.color.outlineVariant}`, p: 1.5 }} {...props} />);
};
export const DialogContent = (props: OvDialogContentProps) => {
  const ref = useRef(null);
  if (props.onRefSet) {
    props?.onRefSet(ref);
  }
  return (<MuiDialogContent {...props} sx={{ ...props.sx, pt: '16px !important' }} ref={ref} />);
};
export const DialogContentText = (props: DialogContentTextProps) => (<MuiDialogContentText {...props} />);
