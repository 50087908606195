import { useContext, useEffect, useState } from 'react';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { flatten, uniq } from 'lodash/fp';
import { gql, useLazyQuery } from '@apollo/client';
import { Selector } from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';
import { getCurrencyFlag } from '../../../util/currency';
import filterHouseholdsWithAccess from '../../../util/filterHouseholdsWithAccess';

const FETCH_USER_AVAILABLE_CURRENCIES = gql`
  query fetchUserAvailableCurrencies($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user{
        id
        availableCurrencies
        households {
          id
          availableCurrencies
          relationships {
            user {
              id
            }
            accessType
          }
        }
      }
    }
  }
`;

const FETCH_CLIENT_GROUP_AVAILABLE_CURRENCIES = gql`
  query fetchClientGroupAvailableCurrencies($clientGroupId: ObjectID!) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup{
        id
        availableCurrencies
        relationships{
          user {
            id
            availableCurrencies
          }
        }
      }
    }
  }
`;

export const MultiCurrencySelector = ({ userId, clientGroupId }: { userId?: string, clientGroupId?: string }) => {
  const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([]);
  const {
    baseCurrency, activeCurrency, setActiveCurrency,
  } = useContext(UserContext);

  const [fetchUserAvailableCurrencies] = useLazyQuery(FETCH_USER_AVAILABLE_CURRENCIES, {
    variables: { userId },
    onCompleted: (data) => {
      if (data?.fetchUser?.user) {
        const { user } = data.fetchUser;
        let userAvailableCurrencies = user?.availableCurrencies ?? [];
        const households = filterHouseholdsWithAccess((user?.households ?? []), userId);
        if (households.length > 0) {
          households.forEach((household) => {
            if (household?.availableCurrencies) {
              userAvailableCurrencies = [...userAvailableCurrencies, ...household.availableCurrencies];
            }
          });
        }
        setAvailableCurrencies(uniq([baseCurrency, ...userAvailableCurrencies]).sort());
      }
    },
  });

  const [fetchClientGroupAvailableCurrencies] = useLazyQuery(FETCH_CLIENT_GROUP_AVAILABLE_CURRENCIES, {
    variables: { clientGroupId },
    onCompleted: (data) => {
      if (data?.fetchClientGroup?.clientGroup) {
        const { clientGroup } = data.fetchClientGroup;
        const clientGroupCurrencies = [
          ...(clientGroup?.availableCurrencies ?? []),
          ...flatten((clientGroup?.relationships ?? []).map((rel: any) => rel.user.availableCurrencies)),
        ];
        setAvailableCurrencies(uniq([baseCurrency, ...clientGroupCurrencies]).sort());
      }
    },
  });

  useEffect(() => {
    if (userId) {
      fetchUserAvailableCurrencies();
    } else if (clientGroupId) {
      fetchClientGroupAvailableCurrencies();
    }
  }, [clientGroupId, userId, baseCurrency, fetchUserAvailableCurrencies, fetchClientGroupAvailableCurrencies]);

  const options = availableCurrencies.map((elem: string) => ({ label: `Show all in ${elem}`, avatar: getCurrencyFlag(elem as CurrencyCodes), type: elem }));

  return (
    <Selector
      options={options}
      disabled={options.length === 1}
      sx={{ width: options.length === 1 ? '77px' : '97px' }}
      optionsPosition='right'
      selectedOptionType={activeCurrency}
      onChange={(e: any) => setActiveCurrency(e.target.value)}
      renderValue={(selected: string) => selected.slice(-3)}
    />
  );
};
