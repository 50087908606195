import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Paper,
  Chip, IconButton, Menu, MenuItem,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as React from 'react';
import CancelWorkFlowDialog from 'ovComponents/3-pattern/cancelWorkFlowDialog/cancelWorkFlowDialog';
import { Typography as NewTypography } from 'ovComponents/1-primative';
import { usePermissions, UserContext } from '../../providers/userContextProvider';
import { usePageState } from '../../util/usePageState';
import { Date } from '../../components/misc/date/date';

const FETCH_WORKFLOWS = gql`
  query fetchWorkflowCompletions($input: FetchWorkflowCompletionsInput!) {
    fetchWorkflowCompletions(input: $input) {
      workflowCompletions {
        id
        objectId
        objectType
        context
        object {
          ... on User {
            firstName
            lastName
          }
        }
        workflow {
          name {
            en
          }
        }
        createdAt
        currentStep {
          name {
            en
          }
        }
        state
      }
      totalCount
    }
  }
`;

const ElderlyClients = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['workflowCompletions']);
  const { activeOrganization } = useContext(UserContext);
  const navigate = useNavigate();
  const [page, setPage] = usePageState(1, 'page');
  const [openMenu, setOpenMenu] = useState<{
    anchorElement: null | HTMLElement,
    open: boolean,
    index: number,
  }>({ anchorElement: null, open: false, index: 0 });
  const [openCloseWorkflowConfirmation, setOpenCloseWorkflowConfirmation] = useState({ open: false, workflowId: '' });
  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.stopPropagation();
    setOpenMenu({ anchorElement: event.currentTarget, open: true, index });
  };
  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpenMenu({ anchorElement: null, open: false, index: 0 });
  };
  const pageSize = 15;
  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_WORKFLOWS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          state: 'ACTIVE',
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    skip: !permissions.includes('read:workflow_completion'),
    fetchPolicy: 'no-cache',
  });

  return (
    <Paper sx={{ width: '100%' }}>
      <Box>
        <Grid container>
          <Grid item xs={1}>
            {loading && previousData ? <CircularProgress size='30px' sx={{ marginTop: '6px', float: 'right' }}/> : <></>}
          </Grid>
        </Grid>
        {loading && !previousData ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ m: 18 }} />
          </Box>
        ) : (
          <>
            <Table sx={{ minWidth: 650 }} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant='overline'>{t('table.object')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('table.workflow')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('table.step')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('table.started')}</Typography></TableCell>
                  <TableCell><Typography variant='overline'>{t('table.state')}</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(data || previousData)?.fetchWorkflowCompletions?.workflowCompletions?.map((workflowCompletion: any, index: number) => (
                  <TableRow
                    hover
                    key={workflowCompletion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                    onClick={() => navigate(`/clients/${workflowCompletion.objectId}?workflowCompletion=${workflowCompletion.id}`)}
                  >
                    <TableCell component="th" scope="row">
                      {workflowCompletion.object.firstName} {workflowCompletion.object.lastName}
                    </TableCell>
                    <TableCell>{workflowCompletion.workflow.name.en}</TableCell>
                    <TableCell>{workflowCompletion.currentStep.name.en}</TableCell>
                    <TableCell><Date variant='subtitle2' date={workflowCompletion.createdAt} /></TableCell>
                    <TableCell><Chip color='success' label={workflowCompletion.state} size='small' /></TableCell>
                    <TableCell>
                      <IconButton onClick={(e) => handleClick(e, index)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        open={openMenu.open && openMenu.index === index}
                        onClose={handleClose}
                        anchorEl={openMenu.anchorElement}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        sx={{
                          '.MuiMenu-paper': {
                            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
                          },
                        }}
                      >
                        <MenuItem
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            handleClose(event);
                            setOpenCloseWorkflowConfirmation({ open: true, workflowId: workflowCompletion.id });
                          }}>
                          <NewTypography variant="button">Cancel Workflow</NewTypography>
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchWorkflowCompletions?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </>
        )}
      </Box>
      <CancelWorkFlowDialog
        open={openCloseWorkflowConfirmation.open}
        setCloseDialog={(value) => {
          setOpenCloseWorkflowConfirmation({ open: value, workflowId: '' });
          refetch().then();
        }}
        worflowId={openCloseWorkflowConfirmation.workflowId}
      />
    </Paper>
  );
};

export default ElderlyClients;
