import { TextField, ListItem } from '@mui/material';
import { NewIntegrationType, CurrentIntegrationType } from 'interfaces/integrations';
import { useTranslation } from 'react-i18next';
import { integrationConfig } from 'pages/devSettings/components/integrationConfig';

interface IntegrationsConfigurationProps {
  integration: NewIntegrationType | CurrentIntegrationType;
  setIntegrationConfiguration: (newConfig: any) => void;
}

const IntegrationsConfiguration = ({ integration, setIntegrationConfiguration }: IntegrationsConfigurationProps) => {
  const { t } = useTranslation(['devSettings']);
  if (!integration?.type) return null;
  const integrations = integrationConfig[integration?.type].integration;

  if (!integrations) return null;
  const findIntegration = integrations?.find((x: any) => x.provider === integration.provider);

  return (
    <>
      {findIntegration?.configuration.map((x: string) => (
        <ListItem key={x as string}>
          <TextField
            required
            value={integration.configuration[x] ? integration.configuration[x] : ''}
            label={t(`integrationsModal.configuration.${x}`)}
            fullWidth
            onChange={(e: any) => {
              setIntegrationConfiguration({ ...integration.configuration, [x]: e.target.value });
            }}
          />
        </ListItem>
      ))}
    </>
  );
};

export default IntegrationsConfiguration;
