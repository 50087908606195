/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  Chip, TextField, MenuItem, Button, Checkbox,
  DialogTitle, Dialog, DialogContent, Link as MuiLink,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { union, remove, isEqual } from 'lodash/fp';
import { Link } from 'react-router-dom';
import { useGlobalStats } from '../../../providers/globalStatsHooks';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import DownloadReconciliation from './downloadReconciliation';

export const FETCH_FLAGS_QUERY = `query fetchFlags($input: FetchFlagsInput!) {
  fetchFlags(input: $input) {
    flags {
      id
      objectId
      objectType
      type
      message
      details
      state
      createdAt
      organization {
        id
      }
      account {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
    totalCount
  }
}`;

const FETCH_FLAGS = gql`${FETCH_FLAGS_QUERY}`;

const TRANSITION_FLAG = gql`
  mutation transitionFlag($input: TransitionFlagInput!) {
    transitionFlag(input: $input) {
      flag {
        id
      }
    }
  }
`;

const BULK_TRANSITION_FLAGS = gql`
  mutation bulkTransitionFlags($input: BulkTransitionFlagsInput!) {
    bulkTransitionFlags(input: $input) {
      flags {
        id
      }
    }
  }
`;

const FETCH_FLAG_TYPES = gql`
  query fetchFlagTypes {
    __type(name: "FlagTypes") {
      enumValues { name }
    }
  }
`;

const Flags = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['reconciliation']);
  const { flagsRefetch } = useGlobalStats();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');
  const [open, setOpen] = useState(false);
  const [activeFlag, setActiveFlag] = useState<any | null>(null);
  const [flagState, setFlagState] = usePageState('FLAGGED', 'flag');
  const [flagType, setFlagType] = usePageState<string | undefined>(undefined, 'type');
  const [selected, setSelected] = useState<string[]>([]);
  const [transitionFlag] = useMutation(TRANSITION_FLAG);
  const [bulkTransitionFlag, bulkOptions] = useMutation(BULK_TRANSITION_FLAGS);
  const [flagTypes, setFlagTypes] = useState([]);

  const queryFilter = {
    state: flagState,
    type: flagType,
    organizationId: activeOrganization.id,
  };

  const { loading, data, refetch, previousData } = useQuery(FETCH_FLAGS, {
    variables: {
      input: {
        filter: { ...queryFilter },
        pagination: {
          sortField: 'createdAt',
          sortDesc: true,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  useQuery(FETCH_FLAG_TYPES, {
    onCompleted: (newData) => {
      setFlagTypes(newData.__type.enumValues.map((enumValue:any) => enumValue.name));
    },
  });

  useEffect(() => function cleanupOnUnmount() {
    setPage(1);
    setPageSize(15);
    setFlagState('FLAGGED');
    setFlagType(undefined);
  }, []);

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='flex-end' spacing={2} sx={{ p: '0 8px' }}>
            <Grid item xs={7}>
              <Button variant='outlined' disabled={isEqual(selected, []) || bulkOptions.loading} sx={{ marginRight: 1 }} onClick={async () => {
                await bulkTransitionFlag({
                  variables: {
                    input: { flagIds: selected, transition: 'fixed' },
                  },
                });
                refetch();
                flagsRefetch();
              }}>{t('flagState.ALL_FIXED')}</Button>
            </Grid>

            <Grid item xs={2}>
              <TextField fullWidth select size='small' label={t('type')}
                value={flagType || 'any'}
                onChange={(e) => setFlagType(e.target.value !== 'any' ? e.target.value : undefined)}
              >
                <MenuItem key='any' value='any'>{t('any')}</MenuItem>
                { flagTypes.map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>) }
              </TextField>
            </Grid>

            <Grid item xs={2}>
              <TextField fullWidth select value={flagState} onChange={(e) => setFlagState(e.target.value)} size='small' label={t('state')}>
                <MenuItem value={'FLAGGED'}>{t('flagState.FLAGGED')}</MenuItem>
                <MenuItem value={'FIXED'}>{t('flagState.FIXED')}</MenuItem>
              </TextField>
            </Grid>

            {permissions.includes('read:api_exports') && (
              <Grid item xs={1} sx={{ textAlign: 'right' }}>
                <DownloadReconciliation tab='flags' queryFilter={queryFilter} />
              </Grid>
            )}
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isEqual(selected, (data?.fetchFlags?.flags || []).map((x: any) => x.id))}
                    onChange={((e: any) => {
                      if (e.target.checked) {
                        setSelected((data?.fetchFlags?.flags || []).map((x: any) => x.id));
                      } else {
                        setSelected([]);
                      }
                    })}
                  />
                </TableCell>
                <TableCell><Typography variant='overline'>{t('table.client')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.type')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.message')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.createdAt')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('table.state')}</Typography></TableCell>
                <TableCell align='right'><Typography variant='overline'>{t('table.actions')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchFlags?.flags?.map((flag: any) => (
                <TableRow
                  hover
                  onClick={() => {
                    setOpen(true);
                    setActiveFlag(flag);
                  }}
                  key={flag.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  selected={ activeFlag?.id === flag.id }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selected.includes(flag.id)}
                      onClick={(e: any) => e.stopPropagation()}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelected(union(selected, [flag.id]));
                        } else {
                          setSelected(remove((x: any) => x === flag.id, selected));
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <MuiLink component={Link} to={`/clients/${flag.account?.user?.id}`}>{flag.account?.user?.firstName} {flag.account?.user?.lastName}</MuiLink>
                  </TableCell>
                  <TableCell><Chip size='small' label={flag.type} /></TableCell>
                  <TableCell>{flag.message}</TableCell>
                  <TableCell><DateTime variant='subtitle2' date={flag.createdAt} /></TableCell>
                  <TableCell><Chip size='small' label={flag.state} color={flag.state === 'FLAGGED' ? 'error' : 'success'} /></TableCell>
                  <TableCell align='right'>
                    <Grid container columnSpacing={1} justifyContent='flex-end'>
                      <Grid item>
                        <Button disabled={flag.state !== 'FLAGGED'} variant='outlined' size='small' onClick={async (e) => {
                          e.stopPropagation();
                          await transitionFlag({
                            variables: {
                              input: { flagId: flag.id, transition: 'fixed' },
                            },
                          });
                          refetch();
                          flagsRefetch();
                        }}>{t('flagState.FIXED')}</Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container sx={{ paddingLeft: 1 }}>
            <Grid item xs={6}>
              <TextField select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value, 10))} size='small' label={t('perPage')} sx={{ minWidth: '150px' }}>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={250}>250</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Pagination
                count={Math.ceil(((data || previousData)?.fetchFlags?.totalCount ?? 0) / pageSize)}
                page={page}
                onChange={(_e, newPage) => setPage(newPage)}
                sx={{
                  p: 1,
                  textAlign: 'right',
                  '.MuiPagination-ul': {
                    justifyContent: 'end',
                  },
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{ activeFlag?.message }</DialogTitle>
        <DialogContent>
          { activeFlag?.details?.account?.user && (
            <Button variant='outlined' sx={{ m: 1 }} fullWidth component={Link} target='_blank' to={`/clients/${activeFlag.details.account?.user}`}>
              {`${t('goTo')} ${activeFlag.details.clientName}`}
            </Button>
          )}
          <pre>{JSON.stringify(activeFlag?.details, null, 2) }</pre>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Flags;
