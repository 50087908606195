import { useContext, useState } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button, CardContent } from '../../../../2-component';
import { Box, Grid, Typography } from '../../../../1-primative';
import SubTradeRequestsTable, { FETCH_SUB_TRADES, TradeToVerify } from './subTradeRequestsTable';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { useLocalization } from '../../../../../util/useLocalization';

const CANCEL_BULK_TRADE = gql`
  mutation transitionBulkTradeRequest($input: TransitionBulkTradeRequestInput!) {
    transitionBulkTradeRequest(input: $input) {
      bulkTradeRequest {
        id
      }
    }
  }
`;

const CANCEL_SUB_TRADE_REQUESTS = gql`
  mutation cancelSubTradeRequests($input: CancelSubTradeRequestsInput!) {
    cancelSubTradeRequests(input: $input)
  }
`;

const EXPORT_SUB_TRADE_REQUESTS = gql`
  mutation exportSubTradeRequests($input: ExportSubTradeRequestsInput!) {
    exportSubTradeRequests(input: $input)
  }
`;

export const VERIFY_SUB_TRADE_REQUESTS = gql`
  mutation verifySubTradeRequests($input: VerifySubTradeRequestsInput!) {
    verifySubTradeRequests(input: $input)
  }
`;

const BulkTradeRequests = ({
  bulkTradeRequestId,
  bulkTradeRequest,
  afterCancel,
  isRefetch,
  isCancelSubTradesPerPortfolioReport,
  sourceId,
  isCancelDisabled,
  portfolioOptimizerCreatedAt,
}: {
  bulkTradeRequestId?: string;
  bulkTradeRequest?: Record<string, any>;
  afterCancel: () => void;
  isRefetch?: boolean;
  isCancelSubTradesPerPortfolioReport?: boolean;
  sourceId?: string;
  isCancelDisabled?: boolean;
  portfolioOptimizerCreatedAt?: Date;
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);

  const { t } = useTranslation(['components', 'shared']);
  const { localizedDateTime } = useLocalization();
  const { showToast } = useGlobalToast();
  const [tradesToVerify, setTradesToVerify] = useState<TradeToVerify[]>([]);

  const [transitionBulkTradeRequest] = useMutation(CANCEL_BULK_TRADE, {
    variables: {
      input: {
        bulkTradeRequestId,
        transition: 'cancel',
      },
    },
    onCompleted: () => afterCancel(),
  });

  const [cancelSubTradeRequests] = useMutation(CANCEL_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        bulkTradeRequestId,
        sourceId,
      },
    },
    onCompleted: () => afterCancel(),
  });

  const [exportSubTradeRequests] = useMutation(EXPORT_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        bulkTradeRequestId,
        sourceId,
      },
    },
    onCompleted: (data: any) => {
      if (data.exportSubTradeRequests) {
        window.open(data.exportSubTradeRequests, '_blank');
      } else {
        showToast({ severity: 'warning', message: t('components:generateTrades.nothingToExport') });
      }
    },
  });

  const [verifySubTradeRequests] = useMutation(VERIFY_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        organizationId: activeOrganization.id,
        subTradeRequestIds: tradesToVerify.map((trade: TradeToVerify) => trade?.id),
      },
    },
    onCompleted: () => {
      setTradesToVerify([]);
      showToast({ severity: 'success', message: t('components:generateTrades.verifyTradesResult') });
    },
    refetchQueries: [FETCH_SUB_TRADES()],
  });

  const cancel = async () => {
    if (isCancelSubTradesPerPortfolioReport && sourceId) {
      cancelSubTradeRequests();
    } else {
      transitionBulkTradeRequest();
    }
  };

  const isShowVerificationBtn = permissions.includes('write:sub_trade_request_verification') && (activeOrganization?.requireSubTradeRequestApproval ?? false);
  const isShowCancelTradesBtn = permissions.includes('transition:sub_trade_request') && (!(isCancelSubTradesPerPortfolioReport && sourceId) ? true : isCancelSubTradesPerPortfolioReport && sourceId);

  return (
    <>
      {bulkTradeRequestId ? (
        <>
          <Grid container justifyContent='space-between' alignItems='center' p={3}>
            <Grid item>
              <Typography variant='headingSmall'>
                {t('components:generateTrades.activeTradeRequests')}
              </Typography>

              {!!portfolioOptimizerCreatedAt && (
                <Typography variant='headingSmall'>
                  ({t('components:generateTrades.generatedByPortfolioOptimizer', { portfolioOptimizerCreatedAt: localizedDateTime(portfolioOptimizerCreatedAt) })})
                </Typography>
              )}
            </Grid>
            <Grid item>
              {isShowVerificationBtn && (
                <Button
                  sx={{ mr: 2 }}
                  variant='outlined'
                  color='destructive'
                  onClick={() => {
                    if (tradesToVerify.length) verifySubTradeRequests();
                  }}
                  disabled={!tradesToVerify.length}
                  label={t('components:generateTrades.verifyTrades')}
                />
              )}

              {isShowCancelTradesBtn && (
                <Button sx={{ mr: 2 }} variant='outlined' color='destructive' onClick={() => exportSubTradeRequests()} label={t('components:generateTrades.exportTrades')} />
              )}

              {isShowCancelTradesBtn && (
                <Button variant='outlined' disabled={isCancelDisabled} color='destructive' onClick={() => cancel()} label={t('components:generateTrades.cancelTrades')} />
              )}
            </Grid>
          </Grid>

          <SubTradeRequestsTable
            id={bulkTradeRequestId}
            sourceId={sourceId}
            isRefetch={isRefetch}
            onTradesToVerifyUpdate={(trades: TradeToVerify[]) => setTradesToVerify(trades)}
            afterCancel={() => afterCancel()}
          />
        </>
      ) : (
        <CardContent sx={{ pb: '16px !important' }}>
          <Box display='flex' flexDirection='row' alignItems='left' justifyContent='center'>
            <InfoRoundedIcon sx={{ mr: 1 }} />
            <Typography variant='bodyLarge' weight='bold'>
              {t('components:generateTrades.noActiveTradeRequests')}
            </Typography>
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default BulkTradeRequests;
