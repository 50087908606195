import { translateBackend } from 'assets/i18n/config';
import { Box, Switch } from '../..';
import InfoDialog from '../../2-component/infoDialog/infoDialog';

export const BooleanEdit = ({
  option, options, setOptions, i,
}: {
  option: any, options: any, setOptions: (x: any) => void, i: number
}) => (
  <Box sx={{ mb: 2 }} display='flex'>
    <Switch label={translateBackend(option.label)} checked={option.value} onChange={(e: any) => {
      const newOptions = [...options];
      newOptions[i].value = e;
      setOptions(newOptions);
    }}></Switch>
    {option.info && (
      <InfoDialog information={translateBackend(option.info)} center />
    )}
  </Box>
);
