import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { generateClientNameString } from '../../../util';
import { RelatedEntity, RelatedEntityTypes, User } from '../../../interfaces';
import {
  WizardBase, StepComponent, GenericStepProps, WizardControlProps,
} from '../wizardBase';
import { AddRelatedEntityActionStep } from './steps/addRelatedEntityActionStep';
import { ChooseClientStep } from './steps/chooseClientStep';
import { SummaryStep } from './steps/summaryStep';

type Step = 'CHOOSE_CLIENT' | 'SUMMARY' | 'ACTION';

const steps:Record<Step, StepComponent> = {
  CHOOSE_CLIENT: ChooseClientStep,
  SUMMARY: SummaryStep,
  ACTION: AddRelatedEntityActionStep,
};

interface WizardContext {
  userId: string,
  name: string,
  existingRelatedEntities: [RelatedEntity]
}

interface WizardState {
  newEntity?: User,
  relation: RelatedEntityTypes | '',
}

export type StepProps = GenericStepProps<WizardContext, WizardState>;

const initial:WizardState = {
  newEntity: undefined,
  relation: '',
};

export interface AddRelatedEntityWizardProps {
  user: User
}

export const FETCH_USER_RELATED_ENTITY_IDS = gql`
  query fetchUserRelatedEntities($userId: ObjectID!) {
    fetchUser(userId:$userId) {
      user {
        relatedEntities {
          relation
          entity { id }
        }
      }
    }
  }
`;

export function AddRelatedEntityWizard({
  user, handleClose,
}: AddRelatedEntityWizardProps & WizardControlProps) {
  const { t } = useTranslation('addRelatedEntityWizard');
  const [existingRelatedEntities, setExistingRelatedEntities] = useState<[RelatedEntity] | null>(null);

  useQuery(FETCH_USER_RELATED_ENTITY_IDS, {
    variables: { userId: user.id },
    fetchPolicy: 'no-cache',
    onCompleted: (existingData) => {
      setExistingRelatedEntities(existingData.fetchUser.user.relatedEntities);
    },
  });

  return (
    <WizardBase
      title={t('title')}
      context={{
        userId: user.id,
        name: generateClientNameString(user),
        existingRelatedEntities,
      }}
      steps={steps}
      firstStep={'CHOOSE_CLIENT'}
      initialState={initial}
      handleClose={handleClose}
    />
  );
}
