import { gql, useQuery } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { SelectField } from '../../2-component';
import { AccountTypes, getSubAccountName, SubAccount } from '../../../interfaces';

const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccounts($input: FetchSubAccountsInput!) {
    fetchSubAccounts(input: $input) {
      subAccounts {
        state
        stateChanges
        financialProduct {
          translatedName { en fr }
        }
        goal {
          name
          type
        }
        account {
          id
          type
          user {
            id
          }
        }
        id
        createdAt
        completedAt
        availableFinancialProducts{
          id
        }
        statistics {
          marketValueCents
          availableFundsToWithdrawCents
        }
      }
      totalCount
    }
  }
`;
interface SubAccountSelectProp {
  userId?: string
  label: string,
  onSubAccountSelect: (subAccount: SubAccount) => void,
  selectedSubAccount: any | undefined,
  accountToExclude?: AccountTypes[],
  subAccountsToExclude?: string[],
  sx?: any,
  size?: 'small' | 'medium',
  showValue?: boolean,
  filter?: any,
  onBlur?: any,
  error?: any,
  filterNotAvailableWithdraw?: boolean,
  excludedIds?: string[],
}
export const SubAccountSelect = ({
  userId, label, onSubAccountSelect, selectedSubAccount, sx = {}, size, showValue = false, filter, onBlur, error, filterNotAvailableWithdraw = false,
  accountToExclude, subAccountsToExclude,
}: SubAccountSelectProp) => {
  const { data } = useQuery(FETCH_SUB_ACCOUNTS, {
    variables: {
      input: {
        filter: filter || {
          userId,
        },
        pagination: {
          perPage: 100,
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  return (
    <SelectField
      onChange={(e: any) => onSubAccountSelect(data?.fetchSubAccounts?.subAccounts?.find((item: SubAccount) => item.id === e.target.value))}
      label={label}
      fullWidth
      sx={sx}
      size={size}
      value={selectedSubAccount?.id ?? ''}
      onBlur={onBlur}
      error={error}
    >
      {
        data?.fetchSubAccounts?.subAccounts
          ?.filter((s: any) => s.state !== 'INACTIVE' && !accountToExclude?.includes(s.account.type)
              && !subAccountsToExclude?.includes(s.id)
              && (!filterNotAvailableWithdraw || s.statistics?.availableFundsToWithdrawCents > 0))
          ?.map((subAccount: { id: string, account: { type: string } }) => (
            <MenuItem key={subAccount.id} value={subAccount.id}>{getSubAccountName(subAccount as SubAccount)}</MenuItem>
          )) ?? []
      }
    </SelectField>
  );
};
