import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardContentProps,
} from '@mui/material';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useCardTokens } from './card.tokens';
import { LinearProgress } from '../linearProgress/linearProgress';

interface CardProps {
  children: React.ReactNode | React.ReactNode[];
  variant?: 'elevated' | 'outlined';
  sx?: any;
  onClick?: (e?: any) => void;
  hover?: boolean;
  fullWidthOnMobile?: boolean,
  loading?: boolean,
}

export const Card = ({
  children, variant = 'elevated', sx, onClick, hover = false, fullWidthOnMobile = false, loading,
}: CardProps) => {
  const tokens = useThemeTokens(useCardTokens());
  const { comp } = tokens;

  return (
    <MuiCard
      onClick={onClick}
      elevation={0}
      sx={{
        borderRadius: { xs: fullWidthOnMobile ? '0px' : comp.card?.borderRadius, sm: comp.card?.borderRadius },
        background: comp.card.background?.color,
        color: comp.card.text?.color,
        boxShadow: variant === 'elevated' ? comp.card.elevation : 'none',
        border: variant === 'elevated' ? 'none' : comp.card.border,
        '&:hover': {
          boxShadow: hover && comp.card.hoverElevation,
          cursor: hover && 'pointer',
        },
        marginLeft: { xs: fullWidthOnMobile ? '-16px' : 0, sm: 0 },
        marginRight: { xs: fullWidthOnMobile ? '-16px' : 0, sm: 0 },
        position: 'relative',
        ...sx,
      }}>
        {loading && (<LinearProgress sx={{ mb: -0.5 }} />)}
        {children}
      </MuiCard>
  );
};
export const CardContent = (props: CardContentProps) => (<MuiCardContent sx={{ padding: '16px', paddingBottom: '16px', ...props.sx }} {...props} />);
