import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import {
  Box, Paper, Typography, Stepper, Step, Tooltip,
  StepLabel, Button, CircularProgress, Grid,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { indexOf } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TradeRequestsTable from '../../components/tables/tradeRequestsTable/tradeRequestsTable';
import UpdateTradesTable from './components/updateTradesTable';
import { useGlobalToast } from '../../providers/globalToastProvider';
import ConfirmationModal from '../../components/modals/confirmationModal';
import { Exchanges } from '../../interfaces';
import BulkTradeRunAllocationsTable from '../../components/tables/bulkTradeRunAllocationsTable/bulkTradeRunAllocationsTable';
import UpdateMutualFundTradesTable from './components/updateMutualFundTradesTable';

const FETCH_BULK_TRADE_RUN = gql`
  query fetchBulkTradeRun($bulkTradeRunId: ObjectID!, $PTF: Boolean) {
    fetchBulkTradeRun(bulkTradeRunId: $bulkTradeRunId) {
      bulkTradeRun {
        id
        createdAt
        buyBulkTradeFileUrl
        sellBulkTradeFileUrl
        mutualFundFileUrl
        state
        bulkPTFFileUrls
        incompleteTradeRequestsByExchange(PTF: $PTF) {
          exchange
          total
        }
        allTradeRequestsByExchange {
          exchange
        }
      }
    }
  }
`;

const TRANSITION_BULK_TRADE_RUN = gql`
  mutation transitionBulkTradeRun($input: TransitionBulkTradeRunInput!) {
    transitionBulkTradeRun(input: $input) {
      bulkTradeRun {
        id
        state
      }
    }
  }
`;

export enum BulkTradeRunStates {
  GENERATING = 'GENERATING',
  BULK_TRADES_INITIATED = 'BULK_TRADES_INITIATED',
  BULK_TRADES_FILES_GENERATING = 'BULK_TRADES_FILES_GENERATING',
  BULK_TRADES_COMPLETED = 'BULK_TRADES_COMPLETED',
  ALLOCATIONS_INITIATED = 'ALLOCATIONS_INITIATED',
  ALLOCATIONS_COMPLETED = 'ALLOCATIONS_COMPLETED',
  COMPLETED = 'COMPLETED',
  COMPLETED_PENDING_PTFS = 'COMPLETED_PENDING_PTFS',
  CANCELED = 'CANCELED',
}

enum BulkTraderAdditionalStates {
  REVIEW_TRADE_ALLOCATIONS = 'REVIEW_TRADE_ALLOCATIONS',
  REVIEW_PTF_TRADE_ALLOCATIONS = 'REVIEW_PTF_TRADE_ALLOCATIONS',
}

const POLLING_TIME = 1500;

const BulkTrader = () => {
  const { t } = useTranslation('bulkTrader');
  const params = useParams();
  const { showToast } = useGlobalToast();
  const bulkTradeRunId = params.id;
  const [activeStep, setActiveStep] = useState(0);
  const [bulkTradeRun, setBulkTradeRun] = useState<any>({});
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);
  const [discrepancyConfirmationOpen, setDiscrepancyConfirmationOpen] = useState(false);
  const [buyTradesWarnings, setBuyTradesWarnings] = useState(false);
  const [sellTradesWarnings, setSellTradesWarnings] = useState(false);
  const [tradesSaved, setTradesSaved] = useState<boolean>(false);
  const [localLoading, setLocalLoading] = useState<boolean>(false);

  /**
   * Step: State
   *    0: GENERATING
   *    1: BULK_TRADES_INITIATED
   *    2: BULK_TRADES_COMPLETED
   *    3: REVIEW_TRADE_ALLOCATIONS
   *    4: COMPLETED_PENDING_PTFS
   *    5: REVIEW_PTF_TRADE_ALLOCATIONS
   *    6: COMPLETED
   */
  const defaultSteps: Record<string, string> = {
    GENERATING: t('generating'),
    BULK_TRADES_INITIATED: t('reviewTrades'),
    BULK_TRADES_COMPLETED: t('updateTrades'),
    REVIEW_TRADE_ALLOCATIONS: t('reviewTradeAllocations'),
    COMPLETED_PENDING_PTFS: t('updatePTFTrades'),
    REVIEW_PTF_TRADE_ALLOCATIONS: t('reviewPTFTradeAllocations'),
    COMPLETED: t('completed'),
  };

  const [steps, setSteps] = useState<Record<string, string>>(defaultSteps);

  const stepToState = (step: number): string => Object.keys(steps)[step];
  const stateToStep = (state: BulkTradeRunStates | BulkTraderAdditionalStates): number => indexOf(state, Object.keys(steps));

  const {
    loading, startPolling, stopPolling, refetch,
  } = useQuery(FETCH_BULK_TRADE_RUN, {
    variables: { bulkTradeRunId, PTF: [BulkTradeRunStates.COMPLETED_PENDING_PTFS, BulkTraderAdditionalStates.REVIEW_PTF_TRADE_ALLOCATIONS].includes(stepToState(activeStep) as any) },
    fetchPolicy: 'no-cache',
    pollInterval: POLLING_TIME,
    notifyOnNetworkStatusChange: true,
    onCompleted: (e: any) => {
      const { bulkTradeRun: fetchedBulkTradeRun } = e.fetchBulkTradeRun;
      const { state }: { state: any } = fetchedBulkTradeRun;

      if (state !== BulkTradeRunStates.GENERATING) {
        const hasBulkTradeOrMutualFundExchanges = fetchedBulkTradeRun?.allTradeRequestsByExchange?.filter((item: any) => item.exchange !== Exchanges.PTF) ?? null;
        const hasPTFExchanges = fetchedBulkTradeRun?.allTradeRequestsByExchange?.filter((item: any) => item.exchange === Exchanges.PTF) ?? null;

        if (!hasBulkTradeOrMutualFundExchanges.length) {
          delete steps.BULK_TRADES_COMPLETED;
          delete steps.REVIEW_TRADE_ALLOCATIONS;
        }

        if (!hasPTFExchanges.length) {
          delete steps.COMPLETED_PENDING_PTFS;
          delete steps.REVIEW_PTF_TRADE_ALLOCATIONS;
        }

        setSteps(steps);
      }

      setBulkTradeRun(fetchedBulkTradeRun);

      setActiveStep(stateToStep(state));

      if (state === BulkTradeRunStates.CANCELED || stateToStep(state) > 0) {
        stopPolling();
        setLocalLoading(false);
      }
    },
  });

  const [transitionBulkTradeRun, { loading: transitionLoading }] = useMutation(TRANSITION_BULK_TRADE_RUN);

  useEffect(() => {
    if (stepToState(activeStep) === BulkTradeRunStates.BULK_TRADES_COMPLETED
     || stepToState(activeStep) === BulkTradeRunStates.COMPLETED_PENDING_PTFS) {
      setTradesSaved(bulkTradeRun.incompleteTradeRequestsByExchange.length === 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, bulkTradeRun]);

  const onClickNext = async () => {
    if (buyTradesWarnings || sellTradesWarnings) {
      setDiscrepancyConfirmationOpen(true);
    } else {
      goNext();
    }
  };

  const reloadBulkTradeWithPolling = () => {
    startPolling(POLLING_TIME);
    setLocalLoading(true);
  };

  const goNext = async () => {
    if (stepToState(activeStep) === BulkTradeRunStates.BULK_TRADES_COMPLETED) {
      setActiveStep(stateToStep(BulkTraderAdditionalStates.REVIEW_TRADE_ALLOCATIONS));
      return;
    }

    if (stepToState(activeStep) === BulkTradeRunStates.COMPLETED_PENDING_PTFS) {
      setActiveStep(stateToStep(BulkTraderAdditionalStates.REVIEW_PTF_TRADE_ALLOCATIONS));
      return;
    }

    await transitionBulkTradeRun({ variables: { input: { bulkTradeRunId: bulkTradeRun.id, transition: 'initiateAllocations' } } });

    reloadBulkTradeWithPolling();
  };

  const goBack = async () => {
    if (stepToState(activeStep) === BulkTradeRunStates.BULK_TRADES_COMPLETED) {
      await transitionBulkTradeRun({ variables: { input: { bulkTradeRunId: bulkTradeRun.id, transition: 'resetBulkTrades' } } });
      setActiveStep(stateToStep(BulkTradeRunStates.BULK_TRADES_INITIATED));
      return;
    }

    reloadBulkTradeWithPolling();
  };

  const emitTransitionBulkTradeRun = async (transition: 'resetBulkTrades' | 'cancel' | 'generateBulkTradeFiles') => {
    await transitionBulkTradeRun({ variables: { input: { bulkTradeRunId: bulkTradeRun.id, transition } } });

    reloadBulkTradeWithPolling();
  };

  return (
    <Box>
      {loading && !bulkTradeRun ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : bulkTradeRun.state === BulkTradeRunStates.CANCELED ? (
        <Typography textAlign='center' color='error' variant='h4' sx={{ marginTop: 5 }}>
          {t('bulkTradeRunCanceled')}
        </Typography>
      ) : (
        <>
          <Paper sx={{ width: '100%', marginTop: 5 }}>
            <Stepper activeStep={activeStep} sx={{ p: 5 }}>
              {Object.values(steps).map((label: string) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {stepToState(activeStep) === BulkTradeRunStates.GENERATING || activeStep === -1 || localLoading ? (
              <Box sx={{ alignItems: 'center', justifyContent: 'center', p: '350px' }}>
                <Box textAlign='center'>
                  <CircularProgress size='60px' />
                </Box>
              </Box>
            ) : (
              <>
                {stepToState(activeStep) === BulkTradeRunStates.BULK_TRADES_INITIATED && (
                  <>
                    <TradeRequestsTable filter={{ bulkTradeRunId: bulkTradeRun.id }} showClient={true} showExpected={true} />
                    <Grid container justifyContent='space-between'>
                      <Grid item>
                        <Button variant='outlined' sx={{ m: 1, textAlign: 'right' }} color='error' disabled={transitionLoading} onClick={() => emitTransitionBulkTradeRun('cancel')}>
                          {t('cancel')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant='contained' sx={{ m: 1, textAlign: 'right' }} disabled={transitionLoading} onClick={() => emitTransitionBulkTradeRun('generateBulkTradeFiles')}>
                          {t('next')}
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}

                {(stepToState(activeStep) === BulkTradeRunStates.BULK_TRADES_COMPLETED || stepToState(activeStep) === BulkTradeRunStates.COMPLETED_PENDING_PTFS) && (
                  <>
                    <Grid container sx={{ p: 1 }}>
                      <Grid item>
                        {stepToState(activeStep) === BulkTradeRunStates.BULK_TRADES_COMPLETED && (
                          <>
                            {bulkTradeRun.buyBulkTradeFileUrl && (
                              <Button variant='contained' sx={{ marginRight: 1 }} onClick={(e) => e.stopPropagation()} href={bulkTradeRun.buyBulkTradeFileUrl} download>
                                {t('buyTradeFile')}
                              </Button>
                            )}
                            {bulkTradeRun.sellBulkTradeFileUrl && (
                              <Button variant='contained' sx={{ marginRight: 1 }} onClick={(e) => e.stopPropagation()} href={bulkTradeRun.sellBulkTradeFileUrl} download>
                                {t('sellTradeFile')}
                              </Button>
                            )}
                            {bulkTradeRun.mutualFundFileUrl && (
                              <Button variant='contained' sx={{ marginRight: 1 }} onClick={(e) => e.stopPropagation()} href={bulkTradeRun.mutualFundFileUrl} download>
                                {t('mutualFundTradeFile')}
                              </Button>
                            )}
                          </>
                        )}

                        {stepToState(activeStep) === BulkTradeRunStates.COMPLETED_PENDING_PTFS && (
                          <>
                            {bulkTradeRun.bulkPTFFileUrls.map((fileUrl: string, index: number) => (
                              <Button variant='contained' sx={{ marginRight: 1 }} onClick={(e) => e.stopPropagation()} href={fileUrl} download>{t('ptfBulkTradeFile')}: {index + 1}</Button>
                            ))}
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                      <Grid item xs={6} sx={{ minWidth: '40em' }}>
                        <Paper>
                          <UpdateTradesTable
                            bulkTradeRunId={bulkTradeRun.id}
                            type='BUY'
                            setDiscrepancyWarning={setBuyTradesWarnings}
                            exchanges={stepToState(activeStep) === BulkTradeRunStates.COMPLETED_PENDING_PTFS ? [Exchanges.PTF] : []}
                            onPriceUpdate={() => refetch()}
                          />
                        </Paper>
                      </Grid>

                      <Grid item xs={6} sx={{ minWidth: '40em' }}>
                        <Paper>
                          <UpdateTradesTable
                            bulkTradeRunId={bulkTradeRun.id}
                            type='SELL'
                            setDiscrepancyWarning={setSellTradesWarnings}
                            exchanges={stepToState(activeStep) === BulkTradeRunStates.COMPLETED_PENDING_PTFS ? [Exchanges.PTF] : []}
                            onPriceUpdate={() => refetch()}
                          />
                        </Paper>
                      </Grid>

                      <Grid item xs={6} sx={{ minWidth: '40em' }}>
                        <Paper>
                          <UpdateMutualFundTradesTable
                            bulkTradeRunId={bulkTradeRun.id}
                            type='BUY'
                          />
                        </Paper>
                      </Grid>

                      <Grid item xs={6} sx={{ minWidth: '40em' }}>
                        <Paper>
                          <UpdateMutualFundTradesTable
                            bulkTradeRunId={bulkTradeRun.id}
                            type='SELL'
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent='space-between'>
                      <Grid item>
                        {(stepToState(activeStep) === BulkTradeRunStates.BULK_TRADES_COMPLETED) && (
                          <>
                            <Button variant='outlined' sx={{ m: 1, textAlign: 'right' }} color='error' disabled={transitionLoading} onClick={() => emitTransitionBulkTradeRun('cancel')}>
                            {t('cancel')}
                            </Button>
                            <Button variant='outlined' sx={{ m: 1, textAlign: 'right' }} color='error' disabled={transitionLoading} onClick={goBack}>
                              {t('back')}
                            </Button>
                          </>
                        )}
                      </Grid>
                      <Grid item>
                        <Tooltip title={t('saveTradesMessage')} followCursor disableHoverListener={tradesSaved}>
                          <span>
                            <Button variant='contained' sx={{ m: 1, textAlign: 'right' }} disabled={!tradesSaved} onClick={onClickNext}>
                              {t('next')}
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </>
                )}

                {(stepToState(activeStep) === BulkTraderAdditionalStates.REVIEW_TRADE_ALLOCATIONS || stepToState(activeStep) === BulkTraderAdditionalStates.REVIEW_PTF_TRADE_ALLOCATIONS) && (
                  <>
                    <BulkTradeRunAllocationsTable filter={{ bulkTradeRunId: bulkTradeRun.id, isPTF: stepToState(activeStep) === BulkTraderAdditionalStates.REVIEW_PTF_TRADE_ALLOCATIONS }} />
                    <Grid container justifyContent='right'>
                      <Grid item>
                        <Button variant='contained' sx={{ m: 1, textAlign: 'right' }} onClick={() => goNext()}>
                          {t('next')}
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}

                {stepToState(activeStep) === BulkTradeRunStates.COMPLETED && (
                  <>
                    <Box sx={{ p: 15 }}>
                      <Grid container justifyContent='center'>
                        <Grid item>
                          <CheckCircleIcon color='success' sx={{ fontSize: '100px' }} />
                        </Grid>
                      </Grid>
                      <Typography textAlign='center' color='primary' variant='h4' sx={{ p: 3 }}>
                        {t('bulkTradeRunCompleted')}
                      </Typography>
                    </Box>
                    <TradeRequestsTable filter={{ bulkTradeRunId: bulkTradeRun.id }} showClient={true} showExpected={true} />
                  </>
                )}
              </>
            )}
          </Paper>

          <ConfirmationModal
            open={cancelConfirmationOpen}
            onCancel={() => setCancelConfirmationOpen(false)}
            onConfirm={async () => {
              await transitionBulkTradeRun({
                variables: { input: { bulkTradeRunId: bulkTradeRun.id, transition: 'cancel' } },
              });
              showToast({ severity: 'info', message: t('cancelTriggeredMessage') });
              setCancelConfirmationOpen(false);
            }}
            loading={loading}
            bodyText={t('cancelConfirmationDialog')}
            title={t('cancelBulkTradeRunTrades')}
          />

          <ConfirmationModal
            open={discrepancyConfirmationOpen}
            onCancel={() => setDiscrepancyConfirmationOpen(false)}
            onConfirm={() => {
              setDiscrepancyConfirmationOpen(false);
              goNext();
            }}
            title={t('discrepancyConfirmationDialog.title')}
            bodyText={t('discrepancyConfirmationDialog.text')}
          />
        </>
      )}
    </Box>
  );
};

export default BulkTrader;
export { BulkTraderBreadcrumb } from './bulkTraderBreadcrumb';
