import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {
  FormControl, InputLabel, ListItemText, useTheme,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, permissions: readonly string[], theme: any) {
  return {
    fontWeight:
    permissions.indexOf(name) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
}

const FETCH_DASHBOARDS = gql`
  query fetchDashboards {
    fetchDashboards {
      dashboards {
        id
        name
      }
    }
  }
`;

export default function DashboardsSelect(
  { label, selectedDashboards, onChange }:
  { label: string, selectedDashboards: string[], onChange: (event: any) => void },
) {
  const theme = useTheme();
  const { data } = useQuery(FETCH_DASHBOARDS);
  const [dashboards, setDashboards] = useState<any[]>([]);

  useEffect(() => {
    if (data?.fetchDashboards) setDashboards(data.fetchDashboards.dashboards);
  }, [data]);

  return (
    <FormControl fullWidth>
      <InputLabel shrink htmlFor="select-multiple-native" variant='outlined'>
      {label}
      </InputLabel>
      { dashboards.length > 0 && (
        <Select
          multiple
          notched={true}
          value={selectedDashboards}
          onChange={onChange}
          label={label}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={dashboards.find((x: any) => x.id === value)?.name } />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {
            dashboards.map((x: any) => (
              <MenuItem key={x.id} value={ x.id } style={getStyles(x, selectedDashboards, theme)}>
                <ListItemText primary={x.name} />
              </MenuItem>
            ))
          }
        </Select>
      )}
    </FormControl>
  );
}
