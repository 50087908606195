import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Card, CardContent, Form, MenuItem, SelectField,
} from '../../../2-component';
import { Box, Grid, Typography } from '../../../1-primative';
import { translateBackend } from '../../../../assets/i18n/config';

const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        riskQuestion1
        timeHorizon
        suitabilityScore
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
        suitabilityScore
      }
    }
  }
`;

export const GoalSuitability = ({ objectId, options }: { objectId: string, options: any }) => {
  const { t } = useTranslation(['client', 'shared']);
  const [goal, setGoal] = useState<any>({});
  const [edited, setEdited] = useState(false);

  const { data } = useQuery(FETCH_GOAL, {
    variables: { goalId: objectId },
    fetchPolicy: 'no-cache',
  });

  const [updateGoal] = useMutation(UPDATE_GOAL, {
    onCompleted: (d: any) => {
      setEdited(false);
      setGoal((prev: any) => ({ ...prev, suitabilityScore: d.updateGoal.goal.suitabilityScore }));
    },
    variables: {
      input: {
        goalId: objectId,
        riskQuestion1: goal.riskQuestion1,
        timeHorizon: goal.timeHorizon,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setGoal(data.fetchGoal.goal);
    }
  }, [data]);

  const update = (value: string, field: string) => {
    setEdited(true);
    setGoal((prev: any) => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <Typography variant='headingSmall' mb={2}>{options.customTitle ? translateBackend(options.customTitle) : t('suitability.title')}</Typography>
      <Card>
        <CardContent>
          <Form onSubmit={updateGoal}>
            <Grid container spacing={2}>
              { options.timeHorizon.enabled && (
                <Grid item xs={12} md={6}>
                  <SelectField
                    label={translateBackend(options.timeHorizon.label)}
                    value={goal.timeHorizon || ''}
                    onChange={(event: any) => update(event.target.value, 'timeHorizon')}
                    fullWidth
                  >
                    <MenuItem value='VERY_SHORT_TERM'>{t('timeHorizonOptions.VERY_SHORT_TERM')}</MenuItem>
                    <MenuItem value='SHORT_TERM'>{t('timeHorizonOptions.SHORT_TERM')}</MenuItem>
                    <MenuItem value='MID_TERM'>{t('timeHorizonOptions.MID_TERM')}</MenuItem>
                    <MenuItem value='LONG_TERM'>{t('timeHorizonOptions.LONG_TERM')}</MenuItem>
                  </SelectField>
                </Grid>
              )}
              { options.riskQuestion1.enabled && (
                <Grid item xs={12} md={6}>
                  <SelectField
                    label={translateBackend(options.riskQuestion1.label)}
                    value={goal.riskQuestion1 || ''}
                    onChange={(event: any) => update(event.target.value, 'riskQuestion1')}
                    fullWidth
                  >
                    <MenuItem value='LEVEL_1'>{t('edit.riskQuestion1Options.LEVEL_1')}</MenuItem>
                    <MenuItem value='LEVEL_2'>{t('edit.riskQuestion1Options.LEVEL_2')}</MenuItem>
                    <MenuItem value='LEVEL_3'>{t('edit.riskQuestion1Options.LEVEL_3')}</MenuItem>
                    <MenuItem value='LEVEL_4'>{t('edit.riskQuestion1Options.LEVEL_4')}</MenuItem>
                    <MenuItem value='LEVEL_5'>{t('edit.riskQuestion1Options.LEVEL_5')}</MenuItem>
                  </SelectField>
                </Grid>
              )}
            </Grid>
            <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
              <Box>
                <Badge label={`${t('suitabilityScore')}: ${goal.suitabilityScore}`} />
              </Box>
              <Button type='submit' label={t('shared:update')} disabled={!edited}/>
            </Box>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};
