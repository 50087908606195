import {
  Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, ListItem, TextField, styled, Stack,
} from '@mui/material';
import {
  CheckCircle, Close, Download, SvgIconComponent, Warning,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IncompleteFormAgreement } from 'interfaces';
import {
  useContext,
  useEffect,
  useState,
  MutableRefObject,
} from 'react';
import FormModal from 'components/modals/formModal';
import DroppableFileInput from 'pages/client/components/droppableFileInput';
import {
  Box,
  Button, Dialog, DialogContent, DialogTitle, Skeleton, StatusTag, Typography, colors, PdfViewer,
  DialogFooter,
  IconButton,
} from '../../../..';
import MugImage from '../../../../../assets/images/custom/mug.svg';
import { downloadHtmlAsPdf } from '../../../../../util/downloadHtmlAsPdf';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export type SignableDocumentState = IncompleteFormAgreement & {
  signed: boolean
};

export type UploadableDocumentState = IncompleteFormAgreement & {
  pending?: boolean
  uploaded: boolean
};

export const DocumentsVisual = ({
  options, signableDocuments, uploadableDocuments,
  loading, previewLoading,
  signFormAgreementPending,
  signAgreement, doTheUpload, continueFunc,
  downloadTemplatePDF, uploadInProgress,
  signableDocumentIndex, setSignableDocumentIndex,
  uploadableDocumentIndex, setUploadableDocumentIndex,
  html, pdfUrl, viewNext, signAll,
}: {
  options: any, signableDocuments: SignableDocumentState[], uploadableDocuments: UploadableDocumentState[],
  loading: boolean, previewLoading: boolean,
  signFormAgreementPending?: boolean,
  signAgreement: () => void, doTheUpload: (file: File, name: string) => void, continueFunc: () => void,
  downloadTemplatePDF: (type: 'signable' | 'uploadable') => void, uploadInProgress?: boolean,
  signableDocumentIndex?: number, setSignableDocumentIndex: (newIndex?: number) => void,
  uploadableDocumentIndex?: number, setUploadableDocumentIndex: (newIndex?: number) => void,
  html: string, pdfUrl?: string, viewNext: () => void, signAll: () => Promise<void>,
}) => {
  const { t } = useTranslation('client');
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [ref, setRef] = useState<MutableRefObject<HTMLDivElement | undefined>>();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [failedPdf, setFailedPdf] = useState(false);

  const scrollToTheTop = () => {
    if (ref && ref.current) ref?.current?.scrollTo(0, 0);
  };

  const onPdfError = () => {
    setLoadingPdf(false);
    setFailedPdf(true);
  };
  const onPdfFulfilled = () => {
    setLoadingPdf(false);
    setFailedPdf(false);
  };

  const numUnsignedDocuments = signableDocuments.filter((x) => !x.signed).length;
  const numUnuploadedDocuments = uploadableDocuments.filter((x) => !x.uploaded).length;

  const signAllAndContinue = async () => {
    sendAnalytic(ovAnalyticsEvents.workflowAgreementsContinueButtonSelect);
    await signAll();
    continueFunc();
  };

  const BorderlessTableCell = styled(TableCell)({ border: 'none' });
  const OverlineTypography = styled(Typography)({ textTransform: 'uppercase', color: colors.neutral600 });

  const DocumentTableHeader = () => <TableHead>
    <TableRow>
      <TableCell sx={{ width: '100%' }}><OverlineTypography variant='labelSmall'>{t('document')}</OverlineTypography></TableCell>
      <TableCell sx={{ minWidth: '140px' }}><OverlineTypography variant='labelSmall'>{t('status')}</OverlineTypography></TableCell>
    </TableRow>
  </TableHead>;

  const getDocumentText = (amountOfDocument: number): string => {
    if (amountOfDocument === 0) return t('continue');
    if (amountOfDocument === 1) return t('signAndContinue');
    return t('signAllAndContinue');
  };
  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      {
        loading && !signFormAgreementPending && (
          <Skeleton width='100%' height='400px' variant='rectangular' />
        )
      }
      {!loading && (signableDocuments.length === 0 && uploadableDocuments.length === 0) && (
        <Box display='flex' alignItems='center' flexDirection='column'>
          <img src={MugImage} alt="mug-icon" />
          <Typography data-testid='nothing-to-sign' variant='bodyLarge' sx={{
            mt: 1, maxWidth: '500px', textAlign: 'center', mb: 3,
          }}>{t('nothingToSign')}</Typography>
        </Box>
      )}

      {signableDocuments.length > 0 && (
        <>
          <Table sx={{ mb: 3 }}>
            <DocumentTableHeader />
            <TableBody data-testid='documents-table'>
              {signableDocuments.map((document, index) => (
                <TableRow
                  data-testid={`document-${index}`}
                  key={index} hover sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSignableDocumentIndex(index);
                    if (document.isPrefilledPdfEnabled) {
                      setLoadingPdf(true);
                      setFailedPdf(false);
                    }
                  }}
                >
                  <BorderlessTableCell>{translateBackend(document.translatedDisplayName)}</BorderlessTableCell>
                  <BorderlessTableCell>
                    <StatusTag
                      color={document.signed ? 'positive' : 'warning'}
                      icon={document.signed ? CheckCircle : Warning}
                      label={document.signed ? t(options.requireSigning ? 'signed' : 'reviewed') : t(options.requireSigning ? 'notSigned' : 'notReviewed')}
                    />
                  </BorderlessTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {options.allowBulk && options.requireSigning && numUnuploadedDocuments > 0 && (
            <Box display='flex' justifyContent='end'>
              <Button label={t('signAll')} disabled={numUnsignedDocuments === 0} onClick={signAll} />
            </Box>
          )}
        </>
      )}

      {uploadableDocuments && uploadableDocuments.length > 0 && (
        <Table sx={{ mt: 2, mb: 3 }}>
          <DocumentTableHeader />
          <TableBody>
            {uploadableDocuments.map((document, index) => (
              <TableRow
                data-testid={`document-${index}`}
                key={index} hover sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!document.pending) { setUploadableDocumentIndex(index); }
                }}
              >
                <BorderlessTableCell>{translateBackend(document.translatedDisplayName)}</BorderlessTableCell>
                <BorderlessTableCell>
                  {document.pending ? (
                    <StatusTag
                      color='informative'
                      icon={CircularProgress as SvgIconComponent}
                      label={t('pending')}
                    />
                  ) : (
                    <StatusTag
                      color={document.uploaded ? 'positive' : 'warning'}
                      icon={document.uploaded ? CheckCircle : Warning}
                      label={document.uploaded ? t(options.requireSigning ? 'uploaded' : 'reviewed') : t(options.requireSigning ? 'upload' : 'notReviewed')}
                    />
                  )}
                </BorderlessTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Box display='flex' justifyContent='end' sx={{ mt: 7 }}>
        {options.allowBulk && options.requireSigning && numUnuploadedDocuments === 0 ? (
          <Button
            label={getDocumentText(numUnsignedDocuments)}
            disabled={loading}
            onClick={signAllAndContinue}
          />
        ) : (
          <Button
            data-testid="continue-btn"
            label={t('continue')}
            disabled={(
              loading
              || (options.requireSigning && (numUnsignedDocuments > 0 || numUnuploadedDocuments > 0))
            )}
            type='submit'
            onClick={continueFunc}
          />
        )}
      </Box>

      {signableDocumentIndex !== undefined && (
        <Dialog open={true} fullWidth maxWidth='lg' onClose={() => setSignableDocumentIndex(undefined)}>
          <DialogTitle>
            <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
              {translateBackend(signableDocuments[signableDocumentIndex].translatedDisplayName)}
              <IconButton data-testid="close-btn" onClick={() => setSignableDocumentIndex(undefined)}>
                <Close />
              </IconButton>
            </Box></DialogTitle>
          <DialogContent onRefSet={setRef}>
            <Box height='100%' overflow='auto'>
              {previewLoading && (
                <Box display='flex' justifyContent='center' width='552px'><CircularProgress /></Box>
              )}
              {html && (
                <Typography sx={{ lineHeight: 'inherit' }}>
                  <span className="content" dangerouslySetInnerHTML={{
                    __html: html,
                  }}></span>
                </Typography>
              )}
              {!html && pdfUrl && <PdfViewer pdfUrl={pdfUrl} onError={onPdfError} onFulfilled={onPdfFulfilled} />}
            </Box>
          </DialogContent>
          <DialogFooter>
            <Box display='flex' justifyContent='flex-end' m={1}>
              {signableDocumentIndex === signableDocuments.length - 1 ? (
                !signableDocuments[signableDocumentIndex].signed ? (
                  <Box display='flex' justifyContent='space-between' gap={2} width='100%'>
                    {pdfUrl && (
                      <Button data-testid="download-template-btn" variant='outlined'
                        label={t('client:downloadTemplate')}
                        onClick={() => downloadTemplatePDF('signable')}
                        disabled={uploadInProgress}
                      />
                    )}
                    {html && (
                      <Button variant='outlined'
                        data-testid="download-html-as-pdf-btn"
                        leadingIcon={Download}
                        label={t('client:documents.downloadHtmlAsPdf')}
                        onClick={() => downloadHtmlAsPdf(html, translateBackend(signableDocuments[signableDocumentIndex].translatedDisplayName))}
                        disabled={uploadInProgress}
                      />
                    )}
                    <Button data-testid='preview-sign-btn' disabled={previewLoading || loadingPdf || failedPdf} label={t(options.requireSigning ? 'sign' : 'review')} onClick={() => {
                      signAgreement();
                      setSignableDocumentIndex(undefined);
                    }} />
                  </Box>
                ) : (
                  <Button disabled={previewLoading} label={t('done')} onClick={() => {
                    viewNext();
                    setSignableDocumentIndex(undefined);
                  }} />
                )
              ) : (
                !signableDocuments[signableDocumentIndex].signed ? (
                  <Box display='flex' justifyContent='space-between' gap={2} width='100%'>
                    {pdfUrl && (
                      <Button variant='outlined'
                        data-testid="download-template-btn"
                        label={t('client:downloadTemplate')}
                        onClick={() => downloadTemplatePDF('signable')}
                        disabled={uploadInProgress}
                      />
                    )}
                    {html && (
                      <Button variant='outlined'
                        data-testid="download-html-as-pdf-btn"
                        leadingIcon={Download}
                        label={t('client:documents.downloadHtmlAsPdf')}
                        onClick={() => downloadHtmlAsPdf(html, translateBackend(signableDocuments[signableDocumentIndex].translatedDisplayName))}
                        disabled={uploadInProgress}
                      />
                    )}
                    <Button
                      data-testid="action-view-next-btn"
                      disabled={previewLoading || loadingPdf || failedPdf}
                      label={t(options.requireSigning ? 'signAndViewNext' : 'reviewNext')}
                      onClick={() => {
                        scrollToTheTop();
                        signAgreement();
                      }} />
                  </Box>
                ) : (
                  <Button data-testid="view-next-btn" disabled={previewLoading} label={t('viewNext')} onClick={() => {
                    scrollToTheTop();
                    viewNext();
                  }} />
                )
              )}
            </Box>
          </DialogFooter>
        </Dialog>
      )}

      {uploadableDocumentIndex !== undefined && uploadableDocuments && (
        <>
          <Dialog open={true} onClose={() => setUploadableDocumentIndex(undefined)}>
            <DialogTitle>{translateBackend(uploadableDocuments[uploadableDocumentIndex].translatedDisplayName)}</DialogTitle>
            <DialogContent>
              <Box width='550px' display='flex' justifyContent='center'>
                {!uploadableDocuments[uploadableDocumentIndex].uploaded ? (
                  <Typography m={5}>{t('documents.cantSignDigitallyPleaseUpload')}</Typography>
                ) : (
                  <Typography m={5}>{t('documents.documentHasBeenUploaded')}</Typography>
                )}
              </Box>
              <Stack spacing={2}>
                {uploadableDocuments[uploadableDocumentIndex].templateUrl && (
                  <Button fullWidth variant='outlined'
                    data-testid="download-template-btn"
                    label={t('client:downloadTemplate')}
                    onClick={() => downloadTemplatePDF('uploadable')}
                    disabled={uploadInProgress}
                  />
                )}
                {
                  !uploadableDocuments[uploadableDocumentIndex].uploaded ? (
                    <Button fullWidth data-testid="upload-signed-btn" label={t(options.requireSigning ? 'uploadSigned' : 'review')} onClick={() => {
                      setUploadModalOpen(true);
                    }} />
                  ) : (
                    <Button fullWidth label={t('done')} onClick={() => {
                      setUploadableDocumentIndex(undefined);
                    }} />
                  )
                }
              </Stack>
            </DialogContent>
          </Dialog>
          {uploadModalOpen && (
            <DocumentUpload
              documentName={translateBackend(uploadableDocuments[uploadableDocumentIndex].translatedDisplayName)}
              doTheUpload={doTheUpload}
              uploadInProgress={uploadInProgress}
              handleClose={() => setUploadModalOpen(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default DocumentsVisual;

const DocumentUpload = ({
  documentName, doTheUpload, uploadInProgress, handleClose,
}: {
  documentName: string,
  doTheUpload: (file: File, name: string) => void,
  uploadInProgress?: boolean,
  handleClose: () => void,
}) => {
  const [file, setFile] = useState<File>();
  const [name, setName] = useState('');
  const [nameIsAutomatic, setNameIsAutomatic] = useState(false);

  const { t } = useTranslation('client');

  useEffect(() => {
    if (file?.name) {
      if (!name || nameIsAutomatic) { setName(file.name); setNameIsAutomatic(true); }
    } else {
      setName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <FormModal
      onSubmit={async (event) => {
        event.preventDefault();
        if (!file) return;
        doTheUpload(file, name);
        handleClose();
      }}
      loading={false}
      title={`${documentName} ${t('documentUpload.documentUpload')}`}
      open={true}
      handleClose={handleClose}
      formButton={'upload document'}
      disabled={(!file || !name) || uploadInProgress}
    >
      <ListItem>
        <DroppableFileInput
          onFileChosen={(dropFile: File) => {
            setFile(dropFile);
          }}
        />
      </ListItem>
      <ListItem>
        <TextField fullWidth
          label={t('documentUpload.documentName')}
          value={name}
          onChange={(e) => { setName(e.target.value.trim()); setNameIsAutomatic(false); }}
        />
      </ListItem>
    </FormModal>
  );
};
