import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { isNull } from 'lodash/fp';
import { Box, Typography } from '../../../../1-primative';
import {
  Button,
  Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
} from '../../../../2-component';
import { CopyId, StateStepper } from '../../../../3-pattern';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { formatMoneyValue } from '../../../../../util';
import { usePermissions } from '../../../../../providers/userContextProvider';
import { useLocalization } from '../../../../../util/useLocalization';

enum TransferStates {
  REVIEWING = 'REVIEWING',
  INITIATED = 'INITIATED',
  READY = 'READY',
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
  RECONCILED = 'RECONCILED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  ACTIVE = 'ACTIVE',
}

export const TransferModal = ({
  transfer, setOpen, open, transition, options, onTransition,
}: {
  transfer: any, setOpen: any, open: any, transition: (transferId: string, isScheduled: boolean, variables: any) => void, options: any, onTransition?: () => void,
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const [activeStep, setActiveStep] = useState(0);
  const { sys } = useThemeTokens();
  const { permissions } = usePermissions();
  const { localizedDate } = useLocalization();

  const coreStates = [
    {
      name: TransferStates.INITIATED,
    },
    {
      name: TransferStates.READY,
    },
    {
      name: TransferStates.REQUESTED,
    },
    {
      name: TransferStates.PROCESSING,
    },
    {
      name: TransferStates.RECONCILED,
    },
  ];

  const activeStepFinder = (state: string) => {
    switch (state) {
      case TransferStates.INITIATED:
        return 0;
      case TransferStates.READY:
        return 1;
      case TransferStates.REQUESTED:
        return 2;
      case TransferStates.PROCESSING:
        return 3;
      case TransferStates.RECONCILED:
        return 4;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (transfer && transfer.stateChanges) {
      let step = activeStepFinder(transfer.state);
      if (isNull(step)) {
        step = activeStepFinder(transfer.stateChanges.slice(-1)[0].from);
      }
      setActiveStep(step || 0);
    }
  }, [transfer]);

  const transitions = [
    {
      name: 'review',
      from: [TransferStates.INITIATED, TransferStates.READY, TransferStates.REQUESTED, TransferStates.PROCESSING],
      error: false,
      show: options.canTransition,
      tonal: true,
    },
    {
      name: 'initiate',
      from: [TransferStates.REVIEWING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'ready',
      from: [TransferStates.INITIATED, TransferStates.REVIEWING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'request',
      from: [TransferStates.READY, TransferStates.REVIEWING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'process',
      from: [TransferStates.REQUESTED, TransferStates.REVIEWING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'reconcile',
      from: [TransferStates.REQUESTED, TransferStates.PROCESSING],
      error: false,
      show: options.canTransition,
    },
    {
      name: 'fail',
      from: [TransferStates.PROCESSING, TransferStates.REQUESTED],
      error: true,
      show: options.canTransition && options.canCancelPending,
    },
    {
      name: 'cancelReconciled',
      from: [TransferStates.RECONCILED],
      error: true,
      show: options.canTransition && options.canCancelPending,
    },
    {
      name: 'cancel',
      from: [TransferStates.INITIATED, TransferStates.READY, TransferStates.REVIEWING, TransferStates.PROCESSING],
      error: true,
      show: options.canTransition && options.canCancelPending,
    },
    {
      name: 'cancelScheduled',
      from: [TransferStates.ACTIVE],
      error: true,
      show: options.canTransition && options.canCancelScheduled,
    },
  ];

  if (!transfer.state) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {t('components:transfers.transferDetails')}
          <Box display='flex' justifyContent='end' alignItems='center'>
            <CopyId id={transfer.id} />
            <Box ml={1} />
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' alignItems='center'>
              <Typography variant='displayMedium'>{formatMoneyValue(transfer.amountCents)}</Typography>
              <Box sx={{
                background: sys.color.background, borderRadius: sys.borderRadius.round, ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '32px', height: '32px',
              }}>
                {
                  transfer.type === 'DEPOSIT' ? (
                    <ArrowDownwardIcon sx={{ color: sys.color.onSurfaceVariant }} />
                  ) : (
                    <ArrowUpwardIcon sx={{ color: sys.color.onSurfaceVariant }} />
                  )
                }
              </Box>
            </Box>
            <Typography variant='bodySmall' colorVariant='variant'>{t(`transaction.types.${transfer.type}`)}</Typography>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='flex-end' minWidth='250px'>
            { transfer.source && (
              <Box display='flex' justifyContent='space-between' width='100%'>
                <Typography variant='bodySmall'>{t('components:transfers.source')}</Typography>
                <Typography variant='bodySmall' weight='bold'>
                  {transfer.source === 'USER'
                    ? `${transfer.bankAccount.name} (${transfer.bankAccount.bankAccountNumber})`
                    : t(`components:transferSources.${transfer.source}`)}
                  </Typography>
              </Box>
            )}
            <Box display='flex' justifyContent='space-between' width='100%'>
              <Typography variant='bodySmall'>{t('components:transfers.date')}</Typography>
              <Typography variant='bodySmall' weight='bold'>{localizedDate(transfer.scheduledDate ? transfer.scheduledDate : transfer.date)}</Typography>
            </Box>
            { transfer.frequency && (
              <Box display='flex' justifyContent='space-between' width='100%'>
                <Typography variant='bodySmall'>{t('components:transfers.frequency')}</Typography>
                <Typography variant='bodySmall' weight='bold'>{t(`components:transfersTable.frequencyOption.${transfer.frequency}`)}</Typography>
              </Box>
            )}
          </Box>
        </Box>
        { transfer.stateChanges && (
          <Box borderTop={`1px solid ${sys.color.outlineVariant}`} pt={2} mt={2}>
            <Typography variant='bodyMedium' weight='bold' sx={{ mb: 2 }}>{t('components:transfers.transferProgress')}</Typography>
            <StateStepper
              steps={coreStates}
              failed={[TransferStates.CANCELED, TransferStates.FAILED, TransferStates.REVIEWING].includes(transfer.state)}
              activeStep={activeStep}
              currentState={transfer.state}
              stateChanges={[{ to: TransferStates.INITIATED, createdAt: transfer.createdAt }, ...transfer.stateChanges]}
            />
          </Box>
        )}
      </DialogContent>
      {
        permissions.includes('transition:transfer') && transitions.filter((tr) => tr.from.includes(transfer.state) && tr.show).length > 0 && (
          <DialogFooter>
            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <Box display='flex'>
                {transitions.filter((tr) => tr.from.includes(transfer.state) && tr.error && tr.show).map((tr) => (
                  <Button key={tr.name} variant='tonal' color='destructive' label={t(`transferTransitions.${tr.name}`)} onClick={() => {
                    transition(
                      transfer.id,
                      transfer.state === TransferStates.ACTIVE,
                      {
                        transition: tr.name,
                      },
                    );
                    setOpen(false);
                  }} sx={{ mr: 1 }} />
                ))}
              </Box>
              <Box display='flex'>
                {transitions.filter((tr) => tr.from.includes(transfer.state) && !tr.error && tr.show).map((tr) => (
                  <Button key={tr.name} label={t(`transferTransitions.${tr.name}`)} onClick={() => {
                    transition(
                      transfer.id,
                      transfer.state === TransferStates.ACTIVE,
                      {
                        transition: tr.name,
                      },
                    );
                    setOpen(false);
                  }} sx={{ ml: 1 }} variant={tr.tonal ? 'tonal' : 'filled'} />
                ))}
              </Box>
            </Box>
          </DialogFooter>
        )
      }
    </Dialog>
  );
};
