import { gql } from '@apollo/client';

export const CREATE_WITHDRAW_TRANSFER = gql`
  mutation createWithdrawTransfer($input: CreateWithdrawTransferInput!) {
    createWithdrawTransfer(input: $input) {
      transfer {
        id
      }
    }
  }
`;

export const CREATE_WITHDRAW_ALL_TRANSFER = gql`
  mutation withdrawAllFromSubAccount($input: WithdrawAllSubAccountInput!) {
    withdrawAllFromSubAccount(input: $input) {
      transfer {
        id
      }
    }
  }
`;
